// Core
import { call, put } from 'redux-saga/effects';

// Instruments
import { api } from '../../../../helpers/api';
import { uiActions } from '../../../ui/actions';

export function* callRestorePasswordWorker ({ payload: email }) {
    try {
        yield put(uiActions.enableFetching());
        const response = yield call(fetch, `${api}/v1/restore_password?email=${encodeURIComponent(email)}`,{
            method: 'GET',
        });
        const { error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }

        yield put(uiActions.setUiState('isPasswordRestored', true));
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
        yield put(uiActions.setUiState('isPasswordRestored', false));
        yield put(uiActions.setUiState('passwordRestoreError', error.message));
    } finally {
        yield put(uiActions.disableFetching());
    }
}
