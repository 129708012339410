// Core
import { useState, useRef } from "react";

// Components
import { MessageForm } from "../../../MessageForm";
import Avatar from '../../../common/Avatar/index';
import Feather from '../../../icons/Feather';

// Instruments
import { splitBySpace } from "../../../../helpers/helpers";
import moment from 'moment/moment';
import { isNil, isEmpty, includes } from 'ramda';

export const Pitch = ({ data, onMessageFormSubmit, approvePitch }) => {
    /* Ref */
    const timelineRef = useRef(null);

    /* State */
    const [isOpen, setIsOpen] = useState(true);
    const [isTimeline, setIsTimeline] = useState(false);
    const [isForm, setIsForm] = useState(false);

    const {
        copywriter_id,
        isPitchAccepted,
        logs,
        messages,
        notifyList,
        project_id,
        job_type,
        user_id,
        users,
        writer_avatar_url,
        writer_first_name,
        writer_last_name,
        pitch: {
            pitch_about,
            pitch_body,
            pitch_date,
            pitch_id,
            pitch_status,
        }} = data;

    /* Actions */
    const onFormToggle = () => {
        setIsForm(state => !state);
    };
    const onFormSubmit = (data) => {
        onMessageFormSubmit(data);
    };
    const showFullTimeline = () => {
        setIsOpen(true);
    };
    const showTimeline = () => {
        setIsOpen(true);
        setIsTimeline(true);
    };
    const onPitchApprove = () => {
        approvePitch(pitch_id);
    };
    const hideDuplicatedLabels = () => {
        if ( !isNil(timelineRef.current) ) {
            const labels = timelineRef.current.querySelectorAll('.gac-time');

            if ( labels.length ) {
                labels.forEach((item, i) => {
                    const time = item.dataset.time;
                    if ( !isNil(labels[i-1]) && labels[i-1].dataset.time === time ) {
                        item.style.display = 'none';
                    }
                });
            }
        }
    };

    /* Html */
    const getTimeline = () => {
        let datalogs = [];

        if ( pitch_status === 'ACCEPTED' && !isEmpty(logs) && !isNil(logs) ) {
            datalogs = logs.map(({description, create_date}) => {
                return(
                    <div key = { create_date }>
                        <div className = 'gac-time' data-time={moment(create_date).format('MMM-D')}>{ moment(create_date).format('MMM D') }</div>
                        <div className = 'gac-status-changed'>
                            <div className = 'gac-line'/>
                            <div className = 'gac-status-time'>{moment(create_date).format('h:mm A')}</div>
                            <div className = 'gac-status-text'>
                                { description }
                            </div>
                        </div>
                    </div>
                );
            });
        }

        let dataMessages = messages;
        if ( !isOpen ) { dataMessages = dataMessages.filter((o,i) => i >= messages.length - 3); }

        let timeline = dataMessages
            .map(({ message_id, date_added, files, recipient_type, recipient_name, recipient_avatar, sender_type, sender_uid, sender_name, sender_avatar, text, notify_user_uid}) => {

                let filesData = [];
                let usersList = [];

                let note_avatar = '';
                let note_first_name = '';
                let note_last_name = '';

                const isSenderAdmin = sender_type === 'admin';
                const isSenderUser = sender_type === 'user';
                const isSenderMe = sender_uid === user_id;

                const isSenderWriter = sender_type === 'writer';
                const isRecipientWriter = recipient_type === 'writer';

                const isSenderClient = sender_type === 'client';
                const isRecipientClient = recipient_type === 'client';

                if ( files ) {
                    filesData = files.map(({id, filename, url}) => {
                        return(
                            <div key = { id } className = 'gac-message-file'>
                                <i className = { `${ filename.split('.').slice(-1)[0] }` }/>
                                <a target = '_blank' rel = 'noopener noreferrer' className = 'gac-message-file-name' href = { url }>{ filename }</a>
                            </div>
                        );
                    })
                }
                if ( !isNil(notify_user_uid) && !isEmpty(notifyList) ) {
                    usersList = notify_user_uid.map(id => {
                        let avatar_url, first_name, last_name = '';
                        const user = notifyList.filter(o => o.user_id === id)[0];
                        if ( isNil(user) ) {
                            first_name = 'User';
                        } else {
                            ({ avatar_url, first_name, last_name } = user);
                        }

                        return(
                            <div key = { id } className = 'gac-notify-item'>
                                <Avatar url = { avatar_url } name = {[ first_name, last_name ]}/>
                            </div>
                        );
                    });
                }

                if ( isPitchAccepted && isNil(recipient_type) ) {
                    const user = users.filter(o => o.user_id === sender_uid )[0];
                    if ( !isNil(user) ) {
                        ({ avatar_url: note_avatar, first_name: note_first_name, last_name: note_last_name } = user);
                    }
                }

                return <div key = { message_id }>{ isPitchAccepted && isNil(recipient_type)
                    ? <div>
                        <div className = 'gac-time' data-time={moment(date_added).format('MMM-D')}>{ moment(date_added).format('MMM D') }</div>
                        <div className = 'gac-note'>
                            <div className = 'gac-note-head'>
                                <i className = 'gac-note-icon'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="24" xmlSpace="preserve"><path fill="#484B58" d="M11.6 0c.3 0 .6.1.8.3l6.3 6.6c.2.2.3.4.3.8v13.1c0 1.8-1.4 3.2-3.2 3.2H3.2C1.4 24 0 22.5 0 20.8V3.2C0 1.4 1.4 0 3.2 0h8.4zm-1 1.1H3.2c-1.1 0-2 .9-2.1 2V20.7C1.1 22 2 23 3.2 23h12.7c1.1 0 2.1-1 2.1-2.2v-12h-6.3c-.6 0-1.1-.5-1.1-1.1V1.1zM14.3 12c.3 0 .5.3.5.5s-.3.5-.5.5H4.8c-.3 0-.5-.3-.5-.5s.2-.5.5-.5h9.5zM7.9 7.7c.3 0 .5.3.5.5 0 .3-.3.5-.5.5H4.8c-.3 0-.5-.3-.5-.5 0-.3.3-.5.5-.5h3.1zm3.7-6.6v6.6h6.3l-6.3-6.6z"/></svg>
                                </i>
                                <Avatar url = { note_avatar } name = {[ note_first_name, note_last_name ]}/>
                                <div className = 'gac-note-time'>{ moment(date_added).format('h:mm A') }</div>
                            </div>
                            <div className="gac-note-content">{ text }</div>
                            { usersList.length ? <div className = 'gac-notify-list'>{ usersList }</div> : null }
                        </div>
                    </div>
                    : <div>
                        <div className = 'gac-time' data-time={moment(date_added).format('MMM-D')}>{ moment(date_added).format('MMM D') }</div>
                        <div id={`message-${message_id}`} className = { `gac-message-block ${ isSenderMe ? 'gac-message-right' : ''}` }>
                            <div className = 'gac-message-wrap'>
                                <div className = 'gac-message-head'>
                                    { isSenderUser && <>
                                        <div className = 'gac-message-head-from'>
                                            <Avatar url = { sender_avatar } name = {[ splitBySpace(sender_name)[0], splitBySpace(sender_name)[1] ]}/>
                                        </div>
                                        <i className="gac-message-head-arrow"/></> }
                                    <div className = 'gac-user-meta'>
                                        <i className = { `gac-user-meta-icon ${ isRecipientWriter || isSenderWriter ? 'gac-writer' : 'gac-client' }` }/>
                                        { isSenderAdmin
                                            ? null
                                            : isSenderClient || isSenderWriter
                                                ? sender_avatar
                                                    ? <img className="gac-user-meta-img" src={ sender_avatar } alt="Sender avatar"/>
                                                    : <div className = 'gac-no-user-img'/>
                                                : isRecipientClient || isRecipientWriter
                                                    ? recipient_avatar
                                                        ? <img className="gac-user-meta-img" src={ recipient_avatar } alt="Recipient avatar"/>
                                                        : <div className = 'gac-no-user-img'/>
                                                    : null }
                                        { isSenderAdmin || isSenderClient || isSenderWriter
                                            ? <div className = 'gac-user-meta-name' style={{ marginLeft: isSenderAdmin ? 8 : null }}>{ sender_name }.</div>
                                            : isRecipientClient || isRecipientWriter
                                                ? <div className = 'gac-user-meta-name'>{ recipient_name }.</div>
                                                : null }
                                    </div>
                                </div>
                                <div className='gac-message-wrap-2'>
                                    <div className = {`gac-message-content ${ isEmpty(text) ? 'gac-empty' : ''}`}>
                                        <div className = 'gac-message-content-wrap'>{ text }</div>
                                        <div className = 'gac-message-time'>{ moment(date_added).format('h:mm A') }</div>
                                    </div>
                                    { filesData.length ? <div className = 'gac-message-files'>{ filesData }</div> : null }
                                    { usersList.length ? <div className = 'gac-notify-list'>{ usersList }</div> : null }
                                </div>
                            </div>
                        </div>
                    </div> }
                </div> ;
            });
        if ( pitch_body ) {
            timeline.unshift(<div key = { `body-${pitch_id}` }>
                <div className = 'gac-time' data-time={moment(pitch_date).format('MMM-D')}>{ moment(pitch_date).format('MMM D') }</div>
                <div className = 'gac-message-block'>
                    <div className = 'gac-message-wrap'>
                        <div className = 'gac-message-head'>
                            <div className = 'gac-user-meta'>
                                <i className = 'gac-user-meta-icon gac-writer'/>
                                { writer_avatar_url ? <img className="gac-user-meta-img" src={ writer_avatar_url } alt = 'Writer avatar'/> : <div className = 'gac-no-user-img'/> }
                                <div className = 'gac-user-meta-name'>{`${writer_first_name} ${writer_last_name}`}</div>
                            </div>
                        </div>
                        <div className = 'gac-message-content'>
                            <div className = 'gac-message-content-wrap'>
                                { pitch_body }
                            </div>
                            <div className = 'gac-message-time'>{ moment(pitch_date).format('h:mm A') }</div>
                        </div>
                    </div>
                </div>
            </div>);
        }
        timeline = [ ...timeline, ...datalogs ];
        timeline = timeline.sort((a, b) => {
            if ( includes('body',a.key) && !includes('body',b.key) ) {
                return -1;
            } else if ( !includes('body',a.key) && includes('body',b.key) ) {
                return 1;
            }
            return (a.key).localeCompare(b.key);
        });
        if ( !isOpen ) { timeline = timeline.filter((o,i) => i >= timeline.length - 3); }
        return timeline;
    };
    const timeline = getTimeline();

    hideDuplicatedLabels();

    return <div key = { pitch_id } className = 'gac-pitch-item'>
        <div className = 'gac-pitch-content'>
            <div className = 'gac-pitch-head'>
                { writer_avatar_url
                    ? <img className = 'gac-writer-img' src={ writer_avatar_url } alt = 'Writer avatar'/>
                    : <div className = 'gac-no-writer-img'/> }
                <div className = 'gac-writer-name'>{`${writer_first_name} ${writer_last_name}`}</div>
                <div className = 'gac-writer-icons'>
            <span className = 'gac-writer-icon'>
                <i className='gac-icon-1'/>
                {/*gac-icon-2*/}
                <div className = 'gac-icon-desc'>
                    This copywriter has been verified as a native English speaker, and has demonstrated copywriting experience within the selected industry.
                </div>
            </span>
                </div>
                <div className = 'gac-pitch-date'>{ moment(pitch_date).format('MMM D') }</div>
            </div>
            <div className = 'gac-pitch-text'>{ pitch_about }</div>
        </div>
        { isPitchAccepted && pitch_status !== 'ACCEPTED' && !isTimeline
            ? <div className = 'gac-pitch-showmore' onClick = { showTimeline } />
            : <>
                { timeline.length ? <div ref = { timelineRef } className = 'gac-timeline'>
                    { !isOpen && ((!pitch_body && ((messages.length + logs.length) > 3)) || ( pitch_body && ((messages.length + logs.length) > 2))) && <div className = 'gac-showmore' onClick = { showFullTimeline }/> }
                    { timeline }
                </div> : null }
            </>}
        { isForm
            ? <MessageForm
                data = {{
                    project_id,
                    job_type,
                    sender_type: 'user',
                    sender_uid: user_id,
                    recipient_type: 'writer',
                    recipient_uid: copywriter_id,
                    recipient_ava: writer_avatar_url,
                    recipient_name: `${writer_first_name} ${writer_last_name}`,
                    notify_user_uid: notifyList.filter(({ user_id: id }) => id !== user_id),
                }}
                type = 'message'
                onClose = { onFormToggle }
                onSubmit = { onFormSubmit } />
            : isPitchAccepted
                ? null
                : <div className = 'gac-project-btns'>
                    <div className = 'gac-btn gac-btn-s' onClick = { onPitchApprove }>Award project</div>
                    <div className = 'gac-btn-v3' onClick = { onFormToggle }>
                        <i className='gac-svg'><Feather/></i>
                        <span>Message writer</span>
                    </div>
                </div> }
    </div> ;
};
