// Core
import { call, put, select  } from 'redux-saga/effects';

// Instruments
import { api, getKeys, getUsers, authStr } from '../../../../helpers/api';
import { listsActions } from "../../../lists/actions";
import { uiActions } from '../../../ui/actions';
import { userActions } from '../../actions';

export function* callRemoveUserFromAccountWorker ({ payload: { userId, accountId, isCurrentUser }}) {
    try {
        yield put(uiActions.enableFetching());
        const { privateKey, publicKey } = yield select(getKeys);
        const response = yield call(fetch, `${api}/${publicKey}/v1/users/${ userId }/${ accountId }`, {
            method: 'DELETE',
            headers: {
                'Authorization': `${authStr} ${privateKey}`,
            },
        });
        const { response: user, error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }

        let users = yield select(getUsers);
        users = users.filter(o => o.user_id !== userId);
        yield put(listsActions.setListsState('users', [ ...users, user ]));
        if ( isCurrentUser ) {
            yield put(userActions.setUserState('currentUser', user));
        }
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
        yield put(uiActions.setUiState('notification', {
            msg: error.message,
            type: 'error',
            fn: null,
        }));
    } finally {
        yield put(uiActions.disableFetching());
    }
}
