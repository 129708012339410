// Core
import { asyncTypes } from './asyncTypes';

export const uiActionsAsync = Object.freeze({
    uploadBase64Async: ( file, action ) => ({
        type:    asyncTypes.UPLOAD_BASE64_ASYNC,
        payload: {
            file,
            action
        },
    }),
    uploadFileAsync: ( file, action ) => ({
        type:    asyncTypes.UPLOAD_FILE_ASYNC,
        payload: {
            file,
            action
        },
    }),
    uploadFileFromUrlAsync: ( data ) => ({
        type:    asyncTypes.UPLOAD_FILE_FROM_URL_ASYNC,
        payload: data,
    }),
    reportErrorAsync: ( params ) => ({
        type:    asyncTypes.ERROR_REPORT_ASYNC,
        payload: params,
    }),
    checkBriefAsync: (brief) => ({
        type:    asyncTypes.CHECK_BRIEF_ASYNC,
        payload: brief,
    }),
    validateReferralCodeAsync: (code) => ({
        type:    asyncTypes.VALIDATE_REFERRAL_CODE_ASYNC,
        payload: code,
    }),
    gaTrackingDebugAsync: (data) => ({
        type:    asyncTypes.GA_TRACKING_DEBUG_ASYNC,
        payload: data,
    }),
});
