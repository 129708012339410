// Core
import { takeEvery } from 'redux-saga/effects';

// Instruments
import { asyncTypes } from './asyncTypes';
import { callFetchUserDetailsWorker } from './workers/fetchUserDetails';
import { callFetchTalentsWorker } from './workers/fetchTalents';
import { callFetchTalentsBlacklistWorker } from './workers/fetchTalentsBlacklist';
import { callFetchCopywritersListWorker } from './workers/fetchCopywritersList';
import { callFetchBalanceWorker } from './workers/fetchBalance';
import { callFetchAccountWorker } from './workers/fetchAccount';
import { callFetchAccountsWorker } from './workers/fetchAccounts';
import { callUpdateTalentWorker } from './workers/updateTalent';
import { callCreateCompanyWorker } from './workers/createCompany';
import { callUpdateCompanyWorker } from './workers/updateCompany';
import { callFetchCompanyWorker } from './workers/fetchCompany';
import { callCreateAccountWorker } from './workers/createAccount';
import { callDeleteAccountWorker } from './workers/deleteAccount';
import { callCheckEmailExistWorker } from './workers/checkEmailExist';
import { callRestorePasswordWorker } from './workers/restorePassword';
import { callUpdatePasswordWorker } from './workers/updatePassword';
import { callUpdateAccountWorker } from './workers/updateAccount';
import { callSendProspectWorker } from './workers/prospect';
import { callFetchDataByIpWorker } from './workers/fetchDataByIp';
import { callFetchUsersWorker } from './workers/fetchUsers';
import { callAddUserToAccountWorker } from './workers/addUserToAccount';
import { callRemoveUserFromAccountWorker } from './workers/removeUserFromAccount';
import { callCreateUserWorker } from './workers/createUser';
import { callUpdateUserWorker } from './workers/updateUser';
import { callDeleteUserWorker } from './workers/deleteUser';
import { callUpdateOwnPasswordWorker } from './workers/updateOwnPassword';
import { callFetchProposalWorker } from './workers/fetchProposal';
import { callUpdateProposalCompanyWorker } from './workers/updateProposalCompany';
import { callGetReferralIdWorker } from './workers/getReferralId';
import { callGetReferralInfoWorker } from './workers/getReferralInfo';
import { callSendNpsWorker } from './workers/sendNps';
import { callCreateSubscriptionWorker } from './workers/createSubscription';
import { callUpdateSubscriptionWorker } from './workers/updateSubscription';
import { callUpdateSubscriptionCustomWorker } from './workers/updateSubscriptionCustom';
import { callCancelSubscriptionWorker } from './workers/cancelSubscription';
import { callPauseSubscriptionWorker } from './workers/pauseSubscription';
import { callCancelPauseSubscriptionWorker } from './workers/cancelPauseSubscription';
import { callCreatePublicationWorker } from './workers/createPublication';
import { callUpdatePublicationWorker } from './workers/updatePublication';
import { callChangeCardWorker } from './workers/changeCard';
import { callValidateCnameWorker } from './workers/validateCname';
import { callCreateDesignSubscriptionWorker } from './workers/createDesignSubscription';
import { callUpdateDesignSubscriptionWorker } from './workers/updateDesignSubscription';
import { callFetchBrandProfilesWorker } from './workers/fetchBrandProfiles';
import { callCreateBrandProfileWorker } from './workers/createBrandProfile';
import { callUpdateBrandProfileWorker } from './workers/updateBrandProfile';
import { callFetchCompanySubscriptionsWorker } from './workers/fetchCompanySubscriptions';
import { callCreateDesignerWorker } from './workers/createDesigner';
import { callAssignAccountsToSubWorker } from './workers/assignAccountsToSub';
import { callGetHubspotTokenWorker } from "./workers/getHubspotToken";
import { callDownloadTransactionsWorker } from './workers/downloadTransactions';

export const userWatchers = Object.freeze({
    * watchFetchUserDetails () {
        yield takeEvery(asyncTypes.FETCH_USER_DETAILS_ASYNC, callFetchUserDetailsWorker);
    },
    * watchFetchTalents () {
        yield takeEvery(asyncTypes.FETCH_TALENTS_ASYNC, callFetchTalentsWorker);
    },
    * watchFetchTalentsBlacklist () {
        yield takeEvery(asyncTypes.FETCH_TALENTS_BLACKLIST_ASYNC, callFetchTalentsBlacklistWorker);
    },
    * watchFetchCopywritersList () {
        yield takeEvery(asyncTypes.FETCH_COPYWRITERS_LIST_ASYNC, callFetchCopywritersListWorker);
    },
    * watchUpdateTalent () {
        yield takeEvery(asyncTypes.UPDATE_TALENT_ASYNC, callUpdateTalentWorker);
    },
    * watchFetchBalance () {
        yield takeEvery(asyncTypes.FETCH_BALANCE_ASYNC, callFetchBalanceWorker);
    },
    * watchCreateCompany () {
        yield takeEvery(asyncTypes.CREATE_COMPANY_ASYNC, callCreateCompanyWorker);
    },
    * watchFetchCompany () {
        yield takeEvery(asyncTypes.FETCH_COMPANY_ASYNC, callFetchCompanyWorker);
    },
    * watchCreateAccount () {
        yield takeEvery(asyncTypes.CREATE_ACCOUNT_ASYNC, callCreateAccountWorker);
    },
    * watchDeleteAccount () {
        yield takeEvery(asyncTypes.DELETE_ACCOUNT_ASYNC, callDeleteAccountWorker);
    },
    * watchCheckEmailExist () {
        yield takeEvery(asyncTypes.CHECK_EMAIL_EXIST_ASYNC, callCheckEmailExistWorker);
    },
    * watchRestorePassword () {
        yield takeEvery(asyncTypes.RESTORE_PASSWORD_ASYNC, callRestorePasswordWorker);
    },
    * watchUpdatePassword () {
        yield takeEvery(asyncTypes.UPDATE_PASSWORD_ASYNC, callUpdatePasswordWorker);
    },
    * watchSendProspect () {
        yield takeEvery(asyncTypes.SEND_PROSPECT_ASYNC, callSendProspectWorker);
    },
    * watchFetchDataByIp () {
        yield takeEvery(asyncTypes.FETCH_DATA_BY_IP_ASYNC, callFetchDataByIpWorker);
    },

    * watchFetchAccount () {
        yield takeEvery(asyncTypes.FETCH_ACCOUNT_ASYNC, callFetchAccountWorker);
    },
    * watchFetchAccounts () {
        yield takeEvery(asyncTypes.FETCH_ACCOUNTS_ASYNC, callFetchAccountsWorker);
    },
    * watchUpdateAccount () {
        yield takeEvery(asyncTypes.UPDATE_ACCOUNT_ASYNC, callUpdateAccountWorker);
    },
    * watchFetchUsers () {
        yield takeEvery(asyncTypes.FETCH_USERS_ASYNC, callFetchUsersWorker);
    },
    * watchAddUserToAccount () {
        yield takeEvery(asyncTypes.ADD_USER_TO_ACCOUNT_ASYNC, callAddUserToAccountWorker);
    },
    * watchRemoveUserFromAccount () {
        yield takeEvery(asyncTypes.REMOVE_USER_FROM_ACCOUNT_ASYNC, callRemoveUserFromAccountWorker);
    },
    * watchCreateUser () {
        yield takeEvery(asyncTypes.CREATE_USER_ASYNC, callCreateUserWorker);
    },
    * watchUpdateUser () {
        yield takeEvery(asyncTypes.UPDATE_USER_ASYNC, callUpdateUserWorker);
    },
    * watchDeleteUser () {
        yield takeEvery(asyncTypes.DELETE_USER_ASYNC, callDeleteUserWorker);
    },
    * watchUpdateOwnPassword () {
        yield takeEvery(asyncTypes.UPDATE_OWN_PASSWORD_ASYNC, callUpdateOwnPasswordWorker);
    },
    * watchUpdateCompany () {
        yield takeEvery(asyncTypes.UPDATE_COMPANY_ASYNC, callUpdateCompanyWorker);
    },
    * watchFetchProposal () {
        yield takeEvery(asyncTypes.FETCH_PROPOSAL_ASYNC, callFetchProposalWorker);
    },
    * watchUpdateProposalCompany () {
        yield takeEvery(asyncTypes.UPDATE_PROPOSAL_COMPANY_ASYNC, callUpdateProposalCompanyWorker);
    },
    * watchGetReferralId () {
        yield takeEvery(asyncTypes.GET_REFERRAL_ID_ASYNC, callGetReferralIdWorker);
    },
    * watchGetReferralInfo () {
        yield takeEvery(asyncTypes.GET_REFERRAL_INFO_ASYNC, callGetReferralInfoWorker);
    },
    * watchSendNps () {
        yield takeEvery(asyncTypes.SEND_NPS_ASYNC, callSendNpsWorker);
    },
    * watchCreateSubscription () {
        yield takeEvery(asyncTypes.CREATE_SUBSCRIPTION_ASYNC, callCreateSubscriptionWorker);
    },
    * watchUpdateSubscription () {
        yield takeEvery(asyncTypes.UPDATE_SUBSCRIPTION_ASYNC, callUpdateSubscriptionWorker);
    },
    * watchUpdateSubscriptionCustom () {
        yield takeEvery(asyncTypes.UPDATE_SUBSCRIPTION_CUSTOM_ASYNC, callUpdateSubscriptionCustomWorker);
    },
    * watchCancelSubscription () {
        yield takeEvery(asyncTypes.CANCEL_SUBSCRIPTION_ASYNC, callCancelSubscriptionWorker);
    },
    * watchPauseSubscription () {
        yield takeEvery(asyncTypes.PAUSE_SUBSCRIPTION_ASYNC, callPauseSubscriptionWorker);
    },
    * watchCancelPauseSubscription () {
        yield takeEvery(asyncTypes.CANCEL_PAUSE_SUBSCRIPTION_ASYNC, callCancelPauseSubscriptionWorker);
    },
    * watchCreatePublication () {
        yield takeEvery(asyncTypes.CREATE_PUBLICATION_ASYNC, callCreatePublicationWorker);
    },
    * watchUpdatePublication () {
        yield takeEvery(asyncTypes.UPDATE_PUBLICATION_ASYNC, callUpdatePublicationWorker);
    },
    * watchChangeCard () {
        yield takeEvery(asyncTypes.CHANGE_CARD_ASYNC, callChangeCardWorker);
    },
    * watchValidateCname () {
        yield takeEvery(asyncTypes.VALIDATE_CNAME_ASYNC, callValidateCnameWorker);
    },
    * watchCreateDesignSubscription () {
        yield takeEvery(asyncTypes.CREATE_DESIGN_SUBSCRIPTION_ASYNC, callCreateDesignSubscriptionWorker);
    },
    * watchUpdateDesignSubscription () {
        yield takeEvery(asyncTypes.UPDATE_DESIGN_SUBSCRIPTION_ASYNC, callUpdateDesignSubscriptionWorker);
    },
    * watchFetchBrandProfiles () {
        yield takeEvery(asyncTypes.FETCH_BRAND_PROFILES_ASYNC, callFetchBrandProfilesWorker);
    },
    * watchCreateBrandProfile () {
        yield takeEvery(asyncTypes.CREATE_BRAND_PROFILE_ASYNC, callCreateBrandProfileWorker);
    },
    * watchUpdateBrandProfile () {
        yield takeEvery(asyncTypes.UPDATE_BRAND_PROFILE_ASYNC, callUpdateBrandProfileWorker);
    },
    * watchFetchCompanySubscriptions () {
        yield takeEvery(asyncTypes.FETCH_COMPANY_SUBSCRIPTIONS_ASYNC, callFetchCompanySubscriptionsWorker);
    },
    * watchCreateDesigner () {
        yield takeEvery(asyncTypes.CREATE_DESIGNER_ASYNC, callCreateDesignerWorker);
    },
    * watchAssignAccountsToSub () {
        yield takeEvery(asyncTypes.ASSIGN_ACCOUNTS_TO_SUB_ASYNC, callAssignAccountsToSubWorker);
    },
    * watchGetHubspotToken () {
        yield takeEvery(asyncTypes.GET_HUBSPOT_TOKEN_ASYNC, callGetHubspotTokenWorker);
    },
    * watchDownloadTransactions () {
        yield takeEvery(asyncTypes.DOWNLOAD_TRANSACTIONS_ASYNC, callDownloadTransactionsWorker);
    },
});
