// Core
import { CSSTransition } from 'react-transition-group';
import { Link, useLocation } from 'react-router-dom';

// Components
import Hint from '../common/Hint';

// Hooks
import { useModals } from "../../hooks/useModals";
import { useUi } from "../../hooks/useUi";
import { useUser } from "../../hooks/useUser";

// Instruments
import { domainGAC, GAC, epiic } from '../../helpers/api';
import { isClientFn, isSelfClientFn, isEpiic, getRolesDesc } from '../../helpers/helpers';
import { includes } from 'ramda';

export const Footer = () => {
    /* Hooks */
    const { setModal } = useModals();
    const { isAuth, isMobile } = useUi();
    const { details: { user_role = 'basicclient' }, company: { branding: { white_label, host }}} = useUser();
    const { pathname } = useLocation();

    /* Actions */
    const showFeedBackModal = () => {
        setModal('improveThisPage');
    };

    if ( includes(pathname, ['/login', '/password', '/password-update', '/password-create', '/company/new',
        '/designers/plans', '/designers/pricing', '/website-developers/pricing', '/writers/pricing', '/bloggers/pricing', '/bundles',
        '/payment/confirm', '/payment/success', '/assistants/pricing', '/hourly-designers/pricing', '/support/pricing', '/motion/pricing', '/designers-unlimited/pricing' ]) ) return null;
    if ( isEpiic(null) && includes('/apply/designers', pathname) ) return null; //['/apply/designers/ru/success', '/apply/designers/ru', '/apply/designers/success', '/apply/designers']

    return <CSSTransition
        in = { !isMobile && (!isClientFn(user_role) && !isSelfClientFn(user_role) ) }
        timeout = { 200 }
        unmountOnExit
        classNames = 'gac-opacity-200'>
        <div className = 'gac-footer-wrapper'>
            <div className = 'main-container'>
                <div className = 'gac-footer__copyright'>
                    { white_label
                        ? <>
                            White-label platform powered by <a target = '_blank' rel = 'noopener noreferrer' href = {`https://${isEpiic(host) ? epiic : domainGAC}.com`}>{ isEpiic(host) ? epiic : GAC }</a>.
                            <Hint
                                type = 'default'
                                text = { [] }
                                title = 'What can my clients see or do'>
                                <ul className='gac-custom-list'>
                                    { getRolesDesc(host) }
                                </ul>
                            </Hint>
                        </>
                        : <>Tip: you can set up your own
                            <span style={{ marginLeft: '4px' }}>{ includes(user_role, 'administrator/manager') && isAuth
                                ? <Link to = '/settings'>domain name and branding</Link>
                                : <>domain name and branding</> }
                            </span>
                        </> }
                </div>
                <div className = 'gac-footer__feedback'>
                    <span onClick = { showFeedBackModal }>How can we improve this page?</span>
                </div>
            </div>
        </div>
    </CSSTransition> ;
};