// Components
import { MotionOneOff } from "./MotionOneOff";
import { MotionSubscription } from "./MotionSubscription";

// Hooks
import { useOrder } from "../../../hooks/useOrder";

export const Motion = ({ page, fields }) => {
    /* Hooks */
    const { job_pay_type } = useOrder();

    /* Html */
    const getContent = () => {
        const config = {
            'one-off': <MotionOneOff fields = { fields } />,
            'subscription': <MotionSubscription fields = { fields } page = { page } />
        }

        return config[job_pay_type];
    };

    return <>
        { getContent() }
    </>;
};
