// Core
import { useLayoutEffect } from "react";

// Hooks
import { useForms } from "../../../hooks/useForms";

// Instruments
import { initDefaultRedactor } from "../../../helpers/helpers";

export const BloggingExisting = ({ fields }) => {
    /* Hooks */
    const {
        getExistingTopicIdeasField,
        getBriefField,
        getIndustryField,
        getWordCountSimpleField,
        getLocalizationField,
        getOrderOnField,

        onRedactorSynced,
        onRedactorBlur
    } = fields;
    const { bloggingExistingForm: { activeIdea }} = useForms();

    useLayoutEffect(() => {
        initDefaultRedactor('#blogging-brief', 'brief', null, null, onRedactorSynced, onRedactorBlur, true);

        return () => {
            window.$R('#blogging-brief', 'destroy');
        };
        // if ( !equals(props.form.topic_ideas_type, topic_ideas_type) || !equals(props.form.activeIdea, activeIdea) ) {
        //     this._initRedactorForIdeas();
        // }
    }, [activeIdea]);

    return <>
        { getExistingTopicIdeasField() }
        { getBriefField(true) }
        { getIndustryField() }
        { getWordCountSimpleField() }
        { getLocalizationField() }
        { getOrderOnField() }
    </>;
};