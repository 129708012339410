// Core
import { useEffect, useRef } from "react";

// Components
import { useNavigate } from 'react-router-dom';

// Hooks
import { useUser } from "../../../hooks/useUser";

export const ModalDeactivateAccount = ({ closeModal, setPos }) => {
    /* Ref */
    const modalRef = useRef(null);

    /* Hooks */
    const { currentAccount: { account_uid }, deleteAccountAsync } = useUser();
    const navigate = useNavigate();

    /* Actions */
    const onDeleteAccount = () => {
        deleteAccountAsync(account_uid, navigate);
    };

    /* Did mount */
    useEffect(() => {
        setPos( modalRef );
    }, []);

    return(
        <div ref = { modalRef } className = 'gac-modal gac-deactivate-account-modal'>
            <span className = 'gac-close-modal' onClick = { closeModal }/>
            <div className = 'gac-modal-text'>
                Are you sure you want to deactivate the account? To undo deactivation, please contact support.
            </div>
            <span onClick = { onDeleteAccount } className = 'gac-btn gac-btn-s'>Confirm</span>
        </div>
    );
};
