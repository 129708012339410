// Core
import { call, put, select } from 'redux-saga/effects';

// Instruments
import { api, authStr, isStage, getKeys, getPusherData, getCurrencies, getCompanyBranding, getBundlesRef, getUserDetails } from '../../../../helpers/api';
import { purchaseTrack, getAmountByCurrency } from '../../../../helpers/helpers.js';
import { uiActions } from '../../../ui/actions';
import { uiActionsAsync } from "../../../ui/saga/asyncActions";
import { paymentsActions } from '../../../payments/actions';
import { userActionsAsync } from '../../../user/saga/asyncActions';

export function* callBundlesPayWorker ({ payload: { data: obj, navigate } }) {
    try {
        yield put(uiActions.enableFetching());
        const { privateKey, publicKey } = yield select(getKeys);
        const { sessionID } = yield select(getPusherData);
        const response = yield call(fetch, `${api}/${publicKey}/v1/bundles/pay`,{
            method: 'PUT',
            headers: {
                'Authorization': `${authStr} ${privateKey}`,
                'Content-Type':  'application/json',
            },
            body: JSON.stringify({
                ...obj,
                pusherData: {
                    sessionID
                },
            }),
        });
        const { response : data, error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }

        if ( obj.payment_method === 'stripe' ) {
            yield put(paymentsActions.setPaymentsState('bundlesStripe', data.stripe));
        } else {
            yield put(userActionsAsync.fetchBalanceAsync(obj.account_uid));
            if ( obj.payment_method === 'braintree' ) {
                yield put(paymentsActions.setPaymentsState('bundlesBraintree', {}));
            }

            if ( !isStage ) {
                const currencies = yield select(getCurrencies);
                const { host } = yield select(getCompanyBranding);
                const { email } = yield select(getUserDetails);
                let { added_funds: { currency_code, amount, payment_method }} = data;
                const { amount_usd, rate } = getAmountByCurrency(amount, currency_code, currencies);

                purchaseTrack(amount_usd, host, email);
                yield put(uiActionsAsync.gaTrackingDebugAsync({
                    amount: `${amount}`,
                    currency: currency_code,
                    email,
                    items: `bundle: ${obj.name}`,
                    notes: `host: ${host}, amount_usd: ${amount_usd}, rate: ${rate}, payment_method: ${payment_method}, account_uid: ${obj.account_uid}, gtag: ${window.gtag}, url: ${window.location.href}, params: ${window.location.search}` }));
            }

            const bundlesRef = yield select(getBundlesRef);
            if ( bundlesRef === 'transactions' ) {
                navigate('/transactions');
                yield put(uiActions.setUiState('bundlesRef', null));
            } else {
                navigate('/order/confirm');
            }
        }
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
        yield put(uiActions.setUiState('notification', {
            msg: error.message,
            type: 'error',
            fn: null,
        }));
        if ( obj.payment_method === 'braintree' ) {
            yield put(uiActions.setUiState('isBundlesBraintreePaymentFailed', true));
            yield put(paymentsActions.setPaymentsState('bundlesBraintree', {}));
        }
        if ( obj.payment_method === 'stripe' ) {
            yield put(uiActions.disableFetching());
            yield put(uiActions.setUiState('isBlocking', false));
        }
    } finally {
        yield put(uiActions.disableFetching());
        if ( obj.payment_method !== 'stripe' ) {
            yield put(uiActions.setUiState('isBlocking', false));
        }
    }
}
