// Instruments
import { types } from './types';

export const orderActions = Object.freeze({
    setOrderState: (prop, value) => ({
        type:    types.SET_ORDER_STATE,
        payload: {
            prop,
            value
        },
    }),
    updateOrderState: (obj) => ({
        type:    types.UPDATE_ORDER_STATE,
        payload: obj,
    }),
});
