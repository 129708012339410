// Core
import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';
import cx from 'classnames';
import { array, bool, func, object, string } from 'prop-types';

export default class Selectv2 extends Component {
    static propTypes = {
        direction:  string,
        items:      array.isRequired,
        value:      object.isRequired,
        parameter:  string.isRequired,
        invalid:    bool,
        onChange:   func.isRequired,
    };

    state = {
        isOpened: false,
    };

    componentWillUnmount() {
        document.removeEventListener('click', this._onOutsideListClick);
        document.removeEventListener('touchstart', this._onOutsideListClick);
    }

    _toggleList = () => {
        const { isOpened } = this.state;

        this.setState({
            isOpened: !isOpened,
        }, () => {
            if ( isOpened ) {
                document.removeEventListener('click', this._onOutsideListClick);
                document.removeEventListener('touchstart', this._onOutsideListClick);
            } else {
                document.addEventListener('click', this._onOutsideListClick);
                document.addEventListener('touchstart', this._onOutsideListClick);
            }
        });
    };

    _onOutsideListClick = (e) => {
        let target = e.target;

        do {
            if (target === this.select) {
                return;
            }
            target = target.parentNode;
        } while (target);

        this.setState({
            isOpened: false,
        });
    };

    _onClick = ({ currentTarget: { dataset: { value }}}) => {
        const { onChange, items } = this.props;

        this.setState({
            isOpened: false,
        }, () => {
            onChange( items.filter(o => o.value.toLowerCase() === value.toLowerCase())[0]);
        });
    };

    render () {
        const { isOpened } = this.state;
        const { direction, items, parameter, value, invalid } = this.props;
        const data = items.map((item, i) => (
            <li key = { i }>
                <span
                    className = { `gac-selectv2-link ${item.value.toLowerCase() === value.value.toLowerCase() ? 'gac-active' : ''}` }
                    data-value = { item.value.toLowerCase() }
                    title = { item.title }
                    onClick = { item.value.toLowerCase() === value.value.toLowerCase() ? null : this._onClick }>
                    { item.title }
                </span>
            </li>
        ));
        const styles = cx('gac-selectv2', {
            'gac-clicked': isOpened,
            'gac-invalid': invalid,
        });

        return (
            <div id = { parameter } className = { styles } ref = {select => this.select = select }>
                <div className = 'gac-selectv2-value' onClick = { this._toggleList }>
                    { value.title }
                </div>
                <i onClick = { this._toggleList } />
                <CSSTransition
                    in = { isOpened }
                    timeout = { 200 }
                    unmountOnExit
                    classNames = 'gac-opacity-200'>
                    <ul
                        ref = { ref => this.list = ref }
                        style = {{
                            top: direction ? 'auto' : 29,
                            bottom: direction ? 29 : 'auto',
                            boxShadow: `0 ${ direction ? '-' : '' }1px 3px 0 rgba(159, 177, 188, 0.4)`,
                        }}
                        className = 'gac-selectv2-menu'>
                        { data }
                    </ul>
                </CSSTransition>
            </div>
        );
    }
}
