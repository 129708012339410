// Core
import { call, put, select  } from 'redux-saga/effects';

// Instruments
import { api, authStr, getKeys, getMessages } from '../../../../helpers/api';
import { uiActions } from '../../../ui/actions';
import { listsActions } from "../../../lists/actions";
import { projectActions } from '../../actions';

import { sortMessages } from "../../../../helpers/helpers";

export function* callCreateMessageWorker ({ payload }) {
    try {
        yield put(uiActions.enableFetching());
        const { privateKey, publicKey } = yield select(getKeys);
        const response = yield call(fetch, `${api}/${publicKey}/v1/messages`, {
            method: 'POST',
            headers: {
                'Authorization': `${authStr} ${privateKey}`,
                'Content-Type':  'application/json',
            },
            body: JSON.stringify(payload),
        });
        const { response: data, error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }

        let messages = yield select(getMessages);
        yield put( listsActions.setListsState('messages', sortMessages([ ...messages, { ...data, status: 'viewed', project_topic: payload.project_topic } ])) );
        yield put( projectActions.createMessageSuccess(data) );
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
        yield put(uiActions.setUiState('notification', {
            msg: error.message,
            type: 'error',
        }));
    } finally {
        yield put(uiActions.disableFetching());
    }
}
