// Core
import { useLayoutEffect } from "react";

// Instruments
import { initDefaultRedactor } from "../../../helpers/helpers";

export const Proofreading = ({ fields }) => {
    /* Hooks */
    const {
        isDetails,

        getFormatField,
        getIndustryField,
        getTopicField,
        getContentToProofreadField,
        getBriefField,
        getProofreadingWordCountField,
        getLevelField,
        getWritersField,
        getTurnaroundField,
        getOrderOnField,
        getAdditionalDetailsLabel,
        getLocalizationField,
        getTagsField,
        getCopyStyleVoiceField,

        onRedactorSynced,
        onRedactorBlur
    } = fields;

    useLayoutEffect(() => {
        initDefaultRedactor('#proofreading-brief', 'brief', '118px', null, onRedactorSynced, onRedactorBlur, true);
        initDefaultRedactor('#proof-content', 'content', '118px', null, onRedactorSynced, onRedactorBlur, true);

        return () => {
            window.$R('#proofreading-brief', 'destroy');
            window.$R('#proof-content', 'destroy');
        };
    }, []);

    return <>
        { getFormatField() }
        { getIndustryField() }
        { getTopicField() }
        { getContentToProofreadField() }
        { getBriefField(true) }
        { getProofreadingWordCountField() }
        { getLevelField() }
        { getWritersField() }
        { getTurnaroundField() }
        { getOrderOnField() }
        { getAdditionalDetailsLabel() }
        { getLocalizationField() }
        { isDetails && <>
            { getTagsField() }
            { getCopyStyleVoiceField('top') }
        </> }
    </>;
};