// Core
import { combineReducers } from 'redux';

// Instruments
import { authReducer as auth } from '../bus/auth/reducer';
import { projectReducer as project } from '../bus/project/reducer';
import { modalsReducer as modals } from '../bus/modals/reducer';
import { uiReducer as ui } from '../bus/ui/reducer';
import { userReducer as user } from '../bus/user/reducer';
import { paymentsReducer as payments } from '../bus/payments/reducer';
import { orderReducer as order } from '../bus/order/reducer';
import { socketReducer as socket } from '../bus/socket/reducer';
import { formsReducer as forms } from '../bus/forms/reducer';
import { listsReducer as lists } from '../bus/lists/reducer';

export default () => combineReducers({
    auth,
    forms,
    project,
    lists,
    modals,
    ui,
    user,
    order,
    payments,
    socket
});