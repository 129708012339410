// Core
import { useLocation } from 'react-router-dom';

// Components
import { Link } from 'react-router-dom';
import OrderPlus from '../../../icons/OrderPlus';

// Hooks
import { useAuth } from "../../../../hooks/useAuth";
import { useUi } from "../../../../hooks/useUi";
import { useUser } from "../../../../hooks/useUser";
import { useLists } from "../../../../hooks/useLists";

// Instruments
import { isClientFn, isSelfClientFn, getActiveWritingSub, getActiveBloggingSub, isActiveDesignSub } from '../../../../helpers/helpers';
import { isEmpty, isNil } from 'ramda';

export const MainMenu = ({ onHover }) => {
    /* Hooks */
    const { keys: { accountId }} = useAuth();
    const { orderList } = useLists();
    const { isAuth } = useUi();
    const { balance: { words_balance }, company: { subscriptions }, details: { accounts, user_role = 'basicclient' } } = useUser();
    const { pathname } = useLocation();

    const onMouseEnter = ({ currentTarget: { dataset: { path }}}) => {
        onHover(path);
    };

    const getIcon = () => {
        const filtered = isNil(accounts) ? [] : accounts.filter(acc => acc.account_id === accountId);
        const order_count = isNil(accounts) ? '0' : isEmpty(filtered) ? '0' : filtered[0].order_count;

        if ( orderList.length ) {
            return <span className = 'gac-red'>{ orderList.length }</span>
        } else if ( order_count !== '0' && order_count !== 0 && !isNil(order_count) ) {
            return <span className = 'gac-red'>{ order_count }</span>
        } else {
            return <span><OrderPlus/></span>;
        }
    };
    const getOrderLink = () => {
        let activeSub = getActiveWritingSub(accountId, subscriptions);
        let activeBloggingSub = getActiveBloggingSub(accountId, subscriptions);

        if ( isAuth && isClientFn(user_role) ) return null;
        if ( !isActiveDesignSub(accountId, subscriptions)
            && (isEmpty(activeSub) && isEmpty(activeBloggingSub) && (words_balance < 1 || !words_balance))
            && isSelfClientFn(user_role)
            && isAuth
        ) return null;

        return <li>
            <Link data-path = '' className = { pathname.includes('order') ? 'gac-active' : '' } to = { isAuth ? '/order' : '/order/new' } onMouseEnter = { onMouseEnter }>
                Order
                { getIcon() }
            </Link>
        </li>;
    };

    return(
        <div className = 'gac-main-menu'>
            <li>
                { isAuth
                    ? <Link
                        data-path = ''
                        className = { pathname === '/calendar' ? 'gac-active' : '' }
                        to = '/calendar'
                        onMouseEnter = { onMouseEnter }>
                        Plan
                    </Link>
                    : <div data-path = 'plan' onMouseEnter = { onMouseEnter }>Plan</div> }
            </li>
            { getOrderLink() }
            <li>
                { isAuth
                    ? <Link
                        data-path = 'manage'
                        className = { '/draft-projects/current-projects/completed-projects'.includes(pathname) ? 'gac-active' : '' }
                        to = '/current-projects'
                        onMouseEnter = { onMouseEnter }>
                        Manage
                    </Link>
                    : <div data-path = 'manage' onMouseEnter = { onMouseEnter }>Manage</div> }
            </li>
            <li>
                { isAuth
                    ? <Link
                        data-path = ''
                        className = { pathname.includes('images') ? 'gac-active' : '' }
                        to = '/images'
                        onMouseEnter = { onMouseEnter }>
                        Enhance
                    </Link>
                    : <div data-path = 'images' onMouseEnter = { onMouseEnter }>Enhance</div> }
            </li>
        </div>
    );
};