// Core
import { useLayoutEffect, useRef, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';

// Components
import { DayPicker } from 'react-day-picker';

// Hooks
import { useProject } from "../../../../../hooks/useProject";
import { useUser } from "../../../../../hooks/useUser";
import { useUi } from "../../../../../hooks/useUi";
import { useOrder } from "../../../../../hooks/useOrder";
import { useLists } from "../../../../../hooks/useLists";

// Instruments
import {
    bytesToSize,
    clearBrief,
    initDefaultRedactor,
    isClientFn,
    isFetching,
    onOutsideElClick,
    setLinksTarget,
} from "../../../../../helpers/helpers";
import { isNil, isEmpty, last } from 'ramda';
import moment from 'moment/moment';

export const Idea = ({ revision, onTextSelection, onTextSelectionMobile, onSelectionClear, idea: { idea_number, star, revisions, ordered_project_date, ordered_project_status, ordered_project_id }}) => {
    /* Ref */
    const fieldRef = useRef(null);
    const pickerRef = useRef(null);
    const titleRef = useRef(null);
    const descRef = useRef(null);

    /* State */
    const [isBtnHovered, setIsBtnHovered] = useState(false);
    const [isDayPicker, setIsDayPicker] = useState(false);
    const [isNotEnoughWords, setIsNotEnoughWords] = useState(false);

    /* Hooks */
    const { details, updateIdeaRevisionField, deleteIdeaFile, createProjectAsync, deleteProjectAsync, confirmProjectAsync, setIdeaStatusAsync, updateProjectAsync } = useProject();
    const { status, editing, talent, project_id } = details;
    const { details: { user_role }, balance: { words_balance }} = useUser();
    const { isAuth, fetching } = useUi();
    const { addProjectToOrderAsync } = useOrder();
    const { orderList } = useLists();
    const navigate = useNavigate();

    /* Comments */
    const _onTextSelection = ({ currentTarget: { dataset: { field }}}) => {
        if ( revision === revisions.length - 1 && !isNil(fieldRef.current) && fieldRef.current === field ) {
            onTextSelection();
        }
    };
    const _onTextClearSelection = ({ currentTarget: { dataset: { field }}}) => {
        if ( revision === revisions.length - 1 ) {
            if( !isNil(field) ) {
                fieldRef.current = field;
            }
            onSelectionClear();
        }
    };
    const _onTextSelectionMobile = ({ currentTarget: { dataset: { field }}}) => {
        if ( revision === revisions.length - 1 && !isNil(fieldRef.current) && fieldRef.current === field ) {
            onTextSelectionMobile();
        }
    };

    /* Actions */
    const getProjectObj = () => {
        const { talent, account_uid, project_id, format, industry, topic: website, brief: briefData, product_service, target_audience, content_goal, other_industry, level, word_count, writer_id, smart_match, localization, avoid  } = details;
        const { title, description, keywords, files } = revisions[revisions.length-1];
        const filesData = files.map(o => o.file_id);
        const brief = `<p>Description:</p>
        ${description}
        <p></p>
        <p>Keywords:</p>
        <p>${keywords}</p>
        <p></p>
        <p>Website:</p>
        <p>${website}</p>
        <p></p>
        <p>Target product/service or keywords:</p>
        ${product_service}
        <p></p>
        <p>Target audience:</p>
        <p>${target_audience}</p>
        <p></p>
        <p>Content goal:</p>
        <p>${content_goal}</p>
        <p></p>
        <p>Brief:</p>
        ${briefData}`;

        return {
            account_uid,
            format,
            industry,
            other_industry,
            level,
            word_count,
            topic: clearBrief(title),
            smart_match,
            avoid,
            localization,
            brief,
            idea_project_id: project_id,
            idea_number,
            files: filesData.length ? filesData.join(',') : '',
            type: 'marketplace',
            job_type: 'writing',
            invite_writer_active_for: 168,
            invite_writers: [writer_id],
            quantity: 1,
            target_audience: 'general',
            content: 'text',
            copy_style: 'copywriter',
            copy_voice: 'copywriter',
            order_on: moment().format('YYYY-MM-DD HH:mm:ss'),
            written_by: moment().add(1, 'week').format('YYYY-MM-DD HH:mm:ss'),
            talent,
        };
    };

    const onInputChange = ({ currentTarget: { value, dataset: { prop }}}) => {
        if ( !isFetching(fetching) ) {
            updateIdeaRevisionField(prop, value, idea_number, last(revisions).revision_id);
        }
    };
    const onRedactorSynced = (data, prop) => {
        updateIdeaRevisionField(prop, data, idea_number, last(revisions).revision_id);
    };
    const onRemoveFile = ({ currentTarget: { dataset: { id }}}) => {
        if ( !isFetching(fetching) ) {
            deleteIdeaFile(id, idea_number, last(revisions).revision_id);
        }
    };
    const onBtnHover = () => {
        setIsBtnHovered(state => !state);
    };
    const onAddToOrder = () => {
        if ( !isFetching(fetching) ) {
            addProjectToOrderAsync(ordered_project_id, 'add_project_to_order_created_from_idea');
        }
    };
    const onProjectCreate = ({ currentTarget: { dataset: { action }}}) => {
        if ( !isFetching(fetching) ) {
            createProjectAsync(getProjectObj(), { name: action, job_type: 'writing' }, navigate);
        }
    };
    const onDeleteDraft = ({ currentTarget: { dataset: { action }}}) => {
        if ( !isFetching(fetching) ) {
            deleteProjectAsync(ordered_project_id, action, idea_number);
        }
    };
    const onPlaceOrder = ({ currentTarget: { dataset: { action }}}) => {
        let obj = getProjectObj();
        if ( words_balance < 1 || !words_balance || obj.word_count > words_balance ) {
            setIsNotEnoughWords(true);
            return;
        }
        obj = {
            ...obj,
            project_id: ordered_project_id
        };

        if ( !isFetching(fetching) ) {
            confirmProjectAsync(obj, { name: action, job_type: 'writing' }, navigate);
        }
    };
    const toggleIdeaStatus = () => {
        if ( isClientFn(user_role) || !isAuth ) return;

        const value =  star ? 'unstar' : 'star';
        if ( !isFetching(fetching) ) {
            setIdeaStatusAsync(project_id, idea_number, value);
        }
    };

    /* DayPicker */
    const toggleDayPicker = () => {
        setIsDayPicker(state => !state);
    };
    const onDayClick = (day) => {
        const project = { project_id: ordered_project_id, order_on: moment(day).format('YYYY-MM-DD HH:mm:ss') };

        if ( !isFetching(fetching) ) {
            updateProjectAsync(project, { job_type: 'writing', name: 'update_order_on_from_idea' }, idea_number, navigate);
        }
    }

    useLayoutEffect(() => {
        if ( editing && !isNil(titleRef.current) && !isNil(descRef.current) ) {
            initDefaultRedactor(`#title-${idea_number}`, 'title', '48px', null, onRedactorSynced, () => {}, false);
            initDefaultRedactor(`#description-${idea_number}`, 'description', '118px', null, onRedactorSynced, () => {}, false);
        }
    }, [editing, titleRef.current, descRef.current]);
    useLayoutEffect(() => {
        const onOutsideClick = (e) => {
            onOutsideElClick(e, pickerRef.current,() => { setIsDayPicker(false) });
        };

        if ( isDayPicker ) {
            document.addEventListener('click', onOutsideClick, true);
            document.addEventListener('touchstart', onOutsideClick, true);
        }

        return () => {
            document.removeEventListener('click', onOutsideClick, true);
            document.removeEventListener('touchstart', onOutsideClick, true);
        };
    }, [isDayPicker]);

    /* Html */
    const getApprovedIdeaBtns = () => {
        if ( isClientFn(user_role) || !isAuth ) return null;

        const isAddedToOrder = orderList.some(o => o.project_id === ordered_project_id);
        const isDraft = ordered_project_status === 'draft';
        const url = isDraft ? '/order' : '/project';
        const style = { cursor: isDraft ? 'pointer' : null, textDecoration: isDraft ? 'underline' : null };
        const is_subscription = talent === 'bloggers';

        const getBtns = () => {
            if ( isNil(ordered_project_id) ) {
                return <>
                    <div className="gac-btn gac-btn-32" data-action='save_add_to_order_from_idea' onClick = { is_subscription ? onPlaceOrder : onProjectCreate }>{ is_subscription ? 'Place order' : 'Add to order' }</div>
                    <div className="gac-btn-v2 gac-btn-32" data-action='save_draft_from_idea' onClick = { onProjectCreate }>Save draft</div>
                </> ;
            }
            if ( isAddedToOrder ) {
                return <div className="gac-btn gac-btn-32 gac-btn-disable-v2" data-action='delete_from_idea' style={{ cursor: 'pointer' }} data-btn='order' onMouseEnter = { onBtnHover } onMouseLeave = { onBtnHover } onClick = { onDeleteDraft }><i/>{ isBtnHovered ? 'Delete draft' : 'Added to order' }</div> ;
            }
            if ( isDraft ) {
                return <>
                    <div className="gac-btn gac-btn-32" data-action='save_add_to_order_from_idea' onClick = { is_subscription ? onPlaceOrder : onAddToOrder }>{ is_subscription ? 'Place order' : 'Add to order' }</div>
                    <div className="gac-btn gac-btn-32 gac-btn-disable-v2" data-action='delete_from_idea' data-btn='draft' onMouseEnter = { onBtnHover } onMouseLeave = { onBtnHover } style={{ cursor: 'pointer' }} onClick = { onDeleteDraft }><i/>{ isBtnHovered ? 'Delete draft' : 'Saved draft' }</div>
                </> ;
            }

            return <div className="gac-btn gac-btn-32 gac-btn-disable-v2"><i/>Ordered on { moment(ordered_project_date).format('MMM D, YYYY') }</div> ;
        };
        const getDayPicker = () => {
            if ( isNil(ordered_project_date) || !isDraft ) return null;

            return <div ref = { pickerRef } style = { style } className="gac-idea-ordered-meta" onClick = { isDraft ? toggleDayPicker : null } >
                <i className='gac-icon-1'/>
                { moment(ordered_project_date).format('MMM D, YYYY') }
                { isDayPicker && <DayPicker className = 'gac-daypicker-wrap' mode = 'single' selected = { new Date(ordered_project_date) } fromDate = { new Date() } format = 'MMM D, YYYY' onDayClick = { onDayClick }/> }
            </div>
        };

        return <div className="gac-idea-btns">
            { getBtns() }
            { getDayPicker()  }
            { !isNil(ordered_project_id) && <div className="gac-idea-ordered-meta" style={{ paddingLeft: 20 }}>
                <i className='gac-icon-2'/>
                <Link to = {`${url}/${ordered_project_id}`}>{`${url}/${ordered_project_id}`}</Link>
            </div> }
            { isNotEnoughWords && <div className="gac-error">Not enough words on your balance</div> }
        </div> ;
    };
    const getIdea = () => {
        if ( isNil(revision) ) return null;

        const { title, description, keywords, files, revision_id } = revisions[revision];

        const getIdeaContent = () => {
            if ( editing ) {
                return <>
                    <div className="gac-idea-wrap">
                        <i className= { `gac-idea-status ${star ? 'gac-favorite' : ''}` } onClick = { toggleIdeaStatus }/>
                        <div className = 'gac-form-row'>
                            <div className = 'gac-form-field'>
                                <span className = 'gac-form-label'>Topic</span>
                                <div className = 'gac-form-textarea'>
                                    <textarea ref = { titleRef } id = {`title-${idea_number}`} value = { title } readOnly />
                                </div>
                            </div>
                        </div>
                        <div className='gac-form-row'>
                            <div className = 'gac-form-field'>
                                <span className = 'gac-form-label'>Description</span>
                                <div className = 'gac-form-textarea'>
                                    <textarea ref = { descRef } id = {`description-${idea_number}`} value = { description } readOnly />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="gac-idea-keywords">
                        <div className = 'gac-form-row'>
                            <div className = 'gac-form-field'>
                                <span className = 'gac-form-label'>Keywords</span>
                                <div className = 'gac-form-input'>
                                    <input data-prop = 'keywords' data-hj-whitelist type = 'text' value = { keywords } onChange = { onInputChange }/>
                                </div>
                            </div>
                        </div>
                    </div>
                </> ;
            }

            return <>
                <div className="gac-idea-wrap">
                    <i className= { `gac-idea-status ${star ? 'gac-favorite' : ''}` } onClick = { toggleIdeaStatus }/>
                    <div className="gac-idea-title-wrap">
                        <div className="gac-idea-number">{idea_number}.</div>
                        <div data-field={`${idea_number}-title`} data-revision = { revision_id } className="gac-idea-title" onTouchEnd = { _onTextSelectionMobile } onMouseUp = { _onTextSelection } onMouseDown = { _onTextClearSelection } dangerouslySetInnerHTML={{ __html: setLinksTarget(title) }} />
                    </div>
                    <div data-field={`${idea_number}-description`} data-revision = { revision_id } className="gac-idea-desc gac-idea-data" dangerouslySetInnerHTML={{ __html: setLinksTarget(description) }} onTouchEnd = { _onTextSelectionMobile } onMouseUp = { _onTextSelection } onMouseDown = { _onTextClearSelection } />
                </div>
                <div className="gac-idea-keywords">Keywords: {keywords}</div>
            </> ;
        };
        const getFiles = () => {
            let data = [];

            if ( editing ) {
                data = files.map(({ file_id, filename, size }) => {
                    return <div key={file_id} className = 'gac-project-file'>
                        <i className = { `gac-project-file-type ${ filename.split('.').slice(-1)[0] }` }/>
                        <span title = { 'box.png' } className = 'gac-project-file-name'>{filename}</span>
                        <div className = 'gac-align-right'>
                            <span className = 'gac-project-file-size' style={{ marginRight: editing ? 0 : 6 }}>{ bytesToSize(size) }</span>
                            <i data-id = { file_id } onClick = { onRemoveFile } className = 'gac-files-item-remove'/>
                        </div>
                    </div> ;
                });
            } else {
                data = files.map(({ file_id, filename, size, url }) => {
                    return <a key = { file_id } href = { url } target='_blank' rel = 'noopener noreferrer' className = 'gac-project-file'>
                        <i className = { `gac-project-file-type ${ filename.split('.').slice(-1)[0] }` }/>
                        <span title = { 'box.png' } className = 'gac-project-file-name'>{filename}</span>
                        <div className = 'gac-align-right'>
                            <span className = 'gac-project-file-size'>{ bytesToSize(size) }</span>
                        </div>
                    </a> ;
                });
            }

            if ( isEmpty(data) ) return null;

            return <div className="gac-project-files">{ data }</div>;
        };
        const getBtn = () => {
            if ( editing || revisions.length - 1 !== revision || status !== 'approved' ) return null;

            return getApprovedIdeaBtns();
        };

        return <>
            { getIdeaContent() }
            { getFiles() }
            { getBtn() }
        </> ;
    };

    return <div className="gac-project-idea">{ getIdea() }</div>;
};