// Core
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Hooks
import { useLogin } from "../useLogin";
import { useUi } from "../../../hooks/useUi";
import { useUser } from "../../../hooks/useUser";

// Instruments
import { isWhiteLabel, getAppTitle } from "../../../helpers/helpers";

export const Login = () => {
    /* Hooks */
    const { getTitle, getEmail, getPassword, getRecaptcha, getSubmitBtn, getSignUpLink } = useLogin();
    const { loginAttempt } = useUi();
    const { company } = useUser();
    const navigate = useNavigate();

    /* Actions */
    const onForgotPassword = ({currentTarget: { dataset: { path }}}) => {
        navigate(path);
    };

    /* Did mount */
    useEffect(() => {
        document.title = getAppTitle('Login', company);
    }, []);

    return <>
        <div className = 'gac-wrap'>
            { getTitle() }
            { getEmail() }
            { getPassword() }
            <div className = 'gac-restore-password'>
                <span data-path = '/password' onClick = { onForgotPassword }>Forgot password?</span>
            </div>
            { loginAttempt > 2 && !isWhiteLabel() ? getRecaptcha() : null }
            { getSubmitBtn() }
        </div>
        { getSignUpLink() }
    </>;
};