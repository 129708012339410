// Core
import { useEffect, useLayoutEffect, useRef, useState } from "react";

// Components
import Hint from "../../common/Hint";
import { ChromePicker } from 'react-color';

// Hooks
import { useInvalidFields } from "../../../hooks/custom/useInvalidFields";
import { useUser } from "../../../hooks/useUser";
import { useUi } from "../../../hooks/useUi";

// Instruments
import { api } from "../../../helpers/api";
import { onOutsideElClick, isClientFn, isSelfClientFn } from "../../../helpers/helpers";
import { isNil, isEmpty } from "ramda";

export const ModalCreateBrandProfile = ({ closeModal, setPos }) => {
    /* Ref */
    const modalRef = useRef(null);
    const colorPickerRef = useRef(null);

    /* State */
    const [brand, setBrand] = useState({ name: '', target_audience: '', description: '', colors: [], brand_assets: [] });
    const { name, target_audience, description, colors, brand_assets } = brand;
    const [new_color, setNewColor] = useState(null);
    const [isColorPicker, setIsColorPicker] = useState(false);
    const [isUploadFailed, setIsUploadFailed] = useState(false);
    const [isUploadAborted, setIsUploadAborted] = useState(false);

    /* Hooks */
    const { invalidFields, setInvalidFields, removeField } = useInvalidFields();
    const { details: { user_role }, activeBrandProfile, setUserState, createBrandProfileAsync, updateBrandProfileAsync } = useUser();
    const { enableFetching, disableFetching } = useUi();

    /* FileUpload */
    const onFileUploadComplete = (e) => {
        const files = JSON.parse(e.target.response).response;
        setBrand(state => {
            const data = isNil(state.brand_assets) ? [] : state.brand_assets;

            return { ...state, brand_assets: [ ...data, ...files ] };
        });
        setIsUploadFailed(false);
        setIsUploadAborted(false);

        disableFetching();
    };
    const onFileUploadError = () => {
        setIsUploadFailed(true);
    };
    const onFileUploadAbort = () => {
        setIsUploadAborted(true);
    };
    const onFileRemove = (e) => {
        const { currentTarget: { dataset: { id } }} = e;
        e.preventDefault();

        setBrand(state => ({ ...state, brand_assets: state.brand_assets.filter(o => o.file_id !== id) }));
    };
    const uploadFile = (file) => {
        enableFetching();
        const formdata = new FormData();
        formdata.append('attachment[]', file, file.name);
        const xhr = new XMLHttpRequest();
        xhr.addEventListener("load", onFileUploadComplete, false);
        xhr.addEventListener("error", onFileUploadError, false);
        xhr.addEventListener("abort", onFileUploadAbort, false);
        xhr.open('POST', `${api}/v1/upload_file`, true);
        xhr.send(formdata);
    };

    /* Actions */
    const toggleColorPicker = () => {
        setBrand(state => {
            let colorsData = state.colors;
            if ( !isNil(new_color) ) {
                colorsData = [...colorsData, new_color];
            }
            return { ...state, colors: isColorPicker ? colorsData : state.colors };
        });
        setIsColorPicker(state => !state);
        setNewColor(null);
    };
    const onColorChange = ({ hex }) => {
        setNewColor(hex);
    };
    const onColorRemove = ({ currentTarget: { dataset: { item } }}) => {
        setBrand(state => ({ ...state, colors: state.colors.filter(o => o !== item)}));
    };
    const onInputFieldChange = ({ currentTarget: { value, dataset: { prop } }}) => {
        if ( prop === 'name' ) {
            removeField(prop);
        }
        setBrand(state => ({ ...state, [prop]: value }));
    };
    const onAssetsFieldChange = (e) => {
        const files = Array.prototype.slice.call(e.target.files);

        files.forEach((file) => {
            uploadFile(file);
        });
    };
    /* Submit */
    const validateFields = () => {
        return name.length < 1 ? ['name'] : [];
    };
    const onDeactivateProfile = () => {
        updateBrandProfileAsync({ id: brand.id, status: 'hidden' })
        closeModal();
    };
    const onSubmit = () => {
        const fields = validateFields();

        if ( isEmpty(fields) ) {
            const data = {
                ...brand,
                colors: isEmpty(colors) ? [''] : colors,
                brand_assets: isNil(brand_assets) ? null : brand_assets.map(o => o.file_id),
            }
            if ( isNil(activeBrandProfile) || isEmpty(activeBrandProfile) ) {
                createBrandProfileAsync(data);
            } else {
                updateBrandProfileAsync(data)
            }
            closeModal();
        } else {
            setInvalidFields(fields);
        }
    };

    /* Did mount */
    useLayoutEffect(() => {
        if ( !isNil(activeBrandProfile) && !isEmpty(activeBrandProfile) ) {
            setBrand(activeBrandProfile);
        }
        return () => {
            setUserState('activeBrandProfile', null);
        };
    }, []);
    useEffect(() => {
        const onOutsideClick = (e) => {
            onOutsideElClick(
                e,
                colorPickerRef.current,
                () => {
                    setBrand(state => {
                        let colorsData = isNil(state.colors) ? [] : state.colors;
                        if ( !isNil(new_color) ) {
                            colorsData = [...colorsData, new_color];
                        }
                        return { ...state, colors: colorsData };
                    });
                    setIsColorPicker(false);
                    setNewColor(null);
                    setPos( modalRef );
                }
            );
        };

        if ( isColorPicker ) {
            document.addEventListener('click', onOutsideClick, true);
            document.addEventListener('touchstart', onOutsideClick, true);
        }

        return () => {
            document.removeEventListener('click', onOutsideClick, true);
            document.removeEventListener('touchstart', onOutsideClick, true);
        };
    }, [isColorPicker, new_color]);
    useEffect(() => {
        setPos( modalRef );
    }, [colors, brand_assets]);

    /* Html */
    const isActiveBrand = !isNil(activeBrandProfile) && !isEmpty(activeBrandProfile);
    const getTitle = () => {
        return <h1>{ isActiveBrand ? 'Update a' : 'Create a new' } brand profile</h1>;
    };
    const getName = () => {
        return <div className="gac-modal-row">
            <span className="gac-modal-row__label">Name</span>
            <div className="gac-modal-row__field">
                <input
                    data-hj-whitelist
                    className = { invalidFields.includes('name') ? 'gac-invalid' : '' }
                    data-prop = 'name'
                    value = { name }
                    type = 'text'
                    onChange = { onInputFieldChange }/>
            </div>
        </div> ;
    };
    const getTargetAudience = () => {
        return <div className="gac-modal-row">
            <span className="gac-modal-row__label">Target audience</span>
            <div className="gac-modal-row__field">
                <input
                    data-hj-whitelist
                    data-prop = 'target_audience'
                    value = { isNil(target_audience) ? '' : target_audience }
                    type = 'text'
                    onChange = { onInputFieldChange }/>
            </div>
        </div> ;
    };
    const getDescription = () => {
        return <div className="gac-modal-row">
            <span className="gac-modal-row__label gac-label-with-hint">
                Description
                <Hint
                    text = { [] }
                    position = 'bottom'
                    title = ''>
                        <div><strong>What should I include?</strong></div>
                        <div>Brand profiles help your designer understand how your brand should appear and feel to your customers. They also serve as a space to include helpful assets such as colors, logos, and fonts that can then be attached to any design request. There is no limit to the number of brands you can add to each account.</div>
                        <div>Consider including information such as:</div>
                        <div>- What is your brand's mission and vision? <br/>
                        - What words would you use to describe your brand's personality? <br/>
                        - What are your brand's history, values, and stories? <br/>
                        - What are some things you'd like your designer to keep in mind when designing for your brand?</div>
                </Hint>
            </span>
            <div className="gac-modal-row__field">
                <textarea data-hj-whitelist data-prop = 'description' value = { isNil(description) ? '' : description } onChange = { onInputFieldChange } />
            </div>
        </div> ;
    };
    const getColors = () => {
        const brandColorsData = isNil(colors) ? [] : colors.map((item, i) => {
            return(
                <div className="gac-color-picker" key = { i }>
                    <span data-item = { item } onClick = { onColorRemove } className="gac-remove">x</span>
                    <div className="gac-color-picker-value" style = {{
                        backgroundColor: isNil(item) ? null : item,
                        borderColor: item === '#ffffff' ? '#D9D9D9' : isNil(item) ? '#D9D9D9' : item
                    }}/>
                </div>
            );
        });

        return <div className="gac-modal-row">
            <span className="gac-modal-row__label">Brand colors</span>
            <div className="gac-modal-row__field gac-brand-colors">
                { brandColorsData }
                <div ref = { colorPickerRef } className="gac-color-picker">
                    <div className='gac-color-picker-value'
                         onClick = { toggleColorPicker }
                         style = {{
                             backgroundColor: isNil(new_color) ? null : new_color,
                             borderColor: new_color === '#ffffff' ? '#D9D9D9' : isNil(new_color) ? '#59C69F' : new_color
                         }} >
                        { isNil(new_color)
                            ? <svg width="12" height="12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 1a1 1 0 012 0v10a1 1 0 11-2 0V1z" fill="#59C69F"/><path d="M1 7a1 1 0 010-2h10a1 1 0 110 2H1z" fill="#59C69F"/></svg>
                            : null }
                    </div>
                    { isColorPicker
                        ? <ChromePicker
                            color = { isNil(new_color) ? '#59C69F' : new_color }
                            disableAlpha = { true }
                            onChange = { onColorChange } />
                        : null }
                </div>
            </div>
        </div> ;
    };
    const getAssets = () => {
        const brandAssetsData = isNil(brand_assets) ? [] : brand_assets.map(({ file_id, filename, url }, i) => {
            return <a  key = { file_id } href = { url } title = { filename } target='_blank' rel="noopener noreferrer" className="gac-brand-asset">
                <span data-id = { file_id } onClick = { onFileRemove } className="gac-remove">x</span>
                { ( filename.includes('.png') || filename.includes('.jpg') || filename.includes('.jpeg') )
                    ? <img src = { url } alt=""/>
                    : <span className="gac-filename">{ filename }</span> }
            </a>;
        });

        return <div className="gac-modal-row">
            <span className="gac-modal-row__label">Brand assets</span>
            <div className="gac-modal-row__field gac-brand-assets">
                { brandAssetsData }
                <label htmlFor="assets">
                    <input id='assets' onChange = { onAssetsFieldChange } type="file" multiple/>
                    <svg width="12" height="12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 1a1 1 0 012 0v10a1 1 0 11-2 0V1z" fill="#59C69F"/><path d="M1 7a1 1 0 010-2h10a1 1 0 110 2H1z" fill="#59C69F"/></svg>
                </label>
            </div>
            { isUploadFailed && <div className = 'gac-error'>Upload Failed</div> }
            { isUploadAborted && <div className = 'gac-error'>Upload Aborted</div> }
        </div>
    };
    const getBtn = () => {
        return <div className="gac-btn-wrap">
            <div className='gac-btn gac-btn-s' onClick = { onSubmit }>
                { isActiveBrand ? 'Update' : 'Create' } profile
            </div>
            { isActiveBrand && !isClientFn(user_role) && !isSelfClientFn(user_role) && <div className='gac-btn-v2 gac-btn-s' onClick = { onDeactivateProfile }>Deactivate profile</div> }
        </div> ;
    };

    return <div ref = { modalRef } className = 'gac-modal-create-brand'>
        <div className="gac-modal-mobile-wrap">
            <span className = 'gac-close-modal' onClick = { closeModal }/>
            { getTitle() }
            { getName() }
            { getTargetAudience() }
            { getDescription() }
            { getColors() }
            { getAssets() }
        </div>
        { getBtn() }
    </div> ;
};