// Core
import { useEffect, useLayoutEffect, useRef, useState } from "react";

// Components
import Masonry from 'react-masonry-component';
import { RemoveScroll } from 'react-remove-scroll';
import { Scrollbars } from 'react-custom-scrollbars-2';
import Select from '../../common/Select';

// Icons
import Mark from '../../icons/Mark';
import Close from '../../icons/Close';

// Hooks
import { useModals } from "../../../hooks/useModals";
import { useUser } from "../../../hooks/useUser";

// Instruments
import { onOutsideElClick } from "../../../helpers/helpers";
import { days } from '../../../helpers/constants';
import { isNil, isEmpty, includes } from "ramda";
import { talentsState } from "../../../helpers/constants";

export const ModalTalents = ({ closeModal, setPos }) => {
    /* Ref */
    const modalRef = useRef(null);
    const hintRef = useRef(null);

    /* State */
    const [autoHeightMax, setAutoHeightMax] = useState(340);
    const [writers, setWriters] = useState([]);
    const [hintId, setHintId] = useState(null);
    const [activeFor, setActiveFor] = useState(0);
    const [isWritersInvalid, setIsWritersInvalid] = useState(false);
    const [isActiveForInvalid, setIsActiveForInvalid] = useState(false);

    /* Hooks */
    const { modalTalentsData: { invite_writers, invite_writer_active_for, level: projectLevel, job_type }, setModalState } = useModals();
    const isDesigners = includes(job_type, 'design/motion');
    const isProofreaders = includes(job_type, 'proofreading');
    const type = isDesigners ? 'designers' : 'copywriters';
    const label = isDesigners ? 'designers' : isProofreaders ? 'proofreaders' : 'writers';
    const labelSingle = isDesigners ? 'designer' : isProofreaders ? 'proofreader' : 'writers';
    const { talents, setUserState, updateTalentAsync } = useUser();

    /* Actions */
    const toggleSelectAll = () => {
        const selectedAll = talents[type].every(({ writer_uid }) => writers.includes(writer_uid));
        let copywriters = talents[type];
        if ( projectLevel === 'standard' ) {
            copywriters = copywriters.filter(o => !includes(o.level, 'Pro/Expert'));
        }
        const data = selectedAll ? [] : copywriters.map(({ writer_uid }) => writer_uid);

        setWriters(data);
        setIsWritersInvalid(isEmpty(data));
    };
    const toggleWriter = ({ currentTarget: { dataset: { id }}}) => {
        const data = writers.includes(id) ? writers.filter((item) => item !== id) : [ ...writers, id ];

        setWriters(data);
        setIsWritersInvalid(isEmpty(data));
    };
    const toggleStatus = ({ currentTarget: { dataset: { status, id:writer_uid }}}) => {
        const data = {
            type,
            writer_uid,
            status: status === 'regular' ? 'favorite' : 'regular',
        };
        updateTalentAsync(data,'update_status');
    };
    const onShowHint = ({ currentTarget: { dataset: { id }}}) => {
        setHintId(id);
    };
    const onActiveForChange = (prop, value) => {
        setActiveFor(value);
        setIsActiveForInvalid(false);
    };
    const onSubmit = () => {
        if ( activeFor && writers.length ) {
            setModalState('inviteTalentsObj', { invite_writers: talents[type].filter(({ writer_uid }) => writers.includes(writer_uid)), invite_writer_active_for: activeFor });
            setModalState('modalTalentsData', null);
            closeModal();
        } else {
            setIsActiveForInvalid(!activeFor);
            setIsWritersInvalid(!writers.length);
        }
    };

    useLayoutEffect(() => {
        const onResize = () => {
            if ( window.innerWidth < 850 ) {
                setAutoHeightMax(window.innerHeight - ( window.innerWidth < 573 ? 247 : window.innerWidth < 768 ? 217 : 209 ));
            } else {
                setAutoHeightMax(340);
            }
            //this.masonry.performLayout();
        };

        const data = invite_writers ? invite_writers : [];
        setWriters(data.map(({ writer_uid }) => writer_uid));
        if ( invite_writer_active_for ) {
            setActiveFor(invite_writer_active_for);
        }
        setAutoHeightMax(window.innerWidth < 850 ? window.innerHeight - ( window.innerWidth < 573 ? 247 : window.innerWidth < 768 ? 217 : 209 ) : 340);
        window.addEventListener('resize', onResize);

        return () => {
            setUserState('talents', talentsState);
            window.removeEventListener('resize', onResize);
        };
    }, []);
    useEffect(() => {
        if ( autoHeightMax === 340 ) {
            setPos( modalRef );
        }
    }, [autoHeightMax]);
    useEffect(() => {
        const onOutsideClick = (e) => {
            onOutsideElClick(e,hintRef.current,() => { setHintId(null) });
        };

        if ( hintId ) {
            document.addEventListener('click', onOutsideClick, true);
            document.addEventListener('touchstart', onOutsideClick, true);
        }

        return () => {
            document.removeEventListener('click', onOutsideClick, true);
            document.removeEventListener('touchstart', onOutsideClick, true);
        };
    }, [hintId]);

    /* Html */
    const getSelectField = () => {
        return <div className = 'gac-writers-text-wrap'>
            <span className = 'gac-writers-text'>Offer to selected { label } for</span>
            <Select
                isMultiple = { false }
                items = { days }
                value = { `${activeFor}` }
                title = { activeFor ? days.filter((item) => item.value === `${activeFor}`)[0].title : '' }
                parameter = 'activeFor'
                invalid = { isActiveForInvalid }
                onChange = { onActiveForChange }/>
            <span className = 'gac-writers-text'>,</span>
            <span className = 'gac-writers-text'> then make available to all { label }</span>
        </div> ;
    };
    const getTalents = () => {
        const copywriters = [ ...talents[type] ]
            .sort((a,b) => a.status === 'favorite' && b.status !== 'favorite' ? -1 : a.status !== 'favorite' && b.status === 'favorite' ? 1 : 0 )
            .map(({ writer_uid, level, project_count, avg_project_rating, writer_avatar_url, writer_first_name, writer_last_name, notes, status }) => {
                const isActive = writers.includes(writer_uid);
                const isDisabled = !isEmpty(projectLevel) && projectLevel === 'standard' && level !== 'Standard';

                return <div key = { writer_uid } className = { `gac-writer-item ${ isActive ? 'gac-active' : ''} ${ isDisabled ? 'gac-disabled' : ''}` }>
                    <div className = 'gac-writer-item-head'>
                        <div className = { `gac-writer-item-avatar ${!writer_avatar_url ? 'gac-no-avatar' : ''}` }>
                            { writer_avatar_url && <img src = { writer_avatar_url } alt = "Some desc"/> }
                            { isActive && <><div className = 'gac-active'/><i/></> }
                        </div>
                        <div className = 'gac-writer-info'>
                            <div className = 'gac-writer-name-wrap'>
                                <div className = 'gac-writer-item-name'>{ `${writer_first_name} ${writer_last_name}` }</div>
                            </div>
                            <div className = 'gac-writer-icons'>
                                { level !== 'Standard' && <div className = { `gac-writer-level ${ isNil(level) ? '' : level.toLowerCase()}` }><i/><span>{ level }</span></div> }
                                <span className = 'gac-writer-project-count'><i/>{ project_count }</span>
                                <span className = 'gac-writer-project-rating'><i/>{ avg_project_rating }</span>
                                <div data-status = { status } className = 'gac-writer-status'/>
                            </div>
                        </div>
                    </div>
                    { notes && <div className = 'gac-writer-notes'>{ notes }</div> }
                    <div className='gac-writer-item-btns'>
                        <div className = 'gac-btns-overlay'/>
                        <span data-id = { writer_uid } onClick = { isDisabled ? onShowHint : toggleWriter }><i className = 'gac-icon-1'/></span>
                        <span data-id = { writer_uid } data-status = { status } onClick = { toggleStatus }><i className = 'gac-icon-2'/></span>
                    </div>
                </div> ;
            });

        const selectedAll = talents[type].every(({ writer_uid }) => writers.includes(writer_uid));
        let writerLevel = isNil(hintId) ? '' : talents[type].filter(({ writer_uid }) => writer_uid === hintId)[0].level ;

        return <div className = 'gac-writers-list-wrap'>
            { isWritersInvalid && isNil(hintId) && <div className = 'gac-writers-invalid'>Please select at least one { labelSingle }</div> }
            { !isNil(hintId) && <div style={{ left: '50%', transform: 'translateX(-50%)' }} ref = { hintRef } className = 'gac-writers-invalid'>
                { writerLevel === 'Pro' ? 'A Pro writer can be invited only to a Pro level project' : 'An Expert writer can be invited only to a Pro or Expert level project' }
            </div> }
            <Scrollbars
                className = ''
                autoHeight
                autoHeightMax = { autoHeightMax }
                renderTrackVertical = { () => <div className = 'gac-track-vertical'/> }
                renderThumbVertical = { ({ style, ...props }) => <div style={{ ...style }} {...props}/> }>
                <Masonry className = { 'gac-writers-list' } options = {{ transitionDuration: 0, gutter: 16 }} disableImagesLoaded = { true }>
                    { copywriters.length > 1 &&
                        <div onClick = { toggleSelectAll } className = 'gac-writer-item-all'>
                            { selectedAll ? <i className='gac-icon-deselect'><Close/></i> : <i><Mark/></i> }
                            <span>{ selectedAll ? 'Deselect all' : 'Select all' }</span>
                        </div> }
                    { copywriters }
                </Masonry>
            </Scrollbars>
        </div> ;
    };
    const getBtn = () => {
        return <div className = 'gac-writers-btn'>
            <span onClick = { onSubmit } className = 'gac-btn gac-btn-s'>Confirm</span>
        </div> ;
    };

    return <RemoveScroll>
        <div ref = { modalRef } className = 'gac-modal gac-writers-modal'>
            <span className = 'gac-close-modal' onClick = { closeModal }/>
            <h1>Select { label }</h1>
            { getSelectField() }
            { getTalents() }
            { getBtn() }
        </div>
    </RemoveScroll> ;
};