// Core
import { call, put, select } from 'redux-saga/effects';

// Instruments
import { api, getKeys, authStr } from '../../../../helpers/api';
import { uiActions } from '../../../ui/actions';
import moment from 'moment/moment';

export function* callDownloadTransactionsWorker ({ payload: { id, range }}) {
    try {
        yield put(uiActions.enableFetching());
        const { privateKey, publicKey } = yield select(getKeys);
        let from, to = '';
        let title = 'all_time';
        const url = range ? `?${range}` : '';
        const response = yield call(fetch, `${api}/${publicKey}/v1/transactions${id ? `/${id}` : ''}/download${url}`,{
            method: 'GET',
            headers: {
                Authorization: `${authStr} ${privateKey}`
            }
        });
        const blob = yield call([response, response.blob]);

        if (response.status !== 200) {
            throw new Error('Error');
        }
        if ( range ) {
            const a = range.split('&');
            from = a[0].split('=')[1].split(' ')[0];
            to = a[1].split('=')[1].split(' ')[0];
            title = `from_${moment(from).format('MMM-DD-YYYY')}_to_${moment(to).format('MMM-DD-YYYY')}`;
        }

        /* Blob */
        const newBlob = new Blob([blob], {type: "application/text/csv"});
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
        }
        const blobUrl = window.URL.createObjectURL(newBlob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = `Payments_${title}.csv`;
        document.body.appendChild(link);
        link.click();
        link.remove();
        setTimeout(function(){
            window.URL.revokeObjectURL(blobUrl);
        }, 100);

    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
        yield put(uiActions.setUiState('notification', {
            msg: error.message,
            type: 'error',
            fn: null,
        }));
    } finally {
        yield put(uiActions.disableFetching());
    }
}
