// Core
import { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from 'react-router-dom';

// Components
import { ProjectType } from '../ProjectType';
import { Draggable } from "react-beautiful-dnd";
import Dotdotdot from 'react-dotdotdot';

// Hooks
import { useProject } from "../../../hooks/useProject";
import { useUi } from "../../../hooks/useUi";
import { useUser } from "../../../hooks/useUser";

// Instruments
import {
    toLocaleString,
    getDuration,
    addUtcOffset,
    isClientFn,
    isSelfClientFn,
    addSuffix, onOutsideElClick,
} from '../../../helpers/helpers';
import moment from 'moment/moment';
import { isNil, includes, isEmpty } from 'ramda';

export const Project = ({ project, format, page, messagesCount, isSorting, isActiveDesignSub, toggleSorting, isActiveWebBuildingSub }) => {
    /* Ref */
    // const feedbackRef = useRef(null);
    // const iconRef = useRef(null);
    const actionsRef = useRef(null);
    const teamListRef = useRef(null);

    /* State */
    // const [isFeedback, setIsFeedback] = useState(false);
    const [isActions, setIsActions] = useState(false);

    /* Hooks */
    const { deleteProjectAsync, fetchProjectDetailsAsync } = useProject(); // details: { project_id, feedback },
    const { isMobile, setUiState } = useUi();
    const { details: { user_role, timezone }} = useUser();
    const navigate = useNavigate();

    /* Actions */
    const onCloseActions = (e) => {
        e.stopPropagation();
        setIsActions(false);
    };
    const toggleProjectActions = () => {
        setIsActions(state => !state);
    };
    const toggleSortingMode = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const { currentTarget: { dataset: { id }}} = e;

        toggleSorting(id);
    };
    const onMouseOver = () => {
        const teamList = teamListRef.current;
        const rect = teamList.getBoundingClientRect();
        if ( rect.top < 112 ) {
            const offset = teamList.dataset.offset;
            teamList.style.top = 'auto';
            teamList.style.bottom = offset;
            teamList.classList.add('bottom');
        }
    };
    const onMouseOut = () => {
        const teamList = teamListRef.current;
        const offset = teamList.dataset.offset;
        teamList.style.bottom = 'auto';
        teamList.style.top = offset;
        teamList.classList.remove('bottom');
    };
    // const showFeedback = (e) => {
    //     e.preventDefault();
    //
    //     if ( !isMobile ) {
    //         setIsFeedback(state => !state);
    //     }
    // };

    /* Project actions */
    const onProjectClick = ({ currentTarget: { dataset: { path }}}) => {
        if ( !isSorting ) navigate(path);
    };
    const onMobileProjectClick = (e) => {
        if ( isSorting ) e.preventDefault();
    };
    // const onExtendReview = () => {};
    const onProjectDelete = () => {
        const { project_id } = project;
        deleteProjectAsync(project_id,'delete_from_manage');
    };
    const onProjectDuplicate = () => {
        const { project_id } = project;
        fetchProjectDetailsAsync(project_id,'duplicating',navigate);
    };
    const onMessageWriter = () => {
        setUiState('shouldOpenMessageForm', true);
    };

    useEffect(() => {
        const onOutsideClick = (e) => {
            onOutsideElClick(e,actionsRef.current,() => { setIsActions(false) });
        };

        if ( isActions ) {
            document.addEventListener('click', onOutsideClick, true);
            document.addEventListener('touchstart', onOutsideClick, true);
        }

        return () => {
            document.removeEventListener('click', onOutsideClick, true);
            document.removeEventListener('touchstart', onOutsideClick, true);
        };
    }, [isActions]);
    // useEffect(() => {
    //     const onOutsideClick = (e) => {
    //         let target = e.target;
    //
    //         do {
    //             if (target === feedbackRef.current || target === iconRef.current) {
    //                 return;
    //             }
    //             target = target.parentNode;
    //         } while (target);
    //
    //         setIsFeedback(false);
    //     };
    //
    //     if ( project.project_id === project_id && !isNil(feedback) ) {
    //         const rect = feedbackRef.current.getBoundingClientRect();
    //         const w = document.body.clientWidth;
    //
    //         if (rect.right > w) {
    //             feedbackRef.current.style.marginLeft = `-${rect.right - w}px`;
    //         }
    //
    //         document.addEventListener('click', onOutsideClick, true);
    //         document.addEventListener('touchstart', onOutsideClick, true);
    //     }
    //
    //     //     componentDidUpdate(props) {
    //     //         const { feedbackText } = this.props;
    //     //         if ( feedbackText && props.feedbackText !== feedbackText && !isNil(this.feedback)) {
    //     //         }
    //     //     }
    //
    //     return () => {
    //         document.removeEventListener('click', onOutsideClick, true);
    //         document.removeEventListener('touchstart', onOutsideClick, true);
    //     };
    // }, [feedback]);
    // useEffect(() => {
    //     if ( isFeedback ) {
    //         // this.setState({
    //         //     isFeedback: !isFeedback,
    //         // }, () => {
    //         //     if ( !feedbackText ) {
    //         //         this.props.fetchFeedback(project_id);
    //         //     }
    //         // });
    //     }
    // }, [isFeedback]);

    /* Html */
    const getSortingIcon = () =>  <div className="gac-sorting-icon"><i/><i/><i/></div>;
    const getItemStyle = (draggableStyle, isDragging) => ({
        userSelect: 'none',
        background: isDragging ? '#ffffff' : null,
        boxShadow: isDragging ? '0px 0px 24px rgba(0, 0, 0, 0.16)' : null,
        ...draggableStyle
    });
    const generateTeam = (team) => {
        let myTeamHtml, teamList;
        if ( team.length > 1 && team.length < 4 ) {
            myTeamHtml = team.reverse()
                .map((item, i) => {
                    return(
                        <div
                            className = 'gac-my-team-item'
                            key = { i }>
                            <div className = 'gac-my-team-item-avatar'/>
                            <div className = 'gac-my-team-item-name'>
                                { `${item.writer_first_name} ${item.writer_last_name}` }
                            </div>
                        </div>
                    );
                });
        } else if ( team.length > 3 ) {
            myTeamHtml = team.filter((item, i) => i <= 2)
                .reverse()
                .map((item, i) => {
                    return(
                        <div
                            className = 'gac-my-team-item'
                            key = { i }>
                            <div className = 'gac-my-team-item-avatar'/>
                            <div className = 'gac-my-team-item-name'>
                                { `${item.writer_first_name} ${item.writer_last_name}` }
                            </div>
                        </div>
                    );
                });

            teamList = team.filter((item, i) => i > 2 && i < 13)
                .map((item, i) => {
                    return(
                        <div key = { i }>{ `${item.writer_first_name} ${item.writer_last_name}` }</div>
                    );
                });
        }

        return (
            team.length === 0
                ? null
                : team.length === 1
                    ? <>
                        <div className = 'gac-writer-avatar'/>
                        <div className = 'gac-writer-name'>
                            { `${ team[0].writer_first_name } ${team[0].writer_last_name}` }
                        </div>
                    </>
                    : <div className = { `gac-my-team ${ team.length < 4 ? 'gac-my-team-less' : '' } ${ team.length === 2 ? 'gac-my-team-2' : '' }` }>
                        { myTeamHtml }
                        { team.length > 3
                            ? <div className = "gac-my-team-rest" onMouseOver = { onMouseOver } onMouseOut = { onMouseOut }>
                                { `+${team.length - 3}` }
                                <div
                                    data-offset = { `-${40 + 22*(teamList.length - (team.length > 13 ? 0 : 1))}px` }
                                    className = "gac-my-team-rest-list"
                                    ref = { teamListRef }
                                    style = {{ top: `-${40 + 22*(teamList.length - (team.length > 13 ? 0 : 1))}px` }}>
                                    { teamList }
                                    { team.length > 13 && <div>{ `+ ${team.length - 13} more` }</div> }
                                </div>
                            </div>
                            : null }
                    </div>
        );
    };
    const renderActions = () => {
        const { status, project_id } = project;
        const styles = {
            display: 'flex',
            alignItems: 'center',
            padding: '0 30px 0 16px',
        };

        return isClientFn(user_role)
            ? null
            : <div className = 'gac-project-actions' onClick = { toggleProjectActions } ref = { actionsRef }>
                <i className='gac-actions-icon'><i/><i/><i/></i>
                { isActions
                    ? <ul>
                        <li className='gac-project-actions__close' onClick = { onCloseActions }>
                            <svg width="8" height="8" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M1.16 7.8a.68.68 0 01-.96-.96L3.038 4 .199 1.16A.68.68 0 111.16.2L4 3.038 6.84.199a.68.68 0 01.96.961L4.962 4l2.84 2.84a.68.68 0 11-.961.96L4 4.962l-2.84 2.84z" fill="#363737"/></svg>
                        </li>
                        { 'writing/revision/editing/approved/auto/cancelled'.includes(status) && !isSelfClientFn(user_role) &&
                        <li>
                            <Link onClick = { onMessageWriter } to = { `/project/${project_id}/messages` } style = { styles }>
                                <svg width="14" height="14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.981 1.685c.712 0 1.406.138 2.063.41a5.335 5.335 0 011.746 1.171 5.18 5.18 0 011.174 1.81c.262.683.376 1.402.339 2.135a5.285 5.285 0 01-1.468 3.378 5.352 5.352 0 01-3.854 1.643 5.31 5.31 0 01-1.724-.286.697.697 0 00-.342-.028l-2.288.393a.307.307 0 01-.05.004.17.17 0 01-.059-.008l-.008-.004a.201.201 0 01-.111-.263l.003-.008.495-1.165a.681.681 0 00-.092-.688 5.228 5.228 0 01-.751-1.306 4.941 4.941 0 01-.361-1.752v-.007A5.216 5.216 0 013.134 3.34a5.303 5.303 0 013.847-1.654s0 0 0 0zm0-.685c-.051 0-.102 0-.153.002C3.505 1.1.904 3.837 1.003 7.135c.014.687.155 1.36.41 1.992.212.534.495 1.025.848 1.474l-.495 1.165a.883.883 0 00.976 1.22l2.29-.393a6.005 6.005 0 002.545.295c2.955-.294 5.26-2.694 5.415-5.641a5.888 5.888 0 00-1.71-4.463A6.069 6.069 0 006.98 1z" fill="#1D1E1E" stroke="#1D1E1E" strokeWidth=".2"/><path d="M9 5.331c0 .213-.172.4-.368.4H5.368c-.196 0-.368-.175-.368-.4 0-.212.161-.4.368-.4h3.264c.196 0 .368.175.368.4zM9 6.932c0 .224-.172.4-.368.4H5.368c-.196 0-.368-.176-.368-.4 0-.225.161-.4.368-.4h3.264c.196 0 .368.175.368.4zM7.4 8.531c0 .225-.192.4-.439.4H5.44c-.233 0-.439-.175-.439-.4 0-.212.192-.4.439-.4H6.96c.233 0 .439.175.439.4z" fill="#1D1E1E" stroke="#1D1E1E" strokeWidth=".2"/></svg>
                                Message writer
                            </Link>
                        </li> }
                        {/* status === 'approval' && <li onClick={ onExtendReview }>Extend review</li> */}
                        <li style={ styles } onClick = { onProjectDuplicate }>
                            <svg width="12" height="12" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M4.53.8H11c.11 0 .2.09.2.2v8.106a.2.2 0 01-.2.2H9.177v.8H11a1 1 0 001-1V1a1 1 0 00-1-1H4.53a1 1 0 00-1 1v.263h.8V1c0-.11.089-.2.2-.2z" fill="#1D1E1E"/><rect x=".4" y="2.294" width="7.671" height="9.306" rx=".6" stroke="#1D1E1E" strokeWidth=".8"/></svg>
                            Duplicate
                        </li>
                        { status === 'draft' && <li style={ styles } onClick = { onProjectDelete }>
                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="12"><path fill="#1D1E1E" d="M6.8 12H3.2C1.9 12 .9 11 .9 9.7V4.2c0-.3.2-.5.5-.5s.5.2.5.5v5.5c0 .8.6 1.4 1.4 1.4h3.6c.8 0 1.4-.6 1.4-1.4V4.2c0-.3.2-.5.5-.5s.5.2.5.5v5.5C9.1 11 8.1 12 6.8 12zM9.5 2.8h-9c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h9.1c.3 0 .5.2.5.5s-.3.5-.6.5z"/><path fill="#1D1E1E" d="M6.8 2.8H3.2c-.3 0-.5-.2-.5-.5v-.9C2.7.6 3.3 0 4.1 0h1.8c.8 0 1.4.6 1.4 1.4v.9c0 .3-.2.5-.5.5zm-3.2-1h2.7v-.4c0-.3-.2-.5-.5-.5H4.1c-.3 0-.5.2-.5.5v.4zM4.1 9.2c-.3 0-.5-.2-.5-.4V5.5c0-.3.2-.5.5-.5s.5.2.5.5v3.2c-.1.3-.3.5-.5.5zM5.9 9.2c-.2 0-.4-.2-.4-.4V5.5c0-.3.2-.5.5-.5s.5.2.5.5v3.2c-.1.3-.3.5-.6.5z"/></svg>
                            Delete
                        </li> }
                    </ul>
                    : null }
            </div>;
    };
    const renderDraft = (format, isMobile) => {
        const { word_count, idea_count, currency, client_amount, invite_writers, level, order_on, job_type } = project;
        const team = invite_writers ? JSON.parse(invite_writers) : [];

        return (
            <>
                <span className = 'gac-manage-project-format' title = { `${format} ${ word_count ? `${toLocaleString(word_count.toString())}w` : '' }` }>
                    { isMobile && <div className = 'gac-manage-project-label'>Format</div> }
                    { job_type === 'seoaudit'
                        ? 'SEO audit'
                        : job_type === 'webaudit'
                        ? 'Website audit'
                        : job_type === 'keywords'
                        ? 'Keyword research'
                        : job_type === 'ideas'
                            ? `${idea_count} topic ideas`
                            : <>
                                { format || (word_count !== '0' && word_count !== null)
                                    ? `${format} ${ word_count ? `${toLocaleString(word_count.toString())}${job_type === 'ideas' ? 'i' : 'w'}` : '' }`
                                    : null }
                            </> }
                </span>
                { !isSelfClientFn(user_role) && <span className = 'gac-manage-project-amount'>
                    { isMobile && <div className = 'gac-manage-project-label'>Amount</div> }
                    { client_amount !== '0.00' && client_amount ? `${currency}${toLocaleString(client_amount)}` : null }
                    { level === 'pro'
                        ? <i className = 'gac-level-premium'/>
                        : level === 'expert'
                            ? <i className = 'gac-level-expert'/>
                            : null }
                </span> }
                <span className = 'gac-manage-project-writer'>
                    { isMobile && <div className = 'gac-manage-project-label'>Assignee</div> }
                    { generateTeam(team, job_type) }
                </span>

                <span className = 'gac-manage-project-order'>
                    { isMobile && <div className = 'gac-manage-project-label'>Order</div> }
                    { getDuration(moment(order_on).diff(moment())).duration }
                </span>
            </>
        );
    };
    const renderCurrent = (isMobile) => {
        const { status, invite_writers, writer_name, deadline, job_type, due, project_id, designer_logo } = project;
        const team = invite_writers ? JSON.parse(invite_writers) : [];
        const { duration } = getDuration(moment.utc(deadline).diff(moment.utc()));
        const isDesign = job_type === 'design';

        return (
            <>
                <span className = 'gac-manage-project-status'>
                    { isMobile && <div className = 'gac-manage-project-label'>Status</div> }
                    <div className = 'gac-manage-project-status-wrapper'>
                        <div className = { `gac-manage-project-status__bar ${status}` }/>
                    </div>
                    <div style={{ textTransform: 'capitalize' }} className = 'gac-manage-project-status-text'>
                        { status === 'matching' ? 'Queued' : isDesign && status === 'revision' ? 'Designing' : isDesign && status === 'editing' ? 'Review' : status }
                    </div>
                </span>
                <span className = 'gac-manage-project-writer'>
                    { isMobile && <div className = 'gac-manage-project-label'>Assignee</div> }
                    { 'pitching,matching'.includes(status)
                        ? generateTeam(team)
                        : writer_name
                            ? <>
                                { job_type === 'design' && !isEmpty(designer_logo) && !isNil(designer_logo)
                                    ? <img className='gac-img' src={ designer_logo } alt="Designer logo"/>
                                    : <div className = 'gac-writer-avatar'/> }
                                <div className = 'gac-writer-name'>
                                    { writer_name }
                                </div>
                            </>
                            : null }
                </span>
                <span className = 'gac-manage-project-due'>
                    { isMobile && <div className = 'gac-manage-project-label'>Due</div> }
                    { isDesign && status === 'matching' && (isActiveDesignSub || isActiveWebBuildingSub)
                        ? <>
                            { addSuffix(due) }
                            { !isSorting && !isMobile && <em className='gac-change-queue'>
                                <i data-id={ project_id } onClick={ toggleSortingMode }>
                                    <svg width="19" height="14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M4.137 13.283V2.408l-2.39 2.456a.648.648 0 01-.941 0 .746.746 0 010-1.03L4.343.202a.648.648 0 01.94 0L8.82 3.834a.746.746 0 010 1.03.648.648 0 01-.94 0L5.488 2.408v10.875c0 .417-.324.717-.676.717-.353 0-.676-.3-.676-.717zM15.063.717V11.59l2.39-2.455a.648.648 0 01.941 0 .746.746 0 010 1.03l-3.537 3.632a.648.648 0 01-.94 0l-3.537-3.633a.746.746 0 010-1.029.648.648 0 01.94 0l2.391 2.456V.716c0-.417.324-.717.676-.717.353 0 .676.3.676.717z" fill="#AEAEAF"/></svg>
                                </i>
                            </em> }
                        </>
                        : includes(status, 'designing/pitching/matching') || (isDesign && status === 'revision' ) ? null : duration }
                </span>
            </>
        );
    };
    const renderCompleted = (isMobile) => {
        const { status, rating, publish_url, writer_name, deadline, /*feedback,*/ job_type, designer_logo } = project;
        const deadlineData = isNil(timezone) ? deadline : addUtcOffset(deadline, timezone);

        return (
            <>
                <span className = { `gac-manage-project-status gac-completed-page ${status}` }>
                    { isMobile && <div className = 'gac-manage-project-label'>Status</div> }
                    <div className = 'gac-manage-project-status-wrapper'/>
                    <div className = 'gac-manage-project-status-text'>
                        { !isNil(publish_url)
                            ? 'Published'
                            : status === 'auto'
                                ? 'Auto-approved'
                                : <div className = 'gac-manage-project-status-lable'>{ status }</div> }
                    </div>
                </span>
                <span className = 'gac-manage-project-writer'>
                    { isMobile && <div className = 'gac-manage-project-label'>Assignee</div> }
                    { writer_name && (
                        <>
                            { job_type === 'design' && !isEmpty(designer_logo) && !isNil(designer_logo)
                                ? <img className='gac-img' src={ designer_logo } alt="Designer logo"/>
                                : <div className = 'gac-writer-avatar'/> }
                            <div className = 'gac-writer-name'>
                                { writer_name }
                            </div>
                        </> )}
                </span>
                <span className = 'gac-manage-project-rating'>
                    { isMobile && <div className = 'gac-manage-project-label'>Rated</div> }
                    { rating }
                    {/* feedback !== 'no' && <div className="gac-manage-project-feedback">
                        <i onClick = { showFeedback } className = 'gac-feedback-icon' ref = { iconRef }/>
                        { !isMobile && feedbackText && isFeedback && <div ref = { feedbackRef } className = "gac-feedback-text"><p>{ feedbackText }</p></div> }
                    </div> */}
                </span>
                <span className = 'gac-manage-project-approval'>
                    { isMobile && <div className = 'gac-manage-project-label'>Approval</div> }
                    { moment(deadlineData).format('MMM D, YYYY') }
                </span>
            </>
        );
    };
    const renderDesktop = () => {
        return <>
            { page === 'draft' && renderDraft(format, false) }
            { page === 'current' && renderCurrent(false) }
            { page === 'completed' && renderCompleted(false) }
        </>;
    };
    const renderMobile = () => {
        const { job_type, status, project_id } = project;
        const isDesign = job_type === 'design';

        return ( <>
            <div className = 'gac-manage-project-details' data-path={ `/project/${project_id}` } onClick = { onProjectClick }>
                { page === 'draft' && renderDraft(format, true) }
                { page === 'current' && renderCurrent(true) }
                { page === 'completed' && renderCompleted(true) }
            </div>
            { !isSorting && <div className="gac-project-actions-wrap">
                { isDesign && status === 'matching' && isActiveDesignSub
                    ? <em className='gac-change-queue'>
                        <i data-id = { project_id } onClick = { toggleSortingMode }>
                            <svg width="19" height="14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M4.137 13.283V2.408l-2.39 2.456a.648.648 0 01-.941 0 .746.746 0 010-1.03L4.343.202a.648.648 0 01.94 0L8.82 3.834a.746.746 0 010 1.03.648.648 0 01-.94 0L5.488 2.408v10.875c0 .417-.324.717-.676.717-.353 0-.676-.3-.676-.717zM15.063.717V11.59l2.39-2.455a.648.648 0 01.941 0 .746.746 0 010 1.03l-3.537 3.632a.648.648 0 01-.94 0l-3.537-3.633a.746.746 0 010-1.029.648.648 0 01.94 0l2.391 2.456V.716c0-.417.324-.717.676-.717.353 0 .676.3.676.717z" fill="#AEAEAF"/></svg>
                        </i>
                    </em>
                    : null }
                { renderActions() }
            </div> }
        </> );
    };
    const renderHtml = () => {
        const { project_id, project_tags, topic, job_type, talent, revision, due, status } = project;
        const link = `/${page === 'draft' ? 'order' : 'project' }/${project_id}${isClientFn(user_role) ? '/content' : ''}`;
        const messages = () => {
            return messagesCount
                ? <div className = 'gac-messages'>
                    <i>{ messagesCount }</i>
                </div>
                : null;
        };
        let tags;
        let classes = ['gac-manage-project'];

        if ( project_tags && !isClientFn(user_role) ) {
            tags = JSON.parse(project_tags).map((tag, i) => <div key = { i } style = {{ marginLeft: i === 0 ? '10px': 0 }} className = 'gac-project-tag'>{ tag }</div> );
        }
        const getLabel = () => revision && !includes(status, 'approved/auto') ? <div className='gac-revision-label'>Revision</div> : null;
        const getMobile = (provided, snapshot) => {
            const dragHandleProps = provided ? provided.dragHandleProps : {};
            const draggableProps = provided ? provided.draggableProps : {};

            return <div
                ref = { provided ? provided.innerRef : null }
                style = { provided && snapshot ? getItemStyle( provided.draggableProps.style, snapshot.isDragging ) : null }
                { ...dragHandleProps }
                { ...draggableProps }
                className = { classes.join(' ') }>
                <Link to = { link } className = 'gac-manage-project-topic' onClick = { onMobileProjectClick }>
                    <ProjectType id={`${project_id}`} type = { talent === 'bloggers' && job_type === 'writing' ? 'blogging' : job_type } />
                    <Dotdotdot className = 'gac-topic' clamp = { 2 }>
                        { topic }
                    </Dotdotdot>
                    { getLabel() }
                    { messages() }
                </Link>
                { renderMobile() }
                { isSorting && getSortingIcon() }
                { isSorting && provided ? provided.placeholder : null }
            </div>;
        };
        const getDesktop = () => {
            return <>
                <span className = 'gac-manage-project-topic'>
                    <ProjectType id={`${project_id}`} type = { talent === 'bloggers' && job_type === 'writing' ? 'blogging' : job_type } />
                    { topic }
                    { getLabel() }
                    { messages() }
                    { tags }
                </span>
                { renderDesktop() }
            </>;
        };

        return(
            isSorting
                ? <Draggable
                    draggableId = {`${project_id}`}
                    index = { due }>
                    { (provided, snapshot) => (
                        isMobile
                            ? getMobile(provided, snapshot)
                            : <div
                                className = 'gac-manage-project-wrap'
                                ref = { provided.innerRef }
                                { ...provided.dragHandleProps }
                                { ...provided.draggableProps }
                                style = { getItemStyle( provided.draggableProps.style,  snapshot.isDragging )} >
                                <div className = { classes.join(' ') }>
                                    { getDesktop() }
                                </div>
                                { getSortingIcon() }
                                { provided.placeholder }
                            </div>
                    ) }
                </Draggable>
                : isMobile
                    ? getMobile()
                    : <div className = 'gac-manage-project-wrap'>
                        <Link to = { link } className = { classes.join(' ') }>
                            { getDesktop() }
                        </Link>
                        { renderActions() }
                    </div>
        );
    };

    return renderHtml();
};