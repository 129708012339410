// Core
import React from 'react';
import { string, func } from 'prop-types';

// Images
import pixabay from "../../../theme/images/pixabay.svg";
import pexels from "../../../theme/images/pexels.svg";
import unsplash from "../../../theme/images/unsplash.svg";

const config = [
    { platform: 'pixabay', src: pixabay, width: 126 },
    { platform: 'pexels', src: pexels, width: 109 },
    { platform: 'unsplash', src: unsplash, width: 126 },
];

const ResourceSelect = React.memo(({ platform, onResourceClick }) => {
    const data = config.map(o => {
        return(
            <div
                key = { o.platform }
                data-platform = { o.platform }
                className={`gac-library__resourse-item ${ platform === o.platform ? 'gac-active' : '' }`}
                onClick={ onResourceClick }>
                <img style={{ width: o.width }} src={ o.src } alt={o.platform}/>
            </div>
        );
    });
    return (
        <div className="gac-library__resourse">
            <label>Resource</label>
            <div className="gac-library__resourse-wrap">
                { data }
            </div>
        </div>
    );
});
ResourceSelect.propTypes = {
    platform: string,
    onResourceClick: func,
};
export default ResourceSelect;