// Core
import { takeEvery } from 'redux-saga/effects';

// Instruments
import { asyncTypes } from './asyncTypes';
import { callFetchBraintreeTokenWorker } from './workers/fetchBraintreeToken';
import { callFetchStripeCardsWorker } from './workers/fetchStripeCards';
import { callDeleteStripeCardWorker } from './workers/deleteStripeCard';
import { callCheckRefundDesignWorker } from './workers/checkRefundDesign';
import { callPutRefundDesignWorker } from './workers/putRefundDesign';
import { callRetryChargeWorker } from './workers/retryCharge';
import { callStripeCompleteWorker } from './workers/stripeComplete';

export const paymentsWatchers = Object.freeze({
    * watchFetchBraintreeToken () {
        yield takeEvery(asyncTypes.FETCH_BRAINTREE_TOKEN_ASYNC, callFetchBraintreeTokenWorker);
    },
    * watchFetchStripeCards () {
        yield takeEvery(asyncTypes.FETCH_STRIPE_CARDS_ASYNC, callFetchStripeCardsWorker);
    },
    * watchDeleteStripeCard () {
        yield takeEvery(asyncTypes.DELETE_STRIPE_CARD_ASYNC, callDeleteStripeCardWorker);
    },
    * watchCheckRefundDesign () {
        yield takeEvery(asyncTypes.CHECK_REFUND_DESIGN_ASYNC, callCheckRefundDesignWorker);
    },
    * watchPutRefundDesign () {
        yield takeEvery(asyncTypes.PUT_REFUND_DESIGN_ASYNC, callPutRefundDesignWorker);
    },
    * watchRetryCharge () {
        yield takeEvery(asyncTypes.RETRY_CHARGE_ASYNC, callRetryChargeWorker);
    },
    * watchStripeComplete () {
        yield takeEvery(asyncTypes.STRIPE_COMPLETE_ASYNC, callStripeCompleteWorker);
    },
});
