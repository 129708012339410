// Core
import React, { PureComponent } from 'react';
import { CSSTransition } from 'react-transition-group';
import { func, string } from 'prop-types';
import cx from 'classnames';

import Checkbox from '../../icons/Checkbox';
import CheckedCheckbox from '../../icons/CheckedCheckbox';

export default class FilterItemColors extends PureComponent {
    static propTypes = {
        onChange:  func.isRequired,
        colors:    string.isRequired,
        title:     string.isRequired,
        parameter: string.isRequired,
    };

    state = {
        isOpened: false,
    };

    componentWillUnmount() {
        document.removeEventListener('click', this._onOutsideListClick);
        document.removeEventListener('touchstart', this._onOutsideListClick);
    }

    _onOutsideListClick = (e) => {
        let target = e.target;

        do {
            if (target === this.item) {
                return;
            }
            target = target.parentNode;
        } while (target);

        this.setState({
            isOpened: false,
        });
    };
    _toggleList = () => {
        const { isOpened } = this.state;

        this.setState({
            isOpened: !isOpened,
        }, () => {
            if ( isOpened ) {
                document.removeEventListener('click', this._onOutsideListClick);
                document.removeEventListener('touchstart', this._onOutsideListClick);
            } else {
                document.addEventListener('click', this._onOutsideListClick);
                document.addEventListener('touchstart', this._onOutsideListClick);
            }
        });
    };

    _onChange = ({ target: { checked, value }}) => {
        this.props.onChange(checked, value);
    };

    render () {
        const { direction, colors, title } = this.props;
        const { isOpened } = this.state;
        const styles = cx('gac-filter-item', {
            'gac-clicked': isOpened,
        });

        return (
            <div className = { styles } ref = {ref => this.item = ref }>
                <i className = 'gac-filter-item__arrow'/>
                <div className = 'gac-filter-item__value' onClick = { this._toggleList }>{ title ? title : 'Any color' }</div>
                    <CSSTransition
                        in = { isOpened }
                        timeout = { 200 }
                        unmountOnExit
                        classNames = 'gac-opacity-200'>
                        <div
                            style = {{
                                top: direction ? 'auto' : 55,
                                bottom: direction ? 46 : 'auto',
                            }}
                            className = 'gac-filter-item__menu gac-filter-item__menu-colors'>
                            <label  className = 'gac-filter-item__checkbox' htmlFor = 'gac-filter-colors__transparent'>
                                <input id = 'gac-filter-colors__transparent' name = 'colors' value = 'transparent' type = 'checkbox' checked = { colors.includes('transparent') } onChange = { this._onChange }/>
                                <i>{ colors.includes('transparent') ? <CheckedCheckbox/> : <Checkbox/> }</i>Transparent
                            </label>
                            <label className = 'gac-filter-item__checkbox' htmlFor = 'gac-filter-colors__grayscale'>
                                <input id = 'gac-filter-colors__grayscale' name = 'colors' value = 'grayscale' type = 'checkbox' checked = { colors.includes('grayscale') } onChange = { this._onChange }/>
                                <i>{ colors.includes('grayscale') ? <CheckedCheckbox/> : <Checkbox/> }</i>Black and white
                            </label>
                            <div className = 'gac-filter-item__checkboxs-wrap'>
                                <div className = 'gac-filter-item__checkbox-v2'>
                                    <input id = 'gac-filter-colors__red' name = 'colors' value = 'red' type = 'checkbox' checked = { colors.includes('red') } onChange = { this._onChange }/>
                                    <label htmlFor = 'gac-filter-colors__red'/>
                                </div>
                                <div className = 'gac-filter-item__checkbox-v2'>
                                    <input id = 'gac-filter-colors__orange' name = 'colors' value = 'orange' type = 'checkbox' checked = { colors.includes('orange') } onChange = { this._onChange }/>
                                    <label htmlFor = 'gac-filter-colors__orange'/>
                                </div>
                                <div className = 'gac-filter-item__checkbox-v2'>
                                    <input id = 'gac-filter-colors__yellow' name = 'colors' value = 'yellow' type = 'checkbox' checked = { colors.includes('yellow') } onChange = { this._onChange }/>
                                    <label htmlFor = 'gac-filter-colors__yellow'/>
                                </div>
                                <div className = 'gac-filter-item__checkbox-v2'>
                                    <input id = 'gac-filter-colors__green' name = 'colors' value = 'green' type = 'checkbox' checked = { colors.includes('green') } onChange = { this._onChange }/>
                                    <label htmlFor = 'gac-filter-colors__green'/>
                                </div>
                                <div className = 'gac-filter-item__checkbox-v2'>
                                    <input id = 'gac-filter-colors__turquoise' name = 'colors' value = 'turquoise' type = 'checkbox' checked = { colors.includes('turquoise') } onChange = { this._onChange }/>
                                    <label htmlFor = 'gac-filter-colors__turquoise'/>
                                </div>
                                <div className = 'gac-filter-item__checkbox-v2'>
                                    <input id = 'gac-filter-colors__blue' name = 'colors' value = 'blue' type = 'checkbox' checked = { colors.includes('blue') } onChange = { this._onChange }/>
                                    <label htmlFor = 'gac-filter-colors__blue'/>
                                </div>
                                <div className = 'gac-filter-item__checkbox-v2'>
                                    <input id = 'gac-filter-colors__lilac' name = 'colors' value = 'lilac' type = 'checkbox' checked = { colors.includes('lilac') } onChange = { this._onChange }/>
                                    <label htmlFor = 'gac-filter-colors__lilac'/>
                                </div>
                                <div className = 'gac-filter-item__checkbox-v2'>
                                    <input id = 'gac-filter-colors__pink' name = 'colors' value = 'pink' type = 'checkbox' checked = { colors.includes('pink') } onChange = { this._onChange }/>
                                    <label htmlFor = 'gac-filter-colors__pink'/>
                                </div>
                                <div className = 'gac-filter-item__checkbox-v2'>
                                    <input id = 'gac-filter-colors__white' name = 'colors' value = 'white' type = 'checkbox' checked = { colors.includes('white') } onChange = { this._onChange }/>
                                    <label htmlFor = 'gac-filter-colors__white'/>
                                </div>
                                <div className = 'gac-filter-item__checkbox-v2'>
                                    <input id = 'gac-filter-colors__gray' name = 'colors' value = 'gray' type = 'checkbox' checked = { colors.includes('gray') } onChange = { this._onChange }/>
                                    <label htmlFor = 'gac-filter-colors__gray'/>
                                </div>
                                <div className = 'gac-filter-item__checkbox-v2'>
                                    <input id = 'gac-filter-colors__black' name = 'colors' value = 'black' type = 'checkbox' checked = { colors.includes('black') } onChange = { this._onChange }/>
                                    <label htmlFor = 'gac-filter-colors__black'/>
                                </div>
                                <div className = 'gac-filter-item__checkbox-v2'>
                                    <input id = 'gac-filter-colors__brown' name = 'colors' value = 'brown' type = 'checkbox' checked = { colors.includes('brown') } onChange = { this._onChange }/>
                                    <label htmlFor = 'gac-filter-colors__brown'/>
                                </div>
                            </div>
                        </div>
                    </CSSTransition>
            </div>
        );
    }
}
