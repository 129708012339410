// Core
import { call, put, select } from 'redux-saga/effects';

// Instruments
import { api, authStr, getKeys } from '../../../../helpers/api';
import { uiActions } from '../../../ui/actions';
import { modalsActions } from "../../../modals/actions";

export function* callCancelSubscriptionWorker ({ payload: { data, navigate }}) {
    try {
        yield put(uiActions.enableFetching());
        const { privateKey, publicKey } = yield select(getKeys);
        const response = yield call(fetch, `${api}/${publicKey}/v1/subscriptions/${data.type}/cancel`, {
            method: 'PUT',
            headers: {
                'Authorization': `${authStr} ${privateKey}`,
                'Content-Type':  'application/json',
            },
            body: JSON.stringify(data),
        });
        const { error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }

        yield put(modalsActions.setModalState('activeSub', null));
        navigate('/subscriptions');
        yield put(uiActions.setUiState('notification', {
            //msg: 'Your subscription will end on [renew date]',
            msg: 'Subscription cancelled',
            type: 'success',
            fn: null,
        }));
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
        yield put(uiActions.setUiState('notification', {
            msg: error.message,
            type: 'error',
            fn: null,
        }));
    } finally {
        yield put(uiActions.disableFetching());
    }
}
