// Core
import { call, put, select } from 'redux-saga/effects';

// Instruments
import { api, getKeys, authStr } from '../../../../helpers/api';
import { listsActions } from "../../actions";
import { uiActions } from '../../../ui/actions';
import { includes, isEmpty, isNil } from "ramda";

export function* callGetOrderListWorker ({ payload: { navigate } }) {
    try {
        yield put(uiActions.enableFetching());
        const { privateKey, publicKey, accountId } = yield select(getKeys);
        const response = yield call(fetch, `${api}/${publicKey}/v1/order_list?account_uid=${accountId}`,{
            method: 'GET',
            headers: {
                Authorization: `${authStr} ${privateKey}`
            }
        });
        const { response: list, error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }
        if ( includes('/order/confirm', window.location.href) && (isEmpty(list) || isNil(list)) ) {
            navigate('/order');
        }
        yield put(listsActions.setListsState('orderList', list));
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`)
    } finally {
        yield put(uiActions.disableFetching());
    }
}
