// Async types
export const asyncTypes = Object.freeze({
    UPLOAD_BASE64_ASYNC:          'UPLOAD_BASE64_ASYNC',
    UPLOAD_FILE_ASYNC:            'UPLOAD_FILE_ASYNC',
    UPLOAD_FILE_FROM_URL_ASYNC:   'UPLOAD_FILE_FROM_URL_ASYNC',
    ERROR_REPORT_ASYNC:           'ERROR_REPORT_ASYNC',
    CHECK_BRIEF_ASYNC:            'CHECK_BRIEF_ASYNC',
    VALIDATE_REFERRAL_CODE_ASYNC: 'VALIDATE_REFERRAL_CODE_ASYNC',
    GA_TRACKING_DEBUG_ASYNC:      'GA_TRACKING_DEBUG_ASYNC',
});
