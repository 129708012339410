// Core
import { call, put, select, delay } from 'redux-saga/effects';

// Instruments
import { api, getKeys, authStr } from '../../../../helpers/api';
import { uiActions } from '../../../ui/actions';
import { listsActions } from "../../actions";
import { listsActionsAsync } from "../asyncActions";

const Url = require('url-parse');

export function* callGetImagesWorker ({ payload: query }) {
    try {
        yield put(uiActions.enableFetching());
        const { privateKey, publicKey } = yield select(getKeys);
        const url = `${api}/${publicKey}/v1/images?${query}`;
        const { query: queryObj } = new Url(url, true);
        const response = yield call(fetch, `${url}`, {
            method: 'GET',
            headers: {
                Authorization: `${authStr} ${privateKey}`
            }
        });
        const { response: { totalHits, total, hits: images }, error: { message }} = yield call([response, response.json]);

        if ( response.status === 429 ) {
            yield delay(4000);
            yield put(listsActionsAsync.getImagesAsync(query));
            return;
        }
        if (response.status !== 200) {
            throw new Error(message);
        }

        yield put(listsActions.setListsState('images', images));
        yield put(uiActions.setUiState('imagesTotal', queryObj.platform === 'pixabay' ? totalHits : total));
    } catch (error) {
        yield put(uiActions.setUiState('notification', {
            msg: error.message,
            type: 'error',
            fn: null,
        }));
    } finally {
        yield put(uiActions.disableFetching());
    }
}
