// Core
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

// Components
import Dropdownv2 from '../../../common/Dropdownv2/index';
import { BlackFridayImage } from "../../../common/BlackFridayImage";
import { MessagesIcon } from "../../../icons/MessagesIcon";

// Hooks
import { useAuth } from "../../../../hooks/useAuth";
import { useLists } from "../../../../hooks/useLists";
import { useModals } from "../../../../hooks/useModals";
import { useUi } from "../../../../hooks/useUi";
import { useUser } from "../../../../hooks/useUser";
import { useOrder } from "../../../../hooks/useOrder";

// Instruments
import { setAccountCookies, isClientFn, isSelfClientFn, isEpiic } from '../../../../helpers/helpers';
import { domainGAC, epiic } from '../../../../helpers/api';
import { isEmpty, isNil, includes } from "ramda";

export const UserNav = () => {
    /* Hooks */
    const { keys: { accountId }, logout, clearCookies, setAccount } = useAuth();
    const { messages } = useLists();
    const { setModal } = useModals();
    const { editedProject } = useOrder();
    const { isUserLoaded, setUiState } = useUi();
    const {
        details: {
            avatar,
            accounts = [],
            user_id,
            user_role = 'basicclient',
            first_name,
            last_name,
            support_first_name,
            support_last_name,
            support_phone,
            support_extension,
            support_avatar,
            support_email,
        },
        company: {
            branding: {
                host
            }
        }
    } = useUser();
    const { pathname } = useLocation();
    const navigate = useNavigate();

    /* Actions */
    const logOut = () => {
        logout(navigate);
    };
    const onClearCookies = () => {
        clearCookies();
        navigate('/login');
    };
    const switchAccount = ({ currentTarget: { dataset: { value }}}) => {
        if ( accountId !== value ) {
            setAccountCookies(value);
            setAccount(value);
            /* Redirect to /current-projects if we are on /project/project_id page */
            if ( includes('/project',pathname) ) {
                navigate('/current-projects');
            }
            /* Redirect to /order if we are on /order/project_id page */
            if ( pathname === `/order/${editedProject.project_id}` ) {
                navigate('/order');
            }
        }
    };
    const showReferralModal = () => {
        setModal('referral');
    };
    const showMessagesCenter = () => {
        if ( !isNil(messages) ) {
            setUiState('isMsgCenter', true);
        }
    };

    const items = accounts
        .filter(o => o.active === 1)
        .sort((a,b) => (a.account_name).localeCompare(b.account_name))
        .map(o => ({ title: o.account_name, value: o.account_id }));
    let title = '';
    if ( items.length ) {
        const filtered = items.filter(o => o.value === accountId);
        title = isEmpty(filtered) ? '' : filtered[0].title;
    }
    const isSettingPage = pathname.includes('transactions')
        || pathname.includes('account')
        || pathname.includes('user')
        || pathname.includes('settings')
        || pathname.includes('subscriptions');
    const isUnreadMessages = isNil(messages) ? false : messages.some(o => o.status === 'moderated');

    return (
        <div className = { `gac-user-nav-wrap ${ isClientFn(user_role) || isSelfClientFn(user_role) ? 'gac-client' : ''}` }>
            <BlackFridayImage/>
            { !isSettingPage && items.length
                ? <Dropdownv2
                    className = 'gac-accounts'
                    event = 'hover'
                    hasAccess = { user_role === 'administrator' || user_role === 'manager' }
                    onClick = { switchAccount }
                    items = { items }
                    value = { title }/>
                : null }
            { isUserLoaded
                ? <div className = 'gac-user-nav'>
                    {/*
                    <div className = 'gac-user-nav__notifications'>
                        <span>22</span>
                    </div>
                    */}
                    { /* isClientFn(user_role) || isSelfClientFn(user_role)
                        ? null
                        : <div className="gac-user-nav__referral-icon" onClick = { showReferralModal }>
                            <i/>
                        </div> */}
                    <div className="gac-user-nav__messages-icon" onClick = { showMessagesCenter }>
                        <MessagesIcon isActive = { isUnreadMessages } />
                    </div>
                    <div className = 'gac-user-nav__account'>
                        { avatar
                            ? <img className = 'gac-user-nav-account-image' src={ avatar } alt="User"/>
                            : <div className = 'gac-user-nav-no-image'/> }
                        <ul className = 'gac-user-nav__info-menu'>
                            <li className = 'gac-user-nav__account-info'>
                                <div className = 'gac-user-nav__account-name'>{ first_name } { last_name }</div>
                                <div className = 'gac-user-nav__account-settings'>
                                    <Link to = { `/user/${user_id}` }>Profile</Link>
                                </div>
                            </li>
                            { isClientFn(user_role) || isSelfClientFn(user_role)
                                ? null
                                : <li className = 'gac-user-nav__manager'>
                                    <div className = 'gac-user-nav__manager-title'>Your account manager:</div>
                                    <div className = 'gac-user-nav__manager-img'>
                                        { support_avatar && <img alt = 'Manager' src = { support_avatar } /> }
                                    </div>
                                    <div className = 'gac-user-nav__manager-info'>
                                        <div className = 'gac-user-nav__manager-name'>{ support_first_name } { support_last_name }</div>
                                        <div className = 'gac-user-nav__manager-id'>
                                            { `${ support_phone } ${ support_extension ? `ех.${support_extension}` : '' }` }
                                            <br/>
                                            { support_email }
                                        </div>
                                    </div>
                                </li> }
                            { 'administrator/manager'.includes(user_role)
                                ? <>
                                    <li>
                                        <Link className = 'ic-subscriptions' to = '/subscriptions'>Subscriptions</Link>
                                    </li>
                                </>
                                : null }
                            { 'administrator/manager/strategist'.includes(user_role)
                                ? <>
                                    <li>
                                        <Link className = 'ic-payments' to = '/transactions'>Transactions</Link>
                                    </li>
                                    <li>
                                        <Link className = 'ic-clients' to = '/accounts'>Accounts</Link>
                                    </li>
                                </>
                                : null }
                            { 'administrator/manager'.includes(user_role)
                                ? <>
                                    <li>
                                        <Link className = 'ic-users' to = '/users'>Users</Link>
                                    </li>
                                    <li>
                                        <Link className = 'ic-settings' to = '/settings'>General settings</Link>
                                    </li>
                                </>
                                : null }
                            { isClientFn(user_role) || isSelfClientFn(user_role)
                                ? null
                                : <li>
                                    <a
                                        className = 'ic-faq'
                                        target = '_blank'
                                        rel = 'noopener noreferrer'
                                        href = {`https://support.${ isEpiic(host) ? epiic : domainGAC }.com`}>FAQ</a>
                                </li> }
                            {/*
                            <li><a className = 'ic-integrations' href = ''>Integrations</a></li>
                            <li><a className = 'ic-referrals' href = ''>Referrals</a></li>
                            <li><a className = 'ic-support' href = ''>Message support</a></li>
                            */}
                            {/* 'administrator/manager'.includes(user_role) && !isEpiic(host)
                                ? <li><a className = 'ic-api' target = '_blank' rel = 'noopener noreferrer' href = {`https://api-docs.${domainGAC}.com`}>API docs</a></li>
                                : null */}
                            <li className = 'gac-user-nav__signout'>
                                <span onClick = { logOut } className = 'ic-signout'>Sign out</span>
                            </li>
                        </ul>
                    </div>
                </div>
                : <span className='gac-signout' onClick = { onClearCookies }>
                    Sign out
                </span> }
        </div>
    );
};