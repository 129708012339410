// Core
import { useEffect, useState, useRef } from "react";

// Components
import Avatar from '../common/Avatar/index';

// Hooks
import { useDropzoneEl } from "../../hooks/custom/useDropzoneEl";

// Instruments
import { getMessageFromCookies, saveMessageToCookies, removeMessageFromCookies } from "../../helpers/helpers";
import animateScrollTo from 'animated-scroll-to';
import { includes, isEmpty, isNil } from 'ramda';

const maxSize = 50000000; // = 50Mb = 50000000 bytes
const maxSize1Gb = 1080000000; // = 1Gb = 1080000000 bytes

export const MessageForm = ({ type, data, onClose, onSubmit }) => {
    /* Ref */
    const formRef = useRef(null);
    const textareaRef = useRef(null);

    const { job_type, project_id, recipient_type, recipient_uid, recipient_clients, recipient_ava, recipient_name, notify_user_uid } = data;

    /* State */
    const [attachments, setAttachments] = useState([]);
    const [files, setFiles] = useState([]);
    const [notifyList, setNotifyList] = useState([]);
    const [text, setText] = useState('');
    const [isTextInvalid, setIsTextInvalid] = useState(false);
    const [isUploadingInProgress, setIsUploadingInProgress] = useState(false);

    /* Upload files */
    const onUpdateFiles = (prop, value, id) => {
        if ( includes(prop, 'files/logo_file') ) {
            setFiles(state => [ ...state, ...value ]);
        } else {
            if ( isNil(id) ) {
                setAttachments(state => [ ...state, ...value.map(o => ({ file_id: o.id, filename: o.name, size: o.size, url: o.url })) ]);
            } else {
                setAttachments(state => [ ...state.filter(o => o.file_id !== id), ...value ]);
            }
        }
    };
    const onRemoveFile = (prop, value) => {
        if ( includes(prop, 'files/logo_file') ) {
            setFiles(value);
        } else {
            setAttachments(value);
        }
    };

    /* Hooks */
    const { progress, getFilesHtml, getDropzoneMessageForm } = useDropzoneEl(attachments, files, onUpdateFiles, onRemoveFile, includes(job_type, 'design/motion') ? maxSize1Gb : maxSize, [], () => {}, job_type, '', 'files', 'attachments');

    /* Actions */
    const onUserToggle = ({currentTarget: { dataset: { id }}}) => {
        setNotifyList(state => {
            const isChecked = state.includes(id);

            return isChecked ? state.filter(item => item !== id) : [ ...state, id ];
        });
    };
    const onTextChange = ({ currentTarget: { value: text }}) => {
        setText(text);
        setIsTextInvalid(false);
    };
    const validateFields = () => {
        let fields = [];

        if ( !text && isEmpty(attachments) ) {
            setIsTextInvalid(true);
            fields.push('text');
        }
        if ( isEmpty(progress) ) {
            setIsUploadingInProgress(false);
        } else {
            setIsUploadingInProgress(true);
            fields.push('progress');
        }

        return fields;
    }
    const onFormSubmit = () => {
        let notifyData = '';
        if ( !isEmpty(notifyList) ) {
            notifyData = notifyList.join(',');
        }
        if ( type === 'client-user' ) {
            notifyData = notify_user_uid.map(({user_id}) => user_id).join(',');
        }

        const fields = validateFields();

        if ( isEmpty(fields) ) {
            const filesData = files.map(o => o.file_id);
            onSubmit({
                ...data,
                notify_user_uid: notifyData,
                text,
                files: filesData.length ? filesData.join(',') : '',
            });
            onClose();
        }
    };

    useEffect(() => {
        const textStr = getMessageFromCookies(project_id);
        if ( !isNil(textStr) ) {
            setText(textStr);
        }
    }, []);
    useEffect(() => {
        return () => {
            if ( isEmpty(text) ) {
                removeMessageFromCookies(project_id);
            } else {
                saveMessageToCookies(project_id, text);
            }
        };
    }, [text]);
    useEffect(() => {
        if ( !isNil(formRef.current) ) {
            animateScrollTo(formRef.current, {
                verticalOffset: -55,
            }).then(() => {
                textareaRef.current.focus();
            });
        }
    }, [formRef.current]);

    /* Html */
    const isMessageForm = includes(type, 'message/client-user/user-client');
    const getToList = () => {
        let toList = [];
        if ( type === 'user-client' ) {
            toList = recipient_clients.map(({ avatar_url, user_id, first_name, last_name }) => {
                return <div key = { user_id } className = 'gac-user-meta'>
                    <i className = 'gac-user-meta-icon gac-client'/>
                    { avatar_url ? <img className = 'gac-user-meta-img' src = { avatar_url } alt = 'Recipient avatar'/> : <div className = 'gac-no-user-img'/> }
                    <div className = 'gac-user-meta-name'>{ `${first_name} ${last_name}` }</div>
                </div> ;
            });
        } else if ( type === 'message' ) {
            toList.push(
                <div key = { recipient_uid } className = 'gac-user-meta'>
                    <i className = { `gac-user-meta-icon gac-${recipient_type}` }/>
                    { recipient_ava ? <img className = 'gac-user-meta-img' src = { recipient_ava } alt = 'Recipient avatar'/> : <div className = 'gac-no-user-img'/> }
                    <div className = 'gac-user-meta-name'>{ recipient_name }</div>
                </div>
            );
        }

        if ( isEmpty(toList) ) return null;

        return <div className = 'gac-message-to'><div className = 'gac-message-label'>To:</div>{ toList }</div> ;
    };
    const getNotifyList = () => {
        const list = notify_user_uid.map(({ avatar_url, user_id, first_name, last_name }) => {
            const isChecked = notifyList.includes(user_id);
            return(
                <div key = { user_id } data-id = { user_id } className = 'gac-notify-item' onClick = { onUserToggle }>
                    <Avatar isOverlay = { isChecked } url = { avatar_url } name = {[ first_name, last_name ]}/>
                </div>
            );
        });

        if ( isEmpty(list) || type === 'client-user' ) return null;

        return <div className = 'gac-message-notify'>
            <div className = 'gac-message-label'>Notify:</div>
            <div className = 'gac-notify-list'>{ list }</div>
        </div>;
    };
    const getTextarea = () => {
        return <div className="gac-message-textarea">
            <div className = 'gac-message-label'>{ isMessageForm ? 'Message' : 'Notes' }</div>
            <textarea id="gac-message-form-textarea" ref = { textareaRef } className = { isTextInvalid ? 'gac-invalid' : '' } value = { text } onChange = { onTextChange }/>
        </div> ;
    };
    const getFiles = () => {
        if ( !isMessageForm ) return null;

        return <>
            { getFilesHtml() }
            { isUploadingInProgress && <div className='gac-error'>Uploading in progress. Please wait</div> }
        </>;
    };
    const getBtns = () => {
        return <div className = 'gac-message-btns'>
            <div onClick = { onFormSubmit } className = 'gac-btn gac-btn-s'>{ isMessageForm ? 'Send' : 'Save internal note' }</div>
            { isMessageForm ? getDropzoneMessageForm() : null }
        </div>
    };

    return <div ref = { formRef } className = 'gac-message-form'>
        <span onClick = { onClose } className = 'gac-close'/>
        { getToList() }
        { getNotifyList() }
        { getTextarea() }
        { getFiles() }
        { getBtns() }
    </div>
};