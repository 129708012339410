// Core
import { call, put, select } from 'redux-saga/effects';

// Instruments
import { api, getKeys, authStr } from '../../../../helpers/api';
import { uiActions } from '../../../ui/actions';
import { projectActions } from '../../actions';
import { isNil } from 'ramda';

export function* callUpdateCommentWorker ({ payload: { comment, comment_id, project_id, job_type, idea_number, attachment }}) {
    try {
        yield put(uiActions.enableFetching());
        const formData = new FormData();
        formData.append('comment', comment);
        if ( !isNil(attachment) ) {
            attachment.forEach(o => {
                formData.append('attachment[]', o);
            });
        }

        const url = isNil(idea_number) ? '' : `/${idea_number}`;
        const { privateKey, publicKey } = yield select(getKeys);
        const response = yield call(fetch, `${api}/${publicKey}/v1/projects/${job_type}/${project_id}/comments${url}/${comment_id}`, {
            method: 'PUT',
            headers: {
                'Authorization': `${authStr} ${privateKey}`,
            },
            body: formData,
        });
        const { response: data, error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }

        yield put(projectActions.updateCommentSuccess(data));
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
        yield put(uiActions.setUiState('notification', {
            msg: error.message,
            type: 'error',
        }));
    } finally {
        yield put(uiActions.disableFetching());
    }
}
