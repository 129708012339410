// Instrument
import { types } from './types';
import { types as authTypes } from '../auth/types';
import update from 'immutability-helper';

const initialState = {
    existingIdeas: null,
    accountsWithoutSubs: null,
    accountsNoWritingSubs: null,
    queuedProjects: null,
    staticData: null,
    messages: null,
    users: null,
    accounts: null,
    transactions: null,
    images: null,
    imagesCarousel: null,
    imageCategories: null,
    projects: null,
    unreadMessages: null,
    orderList: [],
};

export const listsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.SET_LISTS_STATE:
            return update(state, {
                [payload.prop]: {$set: payload.value},
            });

        case authTypes.LOGOUT_SUCCESS:
            return Object.assign({}, {
                ...initialState,
                staticData: state.staticData
            });

        default:
            return state;
    }
};
