// Core
import { useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';

// Components

// Hooks
import { useModals } from "../../../hooks/useModals";
import { useUser } from "../../../hooks/useUser";

// Instruments
import { subTalentTypeConfig } from "../../../helpers/constants";
import { isNil } from 'ramda';

export const ModalCancelPauseSubscription = ({ closeModal, setPos }) => {
    /* Ref */
    const modalRef = useRef(null);

    /* Hooks */
    const { activeSub } = useModals();
    const { talent, subscription_uid, accounts } = activeSub;
    const { cancelPauseSubscriptionAsync } = useUser();
    const navigate = useNavigate();

    useEffect(() => {
        setPos( modalRef );
    }, []);

    /* Submit */
    const onSubmit = () => {
        if ( isNil(activeSub) ) return null;

        const { account_uid } = accounts[0];
        cancelPauseSubscriptionAsync({
            subscription_uid,
            account_uid,
            type: subTalentTypeConfig[talent]
        }, navigate);
        closeModal();
    };

    return <div ref = { modalRef } className = 'gac-modal-subscription gac-pause-subscription'>
        <span className = 'gac-close-modal' onClick = { closeModal }/>
        <h1>Unpause payment</h1>
        <div className="gac-pause-desc">
            <div>Please note: Upon unpausing, your card will be charged immediately, and your subscription will become active</div>
        </div>
        <div className="gac-btn-wrap">
            <div className='gac-btn gac-btn-s' onClick = { onSubmit }>Confirm unpause</div>
        </div>
    </div> ;
};