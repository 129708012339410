// Core
import { useLayoutEffect } from "react";

// Instruments
import { initDefaultRedactor } from "../../../helpers/helpers";

/**
 * @deprecated
 * Removed. Task 1102
 */
export const SeoAuditCompetitor = ({ fields }) => {
    /* Hooks */
    const {
        getSeoAuditLevelField,
        getSeoAuditWebsiteField,
        getTextareaField,
        getLinkAnalyzedField,
        getGoogleAccessField,

        onRedactorSynced,
        onRedactorBlur
    } = fields;

    useLayoutEffect(() => {
        initDefaultRedactor('#competitors-textarea', 'competitors', '116px', null, onRedactorSynced, onRedactorBlur, true);
        initDefaultRedactor('#seo_strategies-textarea', 'seo_strategies', '116px', null, onRedactorSynced, onRedactorBlur, true);
        initDefaultRedactor('#target_audience-textarea', 'target_audience', '116px', null, onRedactorSynced, onRedactorBlur, true);
        initDefaultRedactor('#keywords-textarea', 'keywords', '116px', null, onRedactorSynced, onRedactorBlur, true);
        initDefaultRedactor('#keywords_google-textarea', 'keywords_google', '116px', null, onRedactorSynced, onRedactorBlur, true);
        initDefaultRedactor('#goals-textarea', 'goals', '116px', null, onRedactorSynced, onRedactorBlur, true);

        return () => {
            window.$R('#competitors-textarea', 'destroy');
            window.$R('#seo_strategies-textarea', 'destroy');
            window.$R('#target_audience-textarea', 'destroy');
            window.$R('#keywords-textarea', 'destroy');
            window.$R('#keywords_google-textarea', 'destroy');
            window.$R('#goals-textarea', 'destroy');
        };
    }, []);

    return <>
        { getSeoAuditLevelField() }
        { getSeoAuditWebsiteField() }
        { getTextareaField('competitors', 'List your 3 main competitors') }
        { getTextareaField('seo_strategies', 'Are there any competitor SEO strategies that you find effective?') }
        { getLinkAnalyzedField() }
        { getTextareaField('target_audience', 'Describe the target audience of your website') }
        { getTextareaField('keywords', `Are there any specific keywords or pages you're struggling to rank for?`) }
        { getTextareaField('keywords_google', `Name 10 keywords you want to rank in the top 10 on Google with`) }
        { getTextareaField('goals', 'Main goals you want to achieve with this audit') }
        { getGoogleAccessField() }
    </>;
};