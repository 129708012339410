// Core
import { call, put, select  } from 'redux-saga/effects';

// Instruments
import { api, getKeys, authStr } from '../../../../helpers/api';
import { uiActions } from '../../../ui/actions';
import { modalsActions } from "../../../modals/actions";

export function* callSendNpsWorker ({ payload: nps }) {
    try {
        yield put(uiActions.enableFetching());
        const { privateKey, publicKey, accountId } = yield select(getKeys);
        const response = yield call(fetch, `${api}/${publicKey}/v1/nps`, {
            method: 'POST',
            headers: {
                'Authorization': `${authStr} ${privateKey}`,
                'Content-Type':  'application/json',
            },
            body: JSON.stringify({
                ...nps,
                account_uid: accountId
            }),
        });
        const { error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }

        yield put(modalsActions.setModal(null));
        if ( nps.score > 8 ) {
            yield put(modalsActions.setModal('clientFeedback'));
        }
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
        yield put(uiActions.setUiState('notification', {
            msg: error.message,
            type: 'error',
            fn: null,
        }));
    } finally {
        yield put(uiActions.disableFetching());
    }
}
