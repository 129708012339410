// Core
import { call, put, select } from 'redux-saga/effects';

// Instruments
import { api, getKeys, authStr } from '../../../../helpers/api';
import { uiActions } from '../../../ui/actions';
import { userActions } from '../../actions';
import { userActionsAsync } from '../asyncActions';
import { modalsActions } from "../../../modals/actions";
import { getErrorMsg } from '../../../../helpers/helpers';

export function* callUpdateProposalCompanyWorker ({ payload: { company, isProposalCompanyUpdated }}) {
    try {
        yield put(uiActions.enableFetching());
        const { privateKey, publicKey, accountId } = yield select(getKeys);
        const response = yield call(fetch, `${api}/${publicKey}/v1/proposal?account_uid=${accountId}`, {
            method: 'PUT',
            headers: {
                'Authorization': `${authStr} ${privateKey}`,
                'Content-Type':  'application/json',
            },
            body: JSON.stringify(company),
        });
        const { error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(getErrorMsg(message));
        }

        yield put(userActions.setUserState('proposal',{}));
        yield put(modalsActions.setModal(null));
        yield put(uiActions.setUiState('isProposalCompanyUpdated', isProposalCompanyUpdated));
        yield put(userActionsAsync.fetchUserDetailsAsync());
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
        yield put(uiActions.setUiState('notification', {
            msg: error.message,
            type: 'error',
            fn: null,
        }));
    } finally {
        yield put(uiActions.disableFetching());
    }
}
