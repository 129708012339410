// Core
import { asyncTypes } from './asyncTypes';

export const orderActionsAsync = Object.freeze({
    addProjectToOrderAsync: (id, action, navigate) => ({
        type: asyncTypes.ADD_PROJECT_TO_ORDER_ASYNC,
        payload: {
            id,
            action,
            navigate
        },
    }),
    removeProjectFromOrderAsync: (project_id, navigate) => ({
        type: asyncTypes.REMOVE_PROJECT_FROM_ORDER_ASYNC,
        payload: {
            project_id,
            navigate,
        },
    }),
    orderPayAsync: (data, navigate) => ({
        type: asyncTypes.ORDER_PAY_ASYNC,
        payload: {
            data,
            navigate
        },
    }),
    bundlesPayAsync: (data, navigate) => ({
        type: asyncTypes.BUNDLES_PAY_ASYNC,
        payload: {
            data,
            navigate
        },
    }),
});
