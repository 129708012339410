// Core
import { call, put, select } from 'redux-saga/effects';

// Instruments
import { api, getKeys, authStr, getPusherData, getOrderList } from '../../../../helpers/api';
import { uiActions } from '../../../ui/actions';
import { userActions } from '../../../user/actions';
import { listsActions } from "../../../lists/actions";
import { includes, isNil } from "ramda";

export function* callRemoveProjectFromOrderWorker ({ payload: { project_id, navigate }}) {
    try {
        yield put(uiActions.enableFetching());
        const { privateKey, publicKey, accountId: account_uid } = yield select(getKeys);
        const { sessionID } = yield select(getPusherData);
        const response = yield call(fetch, `${api}/${publicKey}/v1/order_list`,{
            method: 'DELETE',
            headers: {
                'Authorization': `${authStr} ${privateKey}`,
                'Content-Type':  'application/json',
            },
            body: JSON.stringify({
                account_uid,
                project_id,
                pusherData: {
                    sessionID
                },
            }),
        });
        const { error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }

        const orderList = yield select(getOrderList);
        if ( (isNil(project_id) || orderList.length < 2 ) && includes('/order/confirm', window.location.href) ) {
            navigate('/order');
        }
        yield put(listsActions.setListsState('orderList', isNil(project_id) ? [] : orderList.filter(o => `${o.project_id}` !== `${project_id}`)));
        yield put(userActions.updateOrderCount(account_uid,'0'));
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`)
    } finally {
        yield put(uiActions.disableFetching());
    }
}
