// Core
import { call, put, select } from 'redux-saga/effects';

// Instruments
import { api, getKeys, authStr } from '../../../../helpers/api';
import { userActions } from '../../actions';
import { uiActions } from '../../../ui/actions';
import { formsActions } from "../../../forms/actions";
import { initialState as formsState } from "../../../forms/reducer";
import { talentsState } from "../../../../helpers/constants";
import { isNil } from "ramda";

export function* callFetchAccountWorker ({ payload: { accountId, action }}) {
    try {
        yield put(uiActions.enableFetching());
        const { privateKey, publicKey } = yield select(getKeys);
        const response = yield call(fetch, `${api}/${publicKey}/v1/accounts/${accountId}`,{
            method: 'GET',
            headers: {
                Authorization: `${authStr} ${privateKey}`
            }
        });
        const { response: data, error: { message }} = yield call([response, response.json]);

        if ( response.status !== 200 ) {
            throw new Error(message);
        }

        Object.keys(data).forEach(key => { if ( isNil(data[key]) ) { delete data[key] }});
        yield put(userActions.setUserState('currentAccount',data));
        yield put(userActions.setUserState('talents', talentsState));

        if ( !isNil(action) ) {
            const newData = {};
            Object.keys(data).forEach(key => {
                if ( !isNil( formsState.accountNew[key] ) ) {
                    newData[key] = data[key];
                }
            });
            yield put(formsActions.setFormState('accountNew', {
                ...formsState.accountNew,
                ...newData,
                currency: data.account_currency
            }));
        }
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
        yield put(uiActions.setUiState('notification', {
            msg: error.message,
            type: 'error',
            fn: null,
        }));
    } finally {
        yield put(uiActions.disableFetching());
    }
}
