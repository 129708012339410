// Instruments
import { types } from './types';

export const listsActions = Object.freeze({
    setListsState: (prop, value) => ({
        type:    types.SET_LISTS_STATE,
        payload: {
            prop,
            value
        },
    }),
});
