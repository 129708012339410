// Core
import { useEffect, useRef } from "react";

// Components
import { useNavigate } from 'react-router-dom';

// Hooks
import { useUser } from "../../../hooks/useUser";

export const ModalDeactivateUser = ({ action, closeModal, setPos }) => {
    /* Ref */
    const modalRef = useRef(null);

    /* Hooks */
    const { currentUser: { user_id }, deleteUserAsync } = useUser();
    const navigate = useNavigate();

    /* Actions */
    const onDeleteUser = () => {
        deleteUserAsync(user_id, action, navigate);
    };

    /* Did mount */
    useEffect(() => {
        setPos( modalRef );
    }, []);

    return(
        <div ref = { modalRef } className = 'gac-modal gac-deactivate-account-modal'>
            <span className = 'gac-close-modal' onClick = { closeModal }/>
            <div className = 'gac-modal-text'>Are you sure you want to deactivate the user? To undo deactivation, please contact support.</div>
            <span onClick = { onDeleteUser } className = 'gac-btn gac-btn-s'>Confirm</span>
        </div>
    );
};
