// Async types
export const asyncTypes = Object.freeze({
    FETCH_USER_DETAILS_ASYNC:       'FETCH_USER_DETAILS_ASYNC',
    FETCH_TALENTS_ASYNC:            'FETCH_TALENTS_ASYNC',
    FETCH_TALENTS_BLACKLIST_ASYNC:  'FETCH_TALENTS_BLACKLIST_ASYNC',
    FETCH_COPYWRITERS_LIST_ASYNC:   'FETCH_COPYWRITERS_LIST_ASYNC',
    FETCH_BALANCE_ASYNC:            'FETCH_BALANCE_ASYNC',
    FETCH_DATA_BY_IP_ASYNC:         'FETCH_DATA_BY_IP_ASYNC',
    UPDATE_TALENT_ASYNC:            'UPDATE_TALENT_ASYNC',
    FETCH_ACCOUNTS_ASYNC:           'FETCH_ACCOUNTS_ASYNC',
    FETCH_ACCOUNT_ASYNC:            'FETCH_ACCOUNT_ASYNC',
    FETCH_COMPANY_ASYNC:            'FETCH_COMPANY_ASYNC',
    CREATE_COMPANY_ASYNC:           'CREATE_COMPANY_ASYNC',
    UPDATE_COMPANY_ASYNC:           'UPDATE_COMPANY_ASYNC',
    UPDATE_PROPOSAL_COMPANY_ASYNC:  'UPDATE_PROPOSAL_COMPANY_ASYNC',
    CREATE_ACCOUNT_ASYNC:           'CREATE_ACCOUNT_ASYNC',
    UPDATE_ACCOUNT_ASYNC:           'UPDATE_ACCOUNT_ASYNC',
    DELETE_ACCOUNT_ASYNC:           'DELETE_ACCOUNT_ASYNC',
    CHECK_EMAIL_EXIST_ASYNC:        'CHECK_EMAIL_EXIST_ASYNC',
    RESTORE_PASSWORD_ASYNC:         'RESTORE_PASSWORD_ASYNC',
    UPDATE_PASSWORD_ASYNC:          'UPDATE_PASSWORD_ASYNC',
    SEND_PROSPECT_ASYNC:            'SEND_PROSPECT_ASYNC',
    SEND_NPS_ASYNC:                 'SEND_NPS_ASYNC',
    FETCH_USERS_ASYNC:              'FETCH_USERS_ASYNC',
    CREATE_USER_ASYNC:              'CREATE_USER_ASYNC',
    UPDATE_USER_ASYNC:              'UPDATE_USER_ASYNC',
    DELETE_USER_ASYNC:              'DELETE_USER_ASYNC',
    UPDATE_OWN_PASSWORD_ASYNC:      'UPDATE_OWN_PASSWORD_ASYNC',
    ADD_USER_TO_ACCOUNT_ASYNC:      'ADD_USER_TO_ACCOUNT_ASYNC',
    REMOVE_USER_FROM_ACCOUNT_ASYNC: 'REMOVE_USER_FROM_ACCOUNT_ASYNC',
    FETCH_PROPOSAL_ASYNC:           'FETCH_PROPOSAL_ASYNC',
    GET_REFERRAL_ID_ASYNC:          'GET_REFERRAL_ID_ASYNC',
    GET_REFERRAL_INFO_ASYNC:        'GET_REFERRAL_INFO_ASYNC',
    CREATE_SUBSCRIPTION_ASYNC:      'CREATE_SUBSCRIPTION_ASYNC',
    UPDATE_SUBSCRIPTION_ASYNC:      'UPDATE_SUBSCRIPTION_ASYNC',
    UPDATE_SUBSCRIPTION_CUSTOM_ASYNC:  'UPDATE_SUBSCRIPTION_CUSTOM_ASYNC',
    CANCEL_SUBSCRIPTION_ASYNC:         'CANCEL_SUBSCRIPTION_ASYNC',
    PAUSE_SUBSCRIPTION_ASYNC:         'PAUSE_SUBSCRIPTION_ASYNC',
    CANCEL_PAUSE_SUBSCRIPTION_ASYNC:   'CANCEL_PAUSE_SUBSCRIPTION_ASYNC',
    CREATE_PUBLICATION_ASYNC:          'CREATE_PUBLICATION_ASYNC',
    UPDATE_PUBLICATION_ASYNC:          'UPDATE_PUBLICATION_ASYNC',
    CHANGE_CARD_ASYNC:                 'CHANGE_CARD_ASYNC',
    VALIDATE_CNAME_ASYNC:              'VALIDATE_CNAME_ASYNC',
    CREATE_DESIGN_SUBSCRIPTION_ASYNC:  'CREATE_DESIGN_SUBSCRIPTION_ASYNC',
    UPDATE_DESIGN_SUBSCRIPTION_ASYNC:  'UPDATE_DESIGN_SUBSCRIPTION_ASYNC',
    FETCH_BRAND_PROFILES_ASYNC:        'FETCH_BRAND_PROFILES_ASYNC',
    CREATE_BRAND_PROFILE_ASYNC:        'CREATE_BRAND_PROFILE_ASYNC',
    UPDATE_BRAND_PROFILE_ASYNC:        'UPDATE_BRAND_PROFILE_ASYNC',
    FETCH_COMPANY_SUBSCRIPTIONS_ASYNC: 'FETCH_COMPANY_SUBSCRIPTIONS_ASYNC',
    CREATE_DESIGNER_ASYNC:             'CREATE_DESIGNER_ASYNC',
    ASSIGN_ACCOUNTS_TO_SUB_ASYNC:      'ASSIGN_ACCOUNTS_TO_SUB_ASYNC',
    GET_HUBSPOT_TOKEN_ASYNC:           'GET_HUBSPOT_TOKEN_ASYNC',
    DOWNLOAD_TRANSACTIONS_ASYNC: 'DOWNLOAD_TRANSACTIONS_ASYNC',
});
