// Core
import React, { PureComponent } from 'react';
import { CSSTransition } from 'react-transition-group';

// Components
import { Link } from 'react-router-dom';

class Dropdownv2 extends PureComponent {
    state = {
        isActive: false,
    };

    _onMouseEnter = () => {
        const { event } = this.props;
        if (event === 'hover') {
            this.setState({
                isActive: true,
            });
        }
    };

    _onMouseLeave = () => {
        const { event } = this.props;
        if (event === 'hover') {
            this.setState({
                isActive: false,
            });
        }
    };

    _onClick = () => {
        const { isActive } = this.state;
        const { event } = this.props;

        if (event === 'click') {
            this.setState({
                isActive: !isActive,
            });
        }
    };

    render () {
        const { isActive } = this.state;
        const { className, items, value, onClick, hasAccess } = this.props;
        const data = items.map((item, i) => (
            <li key = { i }>
                <span onClick={ onClick } data-value = { item.value } className = 'gac-dropdown__menu__link'>{ item.title }</span>
            </li>
        ));

        return (
            <div
                className = { isActive ? `gac-dropdown gac-dropdown-active ${className}` : `gac-dropdown ${className}` }
                onClick = { this._onClick }
                onMouseEnter = { this._onMouseEnter }
                onMouseLeave = { this._onMouseLeave }>
                    <div className = 'gac-dropdown__value'>{ value }</div>
                    <CSSTransition
                        in = { isActive }
                        timeout = { 200 }
                        unmountOnExit
                        classNames = 'gac-opacity-200'>
                        <ul className = 'gac-dropdown__menu'>
                            { data }
                            { hasAccess
                                ? <li>
                                    <Link
                                        className = 'gac-dropdown__menu__link'
                                        to = '/account/new'>+ Create a new account</Link>
                                </li>
                                : null }
                        </ul>
                    </CSSTransition>
            </div>
        );
    }
}

export default Dropdownv2;
