// Core
import { call, put, select } from 'redux-saga/effects';

// Instruments
import { api, getProposal, getAuth } from '../../../../helpers/api';
import { uiActions } from '../../../ui/actions';
import { isEmpty } from 'ramda';

export function* callCheckEmailExistWorker ({ payload: email }) {
    try {
        const response = yield call(fetch, `${api}/v1/users/email_exists?email=${encodeURIComponent(email)}`,{
            method: 'GET',
        });
        const { response: { result, email: responseEmail, password }, error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }
        const proposal = yield select(getProposal);
        const isAuth = yield select(getAuth);
        const isEmailExist = result === 'yes' || ( result === 'proposal' && !isEmpty(proposal) && responseEmail !== proposal.user.email );

        yield put(uiActions.setUiState('isEmailExist', isEmailExist));
        if ( !isAuth && result === 'proposal' ) {
            yield put(uiActions.setUiState('isCheckedEmailProposal', { email: responseEmail, password }));
        }
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`)
    }
}
