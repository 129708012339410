// Core
import { put, select  } from 'redux-saga/effects';
import Pusher from 'pusher-js';

// Instruments
import { api, pusherKey, pusherCluster, getKeys, authStr } from '../../../../helpers/api';
import { uiActions } from '../../../ui/actions';
import { socketActions } from '../../actions';

export function* callInitPusherWorker () {
    try {
        const { privateKey, publicKey } = yield select(getKeys);
        const pusher = new Pusher(pusherKey, {
            cluster: pusherCluster,
            forceTLS: true,
            encrypted : true,
            authEndpoint: `${api}/${publicKey}/v1/pusher/auth`,
            auth: {
                headers: {
                    'Authorization': `${authStr} ${privateKey}`,
                }
            }
        });
        yield put(socketActions.initPusherSuccess(pusher));
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
        yield put(uiActions.setUiState('notification', {
            msg: error.message,
            type: 'error',
            fn: null,
        }));
    }
}
