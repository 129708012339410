// Components
import { Link } from 'react-router-dom';
import ConditionalWrapper from "../../common/ConditionalWrapper";
import { Swiper, SwiperSlide } from 'swiper/react';

// Hooks
import { useUi } from "../../../hooks/useUi";
import { useUser } from "../../../hooks/useUser";

// Instruments
import { isClientFn, isSelfClientFn } from "../../../helpers/helpers";
import { includes, isNil, indexOf } from 'ramda';

export const Sidebar = ({ count, page, projectId, status, revision, job_type }) => {
    /* Hooks */
    const { isMobile, isFakeUser: isFake } = useUi();
    const { details: { user_role }} = useUser();

    const pages = ['overview', 'brief', 'messages', 'content', 'sharing'];
    const initialSlide = indexOf(page, pages) ? indexOf(page, pages) : 0;
    const swiperConfig = () => ({ slidesPerView: 'auto', initialSlide });

    const overview = () => <><i>
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="22"><path d="M17 7l-6-6H3c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V7v1h-6c-.6 0-1-.4-1-1V1h1v6h6zm-6-7c.3 0 .5.1.7.3l6 6c.2.2.3.4.3.7v12c0 1.7-1.3 3-3 3H3c-1.7 0-3-1.3-3-3V3c0-1.7 1.3-3 3-3h8zm3 11.5c0 .3-.2.5-.5.5h-9c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h9c.3 0 .5.2.5.5zm-6-4c0 .3-.2.5-.5.5h-3c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h3c.3 0 .5.2.5.5z" fill="#afaeaf"/></svg>
    </i>Overview</>;
    const brief = () => <><i>
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="22"><path d="M17 7l-6-6H3c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V7v1h-6c-.6 0-1-.4-1-1V1h1v6h6zm-6-7c.3 0 .5.1.7.3l6 6c.2.2.3.4.3.7v12c0 1.7-1.3 3-3 3H3c-1.7 0-3-1.3-3-3V3c0-1.7 1.3-3 3-3h8zm3 14.5c0 .3-.2.5-.5.5h-9c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h9c.3 0 .5.2.5.5zm0-3c0 .3-.2.5-.5.5h-9c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h9c.3 0 .5.2.5.5zm-6-4c0 .3-.2.5-.5.5h-3c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h3c.3 0 .5.2.5.5zm6 10c0 .3-.2.5-.5.5h-9c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h9c.3 0 .5.2.5.5z" fill="#afaeaf"/></svg>
    </i>Brief</>;
    const messages = () => <><i>
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M4.8 12.5h.4c.8.5 1.7.7 2.7.7 2.3 0 4.4-1.3 5.5-3.4.4-.8.6-1.7.6-2.7v-.3c-.2-3.2-2.6-5.6-5.7-5.8h-.4c-1 0-1.9.2-2.7.6-2.1 1-3.4 3.2-3.4 5.5 0 1 .2 1.9.6 2.7.1.1.1.3 0 .4l-1.2 3.5 3.6-1.2zm9.4-2.2c-1.7 3.4-5.9 4.9-9.3 3.3L.7 15c-.3.1-.5-.1-.6-.3v-.3l1.4-4.2C-.1 6.7 1.3 2.6 4.8.9c.9-.6 2-.9 3.1-.9h.4c3.6.2 6.5 3.1 6.7 6.7v.4c0 1.1-.3 2.2-.8 3.2z" fill="#afaeaf"/></svg>
    </i>Messages</>;
    const content = () => <><i>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path d="M3 10h3.9v1H3v-1zm5.9 0h3.9v1H8.9v-1zM3 7h9.8v1H3V7zm0-3h5.9v1H3V4zM2 0h12c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H2c-1.1 0-2-.9-2-2V2C0 .9.9 0 2 0zm0 1c-.6 0-1 .4-1 1v12c0 .6.4 1 1 1h12c.6 0 1-.4 1-1V2c0-.6-.4-1-1-1H2z" fill="#afaeaf"/></svg>
    </i>Content</>;
    const sharing = () => <><i>
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"><path d="M8.5 2c.3 0 .5.2.5.5 0 .2-.2.4-.4.5H2.5c-.8 0-1.4.6-1.5 1.4v11.1c0 .8.6 1.4 1.4 1.5h11.1c.8 0 1.4-.6 1.5-1.4V9.5c0-.3.2-.5.5-.5.2 0 .5.2.5.4v6.1c0 1.3-1 2.4-2.3 2.5H2.5C1.2 18 .1 17 0 15.7V4.5C0 3.2 1 2.1 2.3 2h6.2zm9-2s.1 0 0 0h.1v-.1s0 .1.1.1v6.1c.3.7.1.9-.2.9-.2 0-.5-.2-.5-.4V1.7l-8.6 8.6c-.2.2-.5.2-.7 0-.2-.2-.2-.4-.1-.6l.1-.1L16.3 1h-4.8c-.2 0-.4-.2-.5-.4V.5c0-.2.2-.5.4-.5h6.1z" fill="#afaeaf"/></svg>
    </i>Sharing</>;

    const isContent = ( job_type !== 'design' && includes(status, 'editing/revision') ) || includes(status, 'writing/approval/approved/published') || (job_type === 'design' && includes(status, 'matching/revision') && revision );
    const isClient = isClientFn(user_role);
    const isSelfClient = isSelfClientFn(user_role);

    return <div className = 'gac-project-sidebar'>
        <ul className = 'gac-project-pages'>
            <ConditionalWrapper
                condition = { isMobile }
                wrapper={children => <Swiper { ...swiperConfig() }>{children}</Swiper>}>
                { !isClient && !isFake && <SwiperSlide tag='li' className = {`${ 'overview'.includes(page) ? 'gac-active' : '' }`}>
                    { 'overview'.includes(page)
                        ? <span>{ overview() }</span>
                        : <Link to = { `/project/${projectId}` }>{ overview() }</Link> }
                </SwiperSlide> }
                { !isClient && !isFake && <SwiperSlide tag='li' className = {`${ 'brief'.includes(page) ? 'gac-active' : '' }`}>
                    { 'brief'.includes(page)
                        ? <span>{ brief() }</span>
                        : <Link to = { `/project/${projectId}/brief` }>{ brief() }</Link> }
                </SwiperSlide> }
                { !isFake && <SwiperSlide tag='li' className = {`${ 'messages'.includes(page) ? 'gac-active' : '' }`}>
                    { 'messages'.includes(page)
                        ? <span>{ messages() }{ !isNil(count) && <div className="gac-count">{ count }</div> }</span>
                        : <Link to = { `/project/${projectId}/messages` }>
                            { messages() }
                            { !isNil(count) && <div className="gac-count">{ count }</div> }
                        </Link> }
                </SwiperSlide> }
                <SwiperSlide tag='li' className = {`${ 'content'.includes(page) && isContent ? 'gac-active' : '' } ${ !isContent ? 'gac-disabled' : '' }`}>
                    { 'content'.includes(page) || !isContent
                        ? <span>{ content() }</span>
                        : <Link to = { `/project/${projectId}/content` }>{ content() }</Link> }
                </SwiperSlide>
                { !isClient && !isSelfClient && !isFake && <SwiperSlide tag='li' className = {`${ 'sharing'.includes(page) ? 'gac-active' : '' } ${ includes(status, 'pitching/matching') ? 'gac-disabled' : '' }`}>
                    { 'sharing'.includes(page) || includes(status, 'pitching/matching')
                        ? <span>{ sharing() }</span>
                        : <Link to = { `/project/${projectId}/sharing` }>{ sharing() }</Link> }
                </SwiperSlide> }
            </ConditionalWrapper>
        </ul>
    </div> ;
};