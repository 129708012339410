// Core
import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';

// Components
import { Writing } from "../JobTypes/Writing";
import { Ideas } from "../JobTypes/Ideas";
import { Proofreading } from "../JobTypes/Proofreading";
import { Keywords } from "../JobTypes/Keywords";
import { Design } from "../JobTypes/Design";
import { Blogging } from "../JobTypes/Blogging";
import { AiHuman } from "../JobTypes/AiHuman";
import { Motion } from "../JobTypes/Motion";
import { WebAudit } from "../JobTypes/WebAudit";
import { SeoAudit } from "../JobTypes/SeoAudit";
import {SeoBrief} from "../JobTypes/SeoBrief";
import {ContentStrategy} from "../JobTypes/ContentStrategy";
import {LinkBuilding} from "../JobTypes/LinkBuilding";

// Hooks
import { useOrderForm } from "../useOrderForm";
import { useAuth } from "../../../hooks/useAuth";
import { useForms } from "../../../hooks/useForms";
import { useOrder } from "../../../hooks/useOrder";
import { useUi } from "../../../hooks/useUi";
import { useUser } from "../../../hooks/useUser";
import { useModals } from "../../../hooks/useModals";

// Instruments
import { isEpiic, getAppTitle, setAccountCookies } from "../../../helpers/helpers";
import { isNil, isEmpty } from "ramda";
import animateScrollTo from 'animated-scroll-to';

export const Form = ({ page }) => {
    /* Hooks */
    const fields = useOrderForm(page);
    const { getProjectTitle, getJobType, getBtns, onFormDragStart, onFormDrop } = fields;
    const { keys: { accountId }, setAccount } = useAuth();
    const { clearOrderForms } = useForms();
    const { job_type, editedProject, updateOrderState } = useOrder();
    const { setModalState } = useModals();
    const { isAuth, isMobile } = useUi();
    const { company } = useUser();
    const { branding: { host }, subscriptions } = company;
    const navigate = useNavigate();
    
    /* Actions */
    const goPath = ({ currentTarget: { dataset: { path } } }) => {
        navigate(path);
    };

    useEffect(() => {
        if (page === 'new') {
            document.title = getAppTitle('New project', company);
        }
        animateScrollTo(0);

        return () => {
            if (isAuth) {
                clearOrderForms();
                setModalState('inviteTalentsObj', null);
                updateOrderState({ 'editedProject': {}, 'duplicatedProject': {} });
            }

            updateOrderState({ job_group: null, job_type: null, job_pay_type: null, seoaudit_type: null });
        };
    }, []);
    useEffect(() => {
        if (!isEmpty(editedProject)) {
            if (page === 'editing') {
                document.title = getAppTitle(`New project ${editedProject.project_id}`, company);
            }
            if (editedProject.account_uid !== accountId) {
                setAccountCookies(editedProject.account_uid);
                setAccount(editedProject.account_uid);
            }
        }
    }, [editedProject]);

    if (isAuth && isNil(subscriptions)) {
        return null;
    }
    if (page === 'editing' && isEmpty(editedProject)) {
        return null;
    }

    /* Html */
    const getCloseIcon = () => {
        if (!isAuth || isMobile) return null;

        return <i onClick={goPath} data-path='/order' className='gac-close-icon' />;
    };

    const content = {
        blogging: <Blogging fields={fields} page={page} />,
        design: <Design fields={fields} />,
        keywords: <Keywords fields={fields} />,
        proofreading: <Proofreading fields={fields} />,
        ideas: <Ideas fields={fields} />,
        writing: <Writing fields={fields} page={page} />,
        aih: <AiHuman fields={fields} />,
        motion: <Motion fields={fields} page={page} />,
        webaudit: <WebAudit fields={fields} />,
        /*seoaudit: <SeoAudit fields={fields} />,*/
        seobrief: <SeoBrief fields={fields} />,
        contentstrategy: <ContentStrategy fields={fields} />,
        linkbuilding: <LinkBuilding fields={fields} />,
    };

    return <>
        <div className={`gac-new-project ${isAuth ? '' : 'gac-not-logged'} ${isEpiic(host) ? 'gac-epiic' : ''}`} onDragOver={onFormDragStart} onDrop={onFormDrop}>
            {getCloseIcon()}
            {getProjectTitle()}
            {getJobType()}
            {content[job_type]}
            {getBtns()}
        </div>
    </>;
};