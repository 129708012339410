// Core
import { call, put, select } from 'redux-saga/effects';
import Cookies from 'universal-cookie';
import { Crisp } from "crisp-sdk-web";

// Instruments
import { api, authStr, domain, isStage, getCrispId, getKeys } from '../../../../helpers/api';
import { userActions } from '../../actions';
import { uiActions } from '../../../ui/actions';
import { getRole } from "../../../../helpers/helpers";

export function* callFetchUserDetailsWorker () {
    try {
        const { privateKey, publicKey, accountId } = yield select(getKeys);
        const response = yield call(fetch, `${api}/${publicKey}/v1/user_details`,{
            method: 'GET',
            headers: {
                Authorization: `${authStr} ${privateKey}`
            }
        });
        const { response: details, user: { master }, error: { message }} = yield call([response, response.json]);
        const currency = details.accounts.filter((o) => o.account_id === accountId )[0].account_currency;

        if (response.status !== 200) {
            throw new Error(message);
        }

        const cookies = new Cookies();
        const expires = new Date();
        expires.setSeconds(expires.getSeconds() + (3600*24*365));
        const options = {
            path: '/',
            expires,
            domain: `.${domain}`
        };
        cookies.set('_gac_user_uid', details.user_id, options);

        if ( getRole(details.user_role) < 4 && !isStage ) {
            Crisp.configure(getCrispId());
        }
        yield put(userActions.setUserState('details', { ...details, currency, master: !!master }));
        yield put(uiActions.setUiState('isUserLoaded', true));
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`)
    }
}
