// Core
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { authActions } from "../bus/auth/actions";

export const useAuth = () => {
    const selector = (state) => state.auth;
    const auth = useSelector(selector);
    const dispatch = useDispatch();

    const authenticate = (data) => {
        dispatch(authActions.authenticate(data));
    };
    const authenticateSuccess = (data) => {
        dispatch(authActions.authenticateSuccess(data));
    };
    // authenticateFail

    const login = (credentials, navigate) => {
        dispatch(authActions.login(credentials, navigate));
    };
    const loginSuccess = (data) => {
        dispatch(authActions.loginSuccess(data));
    };
    const loginFakeUserSuccess = (data) => {
        dispatch(authActions.loginFakeUserSuccess(data));
    };
    const logout = (navigate) => {
        dispatch(authActions.logout(navigate));
    };
    const logoutSuccess = () => {
        dispatch(authActions.logoutSuccess());
    };
    const clearCookies = () => {
        dispatch(authActions.clearCookies());
    };
    const setAccount = (account) => {
        dispatch(authActions.setAccount(account));
    };

    return {
        ...auth,
        authenticate,
        authenticateSuccess,
        login,
        loginSuccess,
        loginFakeUserSuccess,
        logout,
        logoutSuccess,
        clearCookies,
        setAccount,
    };
};
