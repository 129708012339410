// Core
import { call, put, select } from 'redux-saga/effects';

// Instruments
import { api, getKeys, authStr } from '../../../../helpers/api';
import { listsActions } from "../../actions";
import { uiActions } from '../../../ui/actions';

export function* callGetTransactionsWorker ({ payload: { id, range }}) {
    try {
        yield put(uiActions.enableFetching());
        const { privateKey, publicKey } = yield select(getKeys);
        const url = range ? `?${range}` : '';
        const response = yield call(fetch, `${api}/${publicKey}/v1/transactions${id ? `/${id}` : ''}${url}`,{
            method: 'GET',
            headers: {
                Authorization: `${authStr} ${privateKey}`
            }
        });
        const { response: transactions, error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }

        yield put( listsActions.setListsState('transactions',transactions));
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
        yield put(uiActions.setUiState('notification', {
            msg: error.message,
            type: 'error',
            fn: null,
        }));
    } finally {
        yield put(uiActions.disableFetching());
    }
}
