// Core
import { useEffect } from "react";
import { Link } from 'react-router-dom';

// Hooks
import { useLogin } from "../useLogin";
import { useUi } from "../../../hooks/useUi";
import { useUser } from "../../../hooks/useUser";

// Instruments
import { getAppTitle } from "../../../helpers/helpers";
import { isNil, includes } from "ramda";

export const PasswordUpdate = () => {
    /* Hooks */
    const { getTitle, getPassword, getSubmitBtn } = useLogin();
    const { isAuth, isPasswordUpdated, passwordUpdatingError } = useUi();
    const { company } = useUser();

    /* Did mount */
    useEffect(() => {
        document.title = getAppTitle('Update password', company);
    }, []);

    if ( !isNil(isPasswordUpdated) && !isPasswordUpdated ) {
        return(
            <div className = 'gac-link-error' >
                {`${passwordUpdatingError} `}
                { isAuth
                    ? null
                    : <>
                        <Link to = '/password'>
                            { `Restore ${ includes('invalid', passwordUpdatingError) ? 'password' : 'again' }` }
                        </Link>.
                    </> }
            </div>
        );
    }

    return(
        <div className = 'gac-wrap'>
            { getTitle() }
            { getPassword() }
            { getSubmitBtn() }
        </div>
    );
};