// Core
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { modalsActions } from "../bus/modals/actions";
import { modalsActionsAsync } from "../bus/modals/saga/asyncActions";

export const useModals = () => {
    const selector = (state) => state.modals;
    const modals = useSelector(selector);
    const dispatch = useDispatch();

    /* Actions */
    const setModal = (modal) => {
        dispatch(modalsActions.setModal(modal));
    };
    const setModalState = (prop, data) => {
        dispatch(modalsActions.setModalState(prop, data));
    };

    /* Async actions */
    const createSupportMessageAsync = (data) => {
        dispatch(modalsActionsAsync.createSupportMessageAsync(data));
    };
    const createProjectAsync = (project, action) => {
        dispatch(modalsActionsAsync.createProjectAsync(project, action));
    };

    return {
        ...modals,
        setModal,
        setModalState,
        createSupportMessageAsync,
        createProjectAsync,
    };
};
