// Core
import { call, put, select } from 'redux-saga/effects';

// Instruments
import {api, getKeys, authStr, getPusherData} from '../../../../helpers/api';
import { listsActions } from "../../../lists/actions";
import { uiActions } from '../../../ui/actions';
import { includes } from 'ramda';

export function* callAddProjectToOrderWorker ({ payload: { id, action, navigate }}) {
    try {
        yield put(uiActions.enableFetching());
        const { privateKey, publicKey, accountId: account_uid } = yield select(getKeys);
        const { sessionID } = yield select(getPusherData);
        const response = yield call( fetch, `${api}/${publicKey}/v1/order_list`,{
            method: 'PUT',
            headers: {
                'Authorization': `${authStr} ${privateKey}`,
                'Content-Type':  'application/json',
            },
            body: JSON.stringify({
                account_uid,
                project_id: id,
                pusherData: {
                    sessionID
                },
            }),
        });
        const { response: list, error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }

        yield put( listsActions.setListsState('orderList', list) );
        if ( includes(action, 'save_add_to_order/update_add_to_order') ) {
            navigate(`/order/confirm`);
        }
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
    } finally {
        yield put(uiActions.disableFetching());
    }
}
