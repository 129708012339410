// Core
import { useEffect, useRef } from "react";

// Hooks
import { useProject } from "../../../hooks/useProject";

// Icon
import info from '../../../theme/images/icon-info.gif';

export const ModalRequestRevision = ({ closeModal, setPos }) => {
    /* Ref */
    const modalRef = useRef(null);

    /* Hooks */
    const { details: { project_id, job_type, writer_name }, requestRevisionAsync } = useProject();

    /* Actions */
    const onSubmit = () => {
        requestRevisionAsync({ project_id, job_type });
    };

    /* Did mount */
    useEffect(() => {
        setPos( modalRef );
    }, []);

    return <div ref = { modalRef } className = 'gac-modal gac-request-revision-modal'>
        <span className = 'gac-close-modal' onClick = { closeModal }/>
        <div className="gac-request-revision-modal__icon">
            <img src = { info } alt = 'Icon'/>
        </div>
        <h2>Would you like to request a revision to send the project back to { writer_name }.?</h2>
        <div className="gac-btn-wrap">
            <div className='gac-btn gac-btn-s' onClick = { onSubmit }>Request revision</div>
        </div>
    </div> ;
};