// Core
import { call, put, select } from 'redux-saga/effects';

// Instruments
import { api, getKeys, authStr } from '../../../../helpers/api';
import { notifications } from "../../../../helpers/notifications";
import { projectActions } from "../../actions";
import { userActionsAsync } from "../../../user/saga/asyncActions";
import { uiActions } from '../../../ui/actions';
import { includes } from 'ramda';

export function* callConfirmProjectWorker ({ payload: { project, action, navigate }  }) {
    try {
        yield put(uiActions.enableFetching());
        const { privateKey, publicKey, accountId } = yield select(getKeys);
        const response = yield call(fetch, `${api}/${publicKey}/v1/projects/${action.job_type}/confirm`,{
            method: 'POST',
            headers: {
                'Authorization': `${authStr} ${privateKey}`,
                'Content-Type':  'application/json',
            },
            body: JSON.stringify({
                ...project,
                account_uid: accountId,
            }),
        });
        const { response: data, error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }

        yield put(userActionsAsync.fetchBalanceAsync(accountId));
        if ( includes(action.name, 'update_add_to_order/save_add_to_order') ) {
            navigate('/current-projects');
            yield put(uiActions.setUiState('notification', notifications.orderSuccess));
        }
        if ( action.name === 'save_add_to_order_from_idea' ) {
            const { order_on, project_id, status } = data;
            const { idea_number } = project;
            yield put(projectActions.updateIdeaField('ordered_project_id', project_id, idea_number));
            yield put(projectActions.updateIdeaField('ordered_project_date', order_on, idea_number));
            yield put(projectActions.updateIdeaField('ordered_project_status', status, idea_number));
        }
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
        yield put(uiActions.setUiState('notification', {
            msg: error.message,
            type: 'error',
            fn: null,
        }));
    } finally {
        yield put(uiActions.disableFetching());
    }
}
