// Core
import { useEffect, useRef } from "react";

// Components
import StripeForm from "../../StripeForm";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// Hooks
import { useModals } from "../../../hooks/useModals";
import { usePayments } from "../../../hooks/usePayments";
import { useUser } from "../../../hooks/useUser";
import { useUi } from "../../../hooks/useUi";

// Instruments
import { stripeKeyEpiic, stripeKey } from "../../../helpers/api";
import { getStripe, isFetching } from "../../../helpers/helpers";
import { isNil } from 'ramda';

let stripeGacPromise;
let stripeEpiicPromise;
const dropin = require('braintree-web-drop-in');

export const ModalChangeCard = ({ closeModal, setPos }) => {
    /* Ref */
    const dropinRef = useRef(null);
    const modalRef = useRef(null);

    /* Hooks */
    const { activeSub } = useModals();
    const { payment_method, accounts, subscription_uid } = activeSub;
    const { cardBraintree: { token }, stripe: { client_secret }, setPaymentsState, fetchStripeCardsAsync, deleteStripeCardAsync } = usePayments();
    const { paymentCards: cards, setUserState, changeCardAsync } = useUser();
    const { fetching, isMobile, setUiState, disableFetching } = useUi();

    /* Actions */
    const isSub = (type) => {
        return activeSub.talent === type;
    };
    const initBraintree = (token) => {
        dropin.create({
            authorization: token,
            container: '#card-dropin',
            vaultManager: true,
            card: {
                vault: {
                    allowVaultCardOverride: true,
                },
                overrides: {
                    styles: {
                        input: {
                            color: '#484b58',
                        }
                    }
                }
            },
            threeDSecure: { amount: 1 },
        }, onBraintreeCreate);
    };
    const onBraintreeCreate = (createErr, instance) => {
        window.dropinInstance = instance;
        disableFetching();
        setPos(modalRef);
    };
    const onSubmit = (data) => {
        const { account_uid } = accounts[0];

        if ( !isFetching(fetching) ) {
            if ( payment_method === 'braintree' ) {
                window.dropinInstance.requestPaymentMethod(function (err, payload) {
                    if ( err ) {
                        setPos(modalRef);
                    }
                    if ( !isNil(payload) && !err ) {
                        setUiState('isBlocking', true);
                        changeCardAsync({
                            account_uid,
                            payment_method_nonce: payload.nonce,
                        });
                    }
                });
            } else if ( payment_method === 'stripe' || payment_method === 'stripe-epiic' ) {
                setUiState('isBlocking', true);
                changeCardAsync({
                    ...data,
                    type: 'stripe',
                    subscription_uid,
                    account_uid,
                });
            }
        }
    };

    /* Did mount */
    useEffect(() => {
        if ( isSub('writers') || isSub('bloggers') ) {
            if ( payment_method === 'stripe' || payment_method === 'stripe-epiic' ) {
                fetchStripeCardsAsync(
                    activeSub.accounts[0].account_uid,
                    payment_method === 'stripe-epiic' ? 'epiic' : 'stripe',
                    activeSub.id,
                    activeSub.talent
                );
            } else {
                initBraintree(token);
            }
        } else if ( isSub('designers') ) {
            fetchStripeCardsAsync(
                activeSub.accounts[0].account_uid,
                'epiic',
                activeSub.id,
                activeSub.talent
            );
        } else if ( isSub('webbuilders') || isSub('assistants') ) {
            fetchStripeCardsAsync(
                activeSub.accounts[0].account_uid,
                'epiic'
            );
        }
        setPos( modalRef );

        return () => {
            setUserState('paymentCards', { stripe: null, epiic: null });
            setPaymentsState('cardBraintree', {});
        };
    }, []);

    const type = isSub('assistants') ? 'assistants' : isSub('webbuilders') ? 'webbuilding' : isSub('writers') ? 'writing' : isSub('bloggers') ? 'blogging' : 'design';
    const isStripe = payment_method === 'stripe';
    const isStripeEpiic = payment_method === 'stripe-epiic';
    stripeGacPromise = getStripe(stripeGacPromise, stripeKey, loadStripe);
    stripeEpiicPromise = getStripe(stripeEpiicPromise, stripeKeyEpiic, loadStripe);

    return <div ref = { modalRef } className = 'gac-modal-subscription'>
        <span className = 'gac-close-modal' onClick = { closeModal }/>
        <h1 style={{ marginBottom: 20 }}>Change card</h1>
        { isStripe || isStripeEpiic
            ? (( isStripeEpiic && stripeEpiicPromise) || (isStripe && stripeGacPromise)) && <Elements stripe = {
                isStripeEpiic ? stripeEpiicPromise : stripeGacPromise }>
                <StripeForm
                    parent = 'changeCardModal'
                    type = { type }
                    cards = { cards[isStripeEpiic ? 'epiic' : 'stripe'] }
                    secret = { client_secret }
                    isMobile = { isMobile }
                    isChangeCard = { true }
                    setUiState = { setUiState }
                    deleteCard = { deleteStripeCardAsync }
                    callback = { () => { setPos(modalRef) } }
                    onSubmit = { onSubmit } />
            </Elements>
            : <div className="gac-card-dropin">
                <div id='card-dropin' className='gac-sub-dropin-wrap' ref = { dropinRef }/>
                <div className="gac-dropin-btns">
                    <span className = 'gac-btn gac-btn-s' onClick = { onSubmit }>Change</span>
                </div>
            </div> }
    </div> ;
};