// Core
import { call, select } from 'redux-saga/effects';

// Instruments
import { api, getKeys, authStr } from '../../../../helpers/api';
import moment from 'moment/moment';

export function* callPingWorker () {
    try {
        const { privateKey, publicKey } = yield select(getKeys);
        if ( privateKey && publicKey ) {
            const response = yield call(fetch, `${api}/${publicKey}/v1/ping`, {
                method: 'POST',
                headers: {
                    'Authorization': `${authStr} ${privateKey}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    session_page_url: window.location.href,
                    session_timezone: moment().format('Z'),
                }),
            });
            const {error: {message}} = yield call([response, response.json]);

            if (response.status !== 200) {
                throw new Error(message);
            }
        }
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
    }
}
