// Core
import { Suspense, lazy, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';

// Components
import { CompanyNew } from '../components/CompanyNew';
import { OrderPage } from "../components/OrderPage";
import { ProjectDetails } from '../components/ProjectDetails';
import { Manage } from '../components/Manage';
import { ImagesPage } from "../components/ImagesPage";
import { Calendar } from '../components/Calendar';
import { PaymentConfirm } from "../components/PaymentConfirm";
import { LoginPage } from "../components/LoginPage";
import { MessagesCenter } from "../components/MessagesCenter";
import { NoMatch } from "../components/common/NoMatch";
import { Spinner } from '../components/common/Spinner';
import ReactPixel from 'react-facebook-pixel';
import Pages from '../pages';

// Hooks
import { usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { useAuth } from "../hooks/useAuth";
import { useLists } from "../hooks/useLists";
import { useProject } from "../hooks/useProject";
import { useSocket } from "../hooks/useSocket";
import { useUi } from "../hooks/useUi";
import { useUser } from "../hooks/useUser";

// Instruments
import { getGA4Id, fbPixelId, isStage } from '../helpers/api';
import {
    getRole,
    isClientFn,
    isSelfClientFn,
    setAccountCookies,
    isLocal,
    getCurrencyCode,
} from '../helpers/helpers';
import {
    subPublicChannel,
    unsubPublicChannel,
    bindClientEvent,
    subUserChannel,
    unsubUserChannel,
    bindLogoutEvent,
    bindUnreadMessages,
    bindRemoveFromCartEvent,
    bindRemoveAllFromCartEvent,
    bindAddToCartEvent,
    bindProjectUpdatedEvent,
    bindBundlePurchasedEvent,
    bindChangedQueueEvent,
    bindMessagesCenterNewMessageEvent,
    bindChangedBalanceEvent,
    bindNewSubscriptionPaymentEvent
} from '../init/pusher';
import { isNil, isEmpty, includes } from "ramda";

// Components
const AccessDenied = lazy(() => import('../components/common/AccessDenied').catch(() => window.location.reload()));
const Users = lazy(() => import('../components/Users').catch(() => window.location.reload()));
const UserDetails = lazy(() => import('../components/UserDetails').catch(() => window.location.reload()));
const GeneralSettings = lazy(() => import('../components/GeneralSettings').catch(() => window.location.reload()));
const Accounts = lazy(() => import('../components/Accounts').catch(() => window.location.reload()));
const AccountDetails = lazy(() => import('../components/AccountDetails').catch(() => window.location.reload()));
const Transactions = lazy(() => import('../components/Transactions').catch(() => window.location.reload()));
const PlansDesign = lazy(() => import('../components/Plans/PlansDesign').catch(() => window.location.reload()));
const PlansWritingBlogging = lazy(() => import('../components/Plans/PlansWritingBlogging').catch(() => window.location.reload()));
const PlansBundle = lazy(() => import('../components/Plans/PlansBundle').catch(() => window.location.reload()));
const PlansPrepay = lazy(() => import('../components/Plans/PlansPrepay').catch(() => window.location.reload()));
const PlansWebBuilders = lazy(() => import('../components/Plans/PlansWebBuilders').catch(() => window.location.reload()));
const PlansAssistants = lazy(() => import('../components/Plans/PlansAssistants').catch(() => window.location.reload()));
const SubscriptionsPage = lazy(() => import('../components/SubscriptionsPage').catch(() => window.location.reload()));
const SubscriptionSingle = lazy(() => import('../components/SubscriptionSingle').catch(() => window.location.reload()));

export const Private = () => {
    /* Hooks */
    const { keys: { accountId }, setAccount } = useAuth();
    const { getMessagesAsync, getOrderListAsync } = useLists();
    const { fetchProjectTagsAsync } = useProject();
    const { pusher, channels: { publicChannel, userChannel }, initPusher } = useSocket();
    const { isCompanyLoaded, isUserLoaded } = useUi();
    const {
        details,
        fetchCompanyAsync,
        fetchUserDetailsAsync,
        fetchCompanySubscriptionsAsync,
        fetchBalanceAsync,
        fetchBrandProfilesAsync,
        fetchTalentsBlacklistAsync,
    } = useUser();
    const { accounts, currency, user_role } = details;
    const { search } = useLocation();
    const navigate = useNavigate();
    const [{ options: paypalInitOpts }, paypalDispatch] = usePayPalScriptReducer();

    useEffect(() => {
        if (!isStage) ReactPixel.init(fbPixelId);
        initPusher();
        fetchCompanyAsync({ name: 'get_company' });
        fetchUserDetailsAsync();
        getMessagesAsync(1);
    }, []);
    useEffect(() => {
        if (!isNil(currency) && !isEmpty(currency)) {
            paypalDispatch({
                type: "resetOptions",
                value: {
                    ...paypalInitOpts,
                    currency: getCurrencyCode(currency).toUpperCase(),
                },
            });
        }
    }, [currency]);
    useEffect(() => {
        if (!isNil(user_role)) {
            fetchCompanySubscriptionsAsync();
            if (!isClientFn(user_role)) {
                if (!isNil(accountId)) {
                    fetchBalanceAsync(accountId);
                }
                getOrderListAsync(navigate);
                fetchProjectTagsAsync();
                fetchBrandProfilesAsync();
                fetchTalentsBlacklistAsync('copywriters');
                fetchTalentsBlacklistAsync('designers');
            }
        }
    }, [user_role]);
    useEffect(() => {
        if (!isClientFn(user_role)) {
            fetchUserDetailsAsync();
            fetchBalanceAsync(accountId);
            getOrderListAsync(navigate);
            fetchProjectTagsAsync();
        }
    }, [accountId]);
    useEffect(() => {
        if (!isEmpty(pusher)) {
            subPublicChannel(pusher);
        }
        return () => {
            if (!isEmpty(pusher)) {
                unsubPublicChannel(pusher);
            }
        }
    }, [pusher]);
    useEffect(() => {
        if (!isNil(publicChannel)) {
            bindClientEvent(publicChannel);
        }
    }, [publicChannel]);
    useEffect(() => {
        if (!isEmpty(pusher) && !isEmpty(details)) {
            const { user_id } = details;

            subUserChannel(pusher, user_id);
        }

        return () => {
            if (!isEmpty(pusher) && !isEmpty(details)) {
                const { user_id } = details;
                unsubUserChannel(pusher, user_id);
            }
        }
    }, [pusher, details]);
    useEffect(() => {
        if (!isNil(userChannel)) {
            bindLogoutEvent(userChannel);
            bindRemoveFromCartEvent(userChannel);
            bindRemoveAllFromCartEvent(userChannel);
            bindAddToCartEvent(userChannel);
            bindProjectUpdatedEvent(userChannel);
            bindBundlePurchasedEvent(userChannel);
            bindUnreadMessages(userChannel);
            bindChangedQueueEvent(userChannel);
            bindMessagesCenterNewMessageEvent(userChannel);
            bindChangedBalanceEvent(userChannel);
            bindNewSubscriptionPaymentEvent(userChannel, navigate);
        }
    }, [userChannel]);
    useEffect(() => {
        if (includes('?account=', search)) {
            const acc = search.split('=')[1];
            if (accounts.some(o => o.account_id === acc)) {
                setAccountCookies(acc);
                setAccount(acc);
            }
        }
    }, [isUserLoaded]);

    const login = () => {
        return ['/password-update']
            .map(path => <Route key={`id-${path}`} path={path} element={<LoginPage />} />);
    };
    const calendar = () => {
        return ['/', '/login', '/password', '/password-create']
            .map(path => <Route key={`id-${path}`} path={path} element={<Navigate to='/calendar' />} />);
    };

    if (!isCompanyLoaded || !isUserLoaded) return <Spinner isSpinning={true} />;

    const role = getRole(user_role);
    const isClient = isClientFn(user_role);
    const isSelfClient = isSelfClientFn(user_role);

    return <Suspense fallback={<Spinner isSpinning={true} />}>
        <Routes>
            {login()}
            {calendar()}
            <Route path='/calendar' element={<Calendar />} />
            <Route path='/images' element={<ImagesPage />} />

            <Route path='/order' element={isClient ? <NoMatch /> : <OrderPage page='order' />} />
            <Route path='/order/new' element={isClient ? <NoMatch /> : <OrderPage page='new' />} />
            <Route path='/order/duplicate' element={isClient ? <NoMatch /> : <OrderPage page='duplicate' />} />
            <Route path='/order/duplicate/:projectId' element={isClient ? <NoMatch /> : <OrderPage page='duplicating' />} />
            <Route path='/order/drafts' element={isClient ? <NoMatch /> : <OrderPage page='drafts' />} />
            <Route path='/order/confirm' element={isClient ? <NoMatch /> : <OrderPage page='confirm' />} />
            <Route path='/order/:projectId' element={isClient ? <NoMatch /> : <OrderPage page='editing' />} />

            <Route path='/draft-projects' element={isClient ? <NoMatch /> : <Manage page='draft' />} />
            <Route path='/current-projects' element={<Manage page='current' />} />
            <Route path='/completed-projects' element={<Manage page='completed' />} />

            <Route path='/users' element={isClient || isSelfClient ? <NoMatch /> : role < 3 ? <Users /> : <AccessDenied />} />
            <Route path='/user/:userId/role' element={isClient || isSelfClient ? <NoMatch /> : role < 3 ? <UserDetails page='role' /> : <AccessDenied />} />
            <Route path='/user/new' element={isClient || isSelfClient ? <NoMatch /> : role < 3 ? <UserDetails page='new' /> : <AccessDenied />} />
            <Route path='/user/:userId' element={<UserDetails page='user' />} />

            <Route path='/settings' element={isClient || isSelfClient ? <NoMatch /> : role < 3 ? <GeneralSettings page='branding' /> : <AccessDenied />} />
            <Route path='/settings/billing' element={isClient || isSelfClient ? <NoMatch /> : role < 3 ? <GeneralSettings page='billing' /> : <AccessDenied />} />

            <Route path='/accounts' element={isClient || isSelfClient ? <NoMatch /> : role < 4 ? <Accounts /> : <AccessDenied />} />
            <Route path='/account/:accountId/team' element={isClient || isSelfClient ? <NoMatch /> : role < 3 ? <AccountDetails page='team' /> : <AccessDenied />} />
            <Route path='/account/:accountId/billing' element={isClient || isSelfClient ? <NoMatch /> : role < 3 ? <AccountDetails page='billing' /> : <AccessDenied />} />
            <Route path='/account/:accountId/client' element={isClient || isSelfClient ? <NoMatch /> : role < 3 ? <AccountDetails page='client' /> : <AccessDenied />} />
            <Route path='/account/:accountId/publication' element={isClient || isSelfClient ? <NoMatch /> : role < 3 ? <AccountDetails page='publication' /> : <AccessDenied />} />
            <Route path='/account/new' element={isClient || isSelfClient ? <NoMatch /> : role < 3 ? <AccountDetails page='new' /> : <AccessDenied />} />
            <Route path='/account/:accountId' element={isClient || isSelfClient ? <NoMatch /> : role < 4 ? <AccountDetails page='overview' /> : <AccessDenied />} />

            <Route path='/project/:projectId/brief' element={isClient ? <NoMatch /> : <ProjectDetails page='brief' />} />
            <Route path='/project/:projectId/messages' element={<ProjectDetails page='messages' />} />
            <Route path='/project/:projectId/content' element={<ProjectDetails page='content' />} />
            <Route path='/project/:projectId/sharing' element={isClient || isSelfClient ? <NoMatch /> : <ProjectDetails page='sharing' />} />
            <Route path='/project/:projectId' element={isClient ? <NoMatch /> : <ProjectDetails page='overview' />} />

            <Route path='/payments' element={<Navigate to='/transactions' />} />
            <Route path='/transactions' element={isClient || isSelfClient ? <NoMatch /> : role < 4 ? <Transactions /> : <AccessDenied />} />

            <Route path='/designers/plans' element={<Navigate to='/designers/pricing' />} />
            <Route path='/designers/pricing' element={isClient || isSelfClient ? <NoMatch /> : <Pages.SubscriptionsDesigners type='hourly-designers' />} />
            <Route path='/designers-unlimited/pricing' element={isClient || isSelfClient ? <NoMatch /> : <PlansDesign type="design" />} />
            <Route path='/writers/pricing' element={isClient || isSelfClient ? <NoMatch /> : <PlansWritingBlogging type='writers' />} />
            <Route path='/bloggers/pricing' element={isClient || isSelfClient ? <NoMatch /> : <PlansWritingBlogging type='bloggers' />} />
            <Route path='/bundles' element={isClient || isSelfClient ? <NoMatch /> : <PlansBundle />} />
            <Route path='/prepay' element={isClient || isSelfClient ? <NoMatch /> : <PlansPrepay />} />
            <Route path='/website-builders/pricing' element={<Navigate to='/website-developers/pricing' />} />
            <Route path='/website-developers/pricing' element={isClient || isSelfClient ? <NoMatch /> : <PlansWebBuilders />} />
            <Route path='/assistants/pricing' element={isClient || isSelfClient ? <NoMatch /> : <PlansAssistants />} />
            <Route path='/support/pricing' element={isClient || isSelfClient ? <NoMatch /> : <Pages.PlansSupport />} />
            <Route path='/subscriptions' element={isClient || isSelfClient ? <NoMatch /> : role < 3 ? <SubscriptionsPage /> : <AccessDenied />} />
            <Route path='/subscriptions/:subId/plans' element={isClient || isSelfClient ? <NoMatch /> : role < 3 ? <SubscriptionSingle page='plans' /> : <AccessDenied />} />
            <Route path='/subscriptions/:subId' element={isClient || isSelfClient ? <NoMatch /> : role < 3 ? <SubscriptionSingle page='overview' /> : <AccessDenied />} />

            <Route path='/payment/confirm' element={isClient || isSelfClient ? <NoMatch /> : <PaymentConfirm page='confirm' />} />
            <Route path='/payment/success' element={isClient || isSelfClient ? <NoMatch /> : <PaymentConfirm page='success' />} />

            <Route path='/company/new' element={isClient || isSelfClient ? <NoMatch /> : <CompanyNew />} />

            <Route path='/403' element={<NoMatch />} />
            <Route path='*' element={<NoMatch />} />
        </Routes>
        <MessagesCenter />
    </Suspense>;
};