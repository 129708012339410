export const notifications = {
    refresh: {
        msg: `Please refresh the page!`,
        type: 'error',
        fn: null,
    },
    orderSuccess: {
        msg: 'Success! Your order is placed.',
        type: 'success',
        fn: null,
    },
    changesSaved: {
        msg: 'Changes saved',
        type: 'success',
        fn: null,
    },
    editingProject: {
        msg: 'The project is open in editing mode in more than one tab. Close this or other tabs to avoid losing editing changes.',
        type: 'info',
        fn: null,
    }
};