// Core
import { createStore, applyMiddleware, compose } from 'redux';
import * as Sentry from "@sentry/react";

// Instruments
import createRootReducer from './rootReducer'
import { sagaMiddleware, middleware, dev, history } from './middleware';
import { rootSaga } from './rootSaga';

const devtools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
const composeEnhancers = dev && devtools ? devtools : compose;
const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

const store = createStore(
    createRootReducer(),
    composeEnhancers(applyMiddleware(...middleware), sentryReduxEnhancer),
);

export { store, history };

sagaMiddleware.run(rootSaga);
