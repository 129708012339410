// Core
import { call, put, select } from 'redux-saga/effects';

// Instruments
import { api, getKeys, getUsers, authStr } from '../../../../helpers/api';
import { uiActions } from '../../../ui/actions';
import { modalsActions } from "../../../modals/actions";
import { listsActions } from "../../../lists/actions";

export function* callDeleteUserWorker ({ payload: { userId, action, navigate } }) {
    try {
        yield put(uiActions.enableFetching());
        const { privateKey, publicKey } = yield select(getKeys);
        const response = yield call(fetch, `${api}/${publicKey}/v1/users/${userId}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `${authStr} ${privateKey}`,
            },
        });
        const { error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }
        let msg = 'The user is deactivated.';
        yield put( modalsActions.setModal(null) );
        if ( action === 'delete_user' ) {
            navigate(`/users`);
        } else {
            msg = 'The client is deactivated.';
        }
        const users = yield select(getUsers);
        yield put(listsActions.setListsState('users', users.filter(o => o.user_id !== userId )));
        yield put(uiActions.setUiState('notification', {
            msg,
            type: 'success',
            fn: null,
        }));
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
        yield put(uiActions.setUiState('notification', {
            msg: error.message,
            type: 'error',
            fn: null,
        }));
    } finally {
        yield put(uiActions.disableFetching());
    }
}
