// Core
import { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';

// Components
import { DayPicker } from 'react-day-picker';

// Hooks
import { useModals } from "../../../hooks/useModals";
import { useUser } from "../../../hooks/useUser";

// Instruments
import { toLocaleString, getSubStatus, onOutsideElClick } from "../../../helpers/helpers";
import { subTalentTypeConfig } from "../../../helpers/constants";
import { includes, isNil } from 'ramda';
import moment from 'moment/moment';

export const ModalPauseSubscription = ({ closeModal, setPos }) => {
    /* Ref */
    const modalRef = useRef(null);
    const pickerRef = useRef(null);

    /* State */
    const [isDayPicker, setIsDayPicker] = useState(false);
    const [isEmptyDate, setIsEmptyDate] = useState(false);
    const [end_date, setEndDate] = useState(null);

    /* Hooks */
    const { activeSub } = useModals();
    const { end_date: subEndDate, paused_until, talent, plan_name, words_balance, subscription_uid, accounts } = activeSub;
    const { isPaused, isPauseScheduled } = getSubStatus(activeSub);
    const { details: { master: masterPass }, pauseSubscriptionAsync } = useUser();
    const navigate = useNavigate();

    useEffect(() => {
        const onResize = () => {
            setPos( modalRef );
        };

        if ( isPaused || isPauseScheduled ) {
            setEndDate(paused_until);
        }
        setPos( modalRef );
        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, []);
    useEffect(() => {
        const onOutsideClick = (e) => {
            onOutsideElClick(e,pickerRef.current,() => { setIsDayPicker(false) });
        };

        if ( isDayPicker ) {
            document.addEventListener('click', onOutsideClick, true);
            document.addEventListener('touchstart', onOutsideClick, true);
        }

        return () => {
            document.removeEventListener('click', onOutsideClick, true);
            document.removeEventListener('touchstart', onOutsideClick, true);
        };
    }, [isDayPicker]);

    /* Actions */
    const toggleDayPicker = () => {
        setIsDayPicker(state => !state);
    };
    const onEndDateChange = (day) => {
        const newDate = moment(day).hours(0).minutes(0).format('YYYY-MM-DD HH:mm:ss');

        if ( !moment(newDate).isBefore(moment(subEndDate).add(1, 'day'), 'day') && ( masterPass || !moment(newDate).isAfter(moment(subEndDate).add(3, 'months').add(1, 'day')) ) ) {
            const hours = moment().hour();
            const minutes = moment().minute();
            setIsDayPicker(false);
            setIsEmptyDate(false);
            setEndDate(moment(newDate).hours(hours).minutes(minutes).format('YYYY-MM-DD HH:mm:ss'));
        }
    };
    /* Submit */
    const onSubmit = () => {
        if ( isNil(activeSub) ) return null;

        if ( isNil(end_date) ) {
            setIsEmptyDate(true);
        } else {
            const { account_uid } = accounts[0];

            pauseSubscriptionAsync({
                subscription_uid,
                account_uid,
                end_date,
                type: subTalentTypeConfig[talent],
            }, navigate);
            closeModal();
        }
    };

    /* Html */
    const getText = () => {
        const isEndDatePast = moment().diff(moment(subEndDate).add(1, 'day')) > 0;
        const selectedDays = isNil(end_date) ? null : new Date(end_date);
        const disabledDays = [
            { from: new Date(moment(subEndDate).subtract(1, 'month').format('YYYY-MM-DD')),  to: isEndDatePast ? new Date(moment().format('YYYY-MM-DD')) : new Date(moment(subEndDate).add(1, 'day').format('YYYY-MM-DD')) },
        ];

        if ( !masterPass ) {
            disabledDays.push(
                { from: new Date(moment(subEndDate).add(3, 'months').add(1, 'day').format('YYYY-MM-DD')), to: new Date(moment(subEndDate).add(5, 'months').format('YYYY-MM-DD')) }
            );
        }

        const getDayPicker = () => {
            return(
                <div className="gac-start-date-wrap" style={{ display: isPaused || isPauseScheduled ? 'inline-block' : null }} ref = { pickerRef }>
                    <div className="gac-start-date" onClick = { toggleDayPicker }>
                        <span style={{ color: isEmptyDate ? '#EE5050' : null }}>{ isNil(end_date) ? 'pick a date' : moment(end_date).format('MMM D, YYYY') }</span> <i/>
                        {/*{ isHint && <div ref = {  } className="gac-hint-text">Pausing is available for up to three months</div> }*/}
                    </div>.
                    { isDayPicker && <DayPicker
                        className='gac-daypicker-wrap'
                        mode = 'single'
                        fromDate = { isEndDatePast ? new Date(moment().format('YYYY-MM-DD')) : new Date(moment(subEndDate).subtract(1, 'month').format('YYYY-MM-DD')) }
                        toDate = { masterPass ? null : new Date(moment(subEndDate).add(4, 'months').format('YYYY-MM-DD')) }
                        selected = { selectedDays }
                        disabled = { disabledDays }
                        onDayClick = { onEndDateChange } /> }
                </div>
            );
        };

        if ( includes(talent, 'designers/webbuilders/assistants') ) {
            return <>
                { !isPaused && <div>{`The ${plan_name} subscription is prepaid and remains active until ${ moment(subEndDate).format('MMM D, YYYY')}.` }</div> }
                { !isPaused && !isPauseScheduled && <div>Pause the next payment and restart the subscription on { getDayPicker() }</div> }
                { (isPaused || isPauseScheduled) && <div>The subscription will restart on { getDayPicker() }</div> }
            </>;
        } else if ( includes(talent, 'writers/bloggers') ) {
            return <>
                { !isPaused && !isPauseScheduled && <div>Pause the next payment and restart the subscription on { getDayPicker() }</div> }
                { (isPaused || isPauseScheduled) && <div>The subscription will restart on { getDayPicker() }</div> }
                { !isNil(words_balance) && words_balance > 0 && <div>{ `${ toLocaleString(words_balance) } words on the balance are available for use despite the subscription status.` }</div> }
            </>;
        }
    };

    return <div ref = { modalRef } className = 'gac-modal-subscription gac-pause-subscription'>
        <span className = 'gac-close-modal' onClick = { closeModal }/>
        <h1>{ isPaused || isPauseScheduled ? 'Modify pause' : 'Pause payment' }</h1>
        <div className="gac-pause-desc">{ getText() }</div>
        <div className="gac-btn-wrap">
            <div className='gac-btn gac-btn-s' onClick = { onSubmit }>{ isPaused || isPauseScheduled ? 'Confirm' : 'Confirm pause' }</div>
        </div>
    </div> ;
};