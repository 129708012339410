// Core
import { call, put, select } from 'redux-saga/effects';

// Instruments
import { api, authStr, getKeys, getTags } from '../../../../helpers/api';
import { projectActions } from "../../actions";
import { uiActions } from "../../../ui/actions";

export function* callCreateTagWorker ({ payload: text }) {
    try {
        yield put(uiActions.enableFetching());
        const { privateKey, publicKey, accountId } = yield select(getKeys);
        const response = yield call(fetch, `${api}/${publicKey}/v1/project_tag?account_uid=${accountId}`,{
            method: 'POST',
            headers: {
                'Authorization': `${authStr} ${privateKey}`,
                'Content-Type':  'application/json',
            },
            body: JSON.stringify({text}),
        });
        const { response: tag, error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }

        const tags = yield select(getTags);
        yield put(projectActions.setProjectState('tags', [ ...tags, { value: tag.project_tag_name, title: tag.project_tag_name } ].sort((a,b) => (a.value).localeCompare(b.value)) ));
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
        yield put(uiActions.setUiState('notification', {
            msg: error.message,
            type: 'error',
            fn: null,
        }));
    } finally {
        yield put(uiActions.disableFetching());
    }
}
