// Core
import { call, put, select } from 'redux-saga/effects';

// Instruments
import { api, getKeys, getAuth, authStr } from '../../../../helpers/api';
import { uiActions } from '../../../ui/actions';
import { modalsActions } from "../../actions";

export function* callCreateSupportMessageWorker ({ payload: data }) {
    try {
        yield put(uiActions.enableFetching());
        const { privateKey, publicKey, accountId } = yield select(getKeys);
        const isAuth = yield select(getAuth);
        const response = yield call(fetch, `${api}${isAuth ? `/${publicKey}` : ''}/v1/feedback${isAuth ? `?account_uid=${accountId}` : '' }`, {
            method: 'POST',
            headers: {
                'Authorization': `${authStr} ${privateKey}`,
                'Content-Type':  'application/json',
            },
            body: JSON.stringify(data),
        });
        const { error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }

        yield put(modalsActions.setModalState('isSupportMessageSuccess', true));
    } catch (error) {
        yield put(modalsActions.setModalState('isSupportMessageFail', true));
    } finally {
        yield put(uiActions.disableFetching());
    }
}
