// Core
import { asyncTypes } from './asyncTypes';

export const modalsActionsAsync = Object.freeze({
    createSupportMessageAsync: (data) => ({
        type:    asyncTypes.CREATE_SUPPORT_MESSAGE_ASYNC,
        payload: data,
    }),
    createProjectAsync: (project, action) => ({
        type:    asyncTypes.CREATE_PROJECT_IN_MODAL_ASYNC,
        payload: {
            project,
            action,
        },
    }),
});
