// Core
import { call, put, select } from 'redux-saga/effects';

// Instruments
import { api, getKeys, authStr } from '../../../../helpers/api';
import { uiActions } from '../../../ui/actions';
import { userActions } from "../../actions";

export function* callUpdateTalentWorker ({ payload: { data, action }}) {
    try {
        yield put(uiActions.enableFetching());
        const { privateKey, publicKey, accountId } = yield select(getKeys);
        let obj = {};
        let url = '';
        if ( action === 'update_notes' ) {
            url = `/notes`;
            obj.notes = data.notes;
        } else if ( action === 'update_status' ) {
            url = `/${data.status}`;
        } else if ( action === 'add_to_blacklist' ) {
            url = `/blacklisted`;
        } else if ( action === 'add_to_account' || action === 'add_suggested_to_account' ) {
            url = `/regular`;
        }
        const response = yield call(fetch, `${api}/${publicKey}/v1/${data.type}/${data.writer_uid}${url}`, {
            method: 'PUT',
            headers: {
                'Authorization': `${authStr} ${privateKey}`,
                'Content-Type':  'application/json',
            },
            body: JSON.stringify({
                account_uid: data.account_uid ? data.account_uid : accountId,
                ...obj,
            }),
        });
        const { error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }
        if ( action === 'update_notes' ) {
            yield put(userActions.updateCopywriterNotes({
                type: data.type,
                writer_uid: data.writer_uid,
                notes: data.notes,
            }));
        } else if ( action === 'add_to_blacklist' ) {
            yield put(userActions.addCopywriterToBlacklist({
                type: data.type,
                writer_uid: data.writer_uid,
            }));
        } else if ( action === 'add_to_account' ) {
            yield put(userActions.addCopywriterToAccount({
                type: data.type,
                writer_uid: data.writer_uid,
            }))
        } else if ( action === 'add_suggested_to_account' ) {
            yield put(userActions.addSuggestedCopywriterToAccount({
                type: data.type,
                writer_uid: data.writer_uid,
            }))
        } else {
            yield put(userActions.updateCopywriterStatus({
                type: data.type,
                writer_uid: data.writer_uid,
                status: data.status,
            }));
        }
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`)
    } finally {
        yield put(uiActions.disableFetching());
    }
}
