// Core
import { useRef, useState, useEffect } from "react";
import { useNavigate, Link } from 'react-router-dom';

// Components
import { motion } from "framer-motion"
import Dotdotdot from 'react-dotdotdot';
import { ProjectType } from '../../Manage/ProjectType';
import FilterItem from '../../common/FilterItem';
import { EmptyState } from '../../common/EmptyState';

// Icons
import Filter from '../../icons/Filter';
import FilterBig from '../../icons/FilterBig';
import Plus from '../../icons/Plus';
import Mark from '../../icons/Mark';
import Timer from '../../icons/Timer';
import Pencil from '../../icons/Pencil';

// Hooks
import { useAuth } from "../../../hooks/useAuth";
import { useLists } from "../../../hooks/useLists";
import { useOrder } from "../../../hooks/useOrder";
import { useProject } from "../../../hooks/useProject";
import { useUi } from "../../../hooks/useUi";
import { useUser } from "../../../hooks/useUser";

// Instruments
import {
    addUtcOffset,
    filterProjectsByFilters,
    getAmount,
    getDesignFormat,
    getFormat,
    toLocaleString,
    getAppTitle,
} from '../../../helpers/helpers';
import moment from 'moment/moment';
import { isNil, isEmpty, keys, reverse, includes } from 'ramda';

const filtersObj = {
    format: {
        visibility: false,
        value:      [],
        title:      [],
    },
    status: {
        visibility: false,
        value:      [],
        title:      [],
    },
    project_tags: {
        visibility: false,
        value:      [],
        title:      [],
    },
};

export const List = ({ page }) => {
    /* Ref */
    const pageRef = useRef(null);

    /* State */
    const [isFilters, setIsFilters] = useState(false);
    const [isFiltersBtnActive, setIsFiltersBtnActive] = useState(false);
    const [searchKey, setSearchKey] = useState('');
    const [filters, setFilters] = useState(filtersObj);
    const [projectsStep, setProjectsStep] = useState(1);
    const [openedProjects, setOpenedProjects] = useState([]);
    const { status, project_tags } = filters;

    /* Hooks */
    const { keys: { accountId }} = useAuth();
    const { projects, orderList, staticData: { statuses, formats }, setListsState, getProjectsAsync } = useLists();
    const { addProjectToOrderAsync, removeProjectFromOrderAsync } = useOrder();
    const { tags, fetchProjectDetailsAsync } = useProject();
    const { projectsTotal, isMobile, setUiState } = useUi();
    const { details: { timezone }, company } = useUser();
    const { subscriptions, branding: { host }} = company;
    const navigate = useNavigate();

    const isBtnActive = Object.keys(filters).some((key) => filters[key].value.length > 0);

    /* Actions */
    const fetchProjects = (statuses, last100) => {
        getProjectsAsync(undefined, undefined, undefined, undefined, statuses, last100);
    };
    const onSearchInputChange = ({ target: { value }}) => {
        setSearchKey(value.replace(/ +(?= )/g, ''));
    };
    const onFilterOptionChange = (param, value, title) => {
        const v = filters[param].value;
        const t = filters[param].title;
        const data = v.includes(value) ? v.filter((i) => i !== value) : [...v, value];

        setIsFiltersBtnActive(!isMobile && !isEmpty(data));
        setFilters({
            ...filters,
            [param]: {
                ...filters[param],
                value: data,
                title: v.includes(value) ? t.filter((i) => i !== title) : [...t, title],
            }
        });
    };
    const toggleFilter = (param, bool) => {
        setFilters({
            ...filters,
            [param]: {
                ...filters[param],
                visibility: bool,
            }
        });
    };
    const toggleFilters = () => {
        // setBodyOverflow(isMobile && !isFilters ? 'hidden' : 'auto');
        setIsFilters(state => !state);
    };
    const applyMobileFilters = () => {
        setIsFilters(false);
    };
    const clearFilters = () => {
        const filtersObj = {};

        keys(filters).forEach((key) => {
            filtersObj[key] = {
                ...filters[key],
                value: [],
                title: [],
            };
        });

        setIsFilters(false);
        setIsFiltersBtnActive(false);
        setFilters({
            ...filters,
            ...filtersObj
        });
    };
    const getFilteredProjects = () => {
        if ( isNil(projects) ) return [];

        return filterProjectsByFilters(projects, filters);
    };

    const showProjectDetails = ({ currentTarget: { dataset: { id }}}) => {
        setOpenedProjects(state => {
            const isOpened = includes(id, state);
            return isOpened ? state.filter(el => el !== id ) : [ ...state, id ];
        });
    };
    const toggleProjectToCart = ({ currentTarget: { dataset: { id }}}) => {
        if ( orderList.some(c => `${c.project_id}` === `${id}`) ) {
            removeProjectFromOrderAsync(id, navigate);
        } else {
            addProjectToOrderAsync(id, 'add_project_to_order_from_cart', navigate);
        }
    };
    const loadMore = () => {
        setProjectsStep(state => {
            const data = state + 1;

            if ( data > 5 ) {
                fetchProjects(undefined, undefined);
            }
            return data;
        });
    };
    const editProject = (e) => {
        e.preventDefault();
        const { currentTarget: { dataset: { id }}} = e;


        if ( page === 'drafts' ) {
            fetchProjectDetailsAsync(id, 'editing', navigate);
        }
    };
    const duplicateProject = (e) => {
        const id = e.currentTarget.id;
        let target = e.target;

        if ( page === 'duplicate' ) {
            const moreEl = document.getElementById(`more-${id}`);
            do {
                if (target === moreEl) {
                    return;
                }
                target = target.parentNode;
            } while (target);

            fetchProjectDetailsAsync(id, 'duplicating', navigate);
        }
    };

    useEffect(() => {
        document.title = getAppTitle(page === 'drafts' ? 'Add drafts to order' : 'Duplicate a project', company);

        return () => {
            setListsState('projects',null);
            setUiState('projectsTotal', 0);
        };
    }, []);
    useEffect(() => {
        fetchProjects(page === 'drafts' ? 'draft' : undefined, page === 'duplicate' ? 'last100' : undefined);
    }, [accountId]);
    useEffect(() => {
        if ( !isEmpty(status.value) || !isEmpty(project_tags.value) ) {
            const length = isNil(projects) ? 0 : projects.length;
            if ( !isMobile && page === 'duplicate' && length < projectsTotal ) {
                fetchProjects(undefined, undefined);
            }
        }
    }, [status.value, project_tags.value]);
    useEffect(() => {
        if ( searchKey.length === 1 ) {
            const length = isNil(projects) ? 0 : projects.length;
            if ( page === 'duplicate' && projectsTotal !== length ) {
                fetchProjects(undefined, undefined);
            }
        }
    }, [searchKey]);

    /* Html */
    const isLoaded = !isNil(projects);
    const generateTeam = (team) => {
        let myTeamHtml, teamList;
        if ( team.length > 1 && team.length < 4 ) {
            myTeamHtml = team.reverse()
                .map((item, i) => <div className = 'gac-my-team-item' key = { i }><div className = 'gac-my-team-item-avatar'/><div className = 'gac-my-team-item-name'>{ `${item.writer_first_name} ${item.writer_last_name}` }</div></div> );
        } else if ( team.length > 3 ) {
            myTeamHtml = team.filter((item, i) => i <= 2)
                .reverse()
                .map((item, i) => <div className = 'gac-my-team-item' key = { i }><div className = 'gac-my-team-item-avatar'/><div className = 'gac-my-team-item-name'>{ `${item.writer_first_name} ${item.writer_last_name}` }</div></div> );

            teamList = team.filter((item, i) => i > 2 && i < 13).map((item, i) => <div key = { i }>{ `${item.writer_first_name} ${item.writer_last_name}` }</div> );
        }

        return (
            team.length === 0
                ? null
                : team.length === 1
                    ? <>
                        <div className = 'gac-writer-avatar'/>
                        <div className = 'gac-writer-name'>
                            { `${ team[0].writer_first_name } ${team[0].writer_last_name}` }
                        </div>
                    </>
                    : <div className = { `gac-my-team ${ team.length < 4 ? 'gac-my-team-less' : '' } ${ team.length === 2 ? 'gac-my-team-2' : '' }` }>
                        { myTeamHtml }
                        { team.length > 3
                            ? <div className = "gac-my-team-rest">
                                { `+${team.length - 3}` }
                                <div data-offset = { `-${40 + 22*(teamList.length - (team.length > 13 ? 0 : 1))}px` } className = "gac-my-team-rest-list"
                                    style={{ top: `-${40 + 22*(teamList.length - (team.length > 13 ? 0 : 1))}px` }}>
                                    { teamList }
                                    { team.length > 13 && <div>{ `+ ${team.length - 13} more` }</div> }
                                </div>
                            </div>
                            : null }
                    </div>
        );
    };
    const getEditBtn = (id) => <div onClick = { editProject } data-id = { id } className = 'gac-btn-rounded'><i><Pencil/></i></div> ;
    const getTimelapseBtn = (id) => <div onClick = { editProject } data-id = { id } className = 'gac-btn-add-info'><span>Add info</span><div className = 'gac-btn-timelapse'><i><Timer/></i></div></div> ;
    const getPlusBtn = (id) => {
        const isActive = orderList.some(c => `${c.project_id}` === `${id}`);
        return <div onClick = { toggleProjectToCart } data-id = { id } className = { `gac-btn-rounded ${ isActive ? 'gac-active' : ''}` }>
            { isActive ? <i><Mark/></i> : <i><Plus/></i> }
        </div> ;
    };
    const getBtns = (o) => {
        const { type, project_id: id, job_type, pay_type } = o;
        return <div className = { `gac-project-item-btn ${type === 'none' ? 'gac-type-none' : '' }` }>
            { includes(job_type, 'writing/design/motion' ) && pay_type === 'subscription'
                ? getEditBtn(id)
                : type === 'none'
                    ? getTimelapseBtn(id)
                    : getPlusBtn(id) }
        </div> ;
    };
    const getTitle = () => {
        return <div className = 'gac-projects-list-title'>{ page === 'duplicate' ? 'Duplicate an existing project' : 'Add draft project to my order' }</div> ;
    };
    const getBackBtn = () => {
        if ( isMobile ) return null;
        return <Link to = '/order' className = 'gac-back-btn'><i/>Back</Link> ;
    };
    const getSearchInput = () => {
        return <div className = { `gac-search ${ isFilters ? 'gac-filters-on' : '' }` }>
            <div className = 'gac-search-input-wrap'>
                <input className = 'gac-search-input' placeholder = 'Search topics' type = 'text' value = { searchKey } data-hj-whitelist onChange = { onSearchInputChange }/>
                <span className = 'gac-search__btn'/>
            </div>
            <span className = { `gac-filters-btn ${ (!isMobile && isBtnActive) || isFiltersBtnActive ? 'gac-filters-btn-active' : ''}` } onClick = { toggleFilters }><i>{ isMobile ? <FilterBig/> : <Filter/> }</i>Filters</span>
        </div> ;
    };
    const getFilters = () => {
        return <motion.div className = 'gac-order-filters' initial = {{ opacity: 0, height: 0 }} animate = {{ opacity: isFilters ? 1 : 0, height: isFilters ? isMobile ? `${window.innerHeight - 186}px` : 'auto' : 0 }} transition = {{ ease: 'easeInOut' }}>
            { page === 'duplicate' && <FilterItem
                isMultiple = { true }
                enableOnClickOutside = { status.visibility }
                disableOnClickOutside = { !status.visibility }
                items = { [ { value: 'auto', title: 'Auto-approved' }, ...statuses ] }
                isOpened = { status.visibility }
                parameter = 'status'
                value = { status.value.length ? status.value : [] }
                title = { status.title.length ? status.title : ['Statuses'] }
                onChange = { onFilterOptionChange }
                onToggle = { toggleFilter }/> }
            <FilterItem
                isMultiple = { true }
                enableOnClickOutside = { project_tags.visibility }
                disableOnClickOutside = { !project_tags.visibility }
                items = { isNil(tags) ? [] : tags }
                isOpened = { project_tags.visibility }
                parameter = 'project_tags'
                value = { project_tags.value.length ? project_tags.value : [] }
                title = { project_tags.title.length ? project_tags.title : ['Tags'] }
                onChange = { onFilterOptionChange }
                onToggle = { toggleFilter }/>
            { isMobile && <div className = 'gac-filters-btns'>
                <span className = 'gac-btn gac-btn-s' onClick = { applyMobileFilters }>Apply</span>
                <span className = 'gac-btn-v2 gac-btn-s' onClick = { clearFilters }>Clear all</span>
            </div> }
        </motion.div> ;
    };
    const getData = () => {
        if ( isNil(projects) ) return null;
        let data = getFilteredProjects();
        if ( projectsStep < 6 && projectsTotal !== projects.length ) {
            data = data.filter((item, i) => i < projectsStep * 20);
        }
        data = data.filter(o => {
            const topic = o.topic ? o.topic.toLowerCase().trim() : '';
            return topic.includes(searchKey.toLowerCase().trim());
        });

        data = data.map((o) => {
            let format = '';
            const { job_type, talent } = o;

            if ( job_type === 'ideas' ) {
                format = `${o.idea_count} topic ideas`;
            } else if ( job_type === 'keywords' ) {
                format = `Keyword research`;
            } else if ( includes(job_type, 'design/motion') ) {
                format = getDesignFormat(formats[job_type], o.format);
            } else {
                format = o.format && getFormat(formats.writing, o.format);
            }
            const amount = getAmount(o.client_amount, o.currency);
            const isOpen = openedProjects.includes(o.project_id);

            const deadlineData = isNil(timezone) ? o.deadline : addUtcOffset(o.deadline, timezone);

            const getDesktop = () => {
                return <>
                    <div className = 'gac-project-item-topic'>
                        <Dotdotdot className = 'gac-project-item-title' clamp = { 1 }>
                            { page === 'drafts' ? <Link onClick = { editProject } data-id = { o.project_id } to = { `/order/${o.project_id}` }>{ o.topic }</Link> : o.topic }
                        </Dotdotdot>
                        <p>
                            { format || (o.word_count !== '0' && o.word_count !== null) ? <i> {`${format} ${ o.word_count ? `${toLocaleString(o.word_count.toString())}w` : '' }`} </i> : null }
                            { amount && <i>{ getAmount(o.client_amount, o.currency) }</i> }
                            { page === 'drafts' ? <i>{ moment(deadlineData).format('MMM D, YYYY') }</i> : null }
                        </p>
                    </div>
                    { o.invite_writers ? <div className = 'gac-project-item-team'> { generateTeam( o.invite_writers ? JSON.parse(o.invite_writers) : [] ) } </div> : null }
                    { page === 'duplicate' && <div className = 'gac-project-item-status'>
                        <span className = 'gac-status'>
                            <i className = { `gac-${ o.status }` }/>
                            { o.status === 'matching' ? 'Queued' : job_type === 'design' && o.status === 'revision' ? 'Designing' : o.status }
                        </span>
                        <span className = 'gac-date'>
                            { moment(deadlineData).format('MMM D, YYYY') }
                        </span>
                    </div> }
                    { page === 'drafts' && getBtns(o) }
                </> ;
            };
            const getMobile = () => {
                return <>
                    <div className = 'gac-project-item-topic'>
                        <Dotdotdot className = 'gac-project-item-title' clamp = { 1 }>
                            { page === 'drafts' ? <Link to = { `/order/${o.project_id}` }>{ o.topic }</Link> : o.topic }
                        </Dotdotdot>
                    </div>
                    <div id = {`more-${o.project_id}`} data-id = { o.project_id } className = { `gac-show-project-details-btn ${ isOpen ? 'gac-is-open' : '' }` } onClick = { showProjectDetails }/>
                    { page === 'drafts' && getBtns(o) }
                    <motion.div
                        className = 'gac-project-details'
                        initial = {{ opacity: 0, height: 0, top: 0 }}
                        animate = {{ opacity: isOpen ? 1 : 0, height: isOpen ? 'auto' : 0, top: isOpen ? -5 : 0 }}
                        transition = {{ ease: 'easeInOut' }}>
                        <div>
                            <div className = 'gac-project-detail-label'>Format</div>
                            { format || (o.word_count !== '0' && o.word_count !== null) ? <span> {`${format} ${ o.word_count ? `${toLocaleString(o.word_count.toString())}w` : '' }`} </span> : null }
                        </div>
                        <div>
                            <div className = 'gac-project-detail-label'>Amount</div>
                            { amount && <span>{ getAmount(o.client_amount, o.currency) }</span> }
                        </div>
                        { page === 'drafts' && <div>
                            <div className = 'gac-project-detail-label'>Writer</div>
                            { o.invite_writers ? <div className = 'gac-project-item-team'>{ generateTeam( o.invite_writers ? JSON.parse(o.invite_writers) : [] ) }</div> : null }
                        </div> }
                        { page === 'duplicate' && <div><div className = 'gac-project-detail-label'>Status</div><span className = 'gac-status'><i className = { `gac-${ o.status }` }/>{ o.status }</span></div> }
                        <div><div className = 'gac-project-detail-label'>{ page === 'duplicate' ? 'Approval' : 'Order' }</div><span>{ moment(deadlineData).format('MMM D, YYYY') }</span></div>
                    </motion.div>
                </> ;
            };

            return <div key = { o.project_id } id = { o.project_id } className = { `gac-project-item ${ isOpen ? 'gac-is-open' : ''} ${page === 'drafts' ? 'gac-project-item-draft' : ''}` } onClick = { duplicateProject }>
                <ProjectType type = { talent === 'bloggers' && job_type === 'writing' ? 'blogging' : job_type } />
                { isMobile ? getMobile() : getDesktop() }
            </div> ;
        });

        return data;
    };
    const getShowMoreBtn = () => {
        if ( isNil(projects) ) return null;
        if ( !getData().length || projectsTotal === projects.length || projectsStep === 6 || page !== 'duplicate' ) return null;

        return <div onClick = { loadMore } className = 'gac-show-more'>Show more</div>;
    };
    const getEmptyState = () => {
        if ( isNil(projects) || !isLoaded || getData().length ) return null;

        return <EmptyState page = { 'draft' } text = { searchKey ? `No topics matched` : (!isMobile && isBtnActive) || isFiltersBtnActive ? `No projects matched` : `No ${page === 'drafts' ? 'draft' : 'current'} projects` }/> ;
    };

    const projectsData = getData();

    return <div className = { `gac-order-projects-wrap ${ page === 'duplicate' ? 'gac-duplicate' : '' } ${ isLoaded && projectsData.length ? '' : !isNil(projects) ? 'gac-order-empty' : '' }` } ref = { pageRef }>
        {/* style = {{ height: isLoaded && data.length ? 'auto' : isLoaded !== null ? window.innerHeight - 266 : 'auto' }} */}
        <div className = 'gac-order-projects-list'>
            <div className = 'gac-projects-list-head'>
                { getTitle() }
                { getBackBtn() }
            </div>
            { getSearchInput() }
            { getFilters() }
            { isLoaded && projectsData.length ? <div className = 'gac-projects-list'>{ projectsData }</div> : null }
            { getShowMoreBtn() }
        </div>
        { getEmptyState() }
    </div> ;
};