// Core
import { call, select } from 'redux-saga/effects';

// Instruments
import { api, authStr, getKeys } from '../../../../helpers/api';

export function* callGaTrackingDebugWorker ({ payload: data}) {
    try {
        const { privateKey, publicKey } = yield select(getKeys);
        const response = yield call(fetch, `${api}/${publicKey}/v1/debug/google/conversion`, {
            method: 'POST',
            headers: {
                'Authorization': `${authStr} ${privateKey}`,
                'Content-Type':  'application/json',
            },
            body: JSON.stringify(data),
        });
        const { error: {message}} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }

    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
    }
}
