// Core
import { call, put, select } from 'redux-saga/effects';

// Instruments
import { api, getKeys, authStr } from '../../../../helpers/api';
import { uiActions } from '../../../ui/actions';
import { projectActions } from '../../actions';
import { isNil } from 'ramda';

export function* callFetchProjectRevisionWorker ({ payload: { project_id, job_type, idea_number } }) {
    try {
        const { privateKey, publicKey, accountId } = yield select(getKeys);
        const url = isNil(idea_number) ? '' : `/${idea_number}`;
        const response = yield call(fetch, `${api}/${publicKey}/v1/projects/${job_type}/${project_id}${url}/revisions/latest?account_uid=${accountId}`, {
            method: 'GET',
            headers: {
                Authorization: `${authStr} ${privateKey}`
            }
        });
        const { response: revision, error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }
        if ( job_type === 'ideas' ) {
            yield put(projectActions.updateIdeaRevision(revision, idea_number, revision.revision_id ));
        } else {
            yield put(projectActions.fetchProjectRevisionSuccess(revision[0]));
        }
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
        yield put(uiActions.setUiState('notification', {
            msg: error.message,
            type: 'error',
        }));
    }
}
