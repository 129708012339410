// Instruments
import { types } from './types';

export const paymentsActions = Object.freeze({
    setPaymentsState: (prop, data) => ({
        type:    types.SET_PAYMENTS_STATE,
        payload: {
            prop,
            data,
        },
    }),
});
