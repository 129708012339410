// Core
import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';
import { array, func, string } from 'prop-types';
import cx from 'classnames';

export default class FilterItem extends Component {
    static propTypes = {
        items:     array.isRequired,
        onChange:  func.isRequired,
        parameter: string,
    };

    state = {
        isOpened: false,
    };

    componentWillUnmount() {
        document.removeEventListener('click', this._onOutsideListClick, true);
        document.removeEventListener('touchstart', this._onOutsideListClick, true);
    }

    _onOutsideListClick = (e) => {
        let target = e.target;

        do {
            if (target === this.item) {
                return;
            }
            target = target.parentNode;
        } while (target);

        this.setState({
            isOpened: false,
        });
    };
    _toggleList = () => {
        const { isOpened } = this.state;
        const { isDisabled } = this.props;
        if ( isDisabled ) return;

        this.setState({
            isOpened: !isOpened,
        }, () => {
            if ( isOpened ) {
                document.removeEventListener('click', this._onOutsideListClick, true);
                document.removeEventListener('touchstart', this._onOutsideListClick, true);
            } else {
                document.addEventListener('click', this._onOutsideListClick, true);
                document.addEventListener('touchstart', this._onOutsideListClick, true);
            }
        });
    };

    /* onChange */
    _onClick = ({ currentTarget: { dataset: { value, title }}}) => {
        const { onChange, parameter, isMultiple } = this.props;

        onChange(parameter, value, title, true);
        if (!isMultiple) {
            this.setState({
                isOpened: false,
            }, () => {
                document.removeEventListener('click', this._onOutsideListClick, true);
                document.removeEventListener('touchstart', this._onOutsideListClick, true);
            });
        }
    };

    render () {
        const { direction, parameter, items, isInvalid, isMultiple, value, title, isDisabled } = this.props;
        const { isOpened } = this.state;
        const data = items.map((item, i) => (
            <li key = { i } style={{ display: item.isHidden ? 'none' : null }}>
                <span
                    className = 'gac-filter-item__menu__link'
                    data-value = { item.value.toLowerCase() }
                    data-title = { item.title }
                    onClick = { item.isDisabled ? () => {} : this._onClick }
                    style = {{ opacity: item.isDisabled ? 0.8 : null, cursor: item.isDisabled ? 'default' : 'pointer' }}>
                    { item.title }{ item.isDisabled ? ' (unavailable)' : null }
                    { isMultiple
                        ? value.some(o => o.toLowerCase() === item.value.toLowerCase())
                            ? <i/>
                            : <span/>
                        : item.value.toLowerCase() === value.toLowerCase()
                            ? <i/>
                            : null }
                </span>
            </li>
        ));
        const styles = cx('gac-filter-item', {
            'gac-clicked': isOpened,
        });

        return (
            <div
                className = { styles }
                ref = {ref => this.item = ref }
                style = {{
                    borderColor: isInvalid ? '#dc5d5b' : '#D9D9D9',
                    backgroundColor: isDisabled ? '#fafafa' : null,
                    cursor: isDisabled ? 'default' : 'pointer',
                }}>
                <div className = 'gac-filter-item__value' onClick = { this._toggleList }>
                    { isMultiple ? title.join(', ') : title }
                </div>
                <CSSTransition
                    in = { isOpened }
                    timeout = { 200 }
                    unmountOnExit
                    classNames = 'gac-opacity-200'>
                    <ul
                        style = {{
                            top: direction ? 'auto' : 55,
                            bottom: direction ? 46 : 'auto',
                        }}
                        className = 'gac-filter-item__menu'>
                        { data.length
                            ? data
                            : parameter === 'project_tags' ? <span className = 'gac-no-options'>No tags created</span> : '' }
                    </ul>
                </CSSTransition>
            </div>
        );
    }
}