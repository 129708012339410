// Core
import { call, put, select } from 'redux-saga/effects';
import Cookies from 'universal-cookie';

// Instruments
import { authActions } from '../../actions';
import { modalsActions } from "../../../modals/actions";
import { orderActions } from "../../../order/actions";
import { projectActionsAsync } from "../../../project/saga/asyncActions";
import { uiActions } from '../../../ui/actions';
import { userActions } from "../../../user/actions";
import {
    api,
    domain,
    domainGAC,
    getLoginAttempt,
    getInitPath,
    getRef,
    getProposal,
    getActiveSub,
    getActiveBloggingSub,
    getActiveDesignSub,
    getPaymentsType,
    getSubs,
    getRates,
    getBundles,
    getActiveBundle,
    getOrderState,
    getActiveWebBuildingSub,
    getActiveAssistantsSub, getInitSearch
} from '../../../../helpers/api';
import {getApp, getCurrencySign, getUrl, isLocal, isProd, isWhiteLabel} from '../../../../helpers/helpers';
import { isEmpty, includes, isNil } from 'ramda';
import { jobTypesArr, promoCodesArr, promoCodesConfig } from "../../../../helpers/constants";

export function* callLoginWorker({ payload: { credentials, navigate } }) {
    try {
        yield put(uiActions.enableFetching());
        const response = yield call(fetch, `${api}/v1/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(credentials),
        });
        const { response: keys, error: { message } } = yield call([response, response.json]);
        let href = window.location.href;

        if (response.status === 401) {
            if (href.includes('password-create')) {
                yield put(uiActions.setUiState('loginEmail', credentials.email));
                navigate('/login');
            }
        }
        if (response.status !== 200) {
            throw new Error(message);
        }
        if (isProd && keys.type === 'client') {
            throw new Error('Incorrect email and/or password');
        }

        const cookies = new Cookies();
        const expires = new Date();
        expires.setSeconds(expires.getSeconds() + (3600 * 24 * 365));
        //ToDo voffka
        const cookieDomain = !isNil(keys.whitelabel_domain) ? keys.whitelabel_domain : domain;

        let options = {
            path: '/',
            expires,
        };
        if (!isLocal) {
            options = {
                ...options,
                //domain: isWhiteLabel() ? `.${window.location.hostname}` : `.${domain}`
                domain: cookieDomain
            };
        }
        cookies.set('_gac_prk', keys._gac_prk, options);
        cookies.set('_gac_pbk', keys._gac_pbk, options);
        cookies.set('_gac_acc', keys._gac_acc, options);
        cookies.set('GACSESSID', keys._gac_sessid, options);

        const url = getUrl();
        const ref = yield select(getRef);
        const proposal = yield select(getProposal);
        const { formProject, formAction } = yield select(getOrderState);

        if (includes(keys.type, 'user/client')) {
            yield put(authActions.loginSuccess({
                privateKey: keys._gac_prk,
                publicKey: keys._gac_pbk,
                accountId: keys._gac_acc,
                sessid: keys._gac_sessid,
            }));
            if (!isEmpty(proposal)) {
                navigate(`/order/${proposal.project_id}`);
                return false;
            } else if (ref) {
                window.location.href = ref;
            }
            const path = yield select(getInitPath);
            const initSearch = yield select(getInitSearch);

            if (path === '/password-update') {
                navigate('/calendar');
                yield put(uiActions.setUiState('notification', {
                    msg: 'Password updated',
                    type: 'success',
                    fn: null,
                }));
                yield put(uiActions.setUiState('initPath', ''));
            //} else if (path === '/company/new' && includes('referral-code', initSearch) && promoCodesArr.some(code => includes(code, initSearch))) {
            } else if (path === '/company/new') {
                if(includes('referral-code', initSearch) && promoCodesArr.some(code => includes(code, initSearch))) {
                    const code = promoCodesArr.filter(el => includes(el, initSearch))[0];
                    yield put(orderActions.updateOrderState(promoCodesConfig[code]));
                    navigate('/order/new');
                    return false;
                }
            } else if (path === '/prepay') {
                // Вынесли отдельно, так как нам не надо удалять значение initPath.
                // Оно нужно если надо будет перейти между доменами в src/bus/user/saga/workers/fetchCompany.js:97
                navigate(path);
            } else if (path && !'/login/password/password-create|/company/new'.includes(path)) {
                if (jobTypesArr.some(el => includes(el, initSearch))) {
                    navigate(`${path}${initSearch}`);
                } else {
                    navigate(path);
                }
                yield put(uiActions.setUiState('initPath', ''));
            }
            yield put(modalsActions.setModal(null));

            /* /order/new create account modal */
            if (!isNil(formProject) && !isNil(formAction)) {
                const { pay_type } = formProject;
                const { job_type, name: action } = formAction;

                if ((job_type === 'blogging' || (includes(job_type, 'writing/design/motion') && pay_type === 'subscription')) && includes(action, 'save_add_to_order')) {
                    yield put(projectActionsAsync.confirmProjectAsync(formProject, formAction, navigate));
                } else if (includes(action, 'save_add_to_order')) {
                    yield put(projectActionsAsync.createProjectAsync(formProject, formAction, navigate));
                }
                yield put(orderActions.updateOrderState({ formProject: null, formAction: null }));
                return false;
            }

            /* /company/new */
            const paymentsType = yield select(getPaymentsType);
            const subscriptions = yield select(getSubs);
            const { _gac_currency } = keys;

            if (paymentsType === 'bundle') {
                const activeBundle = yield select(getActiveBundle);
                if (isEmpty(activeBundle)) {
                    navigate('/bundles');
                    return false;
                } else {
                    if (activeBundle.currency_code !== _gac_currency) {
                        let newActiveBundle;
                        const bundles = yield select(getBundles);
                        if (activeBundle.name === 'Custom') {
                            const amount = Number(activeBundle.amount);
                            let currentBundle = bundles.filter(o => !!o.active)
                                .filter(o => o.currency === getCurrencySign(_gac_currency))
                                .sort((a, b) => b.amount - a.amount)
                                .filter(o => amount >= o.amount);
                            const discount = isEmpty(currentBundle) || isNil(currentBundle) ? 0 : currentBundle[0].discount;
                            const save = amount * (discount / 100);

                            newActiveBundle = {
                                ...activeBundle,
                                currency: getCurrencySign(_gac_currency),
                                currency_code: _gac_currency,
                                discount,
                                save,
                            };
                        } else {
                            newActiveBundle = bundles
                                .filter(o => !!o.active)
                                .filter(o => o.currency === getCurrencySign(_gac_currency))
                                .filter(o => o.name === activeBundle.name)[0];
                        }
                        if (!isNil(newActiveBundle)) {
                            yield put(userActions.setUserState('activeBundle', newActiveBundle));
                        }
                    }
                    navigate('/payment/confirm');
                    return false;
                }
            } else if (paymentsType === 'writing' || paymentsType === 'blogging') {
                const writingSub = yield select(getActiveSub);
                const bloggingSub = yield select(getActiveBloggingSub);
                const sub = paymentsType === 'writing' ? writingSub : bloggingSub;
                let subs = subscriptions.blogging;
                let activeSubType = 'activeBloggingSub';
                if (paymentsType === 'writing') {
                    subs = subscriptions.writing;
                    activeSubType = 'activeSub';
                }

                if (isEmpty(sub)) {
                    navigate(`/${paymentsType === 'writing' ? 'writers' : 'bloggers'}/pricing`);
                    return false;
                } else {
                    if (sub.currency.toLowerCase() !== _gac_currency) {
                        let newSub;
                        if (sub.id === 'custom') {
                            const { discount: discountData } = subs.filter(o =>
                                o.currency.toLowerCase() === _gac_currency
                                && o.billing_interval_code === sub.billing_interval_code
                                && !includes('Custom', o.name))[0];
                            const discount = isNil(discountData) ? 1 : (100 - discountData) / 100;
                            const ratesData = yield select(getRates);
                            const frequency = paymentsType === 'writing' ? 1 : Number(sub.frequency);

                            let rates = [];
                            rates = ratesData['writing']
                                .filter((item) => item.currency === getCurrencySign(_gac_currency))
                                .filter((item) => item.format.includes(('blog')));
                            const clientRate = isEmpty(rates) ? '' : rates.filter((o) => o.level === sub.level)[0].rate_client;
                            const amount = Math.round(clientRate * sub.word_count) * frequency * discount;

                            newSub = {
                                id: 'custom',
                                name: 'Custom',
                                frequency: sub.frequency,
                                word_count: sub.word_count,
                                currency: _gac_currency.toUpperCase(),
                                amount,
                                level_code: 'pro',
                                billing_interval_code: sub.billing_interval_code,
                            };
                        } else {
                            newSub = subs.filter(o =>
                                o.currency.toLowerCase() === _gac_currency
                                && o.billing_interval_code === sub.billing_interval_code
                                && o.name === sub.name
                                && o.level === sub.level)[0];
                        }
                        if (!isNil(newSub)) {
                            yield put(userActions.setUserState(activeSubType, newSub));
                        }
                    }
                    navigate('/payment/confirm');
                    return false;
                }
            } else if (paymentsType === 'design' || paymentsType === 'motion') {
                const designSub = yield select(getActiveDesignSub);

                if (isEmpty(designSub)) {
                    navigate(`/${paymentsType === 'motion' ? 'motion' : 'designers'}/pricing`);
                    return false;
                } else {
                    if (designSub.currency.toLowerCase() !== _gac_currency) {
                        const newDesignSub = subscriptions.design.filter(o =>
                            o.currency.toLowerCase() === _gac_currency
                            && o.billing_interval_code === designSub.billing_interval_code
                            && o.name === designSub.name)[0];
                        if (!isNil(newDesignSub)) {
                            yield put(userActions.setUserState('activeDesignSub', newDesignSub));
                        }
                    }
                    navigate('/payment/confirm');
                    return false;
                }
            } else if (paymentsType === 'hourly-designers') {
                const hourlyDesignSub = yield select(getActiveDesignSub);

                if (isEmpty(hourlyDesignSub)) {
                    navigate(`/hourly-designers/pricing`);

                    return false;
                } else {
                    if (hourlyDesignSub.currency.toLowerCase() !== _gac_currency) {
                        const newDesignSub = subscriptions['design_hourly'].filter(o =>
                            o.currency.toLowerCase() === _gac_currency
                            && o.billing_interval_code === designSub.billing_interval_code
                            && o.name === hourlyDesignSub.name)[0];
                        
                        if (!isNil(newDesignSub)) {
                            yield put(userActions.setUserState('activeDesignSub', newDesignSub));
                        }
                    }
                    navigate('/payment/confirm');

                    return false;
                }
            } else if (paymentsType === 'webbuilding') {
                const webBuildingSub = yield select(getActiveWebBuildingSub);

                if (isEmpty(webBuildingSub)) {
                    navigate(`/website-developers/pricing`);
                    return false;
                } else {
                    if (webBuildingSub.currency.toLowerCase() !== _gac_currency) {
                        let newWebBuildingSub;
                        if (webBuildingSub.id === 'custom') {
                            const { amount: defaultAmount, hours: { middle, senior } } = subscriptions['webbuilding']
                                .filter(o => o.currency.toLowerCase() === _gac_currency)
                                .filter(o => o.billing_interval_code === webBuildingSub.billing_interval_code)
                                .filter(o => !includes('Custom', o.name))
                                .filter(o => o.name === 'Growth accelerator')[0];
                            const qty = webBuildingSub.billing_interval_code === 'annually' ? 12 : webBuildingSub.billing_interval_code === 'quarterly' ? 3 : 1;
                            const total = Number(webBuildingSub.amount) * qty;
                            const middleHours = Math.round(webBuildingSub.amount / (Math.round((defaultAmount / middle) * 100) / 100));
                            const seniorHours = Math.round(webBuildingSub.amount / (Math.round((defaultAmount / senior) * 100) / 100));

                            newWebBuildingSub = {
                                id: 'custom',
                                name: 'Custom',
                                currency: _gac_currency.toUpperCase(),
                                amount: Number(webBuildingSub.amount),
                                total,
                                middleHours,
                                seniorHours,
                                billing_interval_code: webBuildingSub.billing_interval_code,
                            };
                        } else {
                            newWebBuildingSub = subscriptions['webbuilding'].filter(o =>
                                o.currency.toLowerCase() === _gac_currency
                                && o.billing_interval_code === webBuildingSub.billing_interval_code
                                && o.name === webBuildingSub.name)[0];
                        }
                        if (!isNil(newWebBuildingSub)) {
                            yield put(userActions.setUserState('activeWebBuildingSub', newWebBuildingSub));
                        }
                    }
                    navigate('/payment/confirm');
                    return false;
                }
            } else if (paymentsType === 'assistants') {
                const assistantsSub = yield select(getActiveAssistantsSub);

                if (isEmpty(assistantsSub)) {
                    navigate(`/assistants/pricing`);
                    return false;
                } else {
                    if (assistantsSub.currency.toLowerCase() !== _gac_currency) {
                        const newAssistantsSub = subscriptions['assistants'].filter(o =>
                            o.currency.toLowerCase() === _gac_currency
                            && o.billing_interval_code === assistantsSub.billing_interval_code
                            && o.name === assistantsSub.name)[0];
                        if (!isNil(newAssistantsSub)) {
                            yield put(userActions.setUserState('activeAssistantsSub', newAssistantsSub));
                        }
                    }
                    navigate('/payment/confirm');
                    return false;
                }
            }
            if (href.includes('/company/new')) {
                navigate('/calendar');
                return false;
            }
        } else if (includes(keys.type, 'designer')) {
            const url = keys.reviewer ? '/review-projects' : '/current-projects';
            const h = 'https://talent';
            if (includes('app-dev.', href)) {
                window.location.href = `${h}-dev.${domainGAC}.com${url}`;
            } else if (includes('app-qa.', href)) {
                window.location.href = `${h}-qa.${domainGAC}.com${url}`;
            } else if (includes('app-yv.', href)) {
                window.location.href = `${h}-yv.${domainGAC}.com${url}`;
            } else if (includes('app-vi.', href)) {
                window.location.href = `${h}-vi.${domainGAC}.com${url}`;
            } else if (includes('app.', href)) {
                window.location.href = `${h}.${domainGAC}.com${url}`;
            } else if (isLocal) {
                window.location.href = `http://localhost:3001${url}`;
            }
        } else {
            if (ref && ref.includes('https://') && ref.includes(`${domainGAC}.com`) && !ref.includes('app')) {
                window.location.href = ref;
            } else {
                if (keys.type === 'writer') {
                    window.location.href = `https://${url}.${domainGAC}.com/cw/projects/current`;
                } else {
                    window.location.href = `https://${url}.${domainGAC}.com/cw/editing`;
                }
            }
        }
    } catch (error) {
        const attempt = yield select(getLoginAttempt);
        yield put(uiActions.setUiState('isLoginFail', true));
        yield put(uiActions.setUiState('loginError', error.message));
        yield put(uiActions.setUiState('loginAttempt', attempt + 1));
    } finally {
        yield put(uiActions.disableFetching());
    }
}
