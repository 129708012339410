// Instruments
import { types } from './types';
import { types as authTypes } from '../auth/types';
import update from 'immutability-helper';

const initialState = {
    pusher: {},
    channels: {
        userChannel: null,
        projectChannel: null,
        publicChannel: null,
        commentsChannel: null,
    },
};

export const socketReducer = (state = initialState, { payload, type }) => {
    switch (type) {
        case types.INIT_PUSHER_SUCCESS:
            return update(state, {
                pusher: {$set: payload},
            });

        case types.SET_CHANNEL:
            return update(state, {
                channels: {
                    [payload.name]: {$set: payload.channel}
                },
            });

        case authTypes.LOGOUT_SUCCESS:
            return update(state, {$set: {
                pusher: {},
                channels: {},
            }});

        default:
            return state;
    }
};
