// Core
import { useLocation } from 'react-router-dom';

// Components
import { Link } from 'react-router-dom';

// Hooks
import { useUi } from "../../../../hooks/useUi";

export const SubMenu = ({ submenu }) => {
    /* Hooks */
    const { isAuth } = useUi();
    const { pathname } = useLocation();

    const data = submenu.map((item, i) => {
        const isActive = pathname.includes(item.path);
        const classStr = isActive ? 'gac-active' : '';

        return (
            <li key = { i }>
                { !isAuth && item.isPrivate
                    ? <span className = { classStr }>{ item.text }</span>
                    : item.isPath
                        ? <Link
                            className = { classStr }
                            to = { `${ !isAuth && !item.isPrivate ? item.altPath : item.path}` }
                            onClick = { item.path === '/calendar' ? this.props.refreshCalendar : null }>
                            { item.text }
                        </Link>
                        : <a
                            className = { classStr }
                            href = { `${ !isAuth && !item.isPrivate ? item.altPath : item.path}` }>{ item.text }</a> }
            </li>
        );
    });

    return (
        <div className = 'gac-sub-menu-wrap'>
            <ul className = 'gac-sub-menu main-container'>
                { data }
            </ul>
        </div>
    );
};