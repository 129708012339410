// Core
import { call, put, select } from 'redux-saga/effects';

// Instruments
import { api, getKeys, authStr } from '../../../../helpers/api';
import { uiActions } from '../../../ui/actions';
import { projectActions } from '../../../project/actions';

export function* callSetIdeaStatusWorker ({ payload: { project_id, idea_id, value }}) {
    try {
        yield put(uiActions.enableFetching());
        const { privateKey, publicKey, accountId } = yield select(getKeys);
        const response = yield call(fetch, `${api}/${publicKey}/v1/projects/ideas/${project_id}/${idea_id}/${value}`,{
            method: 'POST',
            headers: {
                'Authorization': `${authStr} ${privateKey}`,
                'Content-Type':  'application/json',
            },
            body: JSON.stringify({
                account_uid: accountId,
            }),
        });
        const { error: { message }} = yield call([response, response.json]);

        if ( response.status !== 200 ) {
            throw new Error(message);
        }

        yield put(projectActions.updateIdeaField('star', value === 'star', idea_id));
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
        yield put(uiActions.setUiState('notification', {
            msg: error.message,
            type: 'error',
        }));
    } finally {
        yield put(uiActions.disableFetching());
    }
}
