// Core
import { useState, useEffect, useLayoutEffect } from "react";
import { useLocation } from 'react-router-dom';

// Components
import FilterItem from '../common/FilterItem';
import { EmptyState } from '../common/EmptyState';
import { Project } from './Project';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { motion } from "framer-motion"

// Icons
import Filter from '../icons/Filter';
import FilterBig from '../icons/FilterBig';

// Hooks
import { usePrevious } from "../../hooks/custom/usePrevious";
import { useAuth } from "../../hooks/useAuth";
import { useLists } from "../../hooks/useLists";
import { useProject } from "../../hooks/useProject";
import { useUser } from "../../hooks/useUser";
import { useUi } from "../../hooks/useUi";

// Instruments
import {
    filterProjectsByFilters,
    getAppTitle,
    getDesignFormat,
    getFormat,
    isActiveDesignSub, isActiveWebBuildingSub,
    isClientFn,
    isFetching,
    isSelfClientFn,
    reorder,
    sort,
} from '../../helpers/helpers';
import { keys, isEmpty, isNil } from 'ramda';

const filtersObj = {
    format: {
        visibility: false,
        value:      [],
        title:      [],
    },
    status: {
        visibility: false,
        value:      [],
        title:      [],
    },
    project_tags: {
        visibility: false,
        value:      [],
        title:      [],
    },
};
export const Manage = ({ page }) => {
    /* State */
    const [isFilters, setIsFilters] = useState(false);
    const [isFiltersBtnActive, setIsFiltersBtnActive] = useState(false);
    const [isSorting, setIsSorting] = useState(false);
    const [searchKey, setSearchKey] = useState('');
    const [sorting, setSorting] = useState({ key: 'order_on', asc: true });
    const [filters, setFilters] = useState(filtersObj);
    const { status, project_tags } = filters;

    /* Hooks */
    const { keys: { accountId }} = useAuth();
    const prevAccountId = usePrevious(accountId);
    const { projects, queuedProjects, unreadMessages, staticData: { formats, statuses }, setListsState, getUnreadMessagesAsync, getProjectsAsync, getQueuedProjectsAsync } = useLists();
    const { tags, changeProjectQueueAsync } = useProject();
    const { company, details: { user_role }} = useUser();
    const { subscriptions } = company;
    const { projectsTotal, fetching, isMobile } = useUi();
    const { pathname } = useLocation();

    const isBtnActive = Object.keys(filters).some((key) => filters[key].value.length > 0);

    /* Actions */
    const getTitle = () => {
        const title = pathname.substring(1).replace('-', ' ');

        return title.charAt(0).toUpperCase() + title.slice(1);
    };
    const fetchProjects = () => {
        const statuses = page === 'completed' ? 'approved,published,cancelled' : 'draft,matching,pitching,writing,revision,editing,approval';
        const last100 = page === 'completed' ? true : undefined;
        getProjectsAsync(undefined, undefined, undefined, undefined, statuses, last100);
    };
    const toggleSortingMode = () => {
        if ( isSorting ) {
            setListsState('queuedProjects',null);
        } else {
            getQueuedProjectsAsync();
        }
        setIsSorting(state => !state);
    };
    const getFilteredProjects = () => {
        if ( isNil(projects) ) return [];

        if ( projects.every(o => isNil(o.date) && isNil(o.data) ) ) {
            return filterProjectsByFilters(projects, filters);
        } else {
            return [];
        }
    };

    /* Filters */
    const toggleFilters = () => {
        // setBodyOverflow(isMobile && !isFilters ? 'hidden' : 'auto');
        setIsFilters(state => !state);
    };
    const toggleFilter = (param, bool) => {
        setFilters({
            ...filters,
            [param]: {
                ...filters[param],
                visibility: bool,
            }
        });
    };
    const applyMobileFilters = () => {
        // const { history, projectsTotal, location: { pathname }, isFetching } = this.props;
        // const length = this.props.projects.length;
        //
        // const isFiltersBtnActive = Object.keys(filters).some((key) => this.state.filters[key].value.length > 0);
        //
        // this.setState({
        //     isFilters: false,
        //     isFiltersBtnActive,
        // }, () => {
        //     if ( !isFetching && page === 'completed' && length < projectsTotal ) {
        //         this._fetchProjects('approved,published,cancelled', undefined);
        //     }
        //     this._getFilteredProjects(() => {
        //         history.push(getPathFromState(this))
        //     });
        // });

        setIsFilters(false);
    };
    const clearFilters = () => {
        const filtersObj = {};

        keys(filters).forEach((key) => {
            filtersObj[key] = {
                ...filters[key],
                value: [],
                title: [],
            };
        });

        setIsFilters(false);
        setIsFiltersBtnActive(false);
        setFilters({
            ...filters,
            ...filtersObj
        });

        // this._getFilteredProjects(() => {
        //     history.push(getPathFromState(this))
        // });
    };
    const onFilterOptionChange = (param, value, title) => {
        const v = filters[param].value;
        const t = filters[param].title;
        const data = v.includes(value) ? v.filter((i) => i !== value) : [...v, value];

        setIsFiltersBtnActive(!isMobile && !isEmpty(data));
        setFilters({
            ...filters,
            [param]: {
                ...filters[param],
                value: data,
                title: v.includes(value) ? t.filter((i) => i !== title) : [...t, title],
            }
        });
    };
    const onSearchInputChange = ({ target: { value }}) => {
        setSearchKey(value.replace(/ +(?= )/g, ''));
    };
    const onSearchIconClick = () => {
        if ( !isFetching(fetching) ) {
            fetchProjects();
        }
    };
    const onSortingChange = ({ currentTarget: { dataset: { field }}}) => {
        const { key, asc } = sorting;

        setSorting({
            key: field,
            asc: field !== key ? true : !asc,
        });
    };
    const onDragEnd = (result) => {
        if(!result.destination || result.destination.index === result.source.index) {
            return;
        }
        const items = reorder(
            queuedProjects,
            result.source.index - 1,
            result.destination.index - 1
        );
        const data = items.map((o, i) => ({ ...o, due: i+1 }));
        const updated = projects.map(o => {
            const isQueued = data.some(a => `${a.project_id}` === `${o.id}`);
            return isQueued ? { ...o, due: data.filter(b => `${b.project_id}` === `${o.id}`)[0].due } : o;
        });

        setListsState('projects',updated);
        setListsState('queuedProjects',data);
        changeProjectQueueAsync(result.draggableId, Number(result.destination.index));
    };

    useLayoutEffect(() => {
        document.title = getAppTitle(getTitle(), company);

        setIsFilters(false);
        setIsFiltersBtnActive(false);
        setIsSorting(false);
        setSearchKey('');
        setSorting({ key: page === 'draft' ? 'order_on' : page === 'current' ? 'status' : 'deadline', asc: true });

        if ( !isNil(user_role) && !isClientFn(user_role) ) {
            getUnreadMessagesAsync();
        }
        setListsState('queuedProjects',null);
        fetchProjects();

        return () => {
            setListsState('projects',null);
        };
    }, [page]);
    useEffect(() => {
        if ( !isNil(prevAccountId) && prevAccountId !== accountId ) {
            fetchProjects();
            getUnreadMessagesAsync();
        }
    }, [accountId]);
    useEffect(() => {
        if ( !isEmpty(searchKey) ) {
            const length = isNil(projects) ? 0 : projects.length;
            if ( !isFetching(fetching) && page === 'completed' && length < projectsTotal ) {
                fetchProjects();
            }
        }
    }, [searchKey]);
    useEffect(() => {
        if ( !isEmpty(status.value) || !isEmpty(project_tags.value) ) {
            const length = isNil(projects) ? 0 : projects.length;
            if ( !isFetching(fetching) && page === 'completed' && length < projectsTotal ) {
                fetchProjects();
            }
        }
    }, [status.value, project_tags.value]);
    useEffect(() => {
        if ( sorting.key !== 'order_on' || !sorting.asc ) {
            const length = isNil(projects) ? 0 : projects.length;
            if ( !isFetching(fetching) && page === 'completed' && length < projectsTotal ) {
                fetchProjects();
            }
        }
    }, [sorting.asc, sorting.key]);
    useEffect(() => {
        const onScroll = () => {
            const length = isNil(projects) ? 0 : projects.length;

            if ( !isFetching(fetching) && page === 'completed' && length < projectsTotal ) {
                const scroll = (window.scrollY + window.innerHeight) / document.documentElement.offsetHeight;
                if ( scroll >= 0.7) {
                    getProjectsAsync(undefined, undefined, undefined, undefined, 'approved,published,cancelled', undefined);
                }
            }
        };

        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, [page, fetching, projects, projectsTotal]);

    /* Html */
    const getToggleSortingBtn = () => {
        return <i className='gac-disable-sorting-mode' onClick = { toggleSortingMode }>
            { isMobile
                ? <svg width="19" height="13" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="14.81" height="2.468" rx="1" transform="scale(1 -1) rotate(45 19.956 3.794)" fill="#fff"/><rect width="11.107" height="2.468" rx="1" transform="scale(-1 1) rotate(-45 8.449 18.028)" fill="#fff"/></svg>
                : <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="20" height="20" rx="2" fill="#57C79E"/><rect width="10.627" height="1.771" rx=".886" transform="scale(1 -1) rotate(45 23.252 3.783)" fill="#fff"/><rect width="7.97" height="1.771" rx=".886" transform="scale(-1 1) rotate(-45 11.628 20.002)" fill="#fff"/></svg> }
        </i>
    };
    const getSearchInput = () => {
        return(
            <div className = { `gac-manage-search ${ isFilters ? 'gac-filters-on' : '' }` }>
                <div className = 'gac-search-input-wrap'>
                    <input
                        className = 'gac-search-input'
                        placeholder = 'Search'
                        type = 'text'
                        value = { searchKey }
                        data-hj-whitelist
                        onChange = { onSearchInputChange }/>
                    <span className = 'gac-search__btn' onClick = { onSearchIconClick }/>
                </div>
                { isSorting && isMobile
                    ? getToggleSortingBtn()
                    : <span
                        className = { `gac-filters-btn ${ (!isMobile && isBtnActive) || isFiltersBtnActive ? 'gac-filters-btn-active' : '' }` }
                        onClick = { toggleFilters }><i>{ isMobile ? <FilterBig/> : <Filter/> }</i>Filters</span> }
            </div>
        );
    };
    const getFilters = () => {
        return <motion.div
            initial = {{ opacity: 0, height: 0 }}
            animate = {{ opacity: isFilters ? 1 : 0, height: isFilters ? isMobile ? `${window.innerHeight - 136}px` : 'auto' : 0 }}
            transition = {{ ease: 'easeInOut' }}
            className = 'gac-manage-filters'>
            {/*<FilterItem*/}
            {/*    isMultiple = { true }*/}
            {/*    enableOnClickOutside = { filters.format.visibility }*/}
            {/*    disableOnClickOutside = { !filters.format.visibility }*/}
            {/*    items = { project.format }*/}
            {/*    isOpened = { filters.format.visibility }*/}
            {/*    parameter = 'format'*/}
            {/*    value = { filters.format.value.length ? filters.format.value : [] }*/}
            {/*    title = { filters.format.title.length ? filters.format.title : ['Formats'] }*/}
            {/*    onChange = { this._onFilterOptionChange }*/}
            {/*    onToggle = { this._toggleFilter }/>*/}
            { page !== 'draft' && (
                <FilterItem
                    isMultiple = { true }
                    enableOnClickOutside = { status.visibility }
                    disableOnClickOutside = { !status.visibility }
                    items = {
                        page === 'current'
                            ? statuses.filter(({value}) => 'matching,pitching,writing,revision,editing,approval'.includes(value))
                            : [ { value: 'auto', title: 'Auto-approved' }, ...statuses.filter(({value}) => 'auto,approved,published,cancelled'.includes(value)) ]
                    }
                    isOpened = { status.visibility }
                    parameter = 'status'
                    value = { status.value.length ? status.value : [] }
                    title = { status.title.length ? status.title : ['Statuses'] }
                    onChange = { onFilterOptionChange }
                    onToggle = { toggleFilter }/>
            ) }
            { isClientFn(user_role)
                ? null
                : <FilterItem
                    isMultiple = { true }
                    enableOnClickOutside = { project_tags.visibility }
                    disableOnClickOutside = { !project_tags.visibility }
                    items = { isNil(tags) ? [] : tags }
                    isOpened = { project_tags.visibility }
                    parameter = 'project_tags'
                    value = { project_tags.value.length ? project_tags.value : [] }
                    title = { project_tags.title.length ? project_tags.title : ['Tags'] }
                    onChange = { onFilterOptionChange }
                    onToggle = { toggleFilter }/> }
            { isMobile && (
                <div className = 'gac-filters-btns'>
                    <span className = 'gac-btn gac-btn-s' onClick = { applyMobileFilters }>Apply</span>
                    <span className = 'gac-btn-v2 gac-btn-s' onClick = { clearFilters }>Clear all</span>
                </div>
            )}
        </motion.div> ;
    };
    const getHeadHtml = () => {
        if ( isMobile ) return null;
        const { key, asc } = sorting;

        /* Field template */
        const getField = (field, fieldClass, classStr, title) => {
            return <span data-field = { field } className = {`gac-manage-content__head-${fieldClass} ${classStr}`} onClick = { onSortingChange }>
                <div className = 'gac-manage-content__head-title'>{ title }</div>
                <i className = { `gac-manage-content__head-icon ${ key === field ? 'gac-sorting-active' : '' } ${ key === field && asc ? 'gac-sorting-asc' : '' }` }/>
            </span>;
        };
        /* Html */
        const getDraftHead = () => {
            if ( page !== 'draft' ) return null;

            return <>
                { getField('format', 'format', '', 'Format') }
                { !isSelfClientFn(user_role) && getField('client_amount', 'amount', '', 'Amount') }
                { getField('writer_name', 'writer', '', 'Assignee') }
                { getField('order_on', 'order', '', 'Order') }
            </>;
        };
        const getCurrentHead = () => {
            if ( page !== 'current' ) return null;

            return <>
                { getField('status', 'status', '', 'Status') }
                { getField('writer_name', 'writer', '', 'Assignee') }
                { getField('deadline', 'due', '', 'Due') }
            </>;
        };
        const getCompletedHead = () => {
            if ( page !== 'completed' ) return null;

            return <>
                { getField('status', 'status', 'gac-completed-page', 'Status') }
                { getField('writer_name', 'writer', '', 'Assignee') }
                { getField('rating', 'rating', '', 'Rated') }
                { getField('deadline', 'approval', '', 'Approval') }
            </>;
        };

        return(
            <div className = 'gac-manage-content__head'>
                { getField('id', 'id', '', 'ID') }
                { getField('topic', 'topic', '', 'Topic') }
                { getDraftHead() }
                { getCurrentHead() }
                { getCompletedHead() }
                { isSorting && getToggleSortingBtn() }
            </div>
        );
    };
    const getProjects = () => {
        let projectsData = [];

        if ( isSorting ) {
            if ( !isNil(queuedProjects) ) {
                projectsData = queuedProjects
                    .filter(o => !isNil(o.project_id))
                    .sort((a, b) => a.due - b.due);
            }
        } else {
            let projects = getFilteredProjects().filter((item) =>
                (page === 'draft' && item.status === 'draft')
                || (page === 'current' && item.status !== 'draft')
                || (page === 'completed'))
                .filter((item) => {
                    const fields = ['topic', 'writer_name', 'status', 'id', 'rating', 'client_amount'];
                    return fields.some(el => {
                        const field = item[el]
                            ? el === 'status' && item[el] === 'matching'
                                ? 'queued'
                                : item[el].toLowerCase().trim()
                            : '';

                        return field.includes(searchKey.toLowerCase().trim());
                    });
                });

            if ( page === 'completed' ) {
                projectsData = projects
                    .sort((a, b) => sort(sorting.key, sorting.asc, page, a, b));
            } else {
                const designProjects = projects
                    .filter(o => o.job_type === 'design')
                    .sort((a, b) => sort(sorting.key, sorting.asc, page, a, b));
                const noMatchingProjects = designProjects.filter(o => o.status !== 'matching');
                const matchingProjects = designProjects
                    .filter(o => o.status === 'matching')
                    .sort((a, b) => a.due - b.due);
                const projectsSortedByDue = designProjects
                    .filter(o => o.status === 'matching')
                    .sort((a, b) => sort('due', sorting.asc, page, a, b));

                const allDesignProjects =
                    sorting.key === 'deadline'
                        ? [ ...projectsSortedByDue, ...noMatchingProjects ]
                        : sorting.key === 'status'
                            ? sorting.asc
                                ? [...noMatchingProjects, ...matchingProjects]
                                : [...matchingProjects, ...noMatchingProjects]
                            : designProjects;
                const otherProjects = projects
                    .filter(o => o.job_type !== 'design')
                    .sort((a, b) => sort(sorting.key, sorting.asc, page, a, b));

                projectsData = [...allDesignProjects, ...otherProjects];
            }
        }

        projectsData = projectsData
            .map((item) => {
                const count = isNil(unreadMessages) ? [] : unreadMessages.filter((c) => c.project_id === item.project_id);
                const formatTitle = item.job_type === 'design'
                    ? getDesignFormat(formats['design'], item.format)
                    : item.job_type === 'motion'
                        ? getDesignFormat(formats['motion'], item.format)
                        : item.format
                            ? getFormat(formats['writing'], item.format)
                            : ''
                return (
                    <Project
                        key = { item.project_id }
                        format = { formatTitle }
                        page = { page }
                        project = { item }
                        // feedbackText = { item.project_id === `${ project.project_details.project_id }` ? project.project_details.feedback : '' }
                        // fetchFeedback = { this._fetchProjectDetails }
                        messagesCount = { count.length ? count[0].message_count : null }
                        toggleSorting = { toggleSortingMode }
                        isSorting = { isSorting && !isNil(queuedProjects) }
                        isActiveDesignSub = { isActiveDesignSub(accountId, subscriptions) }
                        isActiveWebBuildingSub = { isActiveWebBuildingSub(accountId, subscriptions) } />
                );
            });

        return projectsData;
    };

    const projectsData = getProjects();

    return <div
        className = { `gac-manage main-container ${ isMobile ? 'gac-mobile-view' : '' } ${ !isNil(projects) && projectsData.length ? '' : !isNil(projects) ? 'gac-manage-empty' : '' }` }
        style = {{ height: !isNil(projects) && projectsData.length ? 'auto' : !isNil(projects) ? window.innerHeight : 'auto' }}>
        { getSearchInput() }
        { getFilters() }
        { !isNil(projects) && projectsData.length
            ? <div className = { `gac-manage-content gac-page-${page}` }>
                { getHeadHtml() }
                { isSorting
                    ? <DragDropContext onDragEnd = { onDragEnd }>
                        <Droppable droppableId="droppable">
                            { (provided) => (
                                <div
                                    className='gac-manage-droppable'
                                    ref = { provided.innerRef }
                                    {...provided.droppableProps}>
                                    { projectsData }
                                    { provided.placeholder }
                                </div>
                            ) }
                        </Droppable>
                    </DragDropContext>
                    : projectsData }
            </div>
            : !isNil(projects) && !isSorting && <EmptyState
            page = { page }
            text = {
                searchKey
                    ? `No projects matched`
                    : (!isMobile && isBtnActive) || isFiltersBtnActive
                        ? `No projects matched`
                        : `No ${page} projects`
            }/> }
    </div> ;
};

/* { isMobile ? <HeaderMobile page = { this._getTitle(pathname) }/> : <HeaderDesktop/> } */