// Core
import { call, put } from 'redux-saga/effects';

// Instruments
import { api } from '../../../../helpers/api';
import { uiActions } from "../../../ui/actions";

export function* callCreateDesignerWorker ({ payload: { data, navigate } }) {
    try {
        yield put(uiActions.enableFetching());
        const response = yield call(fetch, `${api}/v1/designers/create-design-application`,{
            method: 'POST',
            headers: {
                'Content-Type':  'application/json',
            },
            body: JSON.stringify(data),
        });
        const { error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }
        const url = data.lang === 'ru' ? 'ru/' : '';

        navigate(`/apply/designers/${url}success`);
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
        yield put(uiActions.setUiState('notification', {
            msg: error.message,
            type: 'error',
            fn: null,
        }));
    } finally {
        yield put(uiActions.disableFetching());
    }
}
