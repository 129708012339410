// Core
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { paymentsActions } from "../bus/payments/actions";
import { paymentsActionsAsync } from "../bus/payments/saga/asyncActions";

export const usePayments = () => {
    const selector = (state) => state.payments;
    const payments = useSelector(selector);
    const dispatch = useDispatch();

    /* Actions */
    const setPaymentsState = (prop, data) => {
        dispatch(paymentsActions.setPaymentsState(prop, data));
    };

    /* Async actions */
    const fetchBraintreeTokenAsync = (type, action, account_uid) => {
        dispatch(paymentsActionsAsync.fetchBraintreeTokenAsync(type, action, account_uid));
    };
    const retryChargeAsync = (platform, subscription_uid, account_uid, navigate) => {
        dispatch(paymentsActionsAsync.retryChargeAsync(platform, subscription_uid, account_uid, navigate));
    };
    const fetchStripeCardsAsync = (accountUid, type, subscriptionId, talent, currency) => {
        dispatch(paymentsActionsAsync.fetchStripeCardsAsync(accountUid, type, subscriptionId, talent, currency));
    };
    const deleteStripeCardAsync = (card_id, type) => {
        dispatch(paymentsActionsAsync.deleteStripeCardAsync(card_id, type));
    };
    const stripeCompleteAsync = (data, navigate) => {
        dispatch(paymentsActionsAsync.stripeCompleteAsync(data, navigate));
    };
    const checkRefundDesignAsync = (fileUrl) => {
        dispatch(paymentsActionsAsync.checkRefundDesignAsync(fileUrl));
    };
    const putRefundDesignAsync = () => {
        dispatch(paymentsActionsAsync.putRefundDesignAsync());
    };

    return {
        ...payments,
        setPaymentsState,
        fetchBraintreeTokenAsync,
        retryChargeAsync,
        fetchStripeCardsAsync,
        deleteStripeCardAsync,
        stripeCompleteAsync,
        checkRefundDesignAsync,
        putRefundDesignAsync
    };
};
