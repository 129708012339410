// Core
import { call, put, select } from 'redux-saga/effects';

// Instruments
import { api, authStr, isStage, getKeys, getCurrencies, getCompanyBranding, getPusherData, getUserDetails } from '../../../../helpers/api';
import { purchaseTrack, getAmountByCurrency } from '../../../../helpers/helpers';
import { uiActions } from '../../../ui/actions';
import { uiActionsAsync } from "../../../ui/saga/asyncActions";
import { userActionsAsync } from '../../../user/saga/asyncActions';
import { userActions } from '../../../user/actions';
import { paymentsActions } from '../../../payments/actions';
import { listsActions } from "../../../lists/actions";
import { listsActionsAsync } from "../../../lists/saga/asyncActions";
import { notifications } from '../../../../helpers/notifications';

export function* callOrderPayWorker ({ payload: { data: obj, navigate } }) {
    try {
        yield put(uiActions.enableFetching());
        const { privateKey, publicKey, accountId: account_uid } = yield select(getKeys);
        const { sessionID } = yield select(getPusherData);


        const response = yield call(fetch, `${api}/${publicKey}/v1/projects/pay`,{
            method: 'PUT',
            headers: {
                'Authorization': `${authStr} ${privateKey}`,
                'Content-Type':  'application/json',
            },
            body: JSON.stringify({
                account_uid,
                pusherData: {
                    sessionID
                },
                ...obj,

            }),
        });
        const { response : data, error: { message }} = yield call([response, response.json]);

        if ( response.status === 410 ) {
            yield put( listsActionsAsync.getOrderListAsync(navigate) );
            yield put( userActionsAsync.fetchBalanceAsync(account_uid) );
        }
        if (response.status !== 200) {
            throw new Error(message);
        }

        if ( obj.payment_method === 'stripe' ) {
            yield put(paymentsActions.setPaymentsState('stripe', data.stripe));
        } else {
            if ( obj.payment_method === 'braintree' ) {
                yield put(paymentsActions.setPaymentsState(obj.payment_method,{}));
            }

            navigate('/current-projects');
            yield put( listsActions.setListsState('orderList', []) );
            yield put(userActions.updateOrderCount(account_uid,'0'));
            yield put(uiActions.setUiState('notification', notifications.orderSuccess));
        }

        if ( obj.payment_method !== 'stripe' && !isStage ) {
            const currencies = yield select(getCurrencies);
            const { host } = yield select(getCompanyBranding);
            const { email } = yield select(getUserDetails);
            let { added_funds: { currency_code, amount, payment_method }, projects: { paid } } = data;

            if ( amount > 0 ) {
                const { amount_usd, rate } = getAmountByCurrency(amount, currency_code, currencies);
                purchaseTrack(amount_usd, host, email);
                yield put(uiActionsAsync.gaTrackingDebugAsync({
                    amount: `${amount}`,
                    currency: currency_code,
                    email,
                    items: `projects: ${paid}`,
                    notes: `host: ${host}, amount_usd: ${amount_usd}, rate: ${rate}, payment_method: ${payment_method}, account_uid: ${account_uid}, gtag: ${window.gtag}, url: ${window.location.href}, params: ${window.location.search}`
                }));
            }
        }
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
        yield put(uiActions.setUiState('notification', {
            msg: error.message,
            type: 'error',
            fn: null,
        }));
        if ( obj.payment_method === 'braintree' ) {
            yield put(uiActions.setUiState('isBraintreePaymentFailed', true));
            yield put(paymentsActions.setPaymentsState('braintree', {}));
        }
        if ( obj.payment_method === 'stripe' ) {
            yield put(uiActions.setUiState('isBlocking', false));
        }
    } finally {
        yield put(uiActions.disableFetching());
        if ( obj.payment_method !== 'stripe' ) {
            yield put(uiActions.setUiState('isBlocking', false));
        }
    }
}
