// Core
import { call, put, select } from 'redux-saga/effects';

// Instruments
import { api, getKeys, getWhiteLabel, authStr } from '../../../../helpers/api';
import { changeFavicon, isWhiteLabel } from '../../../../helpers/helpers';
import { uiActions } from '../../../ui/actions';
import { notifications } from '../../../../helpers/notifications';
import { isNil } from 'ramda';

export function* callUpdateCompanyWorker ({ payload: { company: data, action } }) {
    try {
        yield put(uiActions.enableFetching());
        const isBilling = action.name === 'update_company_billing';
        const { privateKey, publicKey } = yield select(getKeys);
        const response = yield call(fetch, `${api}/${publicKey}/v1/company/${isBilling ? 'billing' : 'branding'}`, {
            method: 'PUT',
            headers: {
                'Authorization': `${authStr} ${privateKey}`,
                'Content-Type':  'application/json',
            },
            body: JSON.stringify(data),
        });
        const { response: company, error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }

        if ( isBilling ) {
            yield put(uiActions.setUiState('notification', notifications.changesSaved));
        } else {
            const isWL = yield select(getWhiteLabel);
            if ( !isNil(company.favicon) && company.favicon.url && isWhiteLabel() ) {
                changeFavicon(company.favicon.url);
            }
            if ( company.white_label ) {
                if ( !isWL ) {
                    yield put(uiActions.setUiState('notification', {
                        msg: 'Request sent. Expect a response in 1 business day.',
                        type: 'success',
                        fn: null,
                    }));
                } else {
                    yield put(uiActions.setUiState('notification', notifications.changesSaved));
                }
            }
            yield put(uiActions.setUiState('isWhiteLabel', company.white_label));
            if ( !isNil(company.brand_color) ) {
                window.location.reload();
            }
        }
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
        yield put(uiActions.setUiState('notification', {
            msg: error.message,
            type: 'error',
            fn: null,
        }));
    } finally {
        yield put(uiActions.disableFetching());
    }
}
