// Core
import { useState } from "react";

export const useInvalidFields = () => {
    /* State */
    const [invalidFields, setInvalidFields] = useState([]);

    const addField = (prop) => {
        setInvalidFields(state => [ ...state, prop ]);
    };
    const removeField = (prop) => {
        setInvalidFields( state => state.filter(item => item !== prop) );
    };

    return {
        invalidFields,
        addField,
        removeField,
        setInvalidFields
    };
};