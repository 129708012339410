// Core
import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';
import { array, string, oneOfType } from 'prop-types';
import { isNil } from "ramda";

export default class Hint extends Component {

    componentWillUnmount() {
        document.removeEventListener('click', this._onOutsideListClick, true);
    }

    static propTypes = {
        text: oneOfType([
            string.isRequired,
            array.isRequired,
        ]),
        title: string,
        position: string,
        type: string,
    };

    state = {
        isOpened: false,
    };

    _toggleList = (e) => {
        e.stopPropagation();
        const { position = 'top' } = this.props;
        const { isOpened } = this.state;

        this.setState({
            isOpened: !isOpened,
        }, () => {
            if ( isOpened ) {
                document.removeEventListener('click', this._onOutsideListClick, true);
            } else {
                if ( position === 'bottom' ) {
                    this.hintText.style.top = `26px`;
                } else {
                    this.hintText.style.top = `-${ this.hintText.offsetHeight + 10 }px`;
                }
                document.addEventListener('click', this._onOutsideListClick, true);
            }
        });
    };

    _onOutsideListClick = (e) => {
        let target = e.target;

        do {
            if (target === this.hint) {
                return;
            }
            target = target.parentNode;
        } while (target);

        this.setState({
            isOpened: false,
        });
    };

    render () {
        const { isOpened } = this.state;
        const { text, title, type, children, position = 'top', isDisabled } = this.props;
        const data = Array.isArray(text) && text.length ? text.map((item,i) => <div key = {i}>{ item }</div>) : text ;

        return (
            <div className = { `gac-hint ${ type ? `gac-hint-${type}` : ''}` } onClick = { this._toggleList } ref = {hint => this.hint = hint }>
                { title && <span>{ title }</span> }
                <div className = 'gac-hint-wrap'>
                    <i style={ !isNil(isDisabled) && isDisabled ? { opacity: 0.5 } : {} } />
                    <CSSTransition
                        in = { isOpened }
                        timeout = { 200 }
                        unmountOnExit
                        classNames = 'gac-opacity-200'>
                        <div className = { `gac-hint-text gac-${position}` } ref = { ref => this.hintText = ref }>
                            { data.length
                                ? data
                                : children }
                        </div>
                    </CSSTransition>
                </div>
            </div>
        );
    }
}
