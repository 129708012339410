// Core
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';

// Components
import { DayPicker } from 'react-day-picker';
import FilterItem from '../../common/FilterItem';
import Select from '../../common/Select';

// Hooks
import { useAuth } from "../../../hooks/useAuth";
import { useLists } from "../../../hooks/useLists";
import { useModals } from "../../../hooks/useModals";
import { useUser } from "../../../hooks/useUser";
import { useUi } from "../../../hooks/useUi";

// Instruments
import moment from 'moment/moment';
import { clearBrief, isSelfClientFn, getDesignFormat, isActiveDesignSub, isEpiic, getLastSubByTalent, onOutsideElClick } from '../../../helpers/helpers';
import { priorities } from "../../../helpers/constants";
import { includes, isEmpty, isNil } from 'ramda';

export const ModalCreateProject = ({ closeModal, setPos }) => {
    /* Ref */
    const modalRef = useRef(null);
    const pickerRef = useRef(null);

    /* State */
    const [lists, setLists] = useState({ format: false, job_type: false, priority: false });
    const [job_type, setJobType] = useState({ value: '', title: '' });
    const [format, setFormat] = useState({ value: '', title: '' });
    const [word_count, setWordCount] = useState(0);
    const [brief, setBrief] = useState('');
    const [topic, setTopic] = useState('');
    const [order_on, setOrderOn] = useState({});
    const [priority, setPriority] = useState({ value: 'bottom', title: 'Bottom of the queue' });
    const [isTopicInvalid, setIsTopicInvalid] = useState(false);
    const [isOrderOnInvalid, setIsOrderOnInvalid] = useState(false);
    const [isTypeInvalid, setIsTypeInvalid] = useState(false);
    const [isDayPicker, setIsDayPicker] = useState(false);

    /* Hooks */
    const { keys: { accountId }} = useAuth();
    const { staticData: { formats }} = useLists();
    const { newProjectOrderOn, createdProjectId, setModalState, createProjectAsync } = useModals();
    const { details: { user_role }, balance: { words_balance, type }, company: { subscriptions, branding: { host }}} = useUser();
    const { brief_links_shared, isMobile, setUiState, checkBriefAsync } = useUi();
    const navigate = useNavigate();

    /* Actions */
    const onOrderOnInputClick = () => {
        setIsDayPicker(state => !state);
    };
    const onInputChange = (e) => {
        const value = e.target.value.replace(/ +(?= )/g, '');

        setTopic(value);
        setIsTopicInvalid(value.length < 1);
    };
    const onTextareaBlur = () => {
        if (!isEmpty(clearBrief(brief)) && brief_links_shared === 'yes') {
            checkBriefAsync(brief);
        }
    };
    const onTextareaChange = (e) => {
        setBrief(e.target.value.replace(/ +(?= )/g, ''));
        if ( brief_links_shared === 'no' ) {
            setUiState('brief_links_shared', 'yes');
        }
    };
    const onCountChange = ({ currentTarget: { value }}) => {
        const num = Number(value.replace(/[^0-9]/gi, ''));
        const data = job_type.value === 'aih' && num > 1000 ? 1000 : num > 99999 ? 99999 : num;

        setWordCount(data);
    };
    const onCountBlur = ({ currentTarget: { value }}) => {
        const num = Number(value.replace(/[^0-9]/gi, ''));
        const word_count =
            num < 10
                ? job_type.value === 'ideas'
                    ? 4
                    : 10
                : num;

        setWordCount(word_count);
    };
    const onListOptionChange = (prop, value, title) => {
        if ( prop === 'job_type' ) {
            if ( value === 'proofreading' ) {
                setWordCount(0);
            } else if ( value === 'ideas' ) {
                setWordCount(0);
            } else if ( value === 'keywords' ) {
                setFormat({ value: '', title: '' });
                setWordCount(0);
            } else if ( value === 'design' ) {

            } else if ( value === 'aih' ) {
                setWordCount(word_count > 1000 ? 1000 : word_count);
                if ( format.value === 'paper' ) setFormat({ value: '', title: '' });
            }

            if ( isTypeInvalid ) {
                setIsTypeInvalid(false);
            }
            setJobType({ value, title });
        } else if ( prop === 'priority' ) {
            setPriority({ value, title });
        } else if ( prop === 'format' ) {
            setFormat({ value, title });
        }
    };
    const onSelectChange = (prop, value) => {
        const title = getDesignFormat(formats['design'], value);

        setFormat({ value, title });
    };
    const toggleFilter = (parameter, bool) => {
        setLists({
            ...lists,
            [parameter]: bool,
        });
    };
    const orderOnChange = (order_on) => {
        setOrderOn(order_on ? order_on : {});
        setIsOrderOnInvalid(!order_on);
        setIsDayPicker(false);
    };
    const onCreateProject = (action) => {
        let obj = {};

        if ( job_type.value !== 'keywords' ) {
            obj = {
                format: format.value,
                word_count: word_count ? word_count : 10,
            };
        }
        if ( job_type.value === 'ideas' ) {
            obj = {
                format: format.value,
                idea_count: word_count ? word_count : 4,
            };
        }
        if ( job_type.value === 'blogging' ) {
            let lastBloggingSub = getLastSubByTalent(accountId, subscriptions, 'bloggers');

            obj = {
                job_type: 'writing',
                talent: 'bloggers',
                word_count: word_count ? word_count : 100,
                format: 'blog',
                level: type !== 'bloggers' ? 'pro' : lastBloggingSub.level,
            };
        }
        if ( job_type.value === 'writing' && isEpiic(host) ) {
            let lastSub = getLastSubByTalent(accountId, subscriptions, 'writers');

            obj = {
                job_type: 'writing',
                talent: 'writers',
                word_count: word_count ? word_count : 100,
                format: format.value,
                level: type !== 'writers' ? 'pro' : lastSub.level,
            };
        }

        createProjectAsync(
            {
                type: 'none',
                topic,
                brief,
                job_type: job_type.value,
                priority: priority.value,
                order_on: moment(order_on).hours(moment().utc().hour()).minutes(moment().utc().minute()).seconds(moment().utc().second()).format('YYYY-MM-DD HH:mm:ss'),
                ...obj,
            },
            action
        );
    };
    const onSubmit = ({ currentTarget: { dataset: { action }}}) => {
        const obj = {};
        if (topic.length < 1) {
            obj['isTopicInvalid'] = true;
            setIsTopicInvalid(true);
        }
        if ( !job_type.value ) {
            obj['isTypeInvalid'] = true;
            setIsTypeInvalid(true);
        }
        if ( brief_links_shared === 'no' ) {
            obj['brief_links_shared'] = true;
        }

        if ( isEmpty(obj) ) {
            if (!isOrderOnInvalid) {
                if ( action === 'save' ) {
                    closeModal();
                }
                onCreateProject(action);
            }
        }
    };

    /* Did mount */
    useLayoutEffect(() => {
        setOrderOn(newProjectOrderOn);
        setPos( modalRef );

        return () => {
            setModalState('newProjectOrderOn', null);
            setModalState('createdProjectId', null);
            setUiState('brief_links_shared', 'yes');
        };
    }, []);
    useEffect(() => {
        if ( !isNil(createdProjectId) ) {
            navigate(`/order/${createdProjectId}`);
            closeModal();
        }
    }, [createdProjectId]);
    useEffect(() => {
        setPos( modalRef );
    }, [brief_links_shared]);
    useEffect(() => {
        const onOutsideClick = (e) => {
            onOutsideElClick(e,pickerRef.current,() => { setIsDayPicker(false) });
        };

        if ( isDayPicker ) {
            document.addEventListener('click', onOutsideClick, true);
            document.addEventListener('touchstart', onOutsideClick, true);
        }

        return () => {
            document.removeEventListener('click', onOutsideClick, true);
            document.removeEventListener('touchstart', onOutsideClick, true);
        };
    }, [isDayPicker]);

    const isKeywords = job_type.value === 'keywords';
    const isIdeas = job_type.value === 'ideas';
    const isProof = job_type.value === 'proofreading';
    const isDesign = job_type.value === 'design';
    const isBlogging = job_type.value === 'blogging';
    const isAih = job_type.value === 'aih';
    // const isMotion = job_type.value === 'motion';

    const isEmptyWordsBalance = words_balance < 1 || !words_balance;
    const isDisabled = false;
    const typeItems = isEpiic(host)
        ? [
            { value: 'design', title: 'Design', isDisabled: !isActiveDesignSub(accountId, subscriptions), isHidden: isSelfClientFn(user_role) && !isActiveDesignSub(accountId, subscriptions) },
            { value: 'blogging', title: 'Blogging', isDisabled: type !== 'bloggers' || isEmptyWordsBalance, isHidden: isSelfClientFn(user_role) && isEmptyWordsBalance },
            { value: 'writing', title: 'Writing', isDisabled: type !== 'writers' || isEmptyWordsBalance, isHidden: isSelfClientFn(user_role) && isEmptyWordsBalance }
        ]
        : [
            { value: 'writing', title: 'Writing', isHidden: isSelfClientFn(user_role) },
            { value: 'blogging', title: 'Blogging subscription', isDisabled: type !== 'bloggers' || isEmptyWordsBalance, isHidden: isSelfClientFn(user_role) && isEmptyWordsBalance },
            { value: 'design', title: 'Design subscription', isDisabled: !isActiveDesignSub(accountId,subscriptions), isHidden: isSelfClientFn(user_role) && !isActiveDesignSub(accountId, subscriptions) },
            { value: 'ideas', title: 'Topic ideas', isHidden: isSelfClientFn(user_role) },
            { value: 'aih', title: 'AI+Human content', isHidden: isSelfClientFn(user_role) },
            { value: 'proofreading', title: 'Proofreading', isHidden: isSelfClientFn(user_role) },
            { value: 'keywords', title: 'Keyword research', isHidden: isSelfClientFn(user_role) },
            // { value: 'motion', title: 'Motion graphics', isHidden: isSelfClientFn(user_role) },
        ]

    let formatsData = formats['writing'].filter(o => o.value !== 'sales');
    if ( isAih ) formatsData = formatsData.filter(o => !includes(o.value, 'page/paper'));

    return <div ref = { modalRef } className = 'gac-modal gac-create-project-modal'>
        <div className = 'gac-modal-mobile-wrap'>
            <span className = 'gac-close-modal' onClick = { closeModal }/>
            <h2>Add project</h2>
            <div className = 'gac-create-project-modal-fields'>
                <div className = 'gac-create-project-modal-topic'>
                    <label>{ isKeywords || isIdeas ? 'Website*' : isDesign ? 'Project name*' : 'Project topic*' }</label>
                    <input
                        className = { `gac-input ${ isTopicInvalid ? 'gac-invalid' : '' }` }
                        type = 'text'
                        value = { topic }
                        data-hj-whitelist
                        onChange = { onInputChange }/>
                </div>
                <div className = 'gac-create-project-modal-type'>
                    <label>Job type*</label>
                    <FilterItem
                        enableOnClickOutside = { lists.job_type }
                        disableOnClickOutside = { !lists.job_type }
                        items = { typeItems }
                        isOpened = { lists.job_type }
                        parameter = 'job_type'
                        isInvalid = { isTypeInvalid }
                        value = { job_type.value }
                        title = { job_type.title }
                        onChange = { onListOptionChange }
                        onToggle = { toggleFilter }/>
                </div>
            </div>
            <label>{ isKeywords ? 'Primary services or products' : 'Project brief' }</label>
            <textarea
                cols = '30'
                rows = '10'
                className={`${ brief_links_shared === 'no' ? 'gac-invalid' : '' }`}
                value = { brief }
                data-hj-whitelist
                disabled = { isDisabled }
                onBlur = { onTextareaBlur }
                onChange = { onTextareaChange }/>
            { brief_links_shared === 'no' && <div className = 'gac-error'>The link sharing should be set to 'Anyone with the link' or 'Public on the web'</div> }
            <div className = 'gac-modal-additional-fields'>
                { !isKeywords && <>
                    { !isBlogging && <div className = 'gac-modal-field' style={{ marginRight: isDesign && isMobile ? 0 : '16px' }}>
                        <label>Format</label>
                        { isDesign
                            ? <Select
                                direction = 'top'
                                isGroups = { true }
                                items = { formats['design'] }
                                value = { format.value ? format.value : '' }
                                title = { format.title }
                                parameter = 'format'
                                onChange = { onSelectChange }/>
                            : <FilterItem
                                direction = 'top'
                                enableOnClickOutside = { lists.format }
                                disableOnClickOutside = { !lists.format }
                                items = { formatsData }
                                isOpened = { lists.format }
                                parameter = 'format'
                                value = { format.value }
                                title = { format.title }
                                isDisabled = { isDisabled }
                                onChange = { onListOptionChange }
                                onToggle = { toggleFilter }/> }
                    </div> }
                    { !isProof && !isDesign && <div className = 'gac-modal-field gac-word-count'>
                        <label>{ job_type.value === 'ideas' ? 'Idea count' : 'Word count' }</label>
                        <input
                            className = 'gac-input'
                            type = 'number'
                            value = { word_count < 1 ? '' : word_count }
                            data-hj-whitelist
                            disabled = { isDisabled }
                            onBlur = { onCountBlur }
                            onChange = { onCountChange }/>
                    </div> }
                </> }
                { isDesign
                    ? <div className = 'gac-create-project-modal-type'>
                        <label>Priority*</label>
                        <FilterItem
                            enableOnClickOutside = { lists.priority }
                            disableOnClickOutside = { !lists.priority }
                            items = { priorities }
                            isOpened = { lists.priority }
                            parameter = 'priority'
                            isInvalid = { false }
                            value = { priority.value }
                            title = { priority.title }
                            onChange = { onListOptionChange }
                            onToggle = { toggleFilter }/>
                    </div>
                    : <div ref = { pickerRef } className = 'gac-modal-field gac-order-on'>
                        <label>Order on*</label>
                        <input
                            className = {`gac-input gac-input-calendar ${ isOrderOnInvalid ? 'gac-invalid' : '' }`}
                            value = { moment(order_on).format('MMM D, YYYY') }
                            type = 'text'
                            data-hj-whitelist
                            readOnly
                            onClick = { onOrderOnInputClick } />
                        { isDayPicker && <DayPicker
                            className='gac-daypicker-wrap'
                            mode = 'single'
                            selected = { new Date(order_on) }
                            fromDate = { new Date() }
                            onDayClick = { orderOnChange } /> }
                    </div> }
            </div>
        </div>
        <div className = 'gac-modal-btns'>
            <span data-action = 'save' className = 'gac-btn gac-btn-s' onClick = { onSubmit }>Save draft</span>
            <span data-action = 'order' className = 'gac-btn-v2 gac-btn-s' onClick = { onSubmit }>Order</span>
        </div>
    </div> ;
};