// Core
import { useRef, useEffect, useLayoutEffect } from "react";
import { useParams, useNavigate, Link } from 'react-router-dom';

// Components
import { Cart } from "./Cart";
import { Confirm } from "./Confirm";
import { Form } from "./Form";
import { List } from "./List";

// Hooks
import { useAuth } from "../../hooks/useAuth";
import { useLists } from "../../hooks/useLists";
import { useModals } from "../../hooks/useModals";
import { useProject } from "../../hooks/useProject";
import { useUi } from "../../hooks/useUi";
import { useUser } from "../../hooks/useUser";

// Instruments
import { getAppTitle, isClientFn, isSelfClientFn, isFetching, setBodyOverflow } from '../../helpers/helpers';
import { isNil } from 'ramda';

export const OrderPage = ({ page }) => {
    /* Ref */
    const formRef = useRef(null);

    /* Hooks */
    const { keys: { accountId }} = useAuth();
    const { fetchProjectDetailsAsync } = useProject();
    const { isAuth, isMobile, fetching } = useUi();
    const { details: { user_role }, company, fetchBalanceAsync, fetchTalentsBlacklistAsync, fetchBrandProfilesAsync, fetchCompanySubscriptionsAsync } = useUser();
    const { orderList, projects, getOrderListAsync } = useLists();
    const { activeModal } = useModals();
    const { projectId } = useParams();
    const navigate = useNavigate();

    /* Actions */
    const setFormHeight = () => {
        const form = formRef.current;
        if ( form ) {
            const height = window.innerHeight;
            const width = window.innerWidth;
            if ( isMobile ) {
                if ( height > 594 ) {
                    if ( !orderList.length ) {
                        form.style.height = `${height - 192}px`;
                    } else {
                        form.style.height = `${height - 160}px`;
                    }
                } else {
                    form.style.height = `auto`;
                }
            } else if ( width > 1023 ) {
                if ( height > 700 ) {
                    form.style.height = `${height - 210}px`;
                } else {
                    form.style.height = `auto`;
                }
            } else {
                form.style.height = `auto`;
            }
        }
    };

    useLayoutEffect(() => {
        setFormHeight();
    });
    useEffect(() => {
        if ( isAuth ) {
            fetchBalanceAsync(accountId);
            getOrderListAsync(navigate);
            fetchTalentsBlacklistAsync('copywriters');
            fetchTalentsBlacklistAsync('designers');
            fetchBrandProfilesAsync();
            fetchCompanySubscriptionsAsync();
            if ( page === 'duplicating' ) {
                fetchProjectDetailsAsync(projectId,'duplicating',navigate);
            }
            if ( page === 'editing' ) {
                fetchProjectDetailsAsync(projectId,'editing',navigate);
            }
        }

        setBodyOverflow('auto');
        document.body.classList.add('gac-body-order');
        window.addEventListener('resize', setFormHeight, true);

        return () => {
            document.body.classList.remove('gac-body-order');
            window.removeEventListener('resize', setFormHeight, true);
        }
    }, []);
    useEffect(() => {
        if ( page === 'order' ) {
            document.title = getAppTitle('Order', company);
        }
    }, [page]);

    /* Html */
    const getForm = () => {
        return <div ref = { formRef } className = 'gac-order-step-create'>
            <span className = 'gac-create-icon'>
                <svg width="55" height="68" xmlns="http://www.w3.org/2000/svg"><path d="M27 42h-9a1 1 0 1 1 0-2h9v-9a1 1 0 1 1 2 0v9h9a1 1 0 1 1 0 2h-9v9a1 1 0 1 1-2 0v-9zm8-38.717V20h16.78L35 3.283zM53 22H34a1 1 0 0 1-1-1V2H7.625C4.518 2 2 4.508 2 7.6v52.8C2 63.492 4.518 66 7.625 66h39.75C50.482 66 53 63.492 53 60.4V22zM34.125 0a1 1 0 0 1 .706.292l19.875 19.8A1 1 0 0 1 55 20.8v39.6c0 4.198-3.415 7.6-7.625 7.6H7.625C3.415 68 0 64.598 0 60.4V7.6C0 3.402 3.415 0 7.625 0h26.5z" fill="#AFAEAF" fillRule="evenodd"/></svg>
            </span>
            <p>Add a project</p>
            <Link className = 'gac-btn gac-btn-s' to = '/order/new'>Create new</Link>
            <Link className = 'gac-btn-v2 gac-btn-s' to = '/order/duplicate'>Duplicate existing</Link>
            <Link className = 'gac-btn-v2 gac-btn-s' to = '/order/drafts'>Select drafts</Link>
        </div> ;
    };
    const getCart = () => {
        if ( isMobile && (page === 'new' || page === 'confirm' || page === 'editing' || (( page === 'drafts' || page === 'duplicate' ) && isNil(projects) ))) return null;
        if ( isAuth && (isClientFn(user_role) || isSelfClientFn(user_role)) ) return null;

        return <Cart page = { page }/>;
    };
    const content = {
        'order': getForm(),
        'new': <Form page = { page }/>,
        'duplicating': <Form page = { page }/>,
        'editing': <Form page = { page }/>,
        'confirm': <Confirm/>,
        'duplicate': <List page = { page }/>,
        'drafts': <List page = { page }/>,
    };

    return <>
        <div className = { `gac-order-page main-container ${ isMobile ? 'gac-mobile-view' : '' }` }>
            { content[page] }
            { getCart() }
        </div>
        { page !== 'drafts' && isFetching(fetching) && isNil(activeModal) && <div className = 'gac-blocking-overlay'/> }
    </>;
};