// Core
import { call, put, select } from 'redux-saga/effects';

// Instruments
import { api, getKeys, authStr, getTalents } from '../../../../helpers/api';
import { uiActions } from '../../../ui/actions';
import { userActions } from "../../actions";
import { updateTalentsObj } from "../../../../helpers/helpers";

export function* callFetchTalentsBlacklistWorker ({ payload: { account_id, type } }) {
    try {
        yield put(uiActions.enableFetching());
        const { privateKey, publicKey, accountId } = yield select(getKeys);
        const response = yield call(fetch, `${api}/${publicKey}/v1/${type}/blacklisted?account_uid=${ account_id ? account_id : accountId}`, {
            method: 'GET',
            headers: {
                'Authorization': `${authStr} ${privateKey}`,
            }
        });
        const { response: { list }, error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }

        const talents = yield select(getTalents);
        yield put(userActions.setUserState('talents', updateTalentsObj(talents, list, type, 'blacklist')));
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`)
    } finally {
        yield put(uiActions.disableFetching());
    }
}
