// Core
import { useEffect, useLayoutEffect } from "react";

// Components
import { DesignInfo } from "../JobTypesInfo/DesignInfo";

// Hooks
import { useAuth } from "../../../hooks/useAuth";
import { useUser } from "../../../hooks/useUser";
import { useUi } from "../../../hooks/useUi";
import { useProject } from "../../../hooks/useProject";
import { useForms } from "../../../hooks/useForms";
import { useOrder } from "../../../hooks/useOrder";

// Instruments
import { initDefaultRedactor, isActiveDesignSub, getOrderForm, isActiveWebBuildingSub } from "../../../helpers/helpers";
import { isEmpty, includes } from "ramda";

export const DesignSubscription = ({ fields }) => {
    /* Hooks */
    const {
        getDesignFormatField,
        getDimensionsField,
        getTopicField,
        getBriefField,
        getTextareaField,
        getImagesField,
        getImageNotesField,
        getBrandProfilesField,
        getFileTypesField,
        getWritersField,
        getPriorityField,
        getWorkflowField,
        getSitemapField,
        getUsedServicesField,
        getReviewFrequencyField,

        onRedactorSynced,
        onRedactorBlur
    } = fields;
    const { keys: { accountId } } = useAuth();
    const forms = useForms();
    const { updateFormState } = forms;
    const { job_type, job_pay_type, topic_ideas_type, seoaudit_type } = useOrder();
    const { getProjectsDesignCountAsync } = useProject();
    const { brandProfiles, company: { subscriptions } } = useUser();
    const { isAuth, setUiState } = useUi();

    const { image_option, brand_profiles, format } = getOrderForm(job_type, topic_ideas_type, seoaudit_type, job_pay_type, forms);
    const isLogos = `${format}` === '28';

    useLayoutEffect(() => {
        if (isAuth) {
            getProjectsDesignCountAsync();
        }

        initDefaultRedactor('#design-brief', 'brief', null, null, onRedactorSynced, onRedactorBlur, true);
        initDefaultRedactor('#included_text-textarea', 'included_text', '116px', null, onRedactorSynced, onRedactorBlur, true);
        initDefaultRedactor('#design_references-textarea', 'design_references', '116px', null, onRedactorSynced, onRedactorBlur, true);
        initDefaultRedactor('#image_notes-textarea', 'image_notes', '116px', null, onRedactorSynced, onRedactorBlur, true);

        return () => {
            setUiState('projectsDesignTotal', null);
            window.$R('#design-brief', 'destroy');
            window.$R('#included_text-textarea', 'destroy');
            window.$R('#design_references-textarea', 'destroy');
            window.$R('#image_notes-textarea', 'destroy');
        };
    }, []);
    useLayoutEffect(() => {
        if (image_option === 'browse-images' || image_option === 'find-images-for-me') {
            initDefaultRedactor('#image_notes-textarea', 'image_notes', '116px', null, onRedactorSynced, onRedactorBlur, true);
        }
        return () => {
            window.$R('#image_notes-textarea', 'destroy');
        };
    }, [image_option]);
    useLayoutEffect(() => {
        if (format === '28') {
            initDefaultRedactor('#format_description-textarea', 'format_description', '116px', null, onRedactorSynced, onRedactorBlur, true);
            initDefaultRedactor('#format_target_audience-textarea', 'format_target_audience', '116px', null, onRedactorSynced, onRedactorBlur, true);
            initDefaultRedactor('#format_reason-textarea', 'format_reason', '116px', null, onRedactorSynced, onRedactorBlur, true);
        }
        return () => {
            window.$R('#field1-textarea', 'destroy');
            window.$R('#field2-textarea', 'destroy');
            window.$R('#field3-textarea', 'destroy');
        };
    }, [format]);
    useEffect(() => {
        const profiles = brandProfiles.filter(o => includes(o.id, brand_profiles));
        if (!isEmpty(profiles) && profiles.every(o => !o.active)) {
            updateFormState('designSubscriptionForm', { brand_profiles: [] });
        }
    }, [brandProfiles]);

    if (isActiveDesignSub(accountId, subscriptions) || isActiveWebBuildingSub(accountId, subscriptions)) {
        return <>
            {getDesignFormatField()}
            {getDimensionsField()}
            {getWorkflowField()}
            {getSitemapField()}
            {getTopicField()}
            {getBriefField(true)}
            {isLogos && getTextareaField('format_description', 'Description of your company/niche and your top values')}
            {isLogos && getTextareaField('format_target_audience', 'Target audience of your product/service: their psychology, age group, customer journey')}
            {isLogos && getTextareaField('format_reason', 'Why do you need the new logo design?')}
            {getTextareaField('included_text', 'Text to be included in the design, word for word (leave blank if none)')}
            {getTextareaField('design_references', 'Design references (links to designs you like)')}
            {getImagesField()}
            {getImageNotesField()}
            {getBrandProfilesField()}
            {getUsedServicesField()}
            {getFileTypesField()}
            {getWritersField()}
            {getPriorityField()}
            {getReviewFrequencyField()}
        </>;
    }

    return <DesignInfo />;
};