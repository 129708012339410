// Instruments
import { types } from './types';

export const uiActions = Object.freeze({
    initialize: (data) => ({
        type:    types.INITIALIZE,
        payload: data,
    }),
    initializeSuccess: () => ({
        type: types.INITIALIZE_SUCCESS,
    }),
    enableFetching: () => ({
        type: types.ENABLE_FETCHING,
    }),
    disableFetching: () => ({
        type: types.DISABLE_FETCHING,
    }),
    setUiState: (prop, value) => ({
        type:    types.SET_UI_STATE,
        payload: {
            prop,
            value,
        },
    }),
    locationChange: () => ({
        type: types.LOCATION_CHANGE,
    }),
});
