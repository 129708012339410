// Core
import {call, put, select} from 'redux-saga/effects';

// Instruments
import { uiActions } from '../../../ui/actions';
import { api, authStr, getKeys } from "../../../../helpers/api";
import { isNil } from "ramda";

export function* callGetHubspotTokenWorker ({ payload: { email, firstName, lastName }}) {
    try {
        yield put(uiActions.enableFetching());
        const { privateKey, publicKey, accountId } = yield select(getKeys);
        const response = yield call(fetch, `${api}/${publicKey}/v1/hubspot/visitor-token`, {
            method: 'POST',
            headers: {
                'Authorization': `${authStr} ${privateKey}`,
                'Content-Type':  'application/json',
            },
            body: JSON.stringify({
                email,
                first_name: firstName,
                last_name: lastName,
                account_uid: accountId
            }),
        });
        const { response: { token }, error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }

        window['hsConversationsSettings'] = {
            identificationEmail: email,
            identificationToken: token
        };
        if ( !isNil(window.HubSpotConversations) && !isNil(window.HubSpotConversations.resetAndReloadWidget) ) {
            window.HubSpotConversations.resetAndReloadWidget();
        }
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
        yield put(uiActions.setUiState('notification', {
            msg: error.message,
            type: 'error',
            fn: null,
        }));
    } finally {
        yield put(uiActions.disableFetching());
    }
}
