// Core
import { put, call } from 'redux-saga/effects';
import update from 'immutability-helper';

// Instruments
import { api } from '../../../../helpers/api';
import { listsActions } from '../../actions';
import { uiActions } from '../../../ui/actions';

export function* callGetStaticDataWorker () {
    try {
        yield put(uiActions.enableFetching());
        const response = yield call(fetch, `${api}/v1/static-references`,{
            method: 'GET',
        });
        let { response: data, error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }

        data = update(data, {
            content_types: { $set: data.content_types.map(o => ({ value: o.code, title: o.content_type_name })) },
            copy_styles: { $set: data.copy_styles.map(o => ({ value: o.code, title: o.copy_style_name })) },
            copy_voices: { $set: data.copy_voices.map(o => ({ value: o.code, title: o.copy_voice_name })) },
            formats: {
                writing: { $set: data.formats.writing.map(o => ({ value: o.code, title: o.format_name })) }
            },
            industries: { $set: data.industries.map(o => ({ value: o.code, title: o.industry_short_name })) },
            statuses: { $set: data.statuses.map(o => ({ value: o.code, title: o.code === 'matching' ? 'Queued' : o.text })) },
            localizations: { $set: data.localizations.map(o => ({ value: o.code, title: o.localization_name })) },
            target_audiences: { $set: data.target_audiences.map(o => ({ value: o.code, title: o.target_audience_name })) },
        });
        yield put(listsActions.setListsState('staticData',data));
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
        yield put(uiActions.setUiState('notification', {
            msg: error.message,
            type: 'error',
            fn: null,
        }));
    } finally {
        yield put(uiActions.disableFetching());
    }
}
