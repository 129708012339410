// Core
import { asyncTypes } from './asyncTypes';

export const projectActionsAsync = Object.freeze({
    fetchProjectTagsAsync: () => ({
        type: asyncTypes.FETCH_PROJECT_TAGS_ASYNC,
    }),
    fetchProjectDetailsAsync: (id, action, navigate) => ({
        type: asyncTypes.FETCH_PROJECT_DETAILS_ASYNC,
        payload: {
            id,
            action,
            navigate
        },
    }),
    fetchProjectDetailsByCodeAsync: (id, code, action) => ({
        type: asyncTypes.FETCH_PROJECT_DETAILS_BY_CODE_ASYNC,
        payload: {
            id,
            code,
            action,
        },
    }),
    fetchProjectRevisionsAsync: (data) => ({
        type: asyncTypes.FETCH_PROJECT_REVISIONS_ASYNC,
        payload: data,
    }),
    fetchProjectRevisionAsync: (data) => ({
        type: asyncTypes.FETCH_PROJECT_REVISION_ASYNC,
        payload: data,
    }),
    fetchProjectPitchesAsync: (data) => ({
        type: asyncTypes.FETCH_PROJECT_PITCHES_ASYNC,
        payload: data,
    }),
    fetchProjectMessagesAsync: (data) => ({
        type: asyncTypes.FETCH_PROJECT_MESSAGES_ASYNC,
        payload: data,
    }),
    fetchProjectLogsAsync: (data) => ({
        type: asyncTypes.FETCH_PROJECT_LOGS_ASYNC,
        payload: data,
    }),
    fetchProjectCommentsAsync: (data) => ({
        type: asyncTypes.FETCH_PROJECT_COMMENTS_ASYNC,
        payload: data,
    }),
    createTagAsync: (tag) => ({
        type: asyncTypes.CREATE_TAG_ASYNC,
        payload: tag,
    }),
    createMessageAsync: (message) => ({
        type: asyncTypes.CREATE_MESSAGE_ASYNC,
        payload: message,
    }),
    createCommentAsync: (project_id, job_type, commentHtml, commentText, field, idea_number, revision_id, fileId, horizontal, vertical, preview_width, attachment) => ({
        type: asyncTypes.CREATE_COMMENT_ASYNC,
        payload: {
            project_id,
            job_type,
            commentHtml,
            commentText,
            field,
            idea_number,
            revision_id,
            fileId,
            horizontal,
            vertical,
            preview_width,
            attachment
        },
    }),
    updateCommentAsync: (comment, comment_id, project_id, job_type, idea_number, attachment) => ({
        type: asyncTypes.UPDATE_COMMENT_ASYNC,
        payload: {
            comment,
            comment_id,
            project_id,
            job_type,
            idea_number,
            attachment
        },
    }),
    deleteCommentAsync: (comment_id, project_id, job_type, idea_number, field) => ({
        type: asyncTypes.DELETE_COMMENT_ASYNC,
        payload: {
            comment_id,
            project_id,
            job_type,
            idea_number,
            field
        },
    }),
    requestRevisionAsync: (data) => ({
        type: asyncTypes.PUT_REQUEST_REVISION_ASYNC,
        payload: data,
    }),
    updateRevisionAsync: (data) => ({
        type: asyncTypes.UPDATE_REVISION_ASYNC,
        payload: data,
    }),
    fetchRevisionDiffAsync: (project_id, job_type, revision) => ({
        type: asyncTypes.FETCH_REVISION_DIFF_ASYNC,
        payload: {
            project_id,
            job_type,
            revision
        },
    }),
    downloadProjectContentAsync: (project_id, topic, type) => ({
        type: asyncTypes.DOWNLOAD_PROJECT_CONTENT_ASYNC,
        payload: {
            project_id,
            topic,
            type
        },
    }),
    checkTopicAsync: (topic) => ({
        type:    asyncTypes.CHECK_TOPIC_UNIQUE_ASYNC,
        payload: topic,
    }),
    updateIdeasRevisionAsync: (data) => ({
        type: asyncTypes.UPDATE_IDEAS_REVISION_ASYNC,
        payload: data,
    }),
    cancelProjectAsync: (project_id) => ({
        type: asyncTypes.CANCEL_PROJECT_ASYNC,
        payload: {
            project_id
        },
    }),
    changeProjectQueueAsync: (project_id, queue) => ({
        type: asyncTypes.CHANGE_PROJECT_QUEUE_ASYNC,
        payload: {
            project_id,
            queue
        },
    }),
    /* New */
    deleteProjectAsync: (project_id, action, idea_number, navigate) => ({
        type:    asyncTypes.DELETE_PROJECT_ASYNC,
        payload: {
            project_id,
            action,
            idea_number,
            navigate
        },
    }),
    approveProjectAsync: (data) => ({
        type:    asyncTypes.APPROVE_PROJECT_ASYNC,
        payload: data,
    }),
    extendProjectDeadlineAsync: (id) => ({
        type:    asyncTypes.EXTEND_PROJECT_DEADLINE_ASYNC,
        payload: id,
    }),
    updateProjectAsync: (project, action, idea_number, navigate) => ({
        type:    asyncTypes.UPDATE_PROJECT_ASYNC,
        payload: {
            project,
            action,
            idea_number,
            navigate
        },
    }),
    moveProjectAsync: (project_id, status, account_uid, account_name, navigate) => ({
        type:    asyncTypes.MOVE_PROJECT_ASYNC,
        payload: {
            project_id,
            status,
            account_uid,
            account_name,
            navigate
        },
    }),
    awardProjectAsync: (pitchId) => ({
        type:    asyncTypes.AWARD_PROJECT_ASYNC,
        payload: pitchId,
    }),
    reviewProjectAsync: (data) => ({
        type:    asyncTypes.REVIEW_PROJECT_ASYNC,
        payload: data,
    }),
    publishProjectAsync: (data, action) => ({
        type:    asyncTypes.PUBLISH_PROJECT_ASYNC,
        payload: {
            data,
            action
        },
    }),
    setEditingProjectAsync: (project_id, editing) => ({
        type:    asyncTypes.SET_EDITING_PROJECT_ASYNC,
        payload: {
            project_id,
            editing
        },
    }),
    createProjectAsync: (project, action, navigate) => ({
        type:    asyncTypes.CREATE_PROJECT_ASYNC,
        payload: {
            project,
            action,
            navigate
        },
    }),
    confirmProjectAsync: (project, action, navigate) => ({
        type:    asyncTypes.CONFIRM_PROJECT_ASYNC,
        payload: {
            project,
            action,
            navigate
        },
    }),
    setIdeaStatusAsync: (project_id, idea_id, value) => ({
        type:    asyncTypes.SET_IDEA_STATUS_ASYNC,
        payload: {
            project_id,
            idea_id,
            value
        },
    }),
    getProjectsDesignCountAsync: () => ({
        type: asyncTypes.GET_PROJECTS_DESIGN_COUNT_ASYNC,
    }),
    getOriginalityRevisionResultsAsync: (revision_id) => ({
        type: asyncTypes.GET_ORIGINALITY_REVISION_RESULTS_ASYNC,
        payload: {
            revision_id,
        },
    }),
});
