// Instruments
import { types } from './types';

export const projectActions = Object.freeze({
    updateProjectDetails: (data) => ({
        type:    types.UPDATE_PROJECT_DETAILS,
        payload: {
            data
        },
    }),
    fetchProjectRevisionSuccess: (revision) => ({
        type:    types.FETCH_PROJECT_REVISION_SUCCESS,
        payload: revision,
    }),
    createCommentSuccess: (comment) => ({
        type:    types.CREATE_COMMENT_SUCCESS,
        payload: comment,
    }),
    updateCommentSuccess: (comment) => ({
        type:    types.UPDATE_COMMENT_SUCCESS,
        payload: comment,
    }),
    deleteCommentSuccess: (comment_id) => ({
        type:    types.DELETE_COMMENT_SUCCESS,
        payload: comment_id,
    }),
    updateProjectRevision: (html_content) => ({
        type:    types.UPDATE_PROJECT_REVISION,
        payload: html_content,
    }),
    updateIdeaField: (prop, data, idea_number) => ({
        type:    types.UPDATE_IDEA_FIELD,
        payload: {
            prop,
            data,
            idea_number
        },
    }),
    updateIdeaRevisionField: (prop, data, idea_number, revision_id) => ({
        type:    types.UPDATE_IDEA_REVISION_FIELD,
        payload: {
            prop,
            data,
            idea_number,
            revision_id
        },
    }),
    deleteIdeaFile: (file_id, idea_number, revision_id) => ({
        type:    types.DELETE_IDEA_FILE,
        payload: {
            file_id,
            idea_number,
            revision_id
        },
    }),
    updateIdeaRevision: (data, idea_number, revision_id) => ({
        type:    types.UPDATE_IDEA_REVISION,
        payload: {
            data,
            idea_number,
            revision_id
        },
    }),
    /* NEW */
    setProjectState: (prop, value) => ({
        type:    types.SET_PROJECT_STATE,
        payload: {
            prop,
            value
        },
    }),
    createMessageSuccess: (message) => ({
        type:    types.CREATE_MESSAGE_SUCCESS,
        payload: message,
    }),
    approvePitchSuccess: (id) => ({
        type:    types.APPROVE_PITCH_SUCCESS,
        payload: id,
    }),
});
