// Core
import { call, put, select } from 'redux-saga/effects';

// Instruments
import { upperCaseFirstLetter } from "../../../../helpers/helpers";
import { api, authStr, getKeys, getStaticData, getTags, getTalents, getUserDetails } from '../../../../helpers/api';
import { projectActions } from '../../actions';
import { orderActions } from '../../../order/actions';
import { formsActions } from "../../../forms/actions";
import { initialState as formsState } from "../../../forms/reducer";
import { uiActions } from '../../../ui/actions';
import { isNil, includes, isEmpty } from 'ramda';
import moment from 'moment/moment';

export function* callFetchProjectDetailsWorker ({ payload: { id, action, navigate }}) {
    try {
        yield put(uiActions.enableFetching());
        const { privateKey, publicKey, accountId } = yield select(getKeys);
        const response = yield call(fetch, `${api}/${publicKey}/v1/projects/${id}?account_uid=${accountId}`, {
            method: 'GET',
            headers: {
                Authorization: `${authStr} ${privateKey}`
            }
        });
        const { response: project, user: { timezone: responseTimezone }, error: { message }} = yield call([response, response.json]);

        if ( response.status === 403 ) {
            navigate('/403');
            return false;
        }

        if (response.status !== 200) {
            throw new Error(message);
        }

        if ( 'duplicating/editing'.includes(action) ) {
            let obj = {};
            const attachments = project.files.map(o => ({
                ...o,
                name: o.filename,
            }));
            const { formats } = yield select(getStaticData);
            if ( project.job_type === 'design' ) {
                let invite_writers = JSON.parse(project.invite_writers);
                let data = [];
                formats['design'].filter(o => !!o.active).forEach(o => {
                    o.formats.forEach(a => {
                        data.push(a);
                    });
                });
                data = data.filter(o => !!o.active);
                obj = {
                    attachments,
                    invite_writers,
                    format: isEmpty(data) || isNil(project.format) || isEmpty(project.format) ? '' : data.some(o => `${o.id}` === `${project.format}`) ? `${project.format}` : '',
                    file_types: project.file_types.map(o => o.code),
                    format_options: isNil(project.format_options) ? null : project.format_options.map(el => ({ ...JSON.parse(el), amount: isNil(JSON.parse(el).amount) ? '' : Number(JSON.parse(el).amount) })),
                    dimensions: project.dimensions.map(o => ({
                        id: `${o.id}`,
                        custom_vertical: isNil(o.vertical) ? null : `${o.vertical}`,
                        custom_horizontal: isNil(o.horizontal) ? null : `${o.horizontal}`,
                        custom_unit_code: isNil(o.unit_code) ? null : `${o.unit_code}` })),
                };
            } else if ( project.job_type === 'seoaudit' ) {
                obj = {
                    cms: isNil(project.cms) || isEmpty(project.cms) ? project.cms : `${project.cms}`,
                };
            } else if ( includes(project.job_type, 'seobrief/contentstrategy/linkbuilding') ) {
                obj = {
                    attachments
                };
            } else if ( project.job_type === 'webaudit' ) {
                obj = {
                    format: project.format === 'sales' ? 'custom' : project.format,
                    cms: `${project.cms}`,
                };
            } else if ( project.job_type === 'motion' ) {
                let data = [];
                formats['motion'].filter(o => !!o.active).forEach(o => {
                    o.formats.forEach(a => {
                        data.push(a);
                    });
                });
                data = data.filter(o => !!o.active);
                let invite_writers = JSON.parse(project.invite_writers);
                obj = {
                    invite_writers,
                    format: isEmpty(data) || isNil(project.format) || isEmpty(project.format) ? '' : data.some(o => `${o.id}` === `${project.format}`) ? `${project.format}` : '',
                    file_types: project.file_types.map(o => o.code),
                    attachments: project.logo_file.map(o => ({ ...o, name: o.filename })),
                    idea_attachments: isNil(project.idea_files) || isEmpty(project.idea_files) ? [] : project.idea_files.map(o => ({ ...o, name: o.filename })),
                    format_options: isNil(project.format_options) ? null : project.format_options.map(el => ({ ...JSON.parse(el), amount: isNil(JSON.parse(el).amount) ? '' : Number(JSON.parse(el).amount) })),
                    dimensions: project.dimensions.map(o => ({
                        id: `${o.id}`,
                        custom_vertical: isNil(o.vertical) ? null : `${o.vertical}`,
                        custom_horizontal: isNil(o.horizontal) ? null : `${o.horizontal}`,
                        custom_unit_code: isNil(o.unit_code) ? null : `${o.unit_code}` })),
                };
            } else if ( project.job_type === 'keywords' ) {
                obj = {
                    format: project.format === 'sales' ? 'custom' : project.format,
                    attachments,
                    level: 'standard',
                    target_audience: isNil(project.target_audience) ? '' : project.target_audience,
                    competitors: isNil(project.competitors) ? '' : project.competitors,
                    additional_details: isNil(project.additional_details) ? '' : project.additional_details,
                };
            } else if ( project.job_type === 'proofreading' ) {
                const tagsFromState = yield select(getTags);
                const tags = project.project_tags ? JSON.parse(project.project_tags) : [];
                const project_tags = tagsFromState.filter(tag => tags.includes(tag.value)).map(o => ({ value: o.value, label: o.title }));

                let talents = yield select(getTalents);
                const blacklist = talents.blacklist.copywriters.map(o => o.writer_uid);
                let invite_writers = JSON.parse(project.invite_writers);
                if ( !isNil(invite_writers) && blacklist.length ) {
                    invite_writers = invite_writers.filter(o => !blacklist.includes(o.writer_uid));
                }
                obj = {
                    format: project.format === 'sales' ? 'custom' : project.format,
                    attachments,
                    invite_writers,
                    project_tags,
                    word_count_type: 'custom',
                };
            } else {
                const { suggested } = yield select(getStaticData);
                const suggestedArr = project.job_type === 'aih' ? suggested.word_count_aih : suggested.word_count;
                const tagsFromState = yield select(getTags);
                let talents = yield select(getTalents);
                const blacklist = talents.blacklist.copywriters.map(o => o.writer_uid);
                const word_count_array = suggestedArr.filter((item) => item.format === (project.format ? project.format : 'custom'))[0].word_count.split(',');
                const length = project.product_list.length;
                const quantity = project.format === 'product' ? length : 1;
                const tags = project.project_tags ? JSON.parse(project.project_tags) : [];
                const project_tags = tagsFromState.filter(tag => tags.includes(tag.value)).map(o => ({ value: o.value, label: o.title }));
                const wordCountData = project.word_count ? project.word_count : 0;
                const word_count =
                    project.format === 'product'
                        ? length > 1
                        ? Array(length).fill(wordCountData/length)
                        : wordCountData
                        : wordCountData;
                const idea_count_type =
                    project.job_type === 'ideas'
                        ? includes(project.idea_count, [15, 25, 35])
                            ? `${project.idea_count}`
                            : 'custom'
                        : '';
                let level = project.level === 'standard' ? 'pro' : project.level;
                let invite_writers = JSON.parse(project.invite_writers);
                if ( !isNil(invite_writers) && blacklist.length ) {
                    invite_writers = invite_writers.filter(o => !blacklist.includes(o.writer_uid));
                }

                obj = {
                    format: project.format === 'sales' ? 'custom' : project.format,
                    attachments,
                    invite_writers,
                    quantity,
                    project_tags,
                    word_count_type: wordCountData ? word_count_array.includes(`${wordCountData}`) ? `${wordCountData}` : 'custom' : '',
                    idea_count_type,
                    word_count,
                    level
                };
            }
            if ( project.job_type === 'ideas' ) {
                obj = {
                    ...obj,
                    format: project.format === 'sales' ? 'custom' : project.format,
                    level: isNil(project.level) ? 'pro' : project.level,
                    idea_count: isNil(project.idea_count) ? 4 : project.idea_count
                };
            }

            Object.keys(obj).forEach(key => {
                if ( isNil(obj[key]) ) {
                    delete obj[key];
                }
            });

            let data = {
                ...project
            };
            Object.keys(data).forEach(key => {
                if ( isNil(data[key]) ) {
                    delete data[key];
                }
            });

            let formLabel = project.job_type;
            let jobTypeValue = project.job_type;
            let jobGroupValue = 'writing';
            let jobPayTypeValue = 'one-off';
            if ( includes(project.job_type, 'design/motion') ) {
                jobGroupValue = 'design';
                if ( project.pay_type === 'subscription' ) {
                    formLabel = `${project.job_type}Subscription`;
                    jobPayTypeValue = 'subscription';
                }
            } else if ( includes(project.job_type, 'keywords/webaudit/seoaudit/seobrief/contentstrategy/linkbuilding') ) {
                jobGroupValue = 'seo';
                if ( project.job_type === 'seoaudit' ) {
                    formLabel = `${project.job_type}${upperCaseFirstLetter(project.seo_type)}`;
                }
            }
            if ( project.talent === 'bloggers' ) {
                jobTypeValue = 'blogging';
                if ( project.pay_type === 'one-off' ) {
                    formLabel = 'blogging';
                } else {
                    jobPayTypeValue = 'subscription';
                    if ( project.job_type === 'writing' ) {
                        formLabel = 'bloggingOwn';
                        yield put( orderActions.setOrderState('topic_ideas_type', 'own' ) );
                    } else {
                        formLabel = 'bloggingRequest';
                        yield put( orderActions.setOrderState('topic_ideas_type', 'request' ) );
                    }
                }
            } else if ( project.talent === 'writers' ) {
                jobTypeValue = 'writing';
                formLabel = 'writingSubscription';
                jobPayTypeValue = 'subscription';
            }

            if ( action === 'duplicating' ) {
                let duplicatedProject = { ...data, ...obj, order_on: null, written_by: null, };
                const form = Object.keys(formsState[`${formLabel}Form`]);
                const fields = Object.keys(duplicatedProject);
                const tags2 = duplicatedProject.project_tags;
                fields.forEach((key) => {
                    if ( !form.includes(key) ) {
                        delete duplicatedProject[key];
                    }
                });
                duplicatedProject = {
                    ...duplicatedProject,
                    topic: [duplicatedProject.topic],
                    project_tags: Array.isArray(tags2) ? tags2 : tags2 ? tags2.split(',') : tags2,
                };

                yield put( formsActions.updateFormState(`${formLabel}Form`, duplicatedProject) );
                yield put( orderActions.updateOrderState({
                    job_group: jobGroupValue,
                    job_type: jobTypeValue,
                    job_pay_type: jobPayTypeValue,
                    seoaudit_type: project.seo_type,
                    editedProject: {},
                    duplicatedProject: { ...data, ...obj, order_on: null, written_by: null, }
                }) );

                navigate('/order/new');
            } else {
                if ( project.status === 'draft' ) {
                    let editedProject = { ...data, ...obj };
                    const form = Object.keys(formsState[`${formLabel}Form`]);
                    const fields = Object.keys(editedProject);
                    const tags = editedProject.project_tags;

                    const { product_list } = editedProject;
                    const quantity = editedProject.format === 'product' && !isNil(product_list) && !isEmpty(product_list) ? product_list.length : 1;

                    fields.forEach((key) => {
                        if ( !form.includes(key) && key !== 'project_id' && key !== 'account_uid' && key !== 'talent' ) {
                            delete editedProject[key];
                        }
                    });
                    const { timezone } = yield select(getUserDetails);
                    const timezoneStr = isNil(timezone) ? responseTimezone.replace('+','-') : timezone.replace('+','-');

                    editedProject = {
                        ...editedProject,
                        account_uid: editedProject.account_uid,
                        topic: [editedProject.topic],
                        order_on: [ moment.utc(editedProject.order_on).utcOffset(timezoneStr).format('YYYY-MM-DD HH:mm:ss') ],
                        written_by: [ moment.utc(editedProject.written_by).utcOffset(timezoneStr).format('YYYY-MM-DD HH:mm:ss') ],
                        project_tags: Array.isArray(tags) ? tags : tags ? tags.split(',') : tags,
                        quantity,
                    };

                    yield put( formsActions.updateFormState(`${formLabel}Form`, editedProject) );
                    yield put( orderActions.updateOrderState({
                        job_group: jobGroupValue,
                        job_type: jobTypeValue,
                        job_pay_type: jobPayTypeValue,
                        seoaudit_type: project.seo_type,
                        editedProject: { ...data, ...obj },
                        duplicatedProject: {}
                    }) );

                    navigate(`/order/${ project.project_id }`);
                } else {
                    navigate(`/project/${project.project_id}`);
                }
            }
        } else {
            yield put(projectActions.setProjectState('details', project));
        }
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
        if ( action === 'editing' ) {
            navigate('/order');
        }
        if ( action === 'duplicating' ) {
            yield put(uiActions.setUiState('notification', {
                msg: error.message,
                type: 'error',
                fn: null,
            }));
        }
    } finally {
        yield put(uiActions.disableFetching());
    }
}