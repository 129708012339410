// Core
import { useEffect } from "react";

// Components
import { Design } from "./Design";
import { Ideas } from "./Ideas";
import { Motion } from "./Motion";
import { Writing } from "./Writing";

// Hooks
import { useProject } from "../../../hooks/useProject";

// Instruments
import { getAppTitle } from "../../../helpers/helpers";

export const Content = ({ company }) => {
    /* Hooks */
    const { details } = useProject();
    const { job_type } = details;

    useEffect(() => {
        document.title = getAppTitle('Project content', company);
    }, []);

    const getContent = () => {
        if ( job_type === 'ideas' ) return <Ideas/>
        if ( job_type === 'design' ) return <Design/>
        if ( job_type === 'motion' ) return <Motion/>

        return <Writing/>
    };

    return getContent();
};