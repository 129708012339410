// Core
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useParams, Link } from 'react-router-dom';

// Components
import TextareaAutosize from 'react-textarea-autosize';
import EmptyStatev2 from '../../../common/EmptyStatev2/index';
import { RevisionsBtns } from "../../RevisionsBtns";

// Icons
import Arrow from '../../../icons/Arrow';
import Reload from '../../../icons/Reload';
import Share from '../../../icons/Share';
import Pencil from '../../../icons/Pencil';
import Download from '../../../icons/Download';
import CommentAdd from "../../../icons/CommentAdd";
import CommentClose from "../../../icons/CommentClose";
import CommentEdit from "../../../icons/CommentEdit";
import CommentDelete from "../../../icons/CommentDelete";

// Hooks
import { usePrevious } from "../../../../hooks/custom/usePrevious";
import { useOnMouseLeave } from "../../../../hooks/custom/useOnMouseLeave";
import { useAuth } from "../../../../hooks/useAuth";
import { useModals } from "../../../../hooks/useModals";
import { useProject } from "../../../../hooks/useProject";
import { useSocket } from "../../../../hooks/useSocket";
import { useUi } from "../../../../hooks/useUi";
import { useUser } from "../../../../hooks/useUser";

// Instruments
import rangy from 'rangy/lib/rangy-core.js';
import 'rangy/lib/rangy-highlighter';
import 'rangy/lib/rangy-classapplier';
import 'rangy/lib/rangy-textrange';
import 'rangy/lib/rangy-serializer';
import { bindAddCommentEvent, bindDeleteCommentEvent, bindUpdateCommentEvent, bindUpdateProjectEvent, subCommentsChannel, unsubCommentsChannel, } from '../../../../init/pusher';
import { api } from '../../../../helpers/api';
import { notifications } from '../../../../helpers/notifications';
import { setLinksTarget, isClientFn, isSelfClientFn, getAccount, getDuration, copyToClipboard, clearBrief, onOutsideElClick, isFetching } from '../../../../helpers/helpers';
import animateScrollTo from 'animated-scroll-to';
import moment from 'moment/moment';
import { includes, reverse, indexOf, isEmpty, isNil, equals } from 'ramda';
import { regexUrls } from "../../../../helpers/constants";
import { throttle } from 'throttle-debounce';

const observeConfig = { attributes: false, childList: true, subtree: true };
const Entities = require('html-entities').XmlEntities;
const entities = new Entities();

export const Writing = () => {
    /* Ref */
    const mounted = useRef(null);
    const hintRef = useRef(null);
    const listWrapRef = useRef(null);
    const listRef = useRef(null);
    const publishListRef = useRef(null);
    const iconRef = useRef(null);
    const containerRef = useRef(null);
    // const redactorRef = useRef(null);
    const wrapRef = useRef(null);
    const btnsRef = useRef(null);
    const commentsRef = useRef(null);
    const textareaRef = useRef(null);
    const mobileCommentRef = useRef(null);
    const formRef = useRef(null);
    const observerRef = useRef(null);
    const throttledRef = useRef(null);
    // const isUpdateDisabled = useRef(null);

    /* State */
    const [initialSlide, setInitialSlide] = useState(0);
    const [stateRevision, setStateRevision] = useState(null);
    const [commentText, setCommentText] = useState('');
    const [currentRange, setCurrentRange] = useState(null);
    const [mobileRange, setMobileRange] = useState(null);
    const [activeComment, setActiveComment] = useState(null);
    const [editingComment, setEditingComment] = useState(null);
    const prevEditingComment = usePrevious(editingComment);
    const [commentsOrder, setCommentsOrder] = useState(null);
    const [formIndex, setFormIndex] = useState(null);
    const [isCommentForm, setIsCommentForm] = useState(false);
    const [isCommentFormError, setIsCommentFormError] = useState(false);
    const [isEmptyComments, setIsEmptyComments] = useState(false);
    const [isSubmitRequest, setIsSubmitRequest] = useState(false);
    const [isDownloadList, setIsDownloadList] = useState(false);
    const [isPublishList, setIsPublishList] = useState(false);
    const [isRevisionDiff, setIsRevisionDiff] = useState(false);
    const [isFakeRevision, setIsFakeRevision] = useState(false);

    /* Hooks */
    const { keys: { accountId }} = useAuth();
    const {
        details,
        revisions,
        comments,
        revision_diff,
        setProjectState,
        deleteCommentSuccess,
        fetchProjectRevisionsAsync,
        fetchProjectCommentsAsync,
        fetchRevisionDiffAsync,
        createCommentAsync,
        updateCommentAsync,
        deleteCommentAsync,
        publishProjectAsync,
        downloadProjectContentAsync,
        updateProjectRevision,
        updateRevisionAsync,
        setEditingProjectAsync,
        requestRevisionAsync,
        approveProjectAsync,
        reviewProjectAsync,
        getOriginalityRevisionResultsAsync,
    } = useProject();
    const { project_id, status, editing, job_type, topic, word_count, client_reviewed, logged_user, deadline, publish_url, rating, feedback } = details;
    const { setModal } = useModals();
    const { pusher, channels: { projectChannel, commentsChannel }} = useSocket();
    const { sessionID } = pusher;
    const { details: { accounts, user_role, user_id }} = useUser();
    const { isAuth, isFakeUser, isMobile, fetching, setUiState } = useUi();
    const { shareCode } = useParams();

    /* Actions */
    const getMaxRevision = (arr) => {
        if ( isNil(arr) || isEmpty(arr) ) return null;
        return Math.max(...arr.map(o => o.revision_number));
    };
    const getRevisionBtnsWidth = () => {
        const el = document.querySelectorAll(`.gac-project-wrapper`)[0];
        const width = el.offsetWidth;

        return isMobile ? width - 34 : width - 39 - 218 - 66;
    };
    const getJobType = (type) => {
        return type === 'keywords' ? 'Keyword research' : type;
    };
    const getCommentsOrder = () => {
        if ( status !== 'editing' && (!editing || isFakeUser ) && !isFakeRevision && !isNil(containerRef.current) ) {
            let elements = containerRef.current.querySelectorAll('.gac-selected-text');
            elements = [...elements];
            const order = [];
            elements.forEach(el => {
                const id = el.getAttribute('comment-id');
                if ( !includes(id, order) ) {
                    order.push(id);
                }
                el.addEventListener('click', (e) => { onCommentNodeClick(e) });
            });

            setCommentsOrder(order);
        }
    };
    const initRedactor = () => {
        window.$R('#redactor', {
            styles: false,
            source: false,
            buttonsAddAfter: {after: 'italic', buttons: ['undo', 'redo', 'ol', 'ul', 'outdent', 'indent']},
            autoparseImages: false,
            minHeight   : '500px',
            linkSize    : 500,
            buttonsHide : ['deleted', 'lists'],
            plugins     : ['alignment', 'fontcolor', 'table', 'imagemanager'],
            toolbarFixedTopOffset: 56,
            callbacks: {
                synced: (data) => {
                    throttledRef.current(data);
                },
                started: () => {
                    setTimeout(() => {
                        const elements = containerRef.current.querySelectorAll('.gac-selected-text');
                        const order = [];
                        elements.forEach(el => {
                            const id = el.getAttribute('comment-id');
                            if ( !includes(id, order) ) {
                                order.push(id);
                            }
                            el.addEventListener('click', onCommentNodeClick );
                        });

                        observerRef.current.observe(containerRef.current, observeConfig);
                        setCommentsOrder(order);
                    }, 100);
                },
            },
            imageUpload: (formData, files, e, upload) => {
                return new Promise((resolve, reject) => {
                    const data = new FormData();
                    [ ...files].forEach(file => {
                        data.append(`attachment[]`, file, file.name);
                    });
                    const xhr = new XMLHttpRequest();
                    xhr.onreadystatechange = () => {
                        if (xhr.readyState === 4) {
                            if (xhr.status === 200) {
                                resolve(xhr.response);
                            } else {
                                reject(xhr.message);
                            }
                        }
                    };
                    xhr.open('POST', `${api}/v1/upload_file`, true);
                    xhr.send(data);
                }).then((response) => {
                    const { response: filesArr } = JSON.parse(response);
                    const result = {};
                    filesArr.forEach(({ url, filename, file_id }, i) => {
                        result[`file-${i}`] = {
                            url,
                            id: file_id,
                            filename
                        };
                    });
                    upload.complete(result);
                }).catch(() => {
                    upload.complete({ "error": true });
                    setUiState('notification', { msg: 'The file upload failed. Please try again or contact support team.', type: 'error', fn: null });
                });
            },
            autoparseLinks : false,
            focus: true });
    };

    /* Comments */
    const textSelection = () => {
        try {
            const selection = rangy.getSelection();
            const range = selection.getRangeAt(0);
            if ( !selection.isCollapsed ) {
                setCurrentRange(range);
                setMobileRange(null);
            }
        } catch (e) {
            console.log(e);
        }
    };
    const actionSetSelection = (callback) => {
        const applier = rangy.createClassApplier('gac-new-comment', {
            normalize: true,
            useExistingElements: false,
            onElementCreate: (el) => {
                const selection = window.getSelection ? window.getSelection() : document.selection ? document.selection : null;
                if(!!selection) selection.empty ? selection.empty() : selection.removeAllRanges();
            }
        });
        applier.applyToRange(isNil(mobileRange) ? currentRange : mobileRange);
        const el = containerRef.current.querySelectorAll('.gac-new-comment');
        const span = `<span class="gac-new-comment-icon"/>`;
        if ( el.length ) {
            el[el.length - 1].insertAdjacentHTML('beforeend', span);
        }
        if ( !isNil(callback) ) {
            callback();
        }
    };
    const actionClearSelection = () => {
        if ( !isNil(containerRef.current) ) {
            let element = containerRef.current.querySelectorAll('.gac-new-comment');
            element = [...element];
            let icons = containerRef.current.querySelectorAll('.gac-new-comment-icon');
            icons = [...icons];
            icons.forEach(el => {
                el.remove();
            });
            element.forEach(el => {
                const parent = el.parentNode;
                if ( !isNil(parent) ) {
                    while (el.firstChild) parent.insertBefore(el.firstChild, el);
                    parent.removeChild(el);
                }
            });
        }
    };
    const onTextSelection = () => {
        if ( !isCommentForm && status === 'approval' && stateRevision === getMaxRevision(revisions) ) {
            textSelection();
        }
    };
    const onTextClearSelection = () => {
        if ( (!!currentRange || !!mobileRange) && !isCommentForm ) {
            setCurrentRange(null);
            setMobileRange(null);
            actionClearSelection();
        }
    };
    const setCommentsHeight = () => {
        const h1 = isNil(wrapRef.current) ? 0 : wrapRef.current.offsetHeight;
        const h2 = isNil(btnsRef.current) ? 0 : btnsRef.current.offsetHeight;

        if ( !isNil(commentsRef.current) ) {
            commentsRef.current.style.height = `${h1+h2+1}px`;
        }
    };
    const setCommentIconPosition = () => {
        if ( !isNil(containerRef.current) ) {
            const el = containerRef.current.querySelectorAll('.gac-new-comment-icon');
            if ( el.length ) {
                const rect = el[el.length - 1].getBoundingClientRect();
                iconRef.current.style.top = `${rect.bottom}px`;
                iconRef.current.style.left = `${rect.right}px`;
            } else if ( !isNil(iconRef.current) ) {
                iconRef.current.style.visibility = 'hidden';
            }
        }
    };
    const setCommentActive = ({ currentTarget: { dataset: { id }}}) => {
        if ( !isCommentForm ) {
            if ( `${activeComment}` !== `${id}` ) {
                clearActiveComments();
                let data = containerRef.current.querySelectorAll(`.gac-selected-text[comment-id="${id}"]`);
                data = [...data];
                if ( data.length ) {
                    data.forEach(i => {
                        i.classList.add('gac-active-comment');
                    });
                }
                setActiveComment(id);
                setEditingComment(null);
                setIsCommentFormError(false);
            }
        }
    };
    const setInitialAlignment = () => {
        if ( !isMobile && !isNil(commentsOrder) ) {
            commentsOrder.forEach((id, index) => {
                const comment = document.getElementById(`comment-${id}`);
                if ( !isNil(comment) ) {
                    const text = containerRef.current.querySelectorAll(`.gac-selected-text[comment-id="${id}"]`)[0];
                    const delta_y = parseInt(containerRef.current.getBoundingClientRect().top);
                    if ( !isNil(text) ) {
                        const top = text.getBoundingClientRect().top;
                        let topOffset = top - delta_y;
                        const prevId = commentsOrder[index - 1];
                        let height = 0;

                        const prevComment = document.getElementById(`comment-${prevId}`);
                        if ( !isNil(prevComment) ) {
                            const prevTop = prevComment.getBoundingClientRect().top;
                            height = prevComment.offsetHeight;
                            const temp = prevTop - delta_y + height + 6;

                            if ( temp > topOffset ) {
                                topOffset = temp;
                            }
                        }

                        comment.style.top = `${topOffset}px`;
                    }
                }
            });
        }
    };
    const alignComments = () => {
        if ( !isMobile ) {
            let order = isNil(commentsOrder) || isEmpty(commentsOrder) ? [] : [...commentsOrder];
            if ( isCommentForm ) order.splice(formIndex, 0, 'form');
            const delta_y = parseInt(containerRef.current.getBoundingClientRect().top);
            const index = indexOf( isCommentForm ? 'form' : activeComment, order);
            let before = [];
            let after = order;

            alignTargetComment(activeComment, delta_y);

            if ( index > 0 ) {
                before = order.filter((o,i) => i <= index);
                after = order.filter((o,i) => i >= index);
            }
            if ( !isEmpty(before) ) {
                before.reverse();
                alignBeforeComments(before, 0, delta_y);
            }
            if ( !isEmpty(after) ) alignAfterComments(after, 0, delta_y);
        }
    };
    const alignTargetComment = (id, delta_y) => {
        if ( isCommentForm ) {
            if ( !isNil(formRef.current) ) {
                const text = containerRef.current.querySelectorAll('.gac-new-comment')[0];
                const top = text.getBoundingClientRect().top - delta_y;
                formRef.current.style.top = `${top}px`;
            }
        } else {
            const text = containerRef.current.querySelectorAll(`.gac-selected-text[comment-id="${id}"]`)[0];
            const top = isNil(text) ? null : text.getBoundingClientRect().top - delta_y;

            const comment = document.getElementById(`comment-${id}`);
            if ( !isNil(comment) && !isNil(top) ) comment.style.top = `${top}px`;
        }
    };
    const alignBeforeComments = (arr, i, delta_y) => {
        if ( isNil(comments) ) return;
        if ( isNil(arr[i+1]) ) return;

        const el = arr[i] === 'form' ? formRef.current : document.getElementById(`comment-${arr[i]}`);

        if ( isNil(el) ) return;
        const top = el.getBoundingClientRect().top - delta_y;
        const comment = document.getElementById(`comment-${arr[i+1]}`);
        if ( isNil(comment) ) return;
        const height = comment.offsetHeight;
        let commentTop = comment.getBoundingClientRect().top - delta_y;
        commentTop = commentTop < 0 ? 0 : commentTop;
        const bottom = commentTop + height + 6;
        const commentText = containerRef.current.querySelectorAll(`.gac-selected-text[comment-id="${arr[i+1]}"]`)[0];
        const textTop = commentText.getBoundingClientRect().top - delta_y;

        if ( bottom > top ) {
            comment.style.top = `${top - height - 6 }px`;
            alignBeforeComments(arr, i+1, delta_y);
        } else if ( textTop > commentTop ) {
            if ( textTop + bottom > top ) {
                comment.style.top = `${top - height - 6 }px`;
            } else {
                comment.style.top = `${textTop}px`;
            }
            alignBeforeComments(arr, i+1, delta_y );
        } else if ( textTop < commentTop ) {
            comment.style.top = `${textTop}px`;
            alignBeforeComments(arr, i+1, delta_y);
        }
    };
    const alignAfterComments = (arr, i, delta_y) => {
        if ( isNil(arr[i+1]) ) return;

        const comment = arr[i] === 'form' ? formRef.current : document.getElementById(`comment-${arr[i]}`);
        if ( isNil(comment) ) return;
        const currentTop = comment.getBoundingClientRect().top - delta_y;
        const height = comment.offsetHeight;
        const bottom = currentTop + height + 6;

        const nextComment = document.getElementById(`comment-${arr[i+1]}`);
        const nextText =  containerRef.current.querySelectorAll(`.gac-selected-text[comment-id="${arr[i+1]}"]`)[0];
        if ( isNil(nextComment) || isNil(nextText) ) return;
        const top = nextComment.getBoundingClientRect().top - delta_y;
        const nextTextTop = nextText.getBoundingClientRect().top - delta_y;

        if ( bottom > top || bottom > nextTextTop ) {
            nextComment.style.top = `${bottom}px`;
            alignAfterComments(arr, i+1, delta_y);
        } else if ( nextTextTop < top ) {
            nextComment.style.top = `${nextTextTop}px`;
            alignAfterComments(arr, i+1, delta_y);
        }
    };
    const clearActiveComments = () => {
        if ( !isNil(containerRef.current) ) {
            let data = containerRef.current.querySelectorAll('.gac-active-comment');
            data = [...data];
            if ( data.length ) {
                data.forEach(i => {
                    i.classList.remove('gac-active-comment');
                });
            }
        }
    };
    const onCommentFormClose = () => {
        setCommentText('');
        setCurrentRange(null);
        setMobileRange(null);
        setEditingComment(null);
        setActiveComment(null);
        setIsCommentForm(false);
        setIsCommentFormError(false);
        actionClearSelection();
    };
    const onCommentFormShow = () => {
        let elements = containerRef.current.querySelectorAll('.gac-selected-text, .gac-new-comment');
        elements = [...elements];
        let icons = containerRef.current.querySelectorAll('.gac-new-comment-icon');
        icons = [...icons];
        icons.forEach(el => {
            el.remove();
        });
        let index = null;

        if ( elements.length ) {
            const order = [];
            elements.forEach(el => {
                if ( el.classList.contains('gac-new-comment') && !includes('form', order) ) {
                    order.push('form');
                } else {
                    const id = el.getAttribute('comment-id');
                    if ( !includes(id, order) && !isNil(id) ) {
                        order.push(id);
                    }
                }
            });
            order.forEach((el, i) => {
                if ( el === 'form' ) {
                    index = i;
                }
            });
        }
        clearActiveComments();
        setFormIndex(index);
        setIsCommentForm(true);
        setActiveComment(null);
    };
    const onCommentNodeClick = (e) => {
        if ( !isCommentForm ) {
            const { target } = e;
            const id = target.getAttribute('comment-id');
            if ( `${activeComment}` !== `${id}` ) {
                clearActiveComments();
                let comments = containerRef.current.querySelectorAll(`.gac-selected-text[comment-id="${id}"]`);
                comments = [...comments];
                if (!isEmpty(comments)) {
                    comments.forEach(i => {
                        i.classList.add('gac-active-comment');
                    });
                }

                setActiveComment(id);
                setEditingComment(null);
                setIsCommentFormError(false);
            }
        }
    };
    const onCommentTextareaChange = ({ currentTarget: { value }}) => {
        if ( value.length < 1500 ) {
            setCommentText(value);
            setIsCommentFormError(false);
        }
    };
    const onCommentTextareaKeyDown = ({ key }) => {
        if(key === 'Escape'){
            onCommentFormClose()
        }
    };
    const onCommentSubmit = () => {
        if ( !isFetching(fetching) ) {
            if (commentText.length) {
                const commentHtml = containerRef.current.innerHTML;
                createCommentAsync(project_id, job_type, commentHtml, entities.encode(entities.decode(commentText)));
                setCommentText('');
                setCurrentRange(null);
                setMobileRange(null);
                setIsCommentForm(false);
                setIsCommentFormError(false);
                actionClearSelection();
            } else {
                setIsCommentFormError(true);
            }
        }
    };
    const onCommentUpdate = ({ currentTarget: { dataset: { id }}}) => {
        if ( !isFetching(fetching) ) {
            if ( commentText.length ) {
                clearActiveComments();
                updateCommentAsync(entities.encode(entities.decode(commentText)), id, project_id, job_type);
                setEditingComment(null);
                setActiveComment(null);
                setCommentText('');
                setIsCommentForm(false);
                setIsCommentFormError(false);
            } else {
                setIsCommentFormError(true);
            }
        }
    };
    const onCommentSpanDelete = (mutations) => {
        mutations.forEach(mutation => {
            if (mutation.type === 'childList') {
                if ( mutation.removedNodes.length ) {
                    mutation.removedNodes.forEach(el => {
                        if ( !isNil(el.tagName) && el.tagName.toLowerCase() === 'span' && !isNil(el.classList) && el.classList.contains('gac-selected-text') && el.hasAttribute('comment-id') ) {
                            const id = el.getAttribute('comment-id');
                            deleteCommentSuccess(id);
                        }
                    });
                }
            }
        });
    };
    const onCommentEdit = (e) => {
        const { currentTarget: { dataset: { id }}} = e;
        e.preventDefault();
        if ( `${editingComment}` !== `${id}` ) {
            setEditingComment(id);
            setCommentText(comments.filter(o => `${o.id}` === `${id}`)[0].comment);
        }
    };
    const onCommentDelete = ({ currentTarget: { dataset: { id }}}) => {
        if ( !isFetching(fetching) ) {
            deleteCommentAsync(id, project_id, job_type);
        }
    };
    const slideMobileComment = ({ currentTarget: { dataset: { action }}}) => {
        const idx = indexOf(activeComment, commentsOrder);
        const i = action === 'prev' ? idx - 1 : idx + 1;

        if ((action === 'prev' && idx !== 0) || ( action === 'next' && idx + 1 < commentsOrder.length )) {
            clearActiveComments();
            let comments = containerRef.current.querySelectorAll(`.gac-selected-text[comment-id="${commentsOrder[i]}"]`);
            comments = [...comments];
            if ( comments.length ) {
                comments.forEach(i => {
                    i.classList.add('gac-active-comment');
                });
            }
            animateScrollTo(comments[0], {
                verticalOffset: -66,
            });
            setActiveComment(commentsOrder[i]);
            setEditingComment(null);
            setIsCommentFormError(false);
        }
    };
    const onShowMobileCommentForm = () => {
        actionSetSelection(onCommentFormShow);
    };
    const onTextSelectionMobile = () => {
        try {
            if ( !isCommentForm && status === 'approval' && stateRevision === getMaxRevision(revisions) ) {
                const selection = rangy.getSelection();
                const range = selection.getRangeAt(0);
                if (!selection.isCollapsed) {
                    setMobileRange(range);
                    setActiveComment(null);
                    setEditingComment(null);
                    setIsCommentFormError(false);
                }
            }
        } catch (e) {
            console.log(e);
        }
    };

    /* Lists */
    const toggleShowChanges = () => {
        if ( isNil(stateRevision) ) return;
        if ( isFetching(fetching) ) return;

        setIsRevisionDiff(state => !state);
        setEditingComment(null);
        setActiveComment(null);
        setCommentText('');
        setCurrentRange(null);
        setIsCommentForm(false);
        setIsCommentFormError(false);
    };
    const togglePublishList = () => {
        setIsPublishList(state => !state);
    };
    const toggleDownloadList = () => {
        setIsDownloadList(state => !state);
    };

    /* Project Actions */
    const onRevisionChange = (number, newInitialSlide) => {
        const num = number === 'fake' ? null : Number(number);

        if ( stateRevision !== num || initialSlide !== Number(newInitialSlide) ) {
            setStateRevision(num);
            setInitialSlide(newInitialSlide);
            setIsFakeRevision(number === 'fake');
            setCommentText('');
            setIsCommentForm(false);
            setIsCommentFormError(false);
            setIsEmptyComments(false);
            setIsDownloadList(false);
            setIsRevisionDiff(false);
            setActiveComment(null);
            setEditingComment(null);
            setCurrentRange(null);
            setMobileRange(null);
            actionClearSelection();

            if ( editing ) {
                setProjectState('details', { ...details, editing: false });
                setEditingProjectAsync(project_id, 0);
            }
            if ( !isNil(revision_diff) ) setProjectState('revision_diff',null);
        }
    };
    const onProjectPublish = ({ currentTarget: { dataset: { url }}}) => {
        if ( !isFetching(fetching) ) {
            publishProjectAsync({ project_id, publish_url: url });
        }
    };
    const onContentDownload = ({ currentTarget: { dataset: { format }}}) => {
        downloadProjectContentAsync(project_id, topic, format);
    };
    const onProjectSaveChanges = () => {
        const text = window.$R('#redactor', 'source.getCode').replace(' gac-active-comment', '');

        if ( !isFetching(fetching) ) {
            updateProjectRevision(text);
            updateRevisionAsync({ project_id, job_type, revision_number: stateRevision, text, pusherData: { sessionID }});

            observerRef.current.disconnect();
            setProjectState('details', { ...details, editing: false });
            setEditingProjectAsync(project_id, 0);
        }
    };
    const onUpdateRevision = (data) => {
        let text = data.replace(' gac-active-comment', '');

        if ( !isFetching(fetching) && !isNil(revisions) ) {
            const html_content = revisions.filter(o => `${o.revision_number}` === `${stateRevision}`)[0].html_content;
            if (text.length && !equals(text, html_content)) { // (isNil(isUpdateDisabled.current) || !isUpdateDisabled.current)
                setCommentsHeight();
                setInitialAlignment();
                updateRevisionAsync({ project_id, job_type, revision_number: stateRevision, text, pusherData: { sessionID }});
            }
            // isUpdateDisabled.current = false;
        }
    };
    const onRequestRevision = () => {
        if ( !isCommentForm && !isFetching(fetching) ) {
            if ( isNil(commentsOrder) || isEmpty(commentsOrder) ) {
                setIsEmptyComments(true);
            } else {
                requestRevisionAsync({ project_id, job_type });
            }
        }
    };
    const onProjectApprove = () => {
        if ( isNil(rating) ) {
            setModal('rateWriter');
        } else {
            approveProjectAsync({ project_id, project_score: rating, project_feedback: feedback });
        }
    };
    const onProjectReview = () => {
        if ( !isFetching(fetching) ) {
            reviewProjectAsync({project_id, 'code': shareCode});
        }
    };
    const onProjectEdit = () => {
        if ( !isFetching(fetching) ) {
            setActiveComment(null);
            setEditingComment(null);
            setFormIndex(null);
            setCommentText('');
            setCurrentRange(null);
            setIsCommentForm(false);
            setIsEmptyComments(false);
            setIsCommentFormError(false);

            setProjectState('details', { ...details, editing: true });
            setEditingProjectAsync(project_id, 1);
        }
    };
    const onAiResultsClick = ({ currentTarget: { dataset: { revisionId }}}) => {
        getOriginalityRevisionResultsAsync(revisionId);
    };

    let isCurrentRevision = stateRevision === getMaxRevision(revisions);
    useOnMouseLeave(commentsOrder, isCurrentRevision);

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
        } else {
            setCommentsHeight();
        }
    });
    useEffect(() => {
        const onResize = () => {
            setCommentsHeight();
            setCommentIconPosition();
            setInitialAlignment();

            const el = document.querySelectorAll(`.gac-revision-btns`)[0];
            if ( !isNil(el) ) {
                el.style.maxWidth = `${ getRevisionBtnsWidth() }px`;
            }
        };
        const onScroll = () => {
            if ( !isNil(iconRef.current) ) {
                setCommentIconPosition();
            }
        };
        const onKeydown = (e) => {
            const { code, key, ctrlKey, metaKey } = e;

            if ((ctrlKey || metaKey) && (code === 'KeyC' && key === 'c')) {
                if (!isNil(stateRevision) && !isEmpty(revisions)) {
                    const { html_content } = revisions.filter(o => o.revision_number === stateRevision)[0];
                    copyToClipboard( clearBrief(html_content) );
                }
            }
        };
        const onTextClearSelectionMobile = () => {
            if ( !isNil(mobileRange) ) {
                const selection = rangy.getSelection();
                if ( selection.isCollapsed ) {
                    setMobileRange(null);
                }
            }
        };

        observerRef.current = new MutationObserver(onCommentSpanDelete);
        window.addEventListener('resize', onResize, true);
        window.addEventListener('scroll', onScroll, true);
        document.addEventListener('keydown', onKeydown, true);
        document.addEventListener('selectionchange', onTextClearSelectionMobile, true);

        if ( 'revision/editing/approval/approved/published'.includes(status) ) {
            fetchProjectRevisionsAsync({ project: { project_id, job_type } });
            fetchProjectCommentsAsync({ project: { project_id, job_type } });
        }
        if ( editing && !isFakeUser ) {
            setUiState('notification', notifications.editingProject);
        }
        if ( ( isClientFn(user_role) || isSelfClientFn(user_role) ) && status === 'approval' ) {
            setIsEmptyComments(true);
        }

        return () => {
            window.removeEventListener('resize', onResize, true);
            window.removeEventListener('scroll', onScroll, true);
            document.removeEventListener('keydown', onKeydown, true);
            document.removeEventListener('selectionchange', onTextClearSelectionMobile, true);

            setProjectState('comments', null);
            setProjectState('revisions', null);
            setProjectState('revision_diff', null);
            setUiState('shouldRequestRevision',false);
        };
    }, []);
    useEffect(() => {
        if ( !isEmpty(pusher) ) {
            subCommentsChannel(pusher, project_id);
        }

        return () => {
            if ( !isEmpty(pusher) ) {
                unsubCommentsChannel(pusher,project_id);
            }
        };
    }, [pusher]);
    useEffect(() => {
        if ( !isNil(projectChannel) ) {
            bindUpdateProjectEvent(projectChannel);
        }
    }, [projectChannel]);
    useEffect(() => {
        if ( !isNil(commentsChannel) ) {
            bindAddCommentEvent(commentsChannel);
            bindUpdateCommentEvent(commentsChannel);
            bindDeleteCommentEvent(commentsChannel);
        }
    }, [commentsChannel]);
    useEffect(() => {
        if ( !isFetching(fetching) && !isNil(revisions) ) {
            throttledRef.current = throttle(5000, onUpdateRevision);
        }
    }, [revisions, fetching]);
    useLayoutEffect(() => {
        const onOutsideClick = (e) => {
            onOutsideElClick(e,hintRef.current,() => { setIsEmptyComments(false) });
        };

        if ( isEmptyComments ) {
            animateScrollTo(0);
            document.addEventListener('click', onOutsideClick, true);
            document.addEventListener('touchstart', onOutsideClick, true);
        }

        return () => {
            document.removeEventListener('click', onOutsideClick, true);
            document.removeEventListener('touchstart', onOutsideClick, true);
        };
    }, [isEmptyComments]);
    useLayoutEffect(() => {
        const onOutsideClick = (e) => {
            onOutsideElClick(e,listWrapRef.current,() => { setIsDownloadList(false) });
        };

        if ( isDownloadList ) {
            const list = listRef.current;
            if ( !isNil(list) ) {
                const h = window.innerHeight;
                const { top, height } = list.getBoundingClientRect();
                if ( top + height > h ) {
                    list.style.top = `-${height}px`
                }
            }
            document.addEventListener('click', onOutsideClick, true);
            document.addEventListener('touchstart', onOutsideClick, true);
        }

        return () => {
            document.removeEventListener('click', onOutsideClick, true);
            document.removeEventListener('touchstart', onOutsideClick, true);
        };
    }, [isDownloadList]);
    useLayoutEffect(() => {
        const onOutsideClick = (e) => {
            onOutsideElClick(e,publishListRef.current,() => { setIsPublishList(false) });
        };

        if ( isPublishList ) {
            const list = publishListRef.current;
            if ( !isNil( list ) ) {
                const h = window.innerHeight;
                const { top, height } = list.getBoundingClientRect();
                if ( top + height > h ) {
                    list.style.top = `-${height}px`
                }
            }
            document.addEventListener('click', onOutsideClick, true);
            document.addEventListener('touchstart', onOutsideClick, true);
        }

        return () => {
            document.removeEventListener('click', onOutsideClick, true);
            document.removeEventListener('touchstart', onOutsideClick, true);
        };
    }, [isPublishList]);
    useLayoutEffect(() => {
        const isFake = includes(status, 'revision|editing');

        if ( !isNil(revisions) && !isEmpty(revisions) && !isNil(comments)  ) {
            setStateRevision(isFake ? 'fake' : getMaxRevision(revisions));
            setIsFakeRevision(isFake);
        }
    }, [revisions, comments]);
    useLayoutEffect(() => {
        if ( !isNil(stateRevision) ) {
            if ( editing ) {
                initRedactor();
            } else {
                window.$R('#redactor', 'destroy');
                setActiveComment(null);
                setIsCommentFormError(false);
            }
        }
    }, [stateRevision, editing]);
    useLayoutEffect(() => {
        if ( (!isNil(revisions) || !isNil(comments) || !isNil(stateRevision)) && !isEmpty(revisions) && !isNil(stateRevision) && !isRevisionDiff ) {
            getCommentsOrder();
        }
    }, [revisions, comments, stateRevision, isRevisionDiff]);
    useLayoutEffect(() => {
        if ( !isNil(commentsOrder) ) {
            setInitialAlignment();
            if ( !isEmpty(commentsOrder) ) {
                setIsSubmitRequest(true);
            }
        }
        if ( isEmpty(commentsOrder) || isNil(commentsOrder) ) {
            setIsSubmitRequest(false);
        }
    }, [commentsOrder]);
    useLayoutEffect(() => {
        if ( !isNil(currentRange) ) {
            actionSetSelection();
            setCommentIconPosition();
        }
    }, [currentRange]);
    useLayoutEffect(() => {
        if ( isCommentForm ) {
            alignComments();
            if ( !isNil(textareaRef.current) ) {
                setTimeout(() => {
                    textareaRef.current.focus();
                }, 300);
            }
        } else {
            setInitialAlignment();
        }
        // that.setState({ formIndex, isCommentForm: true, activeComment: null }, () => {});
    }, [isCommentForm]);
    useLayoutEffect(() => {
        const onOutsideMobileCommentClick = (e) => {
            let target = e.target;
            do {
                if (target === mobileCommentRef.current || (!isNil(target.classList) && target.classList.contains('gac-selected-text'))) {
                    return;
                }
                target = target.parentNode;
            } while (target);

            setActiveComment(null);
            setIsCommentFormError(false);
        };

        if ( isMobile ) {
            if ( !isNil(activeComment) ) {
                document.addEventListener('click', onOutsideMobileCommentClick, true);
                document.addEventListener('touchstart', onOutsideMobileCommentClick, true);
                let target = containerRef.current.querySelectorAll(`.gac-selected-text[comment-id="${activeComment}"]`)[0];
                animateScrollTo(target, {
                    verticalOffset: -66
                });
            }
        } else {
            if ( !isNil(activeComment) ) {
                alignComments();
            }
        }

        return () => {
            document.removeEventListener('click', onOutsideMobileCommentClick, true);
            document.removeEventListener('touchstart', onOutsideMobileCommentClick, true);
        };
    }, [activeComment]);
    useLayoutEffect(() => {
        if ( !isNil(prevEditingComment) && prevEditingComment !== editingComment ) {
            alignComments();
        }
    }, [editingComment]);
    useLayoutEffect(() => {
        if ( isRevisionDiff ) {
            fetchRevisionDiffAsync(project_id, job_type, stateRevision);
        }
        if ( !isNil(commentsRef.current) ) commentsRef.current.style.visibility = isRevisionDiff ? 'visible' : 'hidden';

        return () => {
            setProjectState('revision_diff',null);
        };
    }, [isRevisionDiff]);

    // that.setState({
    //     setCommentText('');
    //     setCurrentRange(null);
    //     setMobileRange(null);
    //     setEditingComment(null);
    //     setActiveComment(null);
    //     setIsCommentForm(false);
    //     setIsCommentFormError(false);
    // }, () => {
    //     actionClearSelection(that.container);
    //     clearActiveComments();
    //     setInitialAlignment(that, target);
    // });

    // this.setState({
    //     activeComment: null,
    //     isCommentFormError: false,
    // }, () => {
    //     this._getCommentsOrder();
    //     clearActiveComments();
    // });

    // componentDidUpdate(props, state) {
    //     const {
    //         project: {
    //             project_revisions,
    //             project_comments,
    //             project_details
    //         },
    //         socket: { pusher, channels: { commentsChannel: channel, projectChannel }},
    //         isRevisionsLoaded,
    //         isCommentsLoaded,
    //         shouldRequestRevision
    //     } = this.props;
    //     const { revision, isRevisionDiff, commentsOrder } = this.state;
    //     const { project_id, editing, status } = project_details;
    //
    //     if ( isRevisionsLoaded && !isNil(revision) && !equals(props.project.project_revisions, project_revisions) ) {
    //         const numbersArr = project_revisions.map(o => Number(o.revision_number));
    //         const lastRevisions = Math.max(...numbersArr);
    //         const currentRevision = revision === 'fake' ? lastRevisions : revision;
    //         const { html_content } = project_revisions.filter(o => `${o.revision_number}` === `${currentRevision}` )[0];
    //         window.$R('#redactor', 'source.setCode', html_content);
    //         isUpdateDisabled.current = true;
    //     }
    // }

    /* Html */
    const getTopic = () => {
        if ( isMobile ) return null;
        return <div className="gac-project-title">{ topic }</div>;
    };
    const getEmptyState = () => {
        const emptyStatus = isClientFn(user_role) || isSelfClientFn(user_role) ? 'writing' : status === 'writing' ? getJobType(job_type) : status;
        return <div className='gac-project-content'>
            { getTopic() }
            <EmptyStatev2 status = { emptyStatus } type = 'type-1'/>
        </div>;
    };

    if ( isNil(revisions) ) {
        if ( includes(status, 'writing/pitching/matching') ) {
            return getEmptyState();
        }
    } else {
        if ( isEmpty(revisions) || includes(status, 'writing/pitching/matching') ) {
            return getEmptyState();
        }
    }

    const getAiScoreHtml = (revision_id, ai_score, plag_score) => {
        if ( isNil(ai_score) ) return null;
        return <>
            <div>AI detection score: {ai_score}% confidence it’s created by human (<span onClick = { onAiResultsClick } data-revision-id = { revision_id }>results</span>)</div>
            <div>Plagiarism score: {plag_score}% plagiarism match (<span onClick = { onAiResultsClick } data-revision-id = { revision_id }>results</span>)</div>
            <div>Certified by <a target='_blank' rel = 'noopener noreferrer' href = 'https://originality.ai/?lmref=spnD7g'>originality.ai</a></div>
        </>;
    };
    const getCopyScapeHtml = (score,url) => {
        if ( isNil(score) ) return null;
        return <>
            The text has been certified
            { isNil(url) ? ` ${score}% original ` : <> <a target='_blank' rel = 'noopener noreferrer' href={url}>{ `${score}% original` }</a> </> }
            by Copyscape
        </>;
    };
    const getPublishBtn = () => {
        let publications = [];
        const account = getAccount(accountId,accounts);
        if ( !isEmpty(account) ) {
            publications = account.publications.filter(o => o.type_code !== 'delegate');
        }
        const listData = publications .map((o, i) => <li key = { i } data-url = { o.website_url } onClick = { onProjectPublish }>{`on ${o.website_url}`}</li>);

        // <div className='gac-btn gac-btn-s' data-url='manual' onClick = { onProjectPublish }>Mark as published</div>

        return !isNil(publish_url)
            ? <div className='gac-btn gac-btn-s gac-btn-disable-v2'><i/>{ publish_url === 'manual' ? 'Published manually' : `Published on ${publish_url}` }</div>
            : !isEmpty(publications) && <div className="gac-btn-with-list">
                <div className = 'gac-btn gac-btn-s' onClick = { togglePublishList }><span>Mark as published</span><i className="gac-arrow"/></div>
                { isPublishList && <ul ref = { publishListRef }>{ listData }</ul> }
            </div> ;
    };
    const getTextareaAutosize = (props, value) => {
        return <TextareaAutosize
            { ...props }
            ref = { textareaRef }
            minRows = { 4 }
            maxRows = { 8 }
            value = { value }
            onHeightChange = { alignComments }
            onKeyDown = { onCommentTextareaKeyDown }
            onChange = { onCommentTextareaChange }/> ;
    };
    const getCommentForm = () => {
        return <div ref = { formRef } className = { `gac-project-comment gac-comment-textarea ${isCommentFormError ? 'gac-invalid' : ''}` } key = 'gac-comment-textarea'>
            { getTextareaAutosize({}, commentText) }
            <div className = 'gac-project-comment-btns'>
                <span onClick = { onCommentSubmit } className = 'gac-comment'><CommentAdd/></span>
                <span onClick = { onCommentFormClose } className = 'gac-cancel'><CommentClose/></span>
            </div>
        </div> ;
    };
    const getCommentsData = () => {
        let data = comments;
        if ( !isNil(commentsOrder) ) {
            const result = [];
            commentsOrder.forEach(i => {
                const el = data.filter(o => `${o.id}` === `${i}`)[0];
                if (!isNil(el)) {
                    result.push(el);
                }
            });
            data = result;
        } else {
            data = [];
        }

        return data;
    };
    const getComments = () => {
        if ( isMobile ) return null;

        let commentsData = getCommentsData();
        commentsData = commentsData.map(({ comment, id, date_create, user }) => {
            let name, avatar, unique_id;
            if ( !isNil(user) ) {
                ({ name, avatar, unique_id } = user );
            }
            const year = moment().year();
            const dateObj = moment(date_create);
            const isToday = moment().diff(moment(date_create), 'days') === 0;
            const date = dateObj.format( isToday ? 'h:mma' : dateObj.year() === year ? 'MMM D' : 'MMM, YYYY' );
            const isActive = `${activeComment}` === `${id}`;
            const isCommentEditing = `${editingComment}` === `${id}`;

            const getComment = () => {
                let text = entities.decode(comment);
                const matchedUrls = text.match(regexUrls);

                if (matchedUrls) {
                    text = text.replace(regexUrls, (url) => {
                        return `<a href="${url}" target="_blank">${url}</a>`;
                    });
                }

                if ( isCommentEditing ) {
                    return <>
                        { getTextareaAutosize({ autoFocus: true }, entities.decode(commentText)) }
                        <div className = 'gac-project-comment-btns'>
                            <span data-id = { id } onClick = { onCommentUpdate }><CommentAdd/></span>
                            <span onClick = { onCommentFormClose } className = 'gac-cancel'><CommentClose/></span>
                        </div>
                    </> ;
                }

                return <p dangerouslySetInnerHTML={{ __html: text }}/>;
            };
            const getCommentBtns = () => {
                if ( !isActive ) return null;

                return <>
                    { ( (!isClientFn(user_role) && !isFakeUser ) || `${unique_id}` === `${user_id}` || ( isFakeUser && `${unique_id}` === logged_user.user_uid ))
                        && !isCommentEditing && !editing && isCurrentRevision && status === 'approval'
                        && <div className = 'gac-project-comment-btns'>
                            <span data-id = { id } onClick = { onCommentEdit }><CommentEdit/></span>
                            <span data-id = { id } onClick = { onCommentDelete } className = 'gac-cancel'><CommentDelete/></span>
                        </div> }
                    <div className = 'gac-line'/>
                </> ;
            };

            return <div key = { id } data-id = { id } id = {`comment-${id}`} className = { `gac-project-comment ${isActive ? 'gac-active' : ''}` } onClick = { setCommentActive  }>
                <div className = 'gac-project-comment-head'>
                    <div className = 'gac-project-comment-author'>
                        { isNil(avatar) || isEmpty(avatar) ? <div className="gac-no-avatar"/> : <img src={avatar} alt='Avatar'/> }
                        <div className="gac-name">{ name }</div>
                    </div>
                    <div className = 'gac-project-comment-date'>{ date }</div>
                </div>
                { getComment() }
                { getCommentBtns() }
            </div> ;
        });
        if ( isCommentForm ) {
            commentsData.splice(isNil(formIndex) ? 0 : formIndex, 0, getCommentForm());
        }

        if ( isEmpty(commentsData) ) return null;

        return <div ref = { commentsRef } className = 'gac-project-comments'>
            { commentsData }
        </div> ;
    };
    const getRevisionsBtns = () => {
        return (revisions.length > 1 || includes(status, 'revision|editing')) && (!isNil(stateRevision) || isFakeRevision ) && <RevisionsBtns
            revisions = { revisions }
            type = 'writing'
            currentRevision = { stateRevision }
            initialSlide = { initialSlide }
            status = { status }
            isMobile = { isMobile }
            isFakeRevision = { isFakeRevision }
            maxWidth = { getRevisionBtnsWidth() }
            onRevisionChange = { onRevisionChange } />
    };
    const getContentWrap = () => {
        if ( isNil(revisions) ) return null;
        let isCurrentRange = !!currentRange;
        const revisionIndex = indexOf(stateRevision, reverse(revisions.map(o => o.revision_number)));
        let currentRevision = {};

        if (!isNil(stateRevision) || isFakeRevision) {
            currentRevision = revisions.filter(o => o.revision_number === stateRevision)[0];
        }
        let actual_word_count, html_content, copyscape_score, copyscape_url, ai_score, plag_score, revision_id;
        if ( revisions.length && !isFakeRevision ) {
            ({ actual_word_count, html_content, copyscape_score, copyscape_url, ai_score, plag_score, revision_id } = currentRevision);
        }

        const getShowChangesBtn = () => {
            return revisions.length > 1 && !editing && revisionIndex !== 0 && !isNil(stateRevision) && <span className = 'gac-show-changes-btn' onClick = { toggleShowChanges }>
                { isNil(revision_diff) && !isRevisionDiff ? <>{ `Show changes: Revision ${revisionIndex} vs.` }{ revisionIndex === 1 ? ' Original' : ` Revision ${revisionIndex-1}` }</> : 'Hide changes' }
            </span> ;
        };
        const getHtmlContent = () => {
            if ( editing && !isClientFn(user_role) ) {
                return <div ref = { containerRef } className = 'gac-project-redactor'><textarea id = 'redactor' value = { html_content } readOnly/></div>;
            }

            const getRevisionDiff = () => {
                if ( !isNil(revision_diff) && isRevisionDiff ) {
                    return <div id='gac-project-data' className = 'gac-project-data' ref = { containerRef } dangerouslySetInnerHTML={{ __html: setLinksTarget(revision_diff) }} />;
                }

                return <div id='gac-project-data' className = 'gac-project-data' ref = { containerRef } dangerouslySetInnerHTML={{ __html: setLinksTarget(html_content) }} onTouchEnd={ onTextSelectionMobile } onMouseUp = { onTextSelection } onMouseDown = { onTextClearSelection } /> ;
            };

            return <>
                { getRevisionDiff() }
                { (isCurrentRange && !isCommentForm) && <span ref = { iconRef } className = 'gac-show-comment-form' onClick = { onCommentFormShow }/> }
            </>;
        };
        const getHint = () => {
            if ( !isEmptyComments ) return null;

            return <div ref = { hintRef } className = 'gac-empty-comments-hint'>
                { isClientFn(user_role) ? <p>Highlight text and click the icon to add a comment</p> : <><p>Step 1: Highlight text and click the icon to add a comment.</p><p>Step 2: Click 'Submit request' at the bottom of the page.</p></> }
            </div> ;
        };
        const getContentMeta = () => {
            return <div className = 'gac-project-content-meta'>
                { actual_word_count && word_count ? <div>{`Word count: ${actual_word_count}/${word_count}`}</div> : null }
                { getCopyScapeHtml(copyscape_score, copyscape_url) }
                { getAiScoreHtml(revision_id, ai_score, plag_score) }
            </div> ;
        };

        return <div className = 'gac-project-content-wrap'>
            { getRevisionsBtns() }
            { isFakeRevision && includes(status, 'revision|editing')
                ? <EmptyStatev2 status = { isClientFn(user_role) || isSelfClientFn(user_role) ? 'writing' : status } type = 'type-1'/>
                : <>
                    { getShowChangesBtn() }
                    <div ref = { wrapRef }>
                        <div className = 'gac-project-data-wrap'>
                            { getHtmlContent() }
                            { getComments() }
                            { getHint() }
                        </div>
                        { getContentMeta() }
                    </div>
                </> }
        </div> ;
    };
    const getBtns = () => {
        let isMobileRange = !!mobileRange;
        const isKeywords = job_type === 'keywords';
        let publications = [];
        const account = getAccount(accountId,accounts);
        if ( !isEmpty(account) ) {
            publications = account.publications.filter(o => o.type_code !== 'delegate');
        }
        const isHiddenBtns = isKeywords && status === 'approved' && isEmpty(publications);
        const dueIn = deadline ? getDuration(moment.utc(deadline).diff(moment().utc())).duration : '';

        if ( isMobileRange && !isCommentForm ) {
            return <div className="gac-project-btns gac-content-page-btns">
                <div className="gac-btn gac-btn-s" onClick = { onShowMobileCommentForm }>Add comment</div>
            </div> ;
        }

        if ( !isCurrentRevision || !includes(status, 'approval/approved/revision') ) return null;

        const getBtnsContent = () => {
            if ( editing && !isFakeUser ) {
                return <div className="gac-btn gac-btn-s" onClick = { onProjectSaveChanges }>Close editor</div>;
            }

            const getContentData = () => {
                if ( isClientFn(user_role) || !isAuth ) {
                    return status === 'approval' ? !!client_reviewed ? <div style = {{ marginBottom: 16 }} className="gac-reviewed-text">Email sent: project marked as reviewed</div> : <div className='gac-btn gac-btn-s' onClick = { onProjectReview }>Mark as reviewed</div> : null
                }

                return <>
                    { status === 'approved' && getPublishBtn() }
                    { status === 'approval' && <div className="gac-approve-project-wrap"><div className={isSubmitRequest ? 'gac-btn-v3' : 'gac-btn gac-btn-s'} style={{ margin: '0 16px 8px 0' }} onClick = { onProjectApprove }> Approve project</div><div className="gac-auto-approval"><i/>Auto-approval in { dueIn }</div> </div> }
                    { status === 'approval' && !isKeywords && <div className = { isSubmitRequest ? 'gac-btn gac-btn-s' : 'gac-btn-v3' } onClick = { onRequestRevision }>{ isSubmitRequest ? null : <i className='gac-svg'><Reload/></i> }<span>{isSubmitRequest ? 'Submit request' : 'Request revision'}</span></div> }
                    { 'approval/revision'.includes(status) && <Link className="gac-btn-v3" to={`/project/${project_id}/sharing`}><i className="gac-svg"><Share/></i><span>Share project</span></Link>}
                    {'approval/approved'.includes(status) && !isKeywords && <div onClick = { onProjectEdit } className="gac-btn-v3"><i className="gac-svg"><Pencil/></i><span>Edit</span></div>}
                    {'approval/approved/revision'.includes(status) && !isKeywords &&
                        <div className="gac-btn-with-list" ref = { listWrapRef }>
                            <div className='gac-btn-v3' onClick = { toggleDownloadList }><i className="gac-svg"><Download/></i><span>Download</span><i className="gac-arrow"><Arrow/></i></div>
                            { isDownloadList && <ul ref = { listRef }>
                                <li data-format='pdf' onClick = { onContentDownload }><span>PDF</span></li>
                                <li data-format='html' onClick = { onContentDownload }><span>HTML</span></li>
                                <li data-format='word' onClick = { onContentDownload }><span>DOC</span></li>
                            </ul> }
                        </div> }
                </> ;
            };

            return getContentData();
        };

        return <div ref = { btnsRef } style = {{display: isHiddenBtns ? 'none' : 'flex'}} className='gac-project-btns gac-content-page-btns'>
            { getBtnsContent() }
        </div> ;
    };
    const getMobileComments = () => {
        const getMobileCommentForm = () => {
            if ( !isMobile || !isCommentForm ) return null;

            return <div className='gac-mobile-comments'>
                <div className= { `gac-comment-area ${isCommentFormError ? 'gac-invalid' : ''}` }>
                    { getTextareaAutosize({ autoFocus: true }, commentText) }
                </div>
                <div className = 'gac-comment-actions'>
                    <span onClick = { onCommentSubmit } className = 'gac-comment'><i/>Comment</span>
                    <span onClick = { onCommentFormClose } className = 'gac-cancel'><i/>Cancel</span>
                </div>
            </div>
        };
        const getMobileComment = () => {
            let mobileComment = null;
            let mobileCommentsCount = 0;
            let mobileCommentIndex = null;
            let isMobileCommentEditing = false;


            if ( !isNil(activeComment) && isMobile ) {
                mobileCommentsCount = getCommentsData().length;
                mobileCommentIndex = indexOf(activeComment, commentsOrder) + 1;
                mobileComment = getCommentsData().filter(o => `${o.id}` === `${activeComment}` )[0];
                if ( !isNil(mobileComment) ) {
                    isMobileCommentEditing = `${editingComment}` === `${mobileComment.id}`;
                }
            }

            if ( isNil(mobileComment) || !isMobile || isCommentForm ) return null;

            let text = entities.decode(mobileComment.comment);
            const matchedUrls = text.match(regexUrls);

            if (matchedUrls) {
                text = text.replace(regexUrls, (url) => {
                    return `<a href="${url}" target="_blank">${url}</a>`;
                });
            }

            return <div ref = { mobileCommentRef } className = 'gac-mobile-comments'>
                <div className = 'gac-mobile-comments-head'>
                    <div className = 'gac-comments-count'>{ `${ mobileCommentIndex } of ${ mobileCommentsCount }` }</div>
                    { mobileCommentsCount > 1
                        ? <div className = 'gac-mobile-comments-btns'>
                            <span data-action = 'prev' onClick = { slideMobileComment }/>
                            <span data-action = 'next' onClick = { slideMobileComment }/>
                        </div>
                        : null }
                </div>
                { isMobileCommentEditing
                    ? <div className = 'gac-mobile-comment-wrap'> <textarea ref = { textareaRef } value = { commentText } onKeyDown= { onCommentTextareaKeyDown } onChange = { onCommentTextareaChange }/></div>
                    : <div className = 'gac-mobile-comment' dangerouslySetInnerHTML={{ __html: text }}/> }
                { ( (!isClientFn(user_role) && !isFakeUser ) || `${mobileComment.user.unique_id}` === `${user_id}` || ( isFakeUser && `${mobileComment.user.unique_id}` === logged_user.user_uid ))
                    && !editing && isCurrentRevision && status === 'approval'
                    ? <div className = 'gac-comment-actions'>
                        { isMobileCommentEditing
                            ? <>
                                <span data-id = { mobileComment.id } className = 'gac-comment' onClick = { onCommentUpdate }><i/>Update</span>
                                <span className = 'gac-cancel' onClick = { onCommentFormClose }><i/>Cancel</span>
                            </>
                            : <>
                                <span data-id = { mobileComment.id } onClick = { onCommentEdit } className = 'gac-comment'><i/>Edit</span>
                                <span data-id = { mobileComment.id } onClick = { onCommentDelete } className = 'gac-cancel'><i/>Delete</span>
                            </> }
                    </div>
                    : null }
            </div> ;
        };

        return <>
            { getMobileComment() }
            { getMobileCommentForm() }
        </>;
    };

    return <div className='gac-project-content'>
        { !isMobile && <div className="gac-project-title">{ topic }</div> }
        { getContentWrap() }
        { getBtns() }
        { getMobileComments() }
    </div>;
};