// Core
import { call, put, select } from 'redux-saga/effects';

// Instruments
import { api, getKeys, getAccounts, authStr } from '../../../../helpers/api';
import { setAccountCookies } from '../../../../helpers/helpers';
import { paymentsActions } from '../../actions';
import { authActions } from '../../../auth/actions';
import { uiActions } from '../../../ui/actions';
import { userActions } from '../../../user/actions';
import { isNil } from 'ramda';

export function* callFetchBraintreeTokenWorker ({ payload: { type, action, account_uid }}) {
    try {
        yield put(uiActions.enableFetching());
        const { privateKey, publicKey, accountId } = yield select(getKeys);
        const url1 = !isNil(action) && !isNil(action.subId) ? `?subscription_id=${action.subId}&type=subscription` : '';
        const url2 = !isNil(action) && !isNil(action.currency) ? `?currency=${action.currency}&type=subscription` : '';
        const response = yield call(fetch, `${api}/${publicKey}/v1/braintree/token/${ isNil(account_uid) ? accountId : account_uid}${ url1 }${ url2 }`,{
            method: 'GET',
            headers: {
                Authorization: `${authStr} ${privateKey}`
            }
        });
        const { response: { token, account }, error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }
        if ( type === 'bundles' ) {
            yield put(paymentsActions.setPaymentsState('bundlesBraintree', { token }));
        } else if ( type === 'subscriptions' ) {
            yield put(paymentsActions.setPaymentsState('subBraintree', { token }));
            const accounts = yield select(getAccounts);
            if ( !isNil(account) ) {
                if ( !accounts.some(o => o.account_id === account.account_uid ) ) {
                    yield put(userActions.createAccountSuccess(account));
                }
                yield put(authActions.setAccount(account.account_uid));
                setAccountCookies(account.account_uid);
            }
        } else if ( type === 'card' ) {
            yield put(paymentsActions.setPaymentsState('cardBraintree', { token }));
        } else {
            yield put(paymentsActions.setPaymentsState('braintree', { token }));
        }
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
        yield put(uiActions.setUiState('notification', {
            msg: error.message,
            type: 'error',
            fn: null,
        }));
    }
}
