// Instrument
import { types } from './types';
import update from 'immutability-helper';

const initialState = {
    activeModal:                    null,
    isSupportMessageSuccess:        false,
    isSupportMessageFail:           false,
    createdProjectId:               null,
    activeSub:                      null,
    downloadFileUrl:                null,
    fullImageUrl:                   null,
    activeProject:                  null,
    newProjectOrderOn:              null,
    inviteTalentsObj:               null,
    modalTalentsData:               null,
    isRequestRevisionModalDisabled: false,
};

export const modalsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.SET_MODAL:
            return update(state, {activeModal: {$set: payload}});

        case types.SET_MODAL_STATE:
            return update(state, {
                [payload.prop]: {$set: payload.data}
            });

        default:
            return state;
    }
};
