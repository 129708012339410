// Core
import { asyncTypes } from './asyncTypes';

export const listsActionsAsync = Object.freeze({
    getExistingIdeasAsync: () => ({
        type: asyncTypes.GET_EXISTING_IDEAS_ASYNC,
    }),
    getAccountsWithoutSubsAsync: (subscription_uid) => ({
        type: asyncTypes.GET_ACCOUNTS_WITHOUT_SUBS_ASYNC,
        payload: {
            subscription_uid
        },
    }),
    getAccountsNoWritingSubsAsync: (account_uid, currency, talent, platform) => ({
        type: asyncTypes.GET_ACCOUNTS_NO_WRITING_SUBS_ASYNC,
        payload: {
            account_uid,
            currency,
            talent,
            platform
        },
    }),
    getQueuedProjectsAsync: () => ({
        type: asyncTypes.GET_QUEUED_PROJECTS_ASYNC,
    }),
    getStaticDataAsync: () => ({
        type: asyncTypes.GET_STATIC_DATA_ASYNC,
    }),
    getMessagesAsync: (page) => ({
        type: asyncTypes.GET_MESSAGES_ASYNC,
        payload: {
            page,
        },
    }),
    getTransactionsAsync: (id, range) => ({
        type: asyncTypes.GET_TRANSACTIONS_ASYNC,
        payload: {
            id,
            range
        },
    }),
    getImagesAsync: (query) => ({
        type:    asyncTypes.GET_IMAGES_ASYNC,
        payload: query,
    }),
    getImageCategoriesAsync: () => ({
        type: asyncTypes.GET_IMAGE_CATEGORIES_ASYNC,
    }),
    getImagesCarouselAsync: () => ({
        type: asyncTypes.GET_IMAGES_CAROUSEL_ASYNC,
    }),
    getProjectsAsync: (view_by, date_from, date_to, type, status, last100) => ({
        type: asyncTypes.GET_PROJECTS_ASYNC,
        payload: {
            view_by,
            date_from,
            date_to,
            type,
            status,
            last100
        }
    }),
    getUnreadMessagesAsync: () => ({
        type: asyncTypes.GET_UNREAD_MESSAGES_ASYNC,
    }),
    getOrderListAsync: (navigate) => ({
        type: asyncTypes.GET_ORDER_LIST_ASYNC,
        payload: {
            navigate
        },
    }),
});
