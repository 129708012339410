// Components
import { DesignOneOff } from "./DesignOneOff";
import { DesignSubscription } from "./DesignSubscription";

// Hooks
import { useOrder } from "../../../hooks/useOrder";

export const Design = ({ fields }) => {
    /* Hooks */
    const { job_pay_type } = useOrder();

    /* Html */
    const getContent = () => {
        const config = {
            'one-off': <DesignOneOff fields={fields} />,
            'subscription': <DesignSubscription fields={fields} />
        }

        return config[job_pay_type];
    };

    return <>
        {getContent()}
    </>;
};
