// Core
import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import { bool, func, object, number, string } from 'prop-types';

// Instruments
import { bytesToSize } from '../../../helpers/helpers';
import { isNil } from "ramda";

export default class SingleImage extends PureComponent {
    static propTypes = {
        image:     object.isRequired,
        isMobile:  bool.isRequired,
        showModal: func.isRequired,
        width:     number.isRequired,
        height:    number.isRequired,
        top:       number.isRequired,
        left:      number.isRequired,
        platform:  string.isRequired,
    };

    state = {
        isLoaded:       false,
        listLeft:       0,
        listTop:        0,
        isBtns:         false,
        isDownloadList: false,
        isAddToProject: false,
    };

    componentWillUnmount() {
        document.removeEventListener('click', this._onOutsideListClick);
        document.removeEventListener('touchstart', this._onOutsideListClick);
    }

    _onImageClick = () => {
        const { image, showModal } = this.props;

        showModal({ currentTarget: { dataset: { url: image.imageURL || image.fullHDURL || image.largeImageURL || image.webformatURL }}});
    };

    _onImageLoad = () => {
        this.setState({
            isLoaded: true,
        });
    };

    _onImageError = () => {
        this.image.classList.add('gac-error');
    };

    _onMouseEnter = () => {
        const { isMobile } = this.props;
        if(!isMobile) {
            this.setState({
                isBtns: true,
            });
        }
    };

    _onMouseLeave = () => {
        const { isDownloadList } = this.state;
        const { isMobile } = this.props;
        if(!isMobile && !isDownloadList) {
            this.setState({
                isBtns: false,
            });
        }
    };

    _onOutsideListClick = (e) => {
        let target = e.target;

        do {
            if (target === this.btns) {
                return;
            }
            target = target.parentNode;
        } while (target);

        this.setState({
            isBtns: false,
            isDownloadList: false,
        });
    };

    _toggleList = () => {
        const { isDownloadList } = this.state;
        const { image } = this.props;
        const imageW = image.imageWidth;
        const w = window.innerWidth;
        const h = window.innerHeight;
        const btn = ReactDOM.findDOMNode(this.downloadBtn).getBoundingClientRect();
        let left = 0;
        let top = btn.width + 9;

        if (w < btn.left + 250) {
            left = btn.width - 250;
        }
        if (imageW > 1920) {
            if (h < btn.top + btn.width + 9 + 212) {
                top = -221;
            }
        } else {
            if (h < btn.top + btn.width + 9 + 175) {
                top = -184;
            }
        }

        this.setState({
            listLeft: left,
            listTop:  top,
            isDownloadList: !isDownloadList,
        }, () => {
            if ( isDownloadList ) {
                document.removeEventListener('click', this._onOutsideListClick);
                document.removeEventListener('touchstart', this._onOutsideListClick);
            } else {
                document.addEventListener('click', this._onOutsideListClick);
                document.addEventListener('touchstart', this._onOutsideListClick);
            }
        });
    };

    _onLoadImageLinkClick = () => {
        this.setState({
            isBtns: false,
            isDownloadList: false,
        });
    };

    render () {
        const { image, isMobile, width, height, top, left, platform } = this.props;
        const { isLoaded, isDownloadList, isBtns, listLeft, listTop } = this.state;

        const h = image.imageHeight;
        const w = image.imageWidth;
        const ratio = w/h;
        const h1920 = Math.round(1920/ratio);
        const h1280 = Math.round(1280/ratio);
        const h640 = Math.round(640/ratio);
        const w1920 = Math.round(1920*ratio);
        const w1280 = Math.round(1280*ratio);
        const w640 = Math.round(640*ratio);
        const size = image.imageSize;
        const size1920 = size/(w/1920);
        const size1280 = size/(w/1280);
        const size640 = size/(w/640);
        let url = image.imageURL;
        const id = image.id;
        const url1920 = image.fullHDURL;
        const url1280 = image.largeImageURL;
        const url640 = image.webformatURL;
        const isPixabay = platform === 'pixabay';
        const urlEnd = isPixabay ? '?attachment' : '';
        if ( platform === 'pexels' ) {
            url = `https://www.pexels.com/photo/${id}/download`;
        } else if ( platform === 'unsplash' ) {
            url = `https://unsplash.com/photos/${id}/download?force=true`
        }

        return (
            <div
                ref = { ref => this.image = ref }
                className = { `gac-free-images__item ${ isLoaded ? 'gac-loaded' : '' }` }
                style = {{
                    top,
                    left,
                    width,
                    height,
                }}
                onMouseOver = {  this._onMouseEnter }
                onMouseLeave = { this._onMouseLeave }>
                <img
                    alt = ''
                    height = { height }
                    width = { width }
                    src = { url640 }
                    onError = { this._onImageError }
                    onLoad = { this._onImageLoad }
                    onClick = { this._onImageClick }/>
                <CSSTransition
                    in = { isMobile || isBtns }
                    timeout = { 200 }
                    unmountOnExit
                    classNames = 'gac-opacity-200'>
                    <div ref = { ref => this.btns = ref } className = 'gac-free-images__item-btns'>
                        <div className = 'gac-free-images-btns-wrap'>
                            <span
                                ref = { ref => this.downloadBtn = ref }
                                className = 'gac-download-btn gac-download-btn-s'
                                onClick = { this._toggleList }>
                                    <i/>
                            </span>
                            <CSSTransition
                                in = { isDownloadList }
                                timeout = { 200 }
                                unmountOnExit
                                classNames = 'gac-opacity-200'>
                                <div
                                    className = 'gac-choose-size-menu'
                                    style = {{ top:  listTop, left: listLeft }}>
                                    <p>Choose download size:</p>
                                    <ul className={platform}>
                                        { isPixabay && <>
                                            <li>
                                                <a href = { `${url640}${urlEnd}` } className = 'gac-choose-size-menu__item' onClick = { this._onLoadImageLinkClick }>
                                                    <span>{ w >= h ? `640x${h640}` : `${w640}x640` }</span>
                                                    { isPixabay && <>
                                                        <span>{ url640.slice(url640.length - 3, url640.length) }</span>
                                                        <span>{ bytesToSize(size640) }</span>
                                                    </> }
                                                </a>
                                            </li>
                                            <li>
                                                <a href = { `${url1280}${urlEnd}` } className = 'gac-choose-size-menu__item' onClick = { this._onLoadImageLinkClick }>
                                                    <span>{ w >= h ? `1280x${h1280}` : `${w1280}x1280` }</span>
                                                    { isPixabay && <>
                                                        <span>{ url1280.slice(url1280.length - 3, url1280.length) }</span>
                                                        <span>{ bytesToSize(size1280) }</span>
                                                    </> }
                                                </a>
                                            </li>
                                            { w > 1920 && !isNil(url1920)
                                            && <li>
                                                <a href = { `${url1920}${urlEnd}` } className = 'gac-choose-size-menu__item' onClick = { this._onLoadImageLinkClick }>
                                                    <span>{ w >= h ? `1920x${h1920}` : `${w1920}x1920` }</span>
                                                    { isPixabay && <>
                                                        <span>{ url1920.slice(url1920.length - 3, url1920.length) }</span>
                                                        <span>{ bytesToSize(size1920) }</span>
                                                    </> }
                                                </a>
                                            </li> }
                                        </> }
                                        { !isNil(url) &&
                                                <li>
                                                    <a href = { `${url}${urlEnd}` } className = 'gac-choose-size-menu__item' onClick = { this._onLoadImageLinkClick }>
                                                        <span>{ `${w}x${h}` }</span>
                                                        { isPixabay && <>
                                                            <span>{ url.slice(url.length - 3, url.length) }</span>
                                                            <span>{ bytesToSize(size) }</span>
                                                        </> }
                                                    </a>
                                                </li> }
                                    </ul>
                                </div>
                            </CSSTransition>
                        </div>
                    </div>
                </CSSTransition>
            </div>
        );
    }
};
