// Hooks
import { useModals } from "../../../hooks/useModals";
import { useUi } from "../../../hooks/useUi";

export const EmptyState = ({ page, text }) => {
    /* Hooks */
    const { setModal } = useModals();
    const { isMobile } = useUi();

    /* Actions */
    const showModalFeedback = () => {
        setModal('messageSupport');
    };

    return(
        <div className = 'gac-empty-state'>
            <div className = 'gac-empty-state-img'>
                { 'draft,current,completed,transactions'.includes(page)
                    ? <svg xmlns="http://www.w3.org/2000/svg" width="70" height="84"><g transform="translate(.275)"><linearGradient id="a" gradientUnits="userSpaceOnUse" x1="9.96" y1="-15.595" x2="9.328" y2="-16.106" gradientTransform="matrix(98.2389 0 0 117.1429 -917.76 1900.283)"><stop offset="0" stopColor="#4cdcc8"/><stop offset="1" stopColor="#54e5a0"/></linearGradient><path d="M67.1 17.3h-7.8c-3.2 0-5.8-2.6-5.8-5.8V3.2L51.1.8v10.7c0 4.5 3.6 8.2 8.1 8.2h10.2l-2.3-2.4zm0 0h-7.8c-3.2 0-5.8-2.6-5.8-5.8V3.2L51.1.8v10.7c0 4.5 3.6 8.2 8.1 8.2h10.2l-2.3-2.4zM18 16.8c0 .6-.5 1.1-1.1 1.1-.6 0-1.1-.5-1.1-1.1v-3.7c0-2.9 2.4-5.3 5.3-5.3h3.6c.6 0 1.2.5 1.2 1.1 0 .6-.5 1.2-1.2 1.2H21c-1.6 0-2.9 1.3-2.9 2.9l-.1 3.8zm41.7 43c0-.6.5-1.2 1.2-1.2s1.2.5 1.2 1.2v3.7c0 2.9-2.4 5.3-5.3 5.3h-3.6c-.6 0-1.2-.5-1.2-1.2s.5-1.2 1.2-1.2h3.6c1.6 0 2.9-1.3 2.9-2.9v-3.7zm-49.5 8.6c0 3.2 2.6 5.8 5.8 5.8h45.7c3.2 0 5.8-2.6 5.8-5.8V21.7c0-1.5-.6-3-1.6-4.1L52.5 4.1c-1-1.1-2.5-1.7-4.1-1.7H16c-3.2 0-5.8 2.6-5.8 5.8v60.2zm-2.3 0V8.2C7.9 3.7 11.5 0 16 0h32.4c2.2 0 4.3.9 5.8 2.4l13.2 13.4c1.5 1.5 2.4 3.6 2.4 5.7v46.8c0 4.5-3.6 8.2-8.1 8.2H15.9c-4.4.2-8-3.5-8-8.1zm31.9 13.2L42 84H7.9c-4.5 0-8.1-3.7-8.1-8.2V36.7l2.4 2.7v36.5c0 3.2 2.6 5.8 5.8 5.8-.1-.1 31.8-.1 31.8-.1z" fill="url(#a)"/></g></svg>
                    : page === 'images'
                        ? <svg xmlns="http://www.w3.org/2000/svg" width="109" height="84"><g><linearGradient id="a" gradientUnits="userSpaceOnUse" x1="41.438" y1="-4.398" x2="40.659" y2="-5.027" gradientTransform="matrix(123.2967 0 0 95.2967 -5013.813 491.836)"><stop offset="0" stopColor="#4cdcc8"/><stop offset="1" stopColor="#54e5a0"/></linearGradient><path d="M57.2 47.3l4.2 4.2c.5.5.5 1.5 0 2s-1.5.5-2 0l-4.2-4.2-4.4 4.4c-.5.5-1.5.5-2 0s-.5-1.5 0-2l4.4-4.4-4.4-4.3c-.5-.5-.5-1.5 0-2s1.5-.5 2 0l4.3 4.3 4.1-4.1c.5-.5 1.5-.5 2 0s.5 1.5 0 2l-4 4.1zM31.6 10.5C32.3 4.6 37.4 0 43.5 0h22.6C72.2 0 77.2 4.6 78 10.5h17.8c7.2 0 13.1 5.5 13.1 12.3v49c0 6.8-5.9 12.3-13.1 12.3H13.2C5.9 83.9.1 78.5.1 71.7c0-.8.6-1.4 1.4-1.4s1.4.6 1.4 1.4c0 5.1 4.5 9.3 10.2 9.3h82.7c5.7 0 10.2-4.2 10.2-9.3v-49c0-5.1-4.5-9.3-10.2-9.3H13.2C7.5 13.3 3 17.5 3 22.7v33.2c0 .8-.6 1.4-1.4 1.4S.2 56.7.2 55.9V22.7c0-6.8 5.9-12.3 13.1-12.3l18.3.1zm2.9 0H75c-.7-4.3-4.4-7.6-8.9-7.6H43.5c-4.5-.1-8.3 3.3-9 7.6zM2.9 65.3H0h2.9c0-.1 0 0 0 0H0v-.1c0-.8.7-1.4 1.4-1.4.9 0 1.5.6 1.5 1.5zm0 0c0 .8-.6 1.4-1.4 1.4S.1 66.1.1 65.3c0-.1 0-.1 2.8 0zm51.9 7.6c-14.1 0-25.6-11.5-25.6-25.6s11.5-25.6 25.6-25.6c4.5 0 8.8 1.1 12.6 3.3 1.2.7 2.4 1.5 3.4 2.4 1.1.9 2.1 1.9 3 2.9C78 35 80.3 41 80.3 47.4c.1 13.9-11.3 25.5-25.5 25.5zm0-2.9c12.5 0 22.7-10.2 22.7-22.7 0-5.6-2.1-11-5.7-15.2-.8-1-1.8-1.8-2.6-2.6-1-.8-1.9-1.5-3.1-2.1-3.4-1.9-7.2-2.9-11.2-2.9-12.5 0-22.7 10.2-22.7 22.7S42.2 70 54.8 70zM11.7 33.5c0 .8-.6 1.4-1.4 1.4s-1.4-.6-1.4-1.4v-.1c0-7.8 6.4-14.3 14.3-14.3.8 0 1.4.6 1.4 1.4 0 .8-.6 1.4-1.4 1.4-6.4.2-11.5 5.2-11.5 11.6zm85.6 27.4c0-.8.6-1.4 1.4-1.4s1.4.6 1.4 1.4v.1c0 7.8-6.4 14.3-14.3 14.3-.8 0-1.4-.6-1.4-1.4 0-.8.6-1.4 1.4-1.4 6.4-.2 11.5-5.2 11.5-11.6z" fill="url(#a)"/></g></svg>
                        : null }
            </div>
            <div className = 'gac-empty-state-text'>
                { page === 'images'
                    ? <div>
                        <p>Sorry, we couldn't find any matches</p>
                        <ul>
                            <li><i className = "gac-empty-state-icon-1"/>Check the spelling</li>
                            <li><i className = "gac-empty-state-icon-2"/>Try using a simpler search</li>
                            <li><i className = "gac-empty-state-icon-3"/>Still having problems? { isMobile ? 'Contact us' : <span onClick = { showModalFeedback }>Contact us</span> }</li>
                        </ul>
                    </div>
                    : text }
            </div>
        </div>
    );
};