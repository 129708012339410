// Core
import { useEffect, useState } from "react";

// Components
import { Link } from 'react-router-dom';
import Doc from '../../icons/Doc';
import Doc2 from '../../icons/Doc2';

// Hooks
import { useAuth } from "../../../hooks/useAuth";
import { useProject } from "../../../hooks/useProject";
import { useLists } from "../../../hooks/useLists";
import { useUser } from "../../../hooks/useUser";
import { useUi } from "../../../hooks/useUi";

// Instruments
import { getAppTitle, getRolesDesc, getAccount } from "../../../helpers/helpers";
import { isEmpty, includes, isNil, reverse } from 'ramda';
import moment from 'moment/moment';

export const Sharing = ({ company }) => {
    /* State */
    const [input, setInput] = useState('');

    /* Hooks */
    const { keys: { accountId }} = useAuth();
    const { details: { project_id, topic, share_code }, client_logs, fetchProjectLogsAsync } = useProject();
    const { users } = useLists();
    const { details: { accounts = [] }, company: { branding: { white_label, domain_name, host } }, fetchUsersAsync } = useUser();
    const { isMobile } = useUi();

    /* Actions */
    const onCopyToClipboard = ({ currentTarget: { dataset: { id }}}) => {
        const el = document.getElementById(id);
        el.select();
        el.setSelectionRange(0, 99999); // For mobile devices
        navigator.clipboard.writeText(el.value);
        setInput(id);
    };

    /* useEffect */
    useEffect(() => {
        document.title = getAppTitle('Project sharing', company);
        fetchUsersAsync();
        fetchProjectLogsAsync({ project_id, action: 'client_logs' });
    }, []);

    /* Html */
    const getUsersData = () => {
        if ( isNil(users) ) return [];

        return users
            .filter(o => includes(o.user_role, 'basicclient/selfclient') && !isNil(o.accounts) && o.accounts.some(o => o.account_id === accountId) )
            .map(o => <div key = {o.user_id} className = 'gac-accounts-item'>
                { o.avatar_url ? <img src = { o.avatar_url } alt = "Avatar"/> : <span className = 'gac-no-logo'><i/></span> }
                <div className = 'gac-item-desc'>
                    <div className = 'gac-item-name'>{ `${o.first_name} ${o.last_name}` }</div>
                </div>
            </div> );
    };
    const getProjectLinkHtml = () => {
        return <>
            <div className="gac-title">Two sharing options:</div>
            <div className='gac-sharing-desc'><i><Doc/></i>Limited access to a <strong>single project</strong> (no login required)</div>
            <div className="gac-sharing-link">
                <label>Project link:</label>
                <div className="gac-sharing-input">
                    <input id='limited-input' value={ `https://${ white_label ? domain_name : 'qwriter.com' }/project/${project_id}/content/${share_code}` } type="text" readOnly/>
                    <span className={ `${input === 'limited-input' ? 'gac-copied' : ''}` } data-id='limited-input' onClick = { onCopyToClipboard }>{ input === 'limited-input' ? 'Copied' : 'Copy' }</span>
                </div>
            </div>
        </>;
    };
    const getProjectLinkFullHtml = () => {
        const usersData = getUsersData();
        return(
            <div className="gac-sharing-link">
                <label>Project link:</label>
                <div className="gac-sharing-input">
                    <input id = 'full-input' value={ `https://${ white_label ? domain_name : 'qwriter.com' }/project/${project_id}/content` } type="text" readOnly/>
                    { isEmpty(usersData)
                        ? <Link className = 'gac-create-client' to = { `/account/${accountId}/client` }>Create a new client</Link>
                        : <span className={ `${input === 'full-input' ? 'gac-copied' : ''}` } data-id='full-input' onClick = { onCopyToClipboard }>{ input === 'full-input' ? 'Copied' : 'Copy' }</span> }
                </div>
            </div>
        );
    };
    const getAccountHtml = () => {
        let account = '';
        if ( !isEmpty(accounts) ) {
            account = getAccount(accountId, accounts).account_name;
        }

        return(
            <div className='gac-sharing-desc'>
                <i><Doc2/></i>
                Access to <strong>all projects</strong> for clients on
                { includes('account', account) ? <strong>{account}</strong> : <><strong>{account}</strong>  account</> }
            </div>
        );
    };
    const getClientsHtml = () => {
        const usersData = getUsersData();
        if ( isEmpty(usersData) ) return null;

        let account = '';
        if ( !isEmpty(accounts) ) {
            account = getAccount(accountId, accounts).account_name;
        }

        return <div className="gac-clients">
            <div className="gac-title">
                Clients on { includes('account', account) ? <strong>{account}</strong> : <><strong>{account}</strong>account</> }
            </div>
            <div className="gac-accounts-container">
                { usersData }
                <Link className = 'gac-accounts-item gac-add-new' to = { `/account/${accountId}/client` }><i/><span>Manage clients</span></Link>
            </div>
        </div>;
    };
    const getActivityHtml = () => {
        if ( isNil(client_logs) ) return null;

        let logsData = reverse(client_logs).map((o, i) => <div key={i} className = 'gac-activity-row'>
            <div className = 'gac-activity-time'>{ moment(o.create_date).format('MMM D, YYYY h:mma') }</div>
            <div className = 'gac-activity-desc'>{ o.description }</div>
        </div> );

        return <div className="gac-activity">
            <div className="gac-title">Client project activity:</div>
            <div className="gac-activity-list">
                { isEmpty(logsData) ? <div className="gac-activity-empty">No activity yet</div> : logsData }
            </div>
        </div>
    };
    const getSharingInfoHtml = () => {
        return <div className='gac-sharing-info'>
            <div className="gac-title">What can my clients see or do?</div>
            <ul>{ getRolesDesc(host) }</ul>
        </div>;
    };

    return(
        <div className = "gac-project-content">
            { !isMobile && <div className="gac-project-title">{ topic }</div> }
            <div className='gac-project-sharing' style={{ paddingTop: isMobile ? 20 : 0 }}>
                <div className="gac-sharing-wrap">
                    <div className='gac-sharing-links'>
                        { getProjectLinkHtml() }
                        { getAccountHtml() }
                        { getProjectLinkFullHtml() }
                        { getClientsHtml() }
                        { getActivityHtml() }
                    </div>
                    { getSharingInfoHtml() }
                </div>
            </div>
        </div>
    );
};