// Core
import { useEffect, useRef } from "react";

// Hooks
import { useProject } from "../../../hooks/useProject";

export const ModalCancelProject = ({ closeModal, setPos }) => {
    /* Ref */
    const modalRef = useRef(null);

    /* Hooks */
    const { details: { project_id }, cancelProjectAsync } = useProject();

    /* Actions */
    const onSubmit = () => {
        cancelProjectAsync(project_id);
    };

    /* Did mount */
    useEffect(() => {
        setPos( modalRef );
    }, []);

    return <div ref = { modalRef } className = 'gac-modal-subscription gac-cancel-project'>
        <span className = 'gac-close-modal' onClick = { closeModal }/>
        <h1>Are you sure you want to cancel the project?</h1>
        <div className="gac-btn-wrap">
            <div className='gac-btn gac-btn-s' onClick = { onSubmit }>Confirm</div>
        </div>
    </div> ;
};