// Core
import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';
import cx from 'classnames';
import { array, bool, func, string, oneOfType } from "prop-types";
import { isEmpty, isNil, includes } from "ramda";

export default class Select extends Component {

    static propTypes = {
        items: array,
        isGroups: bool,
        isSearch: bool,
        isMultiple: bool,
        invalid: bool,
        parameter: string,
        direction: string,
        value: oneOfType([
            string.isRequired,
            array.isRequired,
        ]),
        title: oneOfType([
            string.isRequired,
            array.isRequired,
        ]),
        onChange: func.isRequired,
    };

    state = {
        isOpened: false,
        searchKey: '',
    };

    componentWillUnmount() {
        document.removeEventListener('click', this._onOutsideListClick);
        document.removeEventListener('touchstart', this._onOutsideListClick);
    }

    _toggleList = () => {
        const { isOpened } = this.state;

        this.setState({
            isOpened: !isOpened,
        }, () => {
            if (isOpened) {
                document.removeEventListener('click', this._onOutsideListClick);
                document.removeEventListener('touchstart', this._onOutsideListClick);
            } else {
                document.addEventListener('click', this._onOutsideListClick);
                document.addEventListener('touchstart', this._onOutsideListClick);
            }
        });
    };

    _onOutsideListClick = (e) => {
        let target = e.target;

        do {
            if (target === this.select) {
                return;
            }
            target = target.parentNode;
        } while (target);

        this.setState({
            isOpened: false,
        });
    };

    _onSearchInputChange = ({ currentTarget: { value } }) => {
        this.setState({
            searchKey: value.replace(/ +(?= )/g, '')
        });
    };

    _onClick = ({ currentTarget: { dataset: { value, title } } }) => {
        const { onChange, parameter, id, isMultiple } = this.props;
        if (!isMultiple) {
            this.setState({
                isOpened: false,
            });
        }
        onChange(parameter, value, id, title);
    };

    _onWrapClick = (e) => {
        const { onClick } = this.props;

        if (onClick) {
            onClick(e);
        }
    };

    _getData = () => {
        const { searchKey } = this.state;
        const { items, isMultiple = false, isGroups = false, value, service_level } = this.props;
        let data = [];

        if (isGroups) {
            data = items.map(o => {
                let formats = o.formats;
                formats = formats.filter(o => !!o.active);
                if (!isEmpty(searchKey)) {
                    formats = formats.filter(o => {
                        const name = o.name ? o.name.toLowerCase().trim() : '';
                        return name.includes(searchKey.toLowerCase().trim());
                    });
                }

                const options = formats.map(a => {

                    return (
                        <li key={a.id}>
                            <span
                                className='gac-select-link'
                                data-value={a.id}
                                onClick={this._onClick}>
                                {a.name}{isNil(service_level) ? null : Number(a.service_level) > Number(service_level) ? ' (available in Double)' : ''}
                                {isMultiple
                                    ? value.join('').includes(a.id)
                                        ? <i />
                                        : <span />
                                    : a.id === value
                                        ? <i />
                                        : null}
                            </span>
                        </li>
                    );
                });

                return <li style={{ display: isEmpty(options) ? 'none' : null }} key={o.id}>
                    <span className='gac-select-link gac-group-name'>{o.name}</span>
                    {!isEmpty(options) && <ul>
                        {options}
                    </ul>}
                </li>;
            });
        } else {
            data = items.filter(o => {
                const title = o.title ? o.title.toLowerCase().trim() : '';
                return title.includes(searchKey.toLowerCase().trim());
            }).map((item, i) => {
                const isActive = isMultiple ? includes(item.value.toLowerCase(), value.map(el => `${el}`.toLowerCase())) : item.value.toLowerCase() === value.toLowerCase();
                return <li key={i}>
                    <span className={`gac-select-link ${isActive ? 'gac-active' : ''} ${isMultiple ? 'gac-multiple' : ''}`}
                        data-value={item.value.toLowerCase()} data-title={item.title} onClick={this._onClick}>
                        {item.title}
                    </span>
                </li>;
            });
        }

        return data;
    };

    render() {
        const { isOpened, searchKey } = this.state;
        const { direction, disabled, isMultiple = false, parameter, title, invalid, isSearch, className } = this.props;
        const data = this._getData();

        const styles = cx('gac-select', {
            'gac-clicked': isOpened,
            'gac-invalid': invalid,
            [className]: className
        });

        return (
            <div style={{ cursor: disabled ? 'default' : null }} onClick={disabled ? () => { } : this._onWrapClick} id={parameter} className={styles} ref={ref => this.select = ref}>
                <div className='gac-select-value' onClick={disabled ? () => { } : this._toggleList}>
                    {isMultiple
                        ? title.join(', ')
                        : title}
                </div>
                <CSSTransition
                    in={isOpened}
                    timeout={200}
                    unmountOnExit
                    classNames='gac-opacity-200'>
                    <ul
                        ref={ref => this.list = ref}
                        style={{
                            top: direction ? 'auto' : 55,
                            bottom: direction ? 55 : 'auto',
                            boxShadow: `0 ${direction ? '-' : ''}1px 3px 0 rgba(159, 177, 188, 0.4)`,
                        }}
                        className='gac-select-menu'>
                        {!isNil(isSearch) && isSearch && <input type='text' value={searchKey} placeholder='Search…' onChange={this._onSearchInputChange} />}
                        {data}
                    </ul>
                </CSSTransition>
            </div>
        );
    }
}
