// Core
import { useEffect } from "react";

// Components

// Hooks
import { useProject } from "../../../hooks/useProject";

// Instruments
import { isNil, isEmpty } from "ramda";
import {setLinksTarget} from "../../../helpers/helpers";

export const ModalAiResults = ({ closeModal }) => {
    /* Hooks */
    const { ai_results, setProjectState } = useProject();

    useEffect(() => {
        return () => {
            setProjectState('ai_results', null);
        };
    }, []);

    if ( isNil(ai_results) ) return null;
    const { ai_score, ai_blocks, plag_score, plag_blocks /*plag_sources*/ } = ai_results;

    /* Html */
    const getTableHead = (blockLabel) => {
        return <div className="gac-ai-results-modal__head">
            <div data-name="block">{blockLabel}</div>
            <div data-name="score">Score</div>
        </div> ;
    };
    const getAiHtml = () => {
        const getBlocks = () => {
            if ( isEmpty(ai_blocks) ) {
                return <div className='gac-ai-results-modal__table-item gac-empty'>AI detection score: { ai_score }%, no matches</div>;
            }
            return ai_blocks.map(({ text, ai_score: score }) => {
                return <div className='gac-ai-results-modal__table-item' style={{ marginBottom: 10 }}>
                    <div data-name="block" className = 'gac-ai-data' dangerouslySetInnerHTML={{ __html: setLinksTarget(text) }}/>
                    <div data-name="score">{ score }%</div>
                </div>;
            });
        };

        return <>
            <div className = 'gac-ai-results-modal__title'>AI detection score: <span>{ ai_score }%</span></div>
            <div className="gac-ai-results-modal__table">
                { getTableHead('Block') }
                { getBlocks() }
            </div>
        </>;
    };
    const getPlagiarismHtml = () => {
        const getBlocks = () => {
            if ( isEmpty(plag_blocks) ) {
                return <div className='gac-ai-results-modal__table-item gac-empty'>Plagiarism score: { plag_score }%, no matches</div>;
            }

            return plag_blocks.map(({ phrase, sources }) => {
                const plagData = sources.map(({ match_score, match_text, match_website }) => {
                    return <>
                        <div className='gac-ai-results-modal__table-item'>
                            <div data-name="block" className = 'gac-ai-data' dangerouslySetInnerHTML={{ __html: setLinksTarget(match_text) }}/>
                            <div data-name="score">{ match_score }%</div>
                        </div>
                        <div className="gac-ai-results-modal__website"><strong>Matched on:</strong> <a href = { match_website } target='_blank' rel = 'noopener noreferrer'>{ match_website }</a></div>
                    </>
                });
                return <>
                    <div className="gac-ai-results-modal__phrase"><strong>Phrase:</strong> { phrase }</div>
                    { plagData }
                </>;
            });
        };

        return <>
            <div className = 'gac-ai-results-modal__title'>Plagiarism score: <span>{plag_score}%</span></div>
            <div className="gac-ai-results-modal__table">
                { getTableHead('Matched text') }
                { getBlocks() }
            </div>
        </>;
    };

    return <div className = 'gac-ai-results-modal'>
        <span className = 'gac-close-modal' onClick = { closeModal }/>
        { getAiHtml() }
        { getPlagiarismHtml() }
    </div> ;
};