// Core
import { call, put, select } from 'redux-saga/effects';

// Instruments
import {
    api,
    authStr,
    isStage,
    getKeys,
    getCurrencies,
    getCompanyBranding,
    getPusherData,
    getBundlesRef,
    getUserDetails
} from '../../../../helpers/api';
import { uiActions } from '../../../ui/actions';
import { uiActionsAsync } from "../../../ui/saga/asyncActions";
import { userActions } from "../../../user/actions";
import { notifications } from "../../../../helpers/notifications";
import { userActionsAsync } from "../../../user/saga/asyncActions";
import { paymentsActions } from "../../actions";
import { listsActions } from "../../../lists/actions";
import { purchaseTrack, getAmountByCurrency } from "../../../../helpers/helpers";

export function* callStripeCompleteWorker ({ payload: { data: obj, navigate }}) {
    try {
        yield put(uiActions.enableFetching());
        const { privateKey, publicKey } = yield select(getKeys);
        const account_uid = obj.account_uid;
        const { sessionID } = yield select(getPusherData);

        const response = yield call(fetch, `${api}/${publicKey}/v1/stripe/pay/complete`,{
            method: 'PUT',
            headers: {
                'Authorization': `${authStr} ${privateKey}`,
                'Content-Type':  'application/json',
            },
            body: JSON.stringify({
                'payment_method': 'stripe',
                account_uid,
                pusherData: {
                    sessionID
                },
                ...obj,
            }),
        });
        const { response: data, error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }

        if ( obj.type === 'project' ) {
            navigate('/current-projects');
            yield put( listsActions.setListsState('orderList', []));
            yield put(userActions.updateOrderCount(account_uid,'0'));
            yield put(uiActions.setUiState('notification', notifications.orderSuccess));
            yield put(paymentsActions.setPaymentsState('stripe', {}));
        } else if ( obj.type === 'bundle' ) {
            const bundlesRef = yield select(getBundlesRef);
            if ( bundlesRef === 'transactions' ) {
                navigate('/transactions');
                yield put(uiActions.setUiState('bundlesRef', null));
            } else {
                navigate('/order/confirm');
            }
            yield put(userActions.setUserState('activeBundleAccount', null));
            yield put(userActionsAsync.fetchBalanceAsync(obj.account_uid));
            yield put(paymentsActions.setPaymentsState('bundlesStripe', {}));
        }

        if ( !isStage ) {
            const currencies = yield select(getCurrencies);
            const { host } = yield select(getCompanyBranding);
            const { email } = yield select(getUserDetails);
            let { added_funds: { currency_code, amount, payment_method }} = data;
            const { amount_usd, rate } = getAmountByCurrency(amount, currency_code, currencies);

            purchaseTrack(amount_usd, host, email);
            yield put(uiActionsAsync.gaTrackingDebugAsync({
                amount: `${amount}`,
                currency: currency_code,
                email,
                items: `type: ${obj.type}`,
                notes: `host: ${host}, amount_usd: ${amount_usd}, rate: ${rate}, payment_method: ${payment_method}, account_uid: ${account_uid}, gtag: ${window.gtag}, url: ${window.location.href}, params: ${window.location.search}` }));
        }
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
        yield put(uiActions.setUiState('notification', {
            msg: error.message,
            type: 'error',
            fn: null,
        }));
    } finally {
        yield put(uiActions.disableFetching());
        yield put(uiActions.setUiState('isBlocking', false));
    }
}
