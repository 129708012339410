// Core
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { uiActions } from "../bus/ui/actions";
import { uiActionsAsync } from "../bus/ui/saga/asyncActions";

export const useUi = () => {
    const selector = (state) => state.ui;
    const ui = useSelector(selector);
    const dispatch = useDispatch();

    const initialize = (data) => {
        dispatch(uiActions.initialize(data));
    };
    const initializeSuccess = () => {
        dispatch(uiActions.initializeSuccess());
    };
    const enableFetching = () => {
        dispatch(uiActions.enableFetching());
    };
    const disableFetching = () => {
        dispatch(uiActions.disableFetching());
    };
    const setUiState = (prop, value) => {
        dispatch(uiActions.setUiState(prop, value));
    };
    const locationChange = () => {
        dispatch(uiActions.locationChange());
    };

    const uploadBase64Async = (file, action) => {
        dispatch(uiActionsAsync.uploadBase64Async(file, action));
    };
    const uploadFileAsync = (file, action) => {
        dispatch(uiActionsAsync.uploadFileAsync(file, action));
    };
    const uploadFileFromUrlAsync = (data) => {
        dispatch(uiActionsAsync.uploadFileFromUrlAsync(data));
    };
    const reportErrorAsync = (data) => {
        dispatch(uiActionsAsync.reportErrorAsync(data));
    };
    const checkBriefAsync = (brief) => {
        dispatch(uiActionsAsync.checkBriefAsync(brief));
    };
    const validateReferralCodeAsync = (code) => {
        dispatch(uiActionsAsync.validateReferralCodeAsync(code));
    };
    const gaTrackingDebugAsync = (data) => {
        dispatch(uiActionsAsync.gaTrackingDebugAsync(data));
    };

    return {
        ...ui,
        initialize,
        initializeSuccess,
        enableFetching,
        disableFetching,
        setUiState,
        locationChange,
        uploadBase64Async,
        uploadFileAsync,
        uploadFileFromUrlAsync,
        reportErrorAsync,
        checkBriefAsync,
        validateReferralCodeAsync,
        gaTrackingDebugAsync
    };
};
