// Components
import GearBig from "../icons/GearBig";

export const MaintenanceScreen = () => {
    return (
        <div className="gac-maintenance-screen">
            <div className="gac-maintenance-screen__image"><GearBig /></div>
            <div className="gac-maintenance-screen__text">Updating the platform. It may take a few minutes, please bear with us…</div>
        </div>
    );
};
