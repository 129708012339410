// Core
import { useState, useEffect, useRef } from "react";

// Components

import { TransactionFeeText } from '../../../components/common/TransactionFeeText';
import { SupportPlan } from './';

// Icons

import monthlyIcon from '../../../theme/images/icons/monthly.svg';
import quarterlyIcon from '../../../theme/images/icons/quarterly.svg';
import annuallyIcon from '../../../theme/images/icons/annually.svg';

const supportIcons = {
    'monthly': monthlyIcon,
    'quarterly': quarterlyIcon,
    'annually': annuallyIcon,
}

/* Black friday discount */
import discountImage from "../../../theme/images/black_friday_discount-wd-2023.svg"

// Hooks

import { useUser } from "../../../hooks/useUser";
import { useLists } from "../../../hooks/useLists";

// Instruments
import {
    getAppTitle,
    getCurrencyCode,
    onOutsideElClick,
} from "../../../helpers/helpers";

import { isEmpty, includes } from "ramda";
import animateScrollTo from "animated-scroll-to";

export const PlansSupport = () => {
    /* Ref */
    const listRef = useRef(null);

    const [isLevelsList, setIsLevelsList] = useState(false);
    const isDiscount = false; // Black friday discount

    /* Hooks */

    const { company, details: { currency }, fetchUserDetailsAsync } = useUser();

    const { staticData: { subscriptions } } = useLists();

    const currencyCode = getCurrencyCode(currency);

    useEffect(() => {
        fetchUserDetailsAsync();
        document.title = getAppTitle(`Ongoing suppirt pricing`, company);
        document.body.classList.add('gac-body-plans-page');
        animateScrollTo(0);

        return () => {
            document.body.classList.remove('gac-body-plans-page');
        };
    }, []);

    useEffect(() => {
        const onOutsideClick = (e) => {
            onOutsideElClick(e, listRef.current, () => { setIsLevelsList(false) });
        };

        if (isLevelsList) {
            document.addEventListener('click', onOutsideClick, true);
            document.addEventListener('touchstart', onOutsideClick, true);
        }

        return () => {
            document.removeEventListener('click', onOutsideClick, true);
            document.removeEventListener('touchstart', onOutsideClick, true);
        };
    }, [isLevelsList]);

    if (isEmpty(subscriptions['webbuilding'])) return null;

    /* Plans */

    const getPlansData = () => {
        return (
            subscriptions['webbuilding']
                .filter(o => o.currency === currencyCode.toUpperCase())
                .filter(o => includes('Ongoing support', o.name))
                .filter(o => o.billing_interval_code !== 'semi-annually')
                .map((o, index) => (
                    <SupportPlan
                        key={index} {...o}
                        icon={supportIcons[o.billing_interval_code]}
                        interval={o.billing_interval_code}
                        currency={currency}
                        currencyCode={currencyCode}
                        isDiscount={isDiscount}
                        middle={o.hours.middle}
                        senior={o.hours.senior}
                    />
                ))
        );
    };

    /* Html */

    const getDiscountImage = () => {
        if (!isDiscount) return null;

        return <div className="gac-design-plans__discount" style={{ marginBottom: '32px' }}>
            <img src={discountImage} data-value='annually' alt="Discount" onClick={onIntervalChange} />
        </div>;
    };

    return (

        <div className="gac-plans-page">
            <div className="gac-plans-page__title">Now, let’s <span>pick</span> the right plan</div>
            {getDiscountImage()}
            <div className='gac-sub-plans gac-sub-plans--support'>
                {getPlansData()}
            </div>
            {<TransactionFeeText paddingBottom={30} paddingTop={30} currency={getCurrencyCode(currency)} />}
        </div>
    );
};

