// Core
import { useEffect, useLayoutEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { CSSTransition } from 'react-transition-group';
import * as Sentry from "@sentry/react";

// Components
import { Private } from './Private';
import { Public } from './Public';
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import { Spinner } from '../components/common/Spinner';
import { ModalsHolder } from '../components/ModalsHolder';
import { Notification } from "../components/Notification";
import { ScrollUpBtn } from "../components/common/ScrollUpBtn"
import { MaintenanceScreen } from "../components/MaintenanceScreen";
import { Crisp } from "crisp-sdk-web";
import ReactPixel from 'react-facebook-pixel';
import ReactGA from "react-ga4";

// Hooks
import { useAuth } from "../hooks/useAuth";
import { useLists } from "../hooks/useLists";
import { useModals } from "../hooks/useModals";
import { useUi } from "../hooks/useUi";
import { useUser } from "../hooks/useUser";

// Instruments
import {
    isSmaller,
    isWhiteLabel,
    isEpiic,
    isProd,
    getPaypalInitOpts,
    changeFavicon,
    loadThemeCss,
    isFetching,
    redirectToProperPath,
} from "../helpers/helpers";
import { includes, isEmpty, isNil } from "ramda";
import { paypalKey, favicon, epiicFavicon, isStage, getCrispId, getGA4Id } from "../helpers/api";

// Css
import 'react-day-picker/dist/style.css';
import '../theme/scss/styles.scss';
import '../theme/scss/svg.scss';
import 'swiper/css';
import 'swiper/css/navigation';

export const App = () => {
    /* Hooks */
    const { keys: { accountId }, logout } = useAuth();
    const { staticData, getStaticDataAsync } = useLists();
    const {
        fetching,
        notification,
        isInit,
        isAuth,
        isMaintenance,
        isBlocking,
        isMobile,
        isMobileLogo,
        isCompanyLoaded,
        initialize,
        locationChange,
        setUiState,
        reportErrorAsync,
    } = useUi();
    const { msg } = notification;
    const { isRequestRevisionModalDisabled, setModalState } = useModals();
    const { details: { email }, company: { branding: { host, brand_color, white_label }}} = useUser();
    const { pathname, search } = useLocation();
    const navigate = useNavigate();

    const loadTheme = () => {
        const isBrandColor = !isNil(brand_color) && white_label;

        if ( isEpiic(host) ) {
            loadThemeCss(process.env.PUBLIC_URL, '#00A99D');

            const epiic = document.createElement("link");
            epiic.rel = 'stylesheet';
            epiic.type = 'text/css';
            epiic.href = `${process.env.PUBLIC_URL}/static/css/epiic.css`;
            document.body.appendChild(epiic);
        }
        if ( isBrandColor ) {
            loadThemeCss(process.env.PUBLIC_URL, brand_color);
        }
    };

    /* Actions */
    const initGA4 = () => {
        window.dataLayer = window.dataLayer || [];

        ReactGA.initialize(getGA4Id());
    };

    useEffect(() => {
        initialize(search.substring(1));
        setUiState('initPath', pathname);
        redirectToProperPath(pathname, navigate);
        if ( !isEmpty(search) ) setUiState('initSearch', search);
        if ( isSmaller(768) ) setUiState('isMobile', true);
        if ( isSmaller(1024) ) setUiState('isMobileLogo', true);

        if ( includes('privateKey', search) && !includes('/company/new', pathname) ) {
            navigate(pathname)
        }

        if ( !includes('/apply/designers', pathname) && !isStage ) {
            initGA4();
        }

        getStaticDataAsync();

        if ( !isWhiteLabel() ) {
            loadTheme();
        }
        if ( isProd ) {
            changeFavicon( isEpiic() ? epiicFavicon : favicon );
            Crisp.configure(getCrispId());
        }
    }, []);
    useEffect(() => {
        locationChange();
        setModalState('isRequestRevisionModalDisabled', false);
        if ( !isStage ) ReactPixel.pageView();
    }, [pathname]);
    useEffect(() => {
        if ( isAuth ) {
            if ( pathname === '/logout' ) {
                logout(navigate);
            }
        }
    }, [isAuth]);
    useEffect(() => {
        if ( isCompanyLoaded ) {
            loadTheme();
        }
    }, [isCompanyLoaded]);
    useEffect(() => {
        if ( isAuth && !isNil(msg) && includes('fetch', msg) ) {
            reportErrorAsync({
                url: window.location.href,
                error: msg,
                account: accountId,
                email,
            });
        }
    }, [msg]);
    useEffect(() => {
        const onResize = () => {
            if ( isMobile && !isSmaller(768) ) {
                setUiState('isMobile', isSmaller(768));
            }
            if ( !isMobile && isSmaller(768) ) {
                setUiState('isMobile', isSmaller(768));
            }
            if ( isMobileLogo && !isSmaller(1024) ) { // black friday banner in header = 1200
                setUiState('isMobileLogo', isSmaller(1024));
            }
            if ( !isMobileLogo && isSmaller(1024) ) { // black friday banner in header = 1200
                setUiState('isMobileLogo', isSmaller(1024));
            }
        };
        window.addEventListener('resize', onResize, true);

        return () => {
            window.removeEventListener('resize', onResize, true);
        }
    }, [isMobile, isMobileLogo, setUiState]);

    if ( !isInit || isNil(staticData) ) {
        return <Sentry.ErrorBoundary>
            <Spinner isSpinning = { true }/>
        </Sentry.ErrorBoundary>;
    }

    /* Html */
    const getPrivate = () => <PayPalScriptProvider options = { getPaypalInitOpts(paypalKey) }><Private/></PayPalScriptProvider> ;

    return <Sentry.ErrorBoundary>
        <Header/>
        { isAuth ? getPrivate() : <Public/> }
        <Footer/>
        <ModalsHolder/>
        <ScrollUpBtn/>
        <Spinner isSpinning = { isFetching(fetching) }/>
        <CSSTransition in = { !isNil(msg) } timeout = { 300 } exit = { false } unmountOnExit classNames = 'gac-opacity-300'>
            <Notification />
        </CSSTransition>
        { isBlocking && <div className = 'gac-blocking-overlay'/> }
        { isMaintenance && <MaintenanceScreen/> }
    </Sentry.ErrorBoundary> ;
};